import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  RadioGroup,
  Select,
  Stack,
  Text,
  Textarea,
  useRadio,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { PiDogThin } from "react-icons/pi";
import { IAccountItem } from "./KsStatisAccount";
import {
  IAccountProps,
  getAccount,
  postKsExpensesAccountTransfer,
} from "../../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

interface KsAccountTransferProps {
  isOpen: boolean;
  onClose: () => void;
}
function RadioCard(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label">
      <Input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "messenger.50",
          color: "white",
          borderColor: "messenger.50",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        width="100%"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function KsStatisAccountTransfer({
  isOpen,
  onClose,
}: KsAccountTransferProps) {
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>(
    undefined
  );
  const { getRadioProps } = useRadioGroup({
    name: "account",
    onChange: setSelectedAccount,
    value: selectedAccount,
  });
  const { register, control, handleSubmit, reset } = useForm<IAccountProps>({});
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(postKsExpensesAccountTransfer, {
    onSuccess: () => {
      toast({
        title: "결제수단 등록!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`]);
      queryClient.refetchQueries([`expenses`, `expense-account-transfer`]);
      reset();
      onClose();
    },
  });
  const onSubmit = (data: IAccountProps) => {
    if (!selectedAccount) {
      alert("계좌를 선택해주세요.");
      return;
    }
    data.withdrawalPk = Number(selectedAccount);
    data.depositPk = Number(data.depositPk);
    mutation.mutate(data);
  };
  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>계좌이체</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <RadioGroup onChange={setSelectedAccount} value={selectedAccount}>
            <List spacing={2}>
              {getAccounts &&
                getAccounts.map((account) => {
                  const radio = getRadioProps({ value: String(account.pk) });
                  return (
                    <ListItem key={account.pk}>
                      <RadioCard {...radio}>
                        <Text fontWeight="bold">{account.account_name}</Text>
                        <Box as="span" color="gray.600" fontSize="md">
                          {Number(account.amount).toLocaleString()}원
                        </Box>
                      </RadioCard>
                    </ListItem>
                  );
                })}
            </List>
          </RadioGroup>
          {selectedAccount && (
            <Box as={"form"} onSubmit={handleSubmit(onSubmit)}>
              <FormControl mt={4}>
                <FormLabel fontSize={"sm"} color={"gray.500"}>
                  얼마나 이체할까요?
                </FormLabel>
                <Controller
                  control={control}
                  name="amount"
                  render={({ field }) => (
                    <NumberInput
                      step={5000}
                      mt={2}
                      onChange={(valueAsString, valueAsNumber) =>
                        field.onChange(valueAsNumber)
                      }
                      onBlur={field.onBlur}
                      value={field.value || ""}
                    >
                      <NumberInputField
                        fontSize={"sm"}
                        placeholder="이체 금액"
                      />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
                <FormLabel mt={4} fontSize={"sm"} color={"gray.500"}>
                  어디로 이체할까요?
                </FormLabel>
                <Controller
                  name="depositPk"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      fontSize={"sm"}
                      color={"gray.500"}
                      placeholder="계좌번호 선택"
                    >
                      {getAccounts &&
                        getAccounts
                          .filter(
                            (account) => account.pk !== Number(selectedAccount)
                          )
                          .map((account) => (
                            <option key={account.pk} value={account.pk}>
                              {account.account_name} - {account.account_number}
                            </option>
                          ))}
                    </Select>
                  )}
                />
                <Textarea
                  mt={4}
                  {...register(`notes`)}
                  fontSize={"sm"}
                  placeholder="왜 이체하나요?"
                />
                <Button
                  isLoading={mutation.isLoading}
                  colorScheme="blue"
                  mt={5}
                  type="submit"
                >
                  이체하기
                </Button>
              </FormControl>
            </Box>
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            mt={5}
          >
            <PiDogThin />
            <Text>kongsam account transfer</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
