import Cookie from "js-cookie";
import axios from "axios";
import { QueryFunctionContext } from "@tanstack/react-query";
import { ICalendar, IDone, IRoomPublic } from "./types";
import {
  IAccount,
  IAccountItem,
} from "./components/ksadmin/KsStatis/KsStatisAccount";
import { IOutlay } from "./components/ksadmin/KsStatis/KsStatisOutlay";
import { formatDate } from "./lib/utils";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/v1/"
      : "https://backend.kongsam.com/api/v1/",
  withCredentials: true,
});

export const getMe = () =>
  instance
    .get(`users/me`)
    .then((response) => response.data)
    .catch((error) => {
      let errorMsg = "";
      if (error.response) {
        errorMsg = `user 로그인 체크 에러`;
      } else {
        errorMsg = `user 서버 에러`;
      }
      return Promise.reject(errorMsg);
    });

export interface IUserVariables {
  pk?: number;
  name?: string;
  phone?: string;
  address?: string;
  username: string;
  new_username?: string;
  user_avatar?: string;
}
export const getEditMe = (variables: IUserVariables) =>
  instance
    .put(`users/@${variables.username}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export interface ISingUpVariables {
  name: string;
  username: string;
  password?: string;
  phone: string;
  is_guest_user?: boolean;
}
export const userSignup = (variables: ISingUpVariables) =>
  instance
    .post(`users/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export interface IUsernameLoginVariables {
  username: string;
  password: string;
}
export interface ISuccessMessage {
  message?: string;
  location?: string;
}
export interface IIsErrorMessage {
  message?: string;
  location?: string;
  error?: string;
}

export const usernameLogIn = (variables: IUsernameLoginVariables) =>
  instance
    .post(`users/log-in`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const logOut = () =>
  instance
    .post("users/log-out", null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

/* eslint-disable */
export const getKongsamUsersList = ({
  pageParam,
  queryKey,
}: QueryFunctionContext) => {
  const [_key, queryString] = queryKey as [string, string, string];
  const { option, term } = JSON.parse(queryString ?? "");
  return instance
    .get(`users?${option}=${term}&page=${pageParam}`)
    .then((response) => response.data);
};
export const getKongsamUsernameDetail = ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, username] = queryKey;
  return instance.get(`users/@${username}`).then((response) => response.data);
};

export const getPetsInfo = ({ queryKey }: QueryFunctionContext) => {
  const [_, _subKey, username] = queryKey;
  return instance
    .get(`pets/ks-pets/@${username}`)
    .then((response) => response.data);
};
export const getUserPetDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, petPk] = queryKey;
  return instance.get(`pets/${petPk}`).then((response) => response.data);
};

export const getPetDoneList = ({
  queryKey,
  pageParam,
}: QueryFunctionContext) => {
  const [_, key, petPk, filter] = queryKey;
  const page = pageParam || 1;
  return instance
    .get(`dones/pet-dones/${petPk}?filter=${filter}&per_page=25&page=${page}`)
    .then((response) => response.data);
};

export const getKongsamPetsList = ({
  pageParam,
  queryKey,
}: QueryFunctionContext) => {
  const [_key, queryString] = queryKey as [string, string, string];
  const { option, term } = JSON.parse(queryString ?? "");
  return instance
    .get(`pets?${option}=${term}&page=${pageParam}`)
    .then((response) => response.data);
};
export const getEditPet = (variables: IUploadPetVariables) =>
  instance
    .put(`pets/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const deletePet = (petPk: number) =>
  instance
    .delete(`pets/${petPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

/* 미용예약 : 선택날짜에 예약되어있는 시간 리스트 호출 */
type CheckBookingQueryKey = [string, Date[]?];
export const getBookingDateTimeCheck = ({
  queryKey,
}: QueryFunctionContext<CheckBookingQueryKey>) => {
  const [_, dates] = queryKey;
  if (dates) {
    const [firstDate] = dates;
    /* 'fr-CA' 로케일은 ISO 8601 날짜 형식 (YYYY-MM-DD)을 사용 */
    const checkIn = firstDate.toLocaleDateString("fr-CA");
    return instance
      .get(`bookings/datetime-check?booking_time=${checkIn}`)
      .then((response) => response.data);
  }
};

export interface IBookingVariables {
  pk: number;
  booking_time: string;
  face_style: string;
  body_length_clip: string;
  body_length_spotting: string;
  body_length_scissors_cut: string;
  leg_length_clip: string;
  leg_length_spotting: string;
  leg_length_scissors_cut: string;
  ear_shape: string;
  foot_shape: string;
  foot_shape_Bath_basics: string;
  tail_shape: string;
  degree_of_tangles: string;
  condition: string;
  beauty_service: string;
  host_approval_state: string;
  owner: number;
  user_pets: number[];
}
export const uploadBeautyBooking = (variables: IBookingVariables) =>
  instance
    .post(`bookings/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getKSUsersPetsList = () =>
  instance.get(`users/ks-users-pets`).then((response) => response.data);

export const getKongsamResList = ({ pageParam = "" }) => {
  return instance
    .get(`bookings?${pageParam}`)
    .then((response) => response.data);
};

export const getBookingDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, bookingPk] = queryKey;
  return instance
    .get(`bookings/${bookingPk}`)
    .then((response) => response.data);
};
export const getBookingUserDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, username, petPk] = queryKey;
  return instance
    .get(`bookings/@${username}/${petPk}`)
    .then((response) => response.data);
};

export interface approvalStateVariables {
  bookingPk: number;
  host_approval_state?: string;
}
export const patchApprovalState = (variables: approvalStateVariables) => {
  return instance
    .put(`bookings/${variables.bookingPk}/approval-state`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
interface IUserPetProps {
  pk: number;
}
interface IOwnerProps {
  pk: number;
}
export interface IBookingUpdateVariables {
  pk: number;
  booking_time: string;
  face_style: string;
  body_length_clip: string;
  body_length_spotting: string;
  body_length_scissors_cut: string;
  leg_length_clip: string;
  leg_length_spotting: string;
  leg_length_scissors_cut: string;
  ear_shape: string;
  foot_shape: string;
  foot_shape_Bath_basics: string;
  tail_shape: string;
  degree_of_tangles: string;
  condition: string;
  beauty_service: string;
  host_approval_state: string;
  owner: IUserPetProps;
  user_pets: IOwnerProps[];
  old_owner: number;
  old_user_pets: number[];
}
export const putBooking = (variables: IBookingUpdateVariables) =>
  instance
    .put(`bookings/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const deltedBooking = (bookingPk: number) =>
  instance
    .delete(`bookings/${bookingPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const getKongsamCancelleNoshowList = ({
  pageParam,
  queryKey,
}: QueryFunctionContext) => {
  const [_key, _subKey, queryString] = queryKey as [string, string, string];
  const { option, term } = JSON.parse(queryString ?? "");
  return instance
    .get(`bookings/cancelle-noshow?${option}=${term}&page=${pageParam}`)
    .then((response) => response.data);
};
export const getKongsamUserLogList = ({
  pageParam,
  queryKey,
}: QueryFunctionContext) => {
  const [_key, _subKey, queryString] = queryKey as [string, string, string];
  const { option, term } = JSON.parse(queryString ?? "");
  return instance
    .get(`users/user-session-statistics?${option}=${term}&page=${pageParam}`)
    .then((response) => response.data);
};
export const getQuestion = () =>
  instance.get(`pets/question`).then((response) => response.data);

export interface IQuestion {
  pk: number;
  name: string;
}
export interface IUploadPetVariables {
  pk: number;
  name: string;
  gender: string;
  breed: string;
  neutering: string;
  weigth: string;
  birthday: string;
  question1?: string;
  question2?: string;
  question3?: string;
  question4?: string;
  question5?: string;
  question6: IQuestion[];
  question7: IQuestion[];
  significant: string;
  owner?: number;
  pet_avatar?: string;
}
export const uploadPet = (variables: IUploadPetVariables) =>
  instance
    .post(`pets/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const getKongsamDoneList = ({
  pageParam,
  queryKey,
}: QueryFunctionContext) => {
  const [_key, queryString] = queryKey as [string, string];
  const { option, term } = JSON.parse(queryString ?? "");
  return instance
    .get(`dones?${option}=${term}&page=${pageParam}`)
    .then((response) => response.data);
};

export const createDoneRes = (variables: IDone) => {
  return instance
    .post(`dones/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const getKongsamDoneDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, donePk] = queryKey;
  return instance.get(`dones/${donePk}`).then((response) => response.data);
};
export const putKongsamDoneEdit = (variables: IDone) => {
  return instance
    .put(`dones/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const deltedDone = (pk: number) =>
  instance
    .delete(`dones/${pk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getKongsamStatistics = () => {
  return instance.get(`users/statistics`).then((response) => response.data);
};
export const getKongsamStatistics2 = () => {
  return instance.get(`users/statistics2`).then((response) => response.data);
};
export const getKongsamStatistics3 = () => {
  return instance.get(`users/statistics3`).then((response) => response.data);
};
export const getKongsamGraphStatistics = () => {
  return instance
    .get(`users/graph-statistics`)
    .then((response) => response.data);
};

export const getKSDoneList = ({ queryKey }: QueryFunctionContext) => {
  const [_key, _subKey, start, end] = queryKey as [
    string,
    string,
    string,
    string
  ];
  return instance
    .get(`dones/calendar?start=${start}&end=${end}`)
    .then((response) => response.data);
};
export const getCalendarDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, groupPk] = queryKey;
  return instance.get(`calendars/${groupPk}`).then((response) => response.data);
};
export const putKongsamCalendarEdit = (variables: ICalendar) => {
  return instance
    .put(`calendars/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const deltedCalendar = (pk: number) =>
  instance
    .delete(`calendars/${pk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const createCalendar = (variables: ICalendar) => {
  return instance
    .post(`calendars/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};

/* 1.이미지 id, url 만들기 */
export const getUploadURL = () =>
  instance
    .post(`medias/photos/get-url`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

/* 2.id,url 생성 후 이미지 업로드 */
export interface IUploadImageVarialbes {
  file: FileList;
  uploadURL: string;
}
export const uploadImage = ({ file, uploadURL }: IUploadImageVarialbes) => {
  const form = new FormData();
  form.append("file", file[0]);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

/* 3. 이미지 업로드 후 룸에 이미지 전송 */
export interface ICreatePhotoVarialbes {
  description: string;
  file: string;
  roomPk: string;
}
export const createPhoto = ({
  description,
  file,
  roomPk,
}: ICreatePhotoVarialbes) =>
  instance
    .post(
      `rooms/${roomPk}/photos`,
      { description, file },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const uploadRoom = (variables: IRoomPublic) =>
  instance
    .post(`rooms/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getRooms = ({ queryKey, pageParam }: QueryFunctionContext) => {
  const [_, filter] = queryKey;
  const filterStr = JSON.stringify(filter);
  const page = pageParam || 1;
  return instance
    .get(
      `rooms?filter=${encodeURIComponent(filterStr)}&per_page=36&page=${page}`
    )
    .then((response) => response.data);
};

export const getIsRoomCheck = () => {
  return instance.get("dones/rooms-check").then((response) => response.data);
};

export const getRoom = ({ queryKey }: QueryFunctionContext) => {
  const [_, pk] = queryKey;
  return instance.get(`rooms/${pk}`).then((response) => response.data);
};

export interface IResMessageProps {
  pk: number;
  name: string;
  phone: string;
  call_time: string;
  description: string;
  msg_beauty_service: string;
  msg_question6: IQuestion[];
  msg_question7: IQuestion[];
}
export const createCallMsg = (variables: IResMessageProps) =>
  instance
    .post(`resmessages/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IResBookingMessageProps {
  name: string;
  phone: string;
  call_time: string;
  description: string;
}
export const createBookingCallMsg = (variables: IResBookingMessageProps) =>
  instance
    .post(`resmessages/booking-resmessages`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface ICallInfoProps {
  name: string;
  phone: string;
}
export const getCallMsg = (variables: ICallInfoProps) =>
  instance
    .get(`resmessages/call-info`, {
      params: variables,
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const putCallMsg = (variables: IResMessageProps) =>
  instance
    .put(`resmessages/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IMsgStateProps {
  pk: number;
  state: boolean;
}
export const putCallState = (variables: IMsgStateProps) =>
  instance
    .put(`resmessages/${variables.pk}/call-state`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const deleteCallMsg = (pk: number) =>
  instance
    .delete(`resmessages/${pk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getCall = () =>
  instance
    .get(`resmessages/call-msg`)
    .then((response) => response.data)
    .catch((error) => {
      let errorMsg = "";
      if (error.response) {
        errorMsg = `call-msg 로그인 체크 에러`;
      } else {
        errorMsg = `call-msg 서버 에러`;
      }
      return Promise.reject(errorMsg);
    });

/* 이미지 삭제 */
export interface IImgDeleteVarialbes {
  imgID: string;
}
export const deltedPhoto = ({ imgID }: IImgDeleteVarialbes) =>
  instance
    .delete(`medias/photos/delete-url/${imgID}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const deltedMediasPhoto = (imgPk: number) =>
  instance
    .delete(`medias/photos/${imgPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const deleteRoom = (roomPk: number) =>
  instance
    .delete(`rooms/${roomPk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export interface doneRoomExceptStateVariables {
  donePk: number;
  isRoomExcept?: boolean;
}
export const doneRoomExceptState = (
  variables: doneRoomExceptStateVariables
) => {
  return instance
    .put(`dones/${variables.donePk}/room-state`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const postKsAccount = (variables: IAccount) =>
  instance
    .post(`expenses/expense-account`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export interface ICategorytemProps {
  pk?: number;
  name: string;
  notes: string;
}
export const postKsOutlayCategory = (variables: ICategorytemProps) =>
  instance
    .post(`expenses/expense-category`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const putKsOutlayCategory = (variables: ICategorytemProps) => {
  return instance
    .put(`expenses/expense-category/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const deleteKsOutlayCategory = (pk: number) =>
  instance
    .delete(`expenses/expense-category/${pk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getExpenses = ({ queryKey }: QueryFunctionContext) => {
  const [_key, queryString] = queryKey as [string, string];
  const { startDate, endDate, term, option, categoryOption, accountOption } =
    JSON.parse(queryString ?? "");
  let queryUrl = `expenses/?startDate=${startDate}&endDate=${endDate}&term=${term}&option=${option}`;
  if (categoryOption) {
    queryUrl += `&option2=${categoryOption}`;
  }
  if (accountOption) {
    queryUrl += `&option3=${accountOption}`;
  }
  return instance.get(queryUrl).then((response) => response.data);
};
interface IOptionProps {
  startDate: string;
  endDate: string;
  term: string;
  option: string;
  categoryOption: string;
  accountOption: string;
}
export const expensesFileDownload = ({
  startDate,
  endDate,
  term,
  option,
  categoryOption,
  accountOption,
}: IOptionProps) => {
  let queryUrl = `expenses/expense-file-download?startDate=${startDate}&endDate=${endDate}&term=${term}&option=${option}`;
  if (categoryOption) {
    queryUrl += `&option2=${categoryOption}`;
  }
  if (accountOption) {
    queryUrl += `&option3=${accountOption}`;
  }
  return instance.get(queryUrl, { responseType: "blob" }).then((response) => {
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const filename = `kongsam_${formatDate(new Date())}.xlsx`;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  });
};
export const getExpenseDetail = ({ queryKey }: QueryFunctionContext) => {
  const [_, expensePk] = queryKey;
  return instance
    .get(`expenses/${expensePk}`)
    .then((response) => response.data);
};
export interface IExpenseProps {
  pk: number;
  category: number;
  account: number;
  amount: number;
  notes: string;
  income_expenditure_status: string;
  date: string;
  cash_receipt: boolean;
}
export const putExpenseDetail = (variables: IExpenseProps) => {
  return instance
    .put(`expenses/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const deleteExpenseDetail = (expensePk: number) =>
  instance
    .delete(`expenses/${expensePk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const getOutlayCategory = () =>
  instance.get(`expenses/expense-category`).then((response) => response.data);
export const getAccount = () =>
  instance.get(`expenses/expense-account`).then((response) => response.data);
export const getCategoryAccount = () =>
  instance
    .get(`expenses/expense-account/category`)
    .then((response) => response.data);

export const postKsExpenses = (variables: IOutlay) =>
  instance
    .post(`expenses/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const putKsAccount = (variables: IAccountItem) => {
  return instance
    .put(`expenses/expense-account/${variables.pk}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};
export const deleteKsAccount = (pk: number) =>
  instance
    .delete(`expenses/expense-account/${pk}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IAccountProps {
  withdrawalPk: number;
  depositPk?: number;
  account_name: string;
  amount: number;
  notes: string;
}
export const postKsExpensesAccountTransfer = (variables: IAccountProps) =>
  instance
    .post(`expenses/expense-account-transfer`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const getTodayBookings = () => {
  return instance
    .get("bookings/today-bookings")
    .then((response) => response.data);
};

export const getSitemap = () =>
  instance
    .get(`common/kongsam-my-sitemap.xml`, {
      headers: { Accept: "application/xml" },
    })
    .then((response) => {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      const serializer = new XMLSerializer();
      return serializer.serializeToString(xmlDoc);
    });

export interface IUploadAvatarImageVarialbes {
  file: string;
  uploadURL: string;
}
export const uploadAvatarImage = ({
  file,
  uploadURL,
}: IUploadAvatarImageVarialbes) => {
  const form = new FormData();
  form.append("file", file);
  return axios
    .post(uploadURL, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export interface IUserEditPassVariables {
  old_password: string;
  new_password: string;
  new_password_chk: string;
}
export const getEditPassword = ({
  old_password,
  new_password,
}: IUserEditPassVariables) =>
  instance
    .put(
      `users/change-password`,
      { old_password, new_password },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const getKsBookingHistoryUsername = ({
  pageParam = "",
  queryKey,
}: QueryFunctionContext) => {
  const [_, username] = queryKey;
  return instance
    .get(`bookings/@${username}/res-history?${pageParam}`)
    .then((response) => response.data);
};

export const getKsPaymentList = ({
  pageParam = "",
  queryKey,
}: QueryFunctionContext) => {
  const [_, username] = queryKey;
  return instance
    .get(`dones/@${username}?${pageParam}`)
    .then((response) => response.data);
};

export interface IUserConfirmationVariables {
  phone: number;
  username: string;
  pname: string;
}
export const getUserConfirmation = (variables: IUserConfirmationVariables) =>
  instance
    .post(`users/user-check`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUserNewPassVariables {
  username: string;
  new_password: string;
  new_password_chk: string;
}
export const getUserNewPassword = (variables: IUserNewPassVariables) =>
  instance
    .put(`users/new-password`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
/* 펫 아바타 이미지 업로드 */
export interface ICreatePetAvatarVarialbes {
  pet_avatar: string;
  petPK: number;
}
export const createPetAvatarPhoto = ({
  pet_avatar,
  petPK,
}: ICreatePetAvatarVarialbes) =>
  instance
    .put(
      `pets/${petPK}`,
      { pet_avatar },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
