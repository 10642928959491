import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import useCallMsg from "../../lib/useCallMsg";
import { FaCheck } from "react-icons/fa6";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { IMsgStateProps, putCallState } from "../../api";
import { formatDateTime } from "../../lib/utils";

interface ICallResDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function CallForResDrawer({
  isOpen,
  onClose,
}: ICallResDrawerProps) {
  const { dataCount, callData } = useCallMsg();
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [boxBgColors, setBoxBgColors] = useState<Record<string, string>>({});
  const [successfulItems] = useState<Record<string, boolean>>({});

  const toast = useToast();
  const updateMsgStateMutation = useMutation(putCallState, {
    onSuccess: (_, variables) => {
      setBoxBgColors(() => ({ [variables.pk]: "white" }));
      toast({
        title: "일정 확인",
        status: "success",
      });
    },
  });
  const handleMsgState = (pk: number, checkedState: boolean) => {
    const newData = {
      pk: pk,
      state: checkedState,
    };
    updateMsgStateMutation.mutate(newData as unknown as IMsgStateProps);
  };
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={"sm"}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Notifications</DrawerHeader>
        <DrawerBody>
          {dataCount !== 0 ? (
            callData?.map((item, index) => {
              const itemKey = String(item.pk);
              return (
                <Box
                  key={index}
                  fontSize={"sm"}
                  bg={boxBgColors[itemKey] || "gray.50"}
                  p="4"
                  mb="5"
                  flex="1"
                  shadow="sm"
                  borderWidth="1px"
                  borderRadius="sm"
                  onMouseEnter={() =>
                    !successfulItems[itemKey] && setHoveredItem(itemKey)
                  }
                  onMouseLeave={() =>
                    !successfulItems[itemKey] && setHoveredItem(null)
                  }
                >
                  <VStack align="start" spacing="1" minW={"80%"}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Text wordBreak="break-all" fontWeight="bold">
                        {item.name}
                      </Text>
                      <Box
                        pr={3}
                        key={index}
                        cursor={"pointer"}
                        onClick={() => {
                          const newCheckedState = !checkedItems[itemKey];
                          setCheckedItems({
                            ...checkedItems,
                            [itemKey]: newCheckedState,
                          });
                          handleMsgState(item.pk, newCheckedState);
                        }}
                      >
                        <FaCheck
                          color={
                            checkedItems[itemKey]
                              ? "gray"
                              : hoveredItem === itemKey
                              ? "tomato"
                              : "transparent"
                          }
                          size="25px"
                        />
                      </Box>
                    </Box>
                    <Text wordBreak="break-all" color={"gray.600"}>
                      {item.description}
                    </Text>
                    {item.description !== "미용예약목록에서 회원예약 확인" ? (
                      <>
                        <Text wordBreak="break-all" color={"gray.600"}>
                          서비스 - {item.msg_beauty_service}
                        </Text>
                        <Text wordBreak="break-all" color={"gray.600"}>
                          예민한부분 -{" "}
                          {item.msg_question6.map((q) => q.name).join(", ")}
                        </Text>
                        <Text wordBreak="break-all" color={"gray.600"}>
                          아픈곳 -{" "}
                          {item.msg_question7.map((q) => q.name).join(", ")}
                        </Text>
                      </>
                    ) : null}
                    <Text wordBreak="break-all" color={"gray.600"}>
                      연락처 :{" "}
                      {item?.phone
                        ? item.phone.substring(0, 3) +
                          "-" +
                          item.phone.substring(3, 7) +
                          "-" +
                          item.phone.substring(7, 11)
                        : ""}
                    </Text>
                    <Text wordBreak="break-all" color={"gray.600"}>
                      미용가능일 : {formatDateTime(new Date(item.call_time))}
                    </Text>
                    <Text wordBreak="break-all" color={"gray.600"}>
                      문의일 : {formatDateTime(new Date(item.created_at))}
                    </Text>
                  </VStack>
                </Box>
              );
            })
          ) : (
            <Center height="100%">
              <VStack spacing={4}>
                <Text fontSize={"xl"} color={"gray"}>
                  There is nothing to see here yet.
                </Text>
                <Text fontSize={"xl"}>❤️</Text>
              </VStack>
            </Center>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
