import {
  Box,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getUserPetDetail } from "../../api";
import { PiDogThin } from "react-icons/pi";
import { formatDateWeigthAge } from "../../lib/utils";
import { InfoRow } from "../../utils/RenderFlex";
import { IPet } from "../../types";

interface IPetProfile {
  isOpen: boolean;
  onClose: () => void;
  userPetPk: number;
}

export default function PetInfoModal({
  isOpen,
  onClose,
  userPetPk,
}: IPetProfile) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const yellowColor = useColorModeValue("yellow.500", "yellow.300");

  const { data: pet } = useQuery<IPet>(
    [`pets`, `${userPetPk}`],
    getUserPetDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>
          {pet?.name}_no{pet?.pk} _INFO
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"2xl"}>
            <Stack spacing={{ base: 3, md: 5 }}>
              <Stack
                spacing={{ base: 2, sm: 3 }}
                direction={"column"}
                divider={<StackDivider borderColor={borderColor} />}
              >
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    User Details
                  </Text>
                  <InfoRow label="아이이름" value={pet?.name} />
                  <InfoRow label="성별" value={pet?.gender} />
                  <InfoRow label="중성화" value={pet?.neutering} />
                  <InfoRow label="품종" value={pet?.breed} />
                  <InfoRow label="체중" value={pet?.weigth} />
                  <InfoRow
                    label="생일"
                    value={`${pet?.birthday}/${formatDateWeigthAge(
                      new Date(String(pet?.birthday))
                    )}`}
                  />
                  <InfoRow label="특이사항" value={pet?.significant} />
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    User Details
                  </Text>
                  <InfoRow
                    label="성격이 예민한 편인가요?"
                    value={pet?.question1}
                  />
                  <InfoRow label="가끔 물기도 하나요?" value={pet?.question2} />
                  <InfoRow label="분리불안이 있나요?" value={pet?.question3} />
                  <InfoRow
                    label="미용 후 가려워한적이 있나요?"
                    value={pet?.question4}
                  />
                  <InfoRow
                    label="미용 후 불안해하거나 평소와 달랐나요?"
                    value={pet?.question5}
                  />
                  <InfoRow
                    label="싫어하거나 예민한 부분을 체크해주세요."
                    value={pet?.question6.map((q) => q.name).join(", ")}
                  />
                  <InfoRow
                    label="아픈곳을 체크해 주세요."
                    value={pet?.question7.map((q) => q.name).join(", ")}
                  />
                </Box>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <PiDogThin />
                <Text>kongsam pet info</Text>
              </Stack>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
