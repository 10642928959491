import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { PiDogThin } from "react-icons/pi";
import {
  deltedCalendar,
  getCalendarDetail,
  putKongsamCalendarEdit,
} from "../../api";
import { FormTextarea } from "../FormGroup";
import { useEffect } from "react";
import { TwitterPicker } from "react-color";
import { ICalendar } from "../../types";

interface ICalendarModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupPk: number;
}
export default function CalendarEditModal({
  isOpen,
  onClose,
  groupPk,
}: ICalendarModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<ICalendar>();

  const { data: calendarDetail } = useQuery<ICalendar>(
    [`calendars`, groupPk],
    getCalendarDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  useEffect(() => {
    if (calendarDetail) {
      setValue("pk", groupPk);
      setValue("title", calendarDetail.title);
      setValue("calendar_memo", calendarDetail.calendar_memo);
      setValue("start_date", calendarDetail.start_date);
      setValue("end_date", calendarDetail.end_date);
      setValue("cal_color", calendarDetail.cal_color);
    }
  }, [calendarDetail, groupPk, setValue]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const calendarEditMutation = useMutation(putKongsamCalendarEdit, {
    onSuccess: () => {
      toast({
        title: "일정 수정!",
        status: "success",
      });
      reset();
      onClose();
      queryClient.refetchQueries([`dones`, `calendar`]);
    },
  });
  const onSubmit = (data: ICalendar) => {
    calendarEditMutation.mutate(data);
  };
  const calendarDeleteMutation = useMutation(deltedCalendar, {
    onSuccess: () => {
      toast({
        title: "일정 삭제!",
        status: "success",
      });
      onClose();
      queryClient.refetchQueries([`dones`, `calendar`]);
    },
  });

  const handleCalendarDelete = () => {
    calendarDeleteMutation.mutate(groupPk);
  };
  return (
    <Modal
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>달력메모입력</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"xl"} mt={10}>
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정제목</FormLabel>
              <Controller
                name="title"
                control={control}
                defaultValue={""}
                render={({ field }) => <Input {...field} type="text" />}
              />
            </FormControl>
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정시작날짜</FormLabel>
              <Controller
                name="start_date"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정종료날짜</FormLabel>
              <Controller
                name="end_date"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
            <FormTextarea
              name="calendar_memo"
              control={control}
              label="일정내용"
              placeholder="일정내용"
              errors={errors}
              FormMtProps={{ mt: 10 }}
            />
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정 색상</FormLabel>
              <Controller
                name="cal_color"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TwitterPicker
                    color={field.value}
                    onChange={(updatedColor: any) =>
                      field.onChange(updatedColor.hex)
                    }
                  />
                )}
              />
            </FormControl>
            <ButtonGroup mt={5} w="100%" py={"7"}>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  w="50%"
                  mr={3}
                  size={"lg"}
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  w="50%"
                  mr={3}
                  colorScheme="red"
                  variant="solid"
                  size={"lg"}
                  onClick={() => handleCalendarDelete()}
                >
                  일정 삭제
                </Button>
                <Button
                  w="50%"
                  colorScheme="blue"
                  variant="solid"
                  size={"lg"}
                  type="submit"
                >
                  일정 수정
                </Button>
              </Flex>
            </ButtonGroup>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <PiDogThin />
              <Text>kongsam calendar</Text>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
