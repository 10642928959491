import { useQuery } from "@tanstack/react-query";
import { IUploadPetVariables, getMe } from "../api";
import { IUser } from "../types";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

export default function useUser() {
  const { isLoading, data, isError } = useQuery<IUser>(["me"], getMe, {
    retry: false,
  });
  return {
    userLoading: isLoading,
    user: data,
    isLoggedIn: !isError,
  };
}

export const useUserForm = (userInfo: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUser>({
    defaultValues: {
      name: userInfo?.name || "",
      phone: userInfo?.phone || "",
      address: userInfo?.address || "",
    },
  });

  useEffect(() => {
    reset({
      name: userInfo?.name || "",
      phone: userInfo?.phone || "",
      address: userInfo?.address || "",
    });
  }, [userInfo, reset]);

  return { control, handleSubmit, errors };
};

export const useUserPetForm = (petInfo: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUploadPetVariables>({
    defaultValues: {
      name: petInfo?.name || "",
      gender: petInfo?.gender || "",
      neutering: petInfo?.neutering || "",
      breed: petInfo?.breed || "",
      weigth: petInfo?.weigth || "",
      birthday: petInfo?.birthday || "",
      significant: petInfo?.significant || "",
    },
  });

  useEffect(() => {
    reset({
      name: petInfo?.name || "",
      gender: petInfo?.gender || "",
      neutering: petInfo?.neutering || "",
      breed: petInfo?.breed || "",
      weigth: petInfo?.weigth || "",
      birthday: petInfo?.birthday || "",
      significant: petInfo?.significant || "",
    });
  }, [petInfo, reset]);

  return { control, handleSubmit, errors };
};
export const useUserPetQuestionForm = (petInfo: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUploadPetVariables>({
    defaultValues: {
      question1: petInfo?.question1 || "",
      question2: petInfo?.question2 || "",
      question3: petInfo?.question3 || "",
      question4: petInfo?.question4 || "",
      question5: petInfo?.question5 || "",
      question6: petInfo?.question6 || "",
      question7: petInfo?.question7 || "",
    },
  });

  useEffect(() => {
    reset({
      question1: petInfo?.question1 || "",
      question2: petInfo?.question2 || "",
      question3: petInfo?.question3 || "",
      question4: petInfo?.question4 || "",
      question5: petInfo?.question5 || "",
      question6: petInfo?.question6 || "",
      question7: petInfo?.question7 || "",
    });
  }, [petInfo, reset]);

  return { control, handleSubmit, errors };
};

export const useUsernameForm = (username: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUser>({
    defaultValues: {
      username: username || "",
    },
  });

  useEffect(() => {
    reset({
      username: username || "",
    });
  }, [username, reset]);

  return { control, handleSubmit, errors };
};

export const processURL = (url: string | URL | undefined) => {
  if (url === undefined || url === "") {
    return false;
  }

  // 인수가 string 또는 URL인 경우에 대한 처리
  const path =
    url instanceof URL
      ? url.pathname
      : new URL(url, "https://www.kongsam.com").pathname;
  const segments = path.match(/[^/]+/g) ?? [];
  return segments;
};
