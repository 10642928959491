import {
  Box,
  Button,
  CloseButton,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  Tooltip,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState } from "react";
import {
  deltedDone,
  doneRoomExceptState,
  doneRoomExceptStateVariables,
  getKongsamDoneList,
} from "../../api";
import DoneInfoModal from "../modal/DoneInfoModal";
import DoneResEditModal from "../modal/DoneResEditModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAutoDelete } from "react-icons/md";
import { GrDocumentUser, GrUpdate } from "react-icons/gr";
import { RenderFlex } from "../../utils/RenderFlex";
import { RiFileShredLine } from "react-icons/ri";
import RoomCreateModal from "../modal/RoomCreateModal";
import UploadPhotos from "../modal/UploadPhotos";
import { IKongsamDoneListResponse } from "../../types";
import { useDoneListMultipleDisclosure } from "../hook/useMultipleDisclosure";
import { PiCheckSquareOffset, PiCheckSquareOffsetFill } from "react-icons/pi";
import { LiaWonSignSolid } from "react-icons/lia";
import { formatDateTime4 } from "../../lib/utils";

export default function KsDoneList() {
  const { colorMode } = useColorMode();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";

  const [searchTerm, setSearchTerm] = useState("");
  const [searchOption, setSearchOption] = useState("name");
  const [query, setQuery] = useState({ option: "name", term: "" });
  const [donePkModel, setDonePkModel] = useState(0);
  const [roomPkModel, setRoomPkModel] = useState(0);

  const disclosures = useDoneListMultipleDisclosure();

  const {
    data: donesData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamDoneListResponse>(
    ["dones", JSON.stringify(query)],
    getKongsamDoneList,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.page.next_link) {
          return undefined;
        }
        const url = new URL(lastPage.page.next_link);
        return url.searchParams.get("page");
      },
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const handleDoneSearch = () => {
    setQuery({ option: searchOption, term: searchTerm });
  };
  const handleMainRoomOpenModal = (donePk: number) => {
    setDonePkModel(donePk);
    disclosures.RoomCreateModal.onOpen();
  };
  const handleDoneInfoOpenModal = (donePk: number) => {
    setDonePkModel(donePk);
    disclosures.DoneInfoModal.onOpen();
  };
  const handleEditDoneOpenModal = (donePk: number) => {
    setDonePkModel(donePk);
    disclosures.DoneResEditModal.onOpen();
  };
  const handleUpdatePhotosOpenModal = (roomPk: number) => {
    setRoomPkModel(roomPk);
    disclosures.UploadPhotos.onOpen();
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const deltedDoneMutation = useMutation(deltedDone, {
    onSuccess: () => {
      toast({
        title: "삭제 성공!",
        status: "success",
      });
      queryClient.refetchQueries(["dones"]);
    },
  });
  const onDoneDelete = (pk: number) => {
    if (
      window.confirm(
        `no${pk}_ 관련된 미용예약 정보 와 완료목록 정보가 삭제됩니다.`
      )
    ) {
      deltedDoneMutation.mutate(pk);
    }
  };

  const doneRoomExecptMutation = useMutation(doneRoomExceptState, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      });
      queryClient.refetchQueries([`dones`]);
    },
  });
  const onRoomExecptState = ({
    isRoomExcept,
    donePk,
  }: doneRoomExceptStateVariables) => {
    doneRoomExecptMutation.mutate({
      isRoomExcept,
      donePk,
    });
  };

  const renderPaymentInfo = (
    payment: number | undefined,
    paymentType: string
  ) => {
    return payment !== undefined && Math.floor(payment) !== 0
      ? `${paymentType} ${Math.floor(payment)}원 `
      : "";
  };
  return (
    <Box
      boxShadow={`0px 0px 8px ${boxShadowColor}`}
      overflowX="auto"
      rounded="md"
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={1}
        p={2}
        align="stretch"
        w="100%"
      >
        <Select
          onChange={(e) => setSearchOption(e.target.value)}
          w={{ base: "100%", md: "22%" }}
        >
          <option value="name">펫 이름</option>
          <option value="owner">유저 이름</option>
        </Select>
        <InputGroup>
          <Input
            placeholder="미용 완료건 검색"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleDoneSearch();
              }
            }}
          />
          {searchTerm && (
            <InputRightElement>
              <CloseButton onClick={() => setSearchTerm("")} />
            </InputRightElement>
          )}
        </InputGroup>
        <Button
          onClick={handleDoneSearch}
          colorScheme={"gray"}
          w={{ base: "100%", md: "22%" }}
        >
          미용 {donesData?.pages[0]?.page.count}건 검색
        </Button>
      </Stack>
      {donesData?.pages.flatMap((pageData, i) =>
        pageData?.content.map((done, index) => (
          <Box
            key={done.pk}
            bg={
              index % 2 === 0
                ? colorMode === "dark"
                  ? "gray.700"
                  : "gray.50"
                : colorMode === "dark"
                ? "gray.800"
                : "white"
            }
            p={3}
            shadow="md"
            borderWidth="1px"
          >
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Popover placement="bottom" isLazy>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="More done options"
                      icon={<BsThreeDotsVertical />}
                      variant="solid"
                      w="fit-content"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    w="fit-content"
                    _focus={{ boxShadow: "none" }}
                  >
                    <PopoverArrow />
                    <PopoverBody>
                      <Stack>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<GrDocumentUser />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => handleDoneInfoOpenModal(done.pk)}
                        >
                          미용완료 _INFO
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<GrUpdate />}
                          justifyContent="space-between"
                          colorScheme="green"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            handleEditDoneOpenModal(done.pk);
                          }}
                        >
                          미용완료 수정
                        </Button>
                        <Tooltip label={"삭제 후 되돌릴수 없으니 신중히 삭제"}>
                          <Button
                            w="194px"
                            variant="ghost"
                            rightIcon={<MdOutlineAutoDelete />}
                            justifyContent="space-between"
                            colorScheme="red"
                            fontWeight="normal"
                            fontSize="sm"
                            onClick={() => onDoneDelete(done.pk)}
                          >
                            미용완료 삭제
                          </Button>
                        </Tooltip>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<RiFileShredLine />}
                          justifyContent="space-between"
                          colorScheme="purple"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            if (done.is_room) {
                              handleUpdatePhotosOpenModal(done.rooms[0].pk);
                            } else {
                              handleMainRoomOpenModal(done.pk);
                            }
                          }}
                        >
                          {done.is_room ? "사진 등록" : "메인 룸 등록"}
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={
                            done.is_room_except ? (
                              <PiCheckSquareOffsetFill />
                            ) : (
                              <PiCheckSquareOffset />
                            )
                          }
                          justifyContent="space-between"
                          colorScheme="blue"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            onRoomExecptState({
                              isRoomExcept: !done.is_room_except,
                              donePk: done.pk,
                            });
                          }}
                        >
                          {done.is_room_except ? "룸 등록 off" : "룸 등록 on"}
                        </Button>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
              <Text fontSize="md" fontWeight="bold" lineHeight="short">
                #{done.owner.name} @
                {done.user_pets.map((pet) => pet.name).join(", ")}
              </Text>
            </Flex>
            <RenderFlex
              label={`예약시간`}
              value={formatDateTime4(new Date(done.booking.booking_time_kst))}
            />
            <RenderFlex
              label="미용 서비스"
              value={done.booking.beauty_service}
            />
            <Flex justifyContent="space-between" pt={1}>
              <Text
                ml={2}
                textTransform="uppercase"
                fontSize="sm"
                color="gray.500"
                fontWeight="bold"
              >
                결제금액
              </Text>
              <Text display={"flex"} alignItems={"center"}>
                <LiaWonSignSolid /> {done.payment_amount}
              </Text>
            </Flex>
            <Flex justifyContent="space-between" pt={1}>
              <Text
                ml={2}
                textTransform="uppercase"
                fontSize="sm"
                color="gray.500"
                fontWeight="bold"
              >
                결제수단
              </Text>
              <Text fontSize={"15px"} w={"280px"} textAlign="right">
                {[
                  renderPaymentInfo(done?.cade_payment, "카드"),
                  renderPaymentInfo(done?.cash_payment, "현금"),
                  done?.cash_receipt ? "(현금영수증) " : "",
                  renderPaymentInfo(done?.bank_payment, "계좌"),
                  done?.bank_receipt ? "(계좌현금영수증) " : "",
                  renderPaymentInfo(done?.donation_payment, "기부금"),
                  renderPaymentInfo(done?.discount_payment, "할인"),
                ]
                  .filter(Boolean)
                  .map((item, index) => (
                    <React.Fragment key={index}>{item}</React.Fragment>
                  ))}
              </Text>
            </Flex>
            <RenderFlex
              label="유저 / 펫 별점"
              value={`${done.user_rating} / ${done.pet_rating}`}
            />
          </Box>
        ))
      )}
      {hasNextPage ? (
        <Button
          colorScheme="gray"
          variant="outline"
          w={"100%"}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "is Loding..."
            : hasNextPage
            ? "Dones Load More"
            : "Dones List End"}
        </Button>
      ) : null}
      {disclosures.DoneInfoModal.isOpen && (
        <DoneInfoModal
          isOpen={disclosures.DoneInfoModal.isOpen}
          onClose={disclosures.DoneInfoModal.onClose}
          donePk={donePkModel}
        />
      )}
      {disclosures.DoneResEditModal.isOpen && (
        <DoneResEditModal
          isOpen={disclosures.DoneResEditModal.isOpen}
          onClose={disclosures.DoneResEditModal.onClose}
          resBookingDonePk={donePkModel}
        />
      )}
      {disclosures.RoomCreateModal.isOpen && (
        <RoomCreateModal
          isOpen={disclosures.RoomCreateModal.isOpen}
          onClose={disclosures.RoomCreateModal.onClose}
          donePk={donePkModel}
        />
      )}
      {disclosures.UploadPhotos.isOpen && (
        <UploadPhotos
          isOpen={disclosures.UploadPhotos.isOpen}
          onClose={disclosures.UploadPhotos.onClose}
          roomPk={String(roomPkModel)}
        />
      )}
    </Box>
  );
}
