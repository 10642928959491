import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  IconButton,
  HStack,
  Textarea,
  NumberInput,
  NumberInputField,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { PiDogThin } from "react-icons/pi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IIsErrorMessage, ISuccessMessage, postKsAccount } from "../../../api";
import { AxiosError } from "axios";
import KsStatisAccountList from "./KsStatisAccountList";
import { useState } from "react";

export interface IAccountItem {
  pk?: number;
  account_number: string;
  account_name: string;
  amount: number;
  notes: string;
  frequently_used: boolean;
}
export interface IAccount {
  items: IAccountItem[];
}
interface KsIAccountModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function KsStatisAccount({
  isOpen,
  onClose,
}: KsIAccountModalProps) {
  const [isListOpen, setIsListOpen] = useState(false);
  const handleToggleList = () => {
    setIsListOpen((prevState) => !prevState);
  };

  const { register, control, handleSubmit, reset } = useForm<IAccount>({
    defaultValues: {
      items: [
        {
          account_number: "",
          amount: 0,
          account_name: "",
          notes: "",
          frequently_used: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IAccount
  >(postKsAccount, {
    onSuccess: () => {
      toast({
        title: "결제수단 등록!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`, `expense-account`]);
      reset();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description:
          typeof error.response?.data.error === "object"
            ? JSON.stringify(error.response?.data.error)
            : error.response?.data.error,
        status: "error",
      });
    },
  });
  const onSubmit = (data: IAccount) => {
    mutation.mutate(data);
  };

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>결제수단 등록</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Button
            onClick={handleToggleList}
            colorScheme="teal"
            size={"sm"}
            mb={3}
          >
            {isListOpen ? "결제수단 목록 닫기" : "결제수단 목록 열기"}
          </Button>
          {isListOpen && <KsStatisAccountList />}

          <Box mt={5} as={"form"} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <FormControl key={item.id} mb={3}>
                <HStack mb={1}>
                  <IconButton
                    variant="outline"
                    colorScheme="teal"
                    aria-label="Remove account"
                    fontSize={"sm"}
                    icon={<MinusIcon />}
                    size={"xs"}
                    mb={2}
                    onClick={() => remove(index)}
                  />
                  <FormLabel fontSize={"sm"} color={"gray.500"}>
                    계좌 정보 입력 {index + 1}
                  </FormLabel>
                </HStack>
                <FormControl display="flex" alignItems="center">
                  <FormLabel
                    htmlFor={`items.${index}.frequently_used`}
                    mb="0"
                    fontSize={"sm"}
                    color={"gray.500"}
                  >
                    자주 사용하는 계좌
                  </FormLabel>
                  <Checkbox
                    size={"md"}
                    id={`items.${index}.frequently_used`}
                    {...register(`items.${index}.frequently_used`)}
                  />
                </FormControl>
                <HStack>
                  <Input
                    fontSize={"sm"}
                    {...register(`items.${index}.account_number`)}
                    placeholder="계좌번호"
                  />
                  <Input
                    fontSize={"sm"}
                    {...register(`items.${index}.account_name`)}
                    placeholder="계좌명"
                  />
                </HStack>
                <Controller
                  control={control}
                  name={`items.${index}.amount`}
                  render={({ field }) => (
                    <NumberInput
                      mt={2}
                      fontSize={"sm"}
                      onChange={(valueAsString, valueAsNumber) =>
                        field.onChange(valueAsNumber)
                      }
                      onBlur={field.onBlur}
                      value={field.value || ""}
                    >
                      <NumberInputField
                        fontSize={"sm"}
                        placeholder="통장의 잔액"
                      />
                    </NumberInput>
                  )}
                />

                <Textarea
                  mt={2}
                  mb={3}
                  fontSize={"sm"}
                  {...register(`items.${index}.notes`)}
                  placeholder="비고를 입력하세요"
                />
              </FormControl>
            ))}

            <Button
              leftIcon={<AddIcon aria-label="Add account" fontSize="15px" />}
              colorScheme="teal"
              variant="outline"
              size={"sm"}
              onClick={() =>
                append({
                  account_number: "",
                  account_name: "",
                  amount: 0,
                  notes: "",
                  frequently_used: false,
                })
              }
            >
              정보추가
            </Button>
          </Box>
          <Button mt={4} fontSize={"sm"} colorScheme="teal" type="submit">
            등록
          </Button>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam account</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
