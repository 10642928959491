import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useState } from "react";
import useUser from "../../../lib/useUser";
import { ImagePreview } from "../../../utils/ImagePreview";
import KsInfoModal from "./KsInfoModal";
import KsProfileList from "./KsProfileList";

export default function KsProfileDashbord() {
  const { user } = useUser();
  const boxMaxWidth = useBreakpointValue({ base: "420px", md: "740px" });
  const {
    isOpen: isUserInfoOpen,
    onOpen: onUserInfoOpen,
    onClose: onUserInfoClose,
  } = useDisclosure();
  const [userName, setUserName] = useState("");
  const handleUserInfoOpenModal = (username: string) => {
    setUserName(username);
    onUserInfoOpen();
  };
  return (
    <>
      <Flex justifyContent="center" alignItems="center" mt={10}>
        <Box
          maxW={boxMaxWidth}
          w={"full"}
          bg={useColorModeValue("white", "gray.900")}
          boxShadow={"2xl"}
          rounded={"lg"}
          p={6}
          textAlign={"center"}
        >
          <ImagePreview
            src={user?.user_avatar}
            name={user?.name}
            FormMtProps={{
              size: "2xl",
              mb: 6,
              pos: "relative",
            }}
          />

          <HStack justifyContent="center" alignItems={"start"} spacing={8}>
            <VStack align="start" spacing={0}>
              <Text>Name</Text>
              <Text fontSize={38}>{user?.name}</Text>
            </VStack>
            <VStack align="start" spacing={0}>
              <Text>Username</Text>
              <Text color={"gray.500"}>{user?.username}</Text>
            </VStack>
          </HStack>
          <HStack
            justifyContent="center"
            alignItems={"start"}
            spacing={4}
            mt={8}
          >
            <Link to={"/ksProfile/user-edit-profile"}>
              <Button colorScheme="gray" variant="outline" w="10rem">
                Edit Profile
              </Button>
            </Link>
            <Button
              colorScheme="blue"
              variant="solid"
              w="10rem"
              onClick={() => handleUserInfoOpenModal(String(user?.username))}
            >
              See Profile
            </Button>
          </HStack>
          {userName && (
            <KsInfoModal
              isOpen={isUserInfoOpen}
              onClose={onUserInfoClose}
              username={userName}
            />
          )}
        </Box>
      </Flex>
      <Flex justifyContent="center" alignItems="center" mt={20}>
        <KsProfileList />
      </Flex>
    </>
  );
}
