import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Img,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logOut } from "../../api";
import { useHeaderMultipleDisclosure } from "../hook/useMultipleDisclosure";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import LoginModal from "../modal/LoginModal";
import NewGuestBookingResModal from "../modal/NewGuestBookingResModal";
import RoomCreateModal from "../modal/RoomCreateModal";
import CallForRes from "../modal/CallForRes";
import CallForResDrawer from "../modal/CallForResDrawer";
import MapModal from "../modal/MapModal";
import kongsamLogo from "../../img/kongsamLogo.png";
import useUser from "../../lib/useUser";
import useCallMsg from "../../lib/useCallMsg";
import { GoBellFill } from "react-icons/go";
import { ImagePreview } from "../../utils/ImagePreview";
import { useEffect, useState } from "react";

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  onClick?: () => void;
}
export default function Header() {
  const { userLoading, isLoggedIn, user } = useUser();
  const { dataCount } = useCallMsg();

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(logOut, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      });
      queryClient.invalidateQueries(["me"]);
      if (data) {
        setNavItems((prevItems) =>
          prevItems.filter((item) => item.label !== "콩삼관리자")
        );
      }
    },
  });
  const onLogOut = () => {
    mutation.mutate();
  };

  const handleHome = () => {
    navigate("/room", {
      state: {
        pname: "",
        start: "",
        end: "",
        breed: "",
        beauty: "",
      },
    });
  };

  const { isOpen, onToggle } = useDisclosure();
  const disclosures = useHeaderMultipleDisclosure();
  const [navItems, setNavItems] = useState([
    {
      label: "공지사항",
      href: "/help",
      onClick: () => {},
    },
    {
      label: "콩삼위치",
      onClick: () => {
        disclosures.MapModal.onOpen();
      },
    },
    {
      label: "예약문의",
      onClick: () => {
        disclosures.CallRes.onOpen();
      },
    },
  ]);

  useEffect(() => {
    if (!user || isLoggedIn === undefined) {
      return;
    }
    if (user?.is_host) {
      setNavItems((prevItems) => {
        const alreadyHasAdminMenu = prevItems.some(
          (item) => item.label === "콩삼관리자"
        );
        if (alreadyHasAdminMenu) {
          return prevItems;
        } else {
          return [
            ...prevItems,
            {
              label: "콩삼관리자",
              onClick: () => {},
              children: [
                {
                  label: "데시보드",
                  subLabel: "데시보드,펫,유저,미용예약,미용종료목록",
                  href: "/ks-admin",
                },
                {
                  label: "신규등록 유저 예약",
                  subLabel: "유저 등록 + 펫 등록 + 미용예약",
                  onClick: () => {
                    disclosures.GuestBookingModal.onOpen();
                  },
                },
                {
                  label: "캘린더",
                  subLabel: "일정 등록 및 확인",
                  href: "/ks-admin/calendar",
                },
                {
                  label: "룸 등록",
                  subLabel: "미용완료된 펫 사진 등록",
                  onClick: () => {
                    disclosures.Room.onOpen();
                  },
                },
              ],
            },
          ];
        }
      });
    } else {
      setNavItems((prevItems) =>
        prevItems.filter((item) => item.label !== "콩삼관리자")
      );
    }

    if (isLoggedIn) {
      setNavItems((prevItems) =>
        prevItems.filter((item) => item.label !== "예약문의")
      );
    } else {
      setNavItems((prevItems) => {
        const alreadyHasReservationInquiry = prevItems.some(
          (item) => item.label === "예약문의"
        );
        if (alreadyHasReservationInquiry) {
          return prevItems;
        } else {
          return [
            ...prevItems,
            {
              label: "예약문의",
              onClick: () => {
                disclosures.CallRes.onOpen();
              },
            },
          ];
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user?.is_host]);

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Flex alignItems="center" cursor={"pointer"}>
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              fontFamily={"heading"}
              color={useColorModeValue("gray.800", "white")}
            >
              <Img
                src={kongsamLogo}
                alt="kongsam logo"
                w="70px"
                onClick={handleHome}
              />
            </Text>

            {/* 데스크탑 화면 */}
            <Flex display={{ base: "none", md: "flex" }} ml={10}>
              <DesktopNav NAV_ITEMS={navItems} />
            </Flex>
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          {!userLoading ? (
            !isLoggedIn ? (
              <Button
                /* display={{ base: 'none', md: 'inline-flex' }} */
                fontSize={"sm"}
                fontWeight={400}
                variant={"link"}
                onClick={disclosures.Login.onOpen}
              >
                로그인
              </Button>
            ) : (
              <Flex alignItems={"center"}>
                <Menu>
                  <MenuButton
                    py={{ base: 1, md: 2 }}
                    transition="all 0.3s"
                    _focus={{ boxShadow: "none" }}
                  >
                    <ImagePreview
                      src={user?.user_avatar}
                      name={user?.name}
                      FormMtProps={{ size: { base: "sm", sm: "md" } }}
                    />
                  </MenuButton>
                  <MenuList>
                    <Link to={"/ksProfile"}>
                      <MenuItem
                        fontSize={{ base: "sm", sm: "md" }}
                        color={"gray.500"}
                      >
                        프로필
                      </MenuItem>
                    </Link>
                    <MenuItem
                      fontSize={{ base: "sm", sm: "md" }}
                      color={"gray.500"}
                      onClick={onLogOut}
                    >
                      로그아웃
                    </MenuItem>
                  </MenuList>
                </Menu>
                {user?.is_host ? (
                  <Box pl={2}>
                    <GoBellFill
                      color={dataCount === 0 ? "black" : "red"}
                      size={"18px"}
                      cursor={"pointer"}
                      title="메신저 알림"
                      onClick={disclosures.CallDrawer.onOpen}
                    />
                  </Box>
                ) : null}
              </Flex>
            )
          ) : null}
        </Stack>
      </Flex>

      {/* 모바일 화면 */}
      <Collapse in={isOpen} animateOpacity>
        <MobileNav NAV_ITEMS={navItems} />
      </Collapse>

      {disclosures.Login.isOpen && (
        <LoginModal
          isOpen={disclosures.Login.isOpen}
          onClose={disclosures.Login.onClose}
        />
      )}

      {disclosures.Room.isOpen && (
        <RoomCreateModal
          isOpen={disclosures.Room.isOpen}
          onClose={disclosures.Room.onClose}
        />
      )}
      {disclosures.CallRes.isOpen && (
        <CallForRes
          isOpen={disclosures.CallRes.isOpen}
          onClose={disclosures.CallRes.onClose}
        />
      )}
      {disclosures.CallDrawer.isOpen && (
        <CallForResDrawer
          isOpen={disclosures.CallDrawer.isOpen}
          onClose={disclosures.CallDrawer.onClose}
        />
      )}
      {disclosures.MapModal.isOpen && (
        <MapModal
          isOpen={disclosures.MapModal.isOpen}
          onClose={disclosures.MapModal.onClose}
        />
      )}
      {disclosures.GuestBookingModal.isOpen && (
        <NewGuestBookingResModal
          isOpen={disclosures.GuestBookingModal.isOpen}
          onClose={disclosures.GuestBookingModal.onClose}
        />
      )}
    </Box>
  );
}

const DesktopNav = ({ NAV_ITEMS }: any) => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem: any) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              {navItem.href ? (
                <Box
                  as={Link}
                  to={navItem.href}
                  p={2}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                >
                  {navItem.label}
                </Box>
              ) : (
                <Box
                  as="a"
                  p={2}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  onClick={navItem.onClick}
                >
                  {navItem.label}
                </Box>
              )}
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child: any) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};
const DesktopSubNav = ({ label, href, subLabel, onClick }: NavItem) => {
  return (
    <Box
      as={Link}
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.900") }}
      onClick={onClick}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"pink.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = ({ NAV_ITEMS }: any) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={2}
      display={{ md: "none" }}
      cursor={"pointer"}
      fontSize={"sm"}
    >
      {NAV_ITEMS.map((navItem: any) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, onClick }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  const handleItemClick = (event: any, childOnClick: any) => {
    if (childOnClick) {
      event.stopPropagation();
      childOnClick(event);
    }
  };

  return (
    <Stack spacing={0} onClick={onToggle}>
      {href ? (
        <Box
          as={Link}
          to={href}
          py={1}
          justifyContent="space-between"
          alignItems="center"
          _hover={{
            textDecoration: "none",
          }}
        >
          <Text fontWeight={600}>{label}</Text>
        </Box>
      ) : (
        <Box
          as="a"
          py={1}
          justifyContent="space-between"
          alignItems="center"
          _hover={{
            textDecoration: "none",
          }}
          onClick={onClick}
        >
          <Text fontWeight={600}>{label}</Text>
          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Box>
      )}

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box
                as={Link}
                key={child.label}
                py={2}
                to={child.href}
                onClick={(event) => handleItemClick(event, child.onClick)}
              >
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
