import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { PiDogThin } from "react-icons/pi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getKongsamDoneDetail, putKongsamDoneEdit } from "../../api";
import { FormTextarea, StarRatingDone } from "../FormGroup";
import { IDone } from "../../types";

interface IDoneResProps {
  isOpen: boolean;
  onClose: () => void;
  resBookingDonePk: number;
}
type PaymentValues = {
  cade_payment: number;
  cash_payment: number;
  bank_payment: number;
  donation_payment: number;
  discount_payment: number;
};

export default function DoneResEditModal({
  isOpen,
  onClose,
  resBookingDonePk,
}: IDoneResProps) {
  const { data: doneInfo, isLoading } = useQuery<IDone>(
    [`dones`, resBookingDonePk],
    getKongsamDoneDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<IDone>();

  const [rating, setRating] = useState(3);
  const [hover, setHover] = useState(0);
  const [petRating, setPetRating] = useState(3);
  const [petHover, setPetHover] = useState(0);

  useEffect(() => {
    if (doneInfo) {
      setValue("cade_payment", doneInfo.cade_payment);
      setValue("cash_payment", doneInfo.cash_payment);
      setValue("cash_receipt", doneInfo.cash_receipt);
      setValue("bank_receipt", doneInfo.bank_receipt);
      setValue("bank_payment", doneInfo.bank_payment);
      setValue("donation_payment", doneInfo.donation_payment);
      setValue("discount_payment", doneInfo.discount_payment);
      setValue("payment_amount", calculatePaymentAmount(doneInfo));
      setValue("start_date", doneInfo.start_date);
      setValue("end_date", doneInfo.end_date);
      setPetRating(doneInfo.pet_rating);
      setRating(doneInfo.user_rating);
      setValue("beauty_content", doneInfo.beauty_content);
      setValue("special_notes", doneInfo.special_notes);
      setValue("admin_memo", doneInfo.admin_memo);
    }
  }, [doneInfo, setValue]);

  useEffect(() => {
    setShowCadePayment(Number(doneInfo?.cade_payment) !== 0);
    setShowCashPayment(Number(doneInfo?.cash_payment) !== 0);
    setShowBankPayment(Number(doneInfo?.bank_payment) !== 0);
    setShowDonationPayment(Number(doneInfo?.donation_payment) !== 0);
    setShowDiscountPayment(Number(doneInfo?.discount_payment) !== 0);
  }, [doneInfo, isOpen]);

  const direction = useBreakpointValue<FlexProps["direction"]>({
    base: "column",
    md: "row",
  });
  const [showCadePayment, setShowCadePayment] = useState(false);
  const [showCashPayment, setShowCashPayment] = useState(false);
  const [showBankPayment, setShowBankPayment] = useState(false);
  const [showDonationPayment, setShowDonationPayment] = useState(false);
  const [showDiscountPayment, setShowDiscountPayment] = useState(false);

  useEffect(() => {
    setValue("pet_rating", petRating);
    setValue("user_rating", rating);
  }, [setValue, rating, petRating]);
  useEffect(() => {
    reset({
      pk: resBookingDonePk,
    });
  }, [resBookingDonePk, reset]);
  const values = watch();
  const calculatePaymentAmount = (values: PaymentValues) => {
    return (
      (Number(values.cade_payment) || 0) +
      (Number(values.cash_payment) || 0) +
      (Number(values.bank_payment) || 0) +
      (Number(values.donation_payment) || 0) -
      (Number(values.discount_payment) || 0)
    );
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const editResBookingDoneMutation = useMutation(putKongsamDoneEdit, {
    onSuccess: (data) => {
      toast({
        title: "수정되었습니다.",
        status: "success",
      });
      queryClient.refetchQueries([`dones`]);
      handleClose();
    },
  });
  const onEditDoneSubmit = (data: IDone) => {
    editResBookingDoneMutation.mutate(data);
  };

  const handleClose = () => {
    queryClient.removeQueries([`dones`, resBookingDonePk]);
    onClose();
    reset();
    setShowCadePayment(false);
    setShowCashPayment(false);
    setShowBankPayment(false);
    setShowDonationPayment(false);
    setShowDiscountPayment(false);
  };

  return (
    <Modal size={"6xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onEditDoneSubmit)}>
        <ModalHeader fontSize={"sm"} color={"gray.600"}>
          done _no{resBookingDonePk}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isLoading ? (
            <Container maxW={"4xl"}>
              <Controller
                name="pk"
                control={control}
                defaultValue={resBookingDonePk}
                render={({ field }) => <Input type="hidden" {...field} />}
              />
              <VStack>
                <Button
                  onClick={() => {
                    setShowCadePayment(!showCadePayment);
                    if (showCadePayment) {
                      setValue("cade_payment", 0);
                    }
                  }}
                  alignSelf="flex-start"
                  w={"250px"}
                  mb={2}
                >
                  {showCadePayment
                    ? "카드 결제금액 취소"
                    : "카드 결제금액 입력"}
                </Button>
                {showCadePayment && (
                  <FormControl isInvalid={Boolean(errors?.cade_payment)} mb={5}>
                    <Controller
                      name="cade_payment"
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="카드 결제 금액 입력"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              "payment_amount",
                              calculatePaymentAmount({
                                ...values,
                                cade_payment: Number(e.target.value),
                              })
                            );
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
                <Button
                  onClick={() => {
                    setShowCashPayment(!showCashPayment);
                    if (showCashPayment) {
                      setValue("cash_payment", 0);
                      setValue("cash_receipt", false);
                    }
                  }}
                  alignSelf="flex-start"
                  w={"250px"}
                  mb={2}
                >
                  {showCashPayment
                    ? "현금 결제금액 취소"
                    : "현금 결제금액 입력"}
                </Button>
                {showCashPayment && (
                  <FormControl isInvalid={Boolean(errors?.cash_payment)}>
                    <Controller
                      name="cash_receipt"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          isChecked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        >
                          현금영수증 발행
                        </Checkbox>
                      )}
                    />
                    <Controller
                      name="cash_payment"
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="현금 결제 금액 입력"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              "payment_amount",
                              calculatePaymentAmount({
                                ...values,
                                cash_payment: Number(e.target.value),
                              })
                            );
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}

                <Button
                  onClick={() => {
                    setShowBankPayment(!showBankPayment);
                    if (showBankPayment) {
                      setValue("bank_payment", 0);
                      setValue("bank_receipt", false);
                    }
                  }}
                  alignSelf="flex-start"
                  w={"250px"}
                  mb={2}
                >
                  {showBankPayment
                    ? "계좌 결제금액 취소"
                    : "계좌 결제금액 입력"}
                </Button>
                {showBankPayment && (
                  <FormControl isInvalid={Boolean(errors?.bank_payment)}>
                    <Controller
                      name="bank_receipt"
                      control={control}
                      defaultValue={false}
                      render={({ field }) => (
                        <Checkbox
                          color={"gray.600"}
                          isChecked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        >
                          <Text fontSize={"sm"} color={"gray.500"}>
                            계좌 현금영수증 발행
                          </Text>
                        </Checkbox>
                      )}
                    />
                    <Controller
                      name="bank_payment"
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="계좌 결제 금액 입력"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              "payment_amount",
                              calculatePaymentAmount({
                                ...values,
                                bank_payment: Number(e.target.value),
                              })
                            );
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
                <Button
                  onClick={() => {
                    setShowDonationPayment(!showDonationPayment);
                    if (showDonationPayment) {
                      setValue("donation_payment", 0);
                    }
                  }}
                  alignSelf="flex-start"
                  w={"250px"}
                  mb={2}
                >
                  {showDonationPayment
                    ? "기부금 결제금액 취소"
                    : "기부금 결제금액 입력"}
                </Button>
                {showDonationPayment && (
                  <FormControl isInvalid={Boolean(errors?.donation_payment)}>
                    <Controller
                      name="donation_payment"
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="기부금 결제 금액 입력"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              "payment_amount",
                              calculatePaymentAmount({
                                ...values,
                                donation_payment: Number(e.target.value),
                              })
                            );
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
                <Button
                  onClick={() => {
                    setShowDiscountPayment(!showDiscountPayment);
                    if (showDiscountPayment) {
                      setValue("discount_payment", 0);
                    }
                  }}
                  alignSelf="flex-start"
                  w={"250px"}
                  mb={2}
                >
                  {showDiscountPayment ? "할인 금액 취소" : "할인 금액 입력"}
                </Button>
                {showDiscountPayment && (
                  <FormControl isInvalid={Boolean(errors?.discount_payment)}>
                    <Controller
                      name="discount_payment"
                      control={control}
                      defaultValue={0}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          placeholder="할인 금액 입력"
                          onChange={(e) => {
                            field.onChange(e);
                            setValue(
                              "payment_amount",
                              calculatePaymentAmount({
                                ...values,
                                discount_payment: Number(e.target.value),
                              })
                            );
                          }}
                        />
                      )}
                    />
                  </FormControl>
                )}
              </VStack>
              <Flex gap={3}>
                <FormControl mt={5}>
                  <FormLabel fontWeight={"bold"}>총 결제금액</FormLabel>
                  <Input
                    name="payment_amount"
                    type="number"
                    value={
                      (Number(values.cade_payment) || 0) +
                      (Number(values.cash_payment) || 0) +
                      (Number(values.bank_payment) || 0) +
                      (Number(values.donation_payment) || 0) -
                      (Number(values.discount_payment) || 0)
                    }
                    readOnly
                  />
                </FormControl>
              </Flex>
              <Flex gap={3} flexDirection={direction}>
                <FormControl mt={5}>
                  <FormLabel fontSize="sm" fontWeight={"bold"}>
                    미용종료일 시작(YYYY-MM-DD 00:00)
                  </FormLabel>
                  <Controller
                    name="start_date"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input {...field} type="datetime-local" />
                    )}
                  />
                </FormControl>
                <FormControl mt={5}>
                  <FormLabel fontSize="sm" fontWeight={"bold"}>
                    미용종료일 종료(YYYY-MM-DD 00:00)
                  </FormLabel>
                  <Controller
                    name="end_date"
                    control={control}
                    defaultValue={""}
                    render={({ field }) => (
                      <Input {...field} type="datetime-local" />
                    )}
                  />
                </FormControl>
              </Flex>
              <Flex w="100%">
                <Box w="50%" mt={10}>
                  <VStack align="start" spacing={2}>
                    <Text fontSize="sm" fontWeight={"bold"}>
                      펫 별점
                    </Text>
                    <HStack spacing={1}>
                      {[...Array(5)].map((_, index) => (
                        <StarRatingDone
                          key={index}
                          index={index}
                          rating={petRating}
                          hover={petHover}
                          setHover={setPetHover}
                          setRating={setPetRating}
                        />
                      ))}
                      <Controller
                        name="pet_rating"
                        control={control}
                        defaultValue={petRating}
                        render={({ field }) => (
                          <input type="hidden" {...field} />
                        )}
                      />
                    </HStack>
                  </VStack>
                </Box>
                <Box w="50%" mt={10}>
                  <VStack align="start" spacing={2}>
                    <Text fontSize="sm" fontWeight={"bold"}>
                      유저 별점
                    </Text>
                    <HStack spacing={1}>
                      {[...Array(5)].map((_, index) => (
                        <StarRatingDone
                          key={index}
                          index={index}
                          rating={rating}
                          hover={hover}
                          setHover={setHover}
                          setRating={setRating}
                        />
                      ))}
                      <Controller
                        name="user_rating"
                        control={control}
                        defaultValue={rating}
                        render={({ field }) => (
                          <input type="hidden" {...field} />
                        )}
                      />
                    </HStack>
                  </VStack>
                </Box>
              </Flex>
              <VStack>
                <FormTextarea
                  name="beauty_content"
                  control={control}
                  label="미용내용"
                  placeholder="미용내용"
                  errors={errors}
                  FormMtProps={{ mt: 10 }}
                  rules={{ required: `미용내용을 입력해주세요.` }}
                />
                <FormTextarea
                  name="special_notes"
                  control={control}
                  label="미용특이사항"
                  placeholder="미용특이사항"
                  errors={errors}
                  FormMtProps={{ mt: 10 }}
                  rules={{ required: `미용특이사항을 입력해주세요.` }}
                />
                <FormTextarea
                  name="admin_memo"
                  control={control}
                  label="관리자메모"
                  placeholder="관리자메모"
                  errors={errors}
                  FormMtProps={{ mt: 10 }}
                />
              </VStack>
              <ButtonGroup mt={5} w="100%" py={"7"}>
                <Flex w="100%" justifyContent="space-between">
                  <Button
                    colorScheme="teal"
                    variant="solid"
                    w="50%"
                    mr={3}
                    size={"lg"}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    w="50%"
                    colorScheme="red"
                    variant="solid"
                    size={"lg"}
                    type="submit"
                  >
                    미용수정
                  </Button>
                </Flex>
              </ButtonGroup>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <PiDogThin />
                <Text>kongsam done edit</Text>
              </Stack>
            </Container>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
