import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  IIsErrorMessage,
  ISuccessMessage,
  approvalStateVariables,
  getKongsamCancelleNoshowList,
  patchApprovalState,
} from "../../api";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import ResBookingInfoModal from "../modal/ResBookingInfoModal";
import { AxiosError } from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FcCancel } from "react-icons/fc";
import { GrDocumentUser, GrUpdate } from "react-icons/gr";
import { RenderFlex } from "../../utils/RenderFlex";
import { IKongsamBookingListResponse } from "../../types";
import { useResListMultipleDisclosure } from "../hook/useMultipleDisclosure";
import BookingEditModal from "../modal/BookingEditModal";
import { FaRegClock } from "react-icons/fa";

export default function KsCancelleList() {
  const { colorMode } = useColorMode();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";
  const [searchTerm, setSearchTerm] = useState("");
  const [searchOption, setSearchOption] = useState("name");
  const [query, setQuery] = useState({ option: "name", term: "" });
  const [bookingPk, setBookingPk] = useState(0);
  const disclosures = useResListMultipleDisclosure();

  const {
    data: cancelleList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamBookingListResponse>(
    ["bookings", "cancelle-noshow", JSON.stringify(query)],
    getKongsamCancelleNoshowList,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.page.next_link) {
          return undefined;
        }
        const url = new URL(lastPage.page.next_link);
        return url.searchParams.get("page");
      },
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const {
    isOpen: isResInfoOpen,
    onClose: onResInfoClose,
    onOpen: onResInfoOpen,
  } = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();
  const approvalStateBeautyBookingMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    approvalStateVariables
  >(patchApprovalState, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      });
      queryClient.refetchQueries([`bookings`]);
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
    },
  });

  const onCancelledApprovalState = ({
    host_approval_state,
    bookingPk,
  }: approvalStateVariables) => {
    approvalStateBeautyBookingMutation.mutate({
      host_approval_state,
      bookingPk,
    });
  };

  const handleCancelledSearch = () => {
    setQuery({ option: searchOption, term: searchTerm });
  };

  return (
    <Box
      boxShadow={`0px 0px 8px ${boxShadowColor}`}
      overflowX="auto"
      rounded="md"
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={1}
        p={2}
        align="stretch"
        w="100%"
      >
        <Select
          onChange={(e) => {
            setSearchOption(e.target.value);
            setSearchTerm("");
            if (e.target.value === "noshow") {
              setQuery({ option: "noshow", term: "노쇼" });
            } else if (e.target.value === "cancel") {
              setQuery({ option: "cancel", term: "예약취소" });
            }
          }}
          w={{ base: "100%", md: "22%" }}
        >
          <option value="name">펫이름</option>
          <option value="owner">유저이름</option>
          <option value="noshow">노쇼</option>
          <option value="cancel">예약취소</option>
        </Select>
        <InputGroup>
          <Input
            placeholder="예약 목록 검색"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleCancelledSearch();
              }
            }}
          />
          {searchTerm && (
            <InputRightElement>
              <CloseButton onClick={() => setSearchTerm("")} />
            </InputRightElement>
          )}
        </InputGroup>
        <Button
          onClick={handleCancelledSearch}
          colorScheme={"gray"}
          w={{ base: "100%", md: "25%" }}
        >
          예약 목록 {cancelleList?.pages[0]?.page.count}건 검색
        </Button>
      </Stack>
      {cancelleList?.pages.flatMap((pageData, i) =>
        pageData?.content.map((bookingPublic, index) => (
          <Box
            key={bookingPublic.pk}
            bg={
              index % 2 === 0
                ? colorMode === "dark"
                  ? "gray.700"
                  : "gray.50"
                : colorMode === "dark"
                ? "gray.800"
                : "white"
            }
            p={3}
            shadow="md"
            borderWidth="1px"
          >
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Popover placement="bottom" isLazy>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="More bookingPublic options"
                      icon={<BsThreeDotsVertical />}
                      variant="solid"
                      w="fit-content"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    w="fit-content"
                    _focus={{ boxShadow: "none" }}
                  >
                    <PopoverArrow />
                    <PopoverBody>
                      <Stack>
                        <Button
                          w="220px"
                          variant="ghost"
                          rightIcon={<GrDocumentUser />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            onResInfoOpen();
                            setBookingPk(bookingPublic.pk);
                          }}
                        >
                          {bookingPublic.owner.name} _INFO
                        </Button>
                        <Button
                          w="220px"
                          variant="ghost"
                          rightIcon={<FaRegClock />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            onCancelledApprovalState({
                              host_approval_state: "예약확인중",
                              bookingPk: bookingPublic.pk,
                            });
                          }}
                        >
                          예약확인중
                        </Button>
                        {bookingPublic.host_approval_state === "예약취소" && (
                          <Button
                            w="220px"
                            variant="ghost"
                            rightIcon={<FcCancel />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            fontSize="sm"
                            onClick={() => {
                              onCancelledApprovalState({
                                host_approval_state: "예약",
                                bookingPk: bookingPublic.pk,
                              });
                            }}
                          >
                            노쇼 취소
                          </Button>
                        )}
                        {bookingPublic.host_approval_state === "노쇼" && (
                          <Button
                            w="220px"
                            variant="ghost"
                            rightIcon={<FcCancel />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            fontSize="sm"
                            onClick={() => {
                              onCancelledApprovalState({
                                host_approval_state: "예약",
                                bookingPk: bookingPublic.pk,
                              });
                            }}
                          >
                            예약취소 취소
                          </Button>
                        )}
                        <Button
                          w="220px"
                          variant="ghost"
                          rightIcon={<GrUpdate />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          colorScheme="green"
                          fontSize="sm"
                          onClick={() => {
                            setBookingPk(bookingPublic.pk);
                            disclosures.BookingEditModal.onOpen();
                          }}
                        >
                          예약정보 수정
                        </Button>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
              <Text fontSize="lg" fontWeight="semibold" lineHeight="short">
                #{bookingPublic.owner.name} @
                {bookingPublic.user_pets.map((pet) => pet.name).join(", ")}
              </Text>
            </Flex>
            <RenderFlex
              label={`예약시간`}
              value={bookingPublic.booking_time_kst}
            />
            <RenderFlex
              label="미용 서비스"
              value={bookingPublic.beauty_service}
            />
            <RenderFlex
              label="예약상태"
              value={bookingPublic.host_approval_state}
            />
          </Box>
        ))
      )}
      {hasNextPage ? (
        <Button
          colorScheme="gray"
          variant="outline"
          w={"100%"}
          mt={3}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "is Loding..."
            : hasNextPage
            ? "Cancelle Load More"
            : "Cancelle List End"}
        </Button>
      ) : null}
      {isResInfoOpen && (
        <ResBookingInfoModal
          isOpen={isResInfoOpen}
          onClose={onResInfoClose}
          bookingPk={bookingPk}
        />
      )}
      {disclosures.BookingEditModal.isOpen && (
        <BookingEditModal
          isOpen={disclosures.BookingEditModal.isOpen}
          onClose={disclosures.BookingEditModal.onClose}
          bookingPk={bookingPk}
        />
      )}
    </Box>
  );
}
