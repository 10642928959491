import { useDisclosure } from "@chakra-ui/react";

export function useHeaderMultipleDisclosure() {
  const disclosures = {
    GuestBookingModal: useDisclosure(),
    Room: useDisclosure(),
    CallRes: useDisclosure(),
    CallDrawer: useDisclosure(),
    Login: useDisclosure(),
    MapModal: useDisclosure(),
  };

  return disclosures;
}
export function useCalendarMultipleDisclosure() {
  const disclosures = {
    CalendarEditModal: useDisclosure(),
    CalendarCreateModal: useDisclosure(),
    DoneInfoModal: useDisclosure(),
    ResBookingInfoModal: useDisclosure(),
  };

  return disclosures;
}
export function useUserListMultipleDisclosure() {
  const disclosures = {
    UserInfoModal: useDisclosure(),
    PetInfoListModal: useDisclosure(),
    UserEditModal: useDisclosure(),
    PetCreateModal: useDisclosure(),
    BookingCreateModal: useDisclosure(),
    UserCreateModal: useDisclosure(),
  };

  return disclosures;
}
export function usePetListMultipleDisclosure() {
  const disclosures = {
    PetInfoModal: useDisclosure(),
    PetEditModal: useDisclosure(),
    BookingCreateModal: useDisclosure(),
    PetDoneListModal: useDisclosure(),
  };

  return disclosures;
}
export function useResListMultipleDisclosure() {
  const disclosures = {
    ResBookingInfoModal: useDisclosure(),
    BookingEditModal: useDisclosure(),
    DoneResCreateModal: useDisclosure(),
  };

  return disclosures;
}
export function useDoneListMultipleDisclosure() {
  const disclosures = {
    DoneInfoModal: useDisclosure(),
    DoneResEditModal: useDisclosure(),
    RoomCreateModal: useDisclosure(),
    UploadPhotos: useDisclosure(),
  };

  return disclosures;
}
export function useKsMultipleDisclosure() {
  const disclosures = {
    KsStatisOutlay: useDisclosure(),
    KsStatisAccount: useDisclosure(),
    KsStatisExpenseInfo: useDisclosure(),
    KsStatisAccountTransfer: useDisclosure(),
    KsStatisGraph: useDisclosure(),
  };

  return disclosures;
}

export function useKsProfileMultipleDisclosure() {
  const disclosures = {
    MyPesIntoModal: useDisclosure(),
    MyBookingCreateModal: useDisclosure(),
    MyBookingEditModal: useDisclosure(),
    MyUserConfirmationModal: useDisclosure(),
  };

  return disclosures;
}
