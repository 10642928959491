import { useInfiniteQuery } from "@tanstack/react-query";
import { Box, Text, useColorMode } from "@chakra-ui/react";
import { LiaWonSignSolid } from "react-icons/lia";
import useUser from "../../../../lib/useUser";
import { MyTable } from "./../../../../utils/MyTable";
import { IDone, IKongsamDoneListResponse } from "../../../../types";
import { getKsPaymentList } from "../../../../api";
import { formatDateTime3 } from "../../../../lib/utils";

export default function MyPaymentList() {
  const { user } = useUser();
  const { colorMode } = useColorMode();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";
  const {
    data: paymentyList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamDoneListResponse>(
    ["dones", user?.username],
    getKsPaymentList,
    {
      getNextPageParam: (lastPage) =>
        lastPage.page.next_link
          ? new URL(lastPage.page.next_link).searchParams.get("page")
          : undefined,
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <Box
      boxShadow={`0px 0px 8px ${boxShadowColor}`}
      overflowX="auto"
      rounded="md"
      mt={10}
    >
      <MyTable<IDone>
        label="Payment"
        caption={paymentyList?.pages[0]?.page.count}
        fetchNextPage={() => fetchNextPage()}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        columns={[
          {
            header: "service",
            render: (item: IDone) => <Text>{item.booking.beauty_service}</Text>,
          },
          {
            header: "결제금액",
            render: (item: IDone) => (
              <Text display={"flex"} alignItems={"center"}>
                <LiaWonSignSolid /> {Math.floor(item.payment_amount)}
              </Text>
            ),
          },
          {
            header: "미용예약일",
            render: (item: IDone) => (
              <Text>{formatDateTime3(new Date(item.start_date))}</Text>
            ),
          },
          {
            header: "아이이름",
            render: (item: IDone) => (
              <Text>{item.user_pets.map((pet) => pet.name).join(", ")}</Text>
            ),
          },
          {
            header: "PROVIDER",
            render: (item: IDone) => {
              let paymentTypes = [];
              if (Math.round(item.cade_payment * 100) !== 0) {
                paymentTypes.push("카드");
              }
              if (Math.round(item.cash_payment * 100) !== 0) {
                paymentTypes.push("현금");
              }
              if (item.cash_receipt) {
                paymentTypes.push("현금영수증");
              }
              if (Math.round(item.bank_payment * 100) !== 0) {
                paymentTypes.push("계좌");
              }
              if (item.bank_receipt) {
                paymentTypes.push("계좌현금영수증");
              }
              if (Math.round(item.donation_payment * 100) !== 0) {
                paymentTypes.push("기부금");
              }
              if (Math.round(item.discount_payment * 100) !== 0) {
                paymentTypes.push("할인");
              }
              let paymentType =
                paymentTypes.length > 0
                  ? paymentTypes.join(", ")
                  : "결제 정보 없음";
              return <Text>{paymentType}</Text>;
            },
          },
        ]}
        data={
          paymentyList ? paymentyList.pages.flatMap((page) => page.content) : []
        }
      />
    </Box>
  );
}
