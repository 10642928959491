import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <VStack bg="gray.100" minH={"100vh"} justifyContent={"center"}>
      <Heading>페이지를 찾을 수 없습니다.</Heading>
      <Text>잘못된 접근 입니다.</Text>
      <Link to={"/"}>
        <Button variant={"link"} colorScheme="red">
          홈 이동 &rarr;
        </Button>
      </Link>
    </VStack>
  );
}