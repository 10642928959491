import {
  Box,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  useBreakpointValue,
} from "@chakra-ui/react";
import { IAccountItem } from "./KsStatisAccount";
import { getAccount } from "../../../api";
import { useQuery } from "@tanstack/react-query";

export default function KsStatisAccountChk() {
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const flexBasis = useBreakpointValue({
    base: "32%",
    md: getAccounts ? `${100 / getAccounts.length - 0.5}%` : "100%",
  });
  return (
    <HStack spacing={1.5} wrap="wrap" mb={3}>
      {getAccounts &&
        getAccounts
          .filter((account) => account.frequently_used)
          .map((account) => (
            <Box key={account.pk} flexBasis={flexBasis} w={"100%"}>
              <Stat
                px={{ base: 2, md: 4 }}
                py={"1"}
                border={"1px solid"}
                borderColor={"gray.500"}
                rounded={"lg"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
              >
                <StatLabel
                  fontSize={{ base: "xs", md: "sm" }}
                  color={"gray.500"}
                >
                  {account.account_name}
                </StatLabel>
                <StatNumber fontSize={"lg"} fontWeight={700} color={"gray.600"}>
                  {Number(account.amount).toLocaleString()}원
                </StatNumber>
              </Stat>
            </Box>
          ))}
    </HStack>
  );
}
