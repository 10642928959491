import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IUserVariables, getEditMe } from "../../../../api";
import { useState } from "react";
import {
  NAME_VALIDATION_RULES_PATTERN,
  PHONE_VALIDATION_RULES_PATTERN,
} from "../../../../lib/constants";
import { useUserForm } from "../../../../lib/useUser";
import { IStatusOk } from "../../../../types";

interface IUserEditProps {
  userInfo: IUserVariables | undefined;
  borderColor: string;
  InputBorderColor: string;
  BoxBgColor: string;
  bgColor: string;
}
type AlertStatus =
  | "info"
  | "warning"
  | "success"
  | "error"
  | "loading"
  | undefined;

export default function UserAccountInfo({
  userInfo,
  borderColor,
  InputBorderColor,
  BoxBgColor,
  bgColor,
}: IUserEditProps) {
  const { control, handleSubmit, errors } = useUserForm(userInfo);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<AlertStatus>();
  const queryClient = useQueryClient();
  const mutation = useMutation(getEditMe, {
    onSuccess: (data: IStatusOk) => {
      if (data.status === 400) {
        setMessage(data.ok);
        setStatus("error");
      } else {
        setMessage("프로필이 업데이트 되었습니다.");
        setStatus("success");
      }
      queryClient.refetchQueries(["me"]);
    },
  });
  const onSubmit = (data: IUserVariables) => {
    if (userInfo) data.username = userInfo?.username;
    mutation.mutate(data);
  };
  return (
    <Grid
      templateColumns={{ base: "1fr", md: "0.5fr 1fr 2fr 0.5fr" }}
      mt={5}
      mb={5}
    >
      <Box gridColumn={{ md: "2 / 3" }}>
        <Heading fontSize={"xl"}>Account Information</Heading>
      </Box>
      <Box
        gridColumn={{ md: "3 / 4" }}
        mt={{ base: "20px", md: "0px" }}
        bg={bgColor}
        rounded={"lg"}
        border={"1px solid"}
        borderColor={borderColor}
        overflow={"hidden"}
        justifyContent={"center"}
        alignItems={"center"}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={3}>
          {mutation.isLoading && (
            <Alert status="info">
              <AlertIcon />
              변경사항 저장 중...
            </Alert>
          )}
          {mutation.isSuccess && (
            <Alert status={status}>
              <AlertIcon />
              {message}
            </Alert>
          )}
        </Stack>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6} p={5}>
          <FormControl isInvalid={Boolean(errors.name?.message)}>
            <FormLabel htmlFor="name" fontWeight={"bold"}>
              Name
            </FormLabel>
            <Controller
              name="name"
              control={control}
              rules={NAME_VALIDATION_RULES_PATTERN}
              render={({ field }) => (
                <Input
                  {...field}
                  variant={"solid"}
                  borderWidth={1}
                  color={"gray.800"}
                  _placeholder={{
                    color: "gray.400",
                  }}
                  borderColor={
                    errors.name?.message ? "red.500" : InputBorderColor
                  }
                  required
                />
              )}
            />
          </FormControl>

          <FormControl isInvalid={Boolean(errors.phone?.message)}>
            <FormLabel htmlFor="phone" fontWeight={"bold"}>
              Phone
            </FormLabel>
            <InputGroup>
              <InputLeftAddon children={"+82"} />
              <Controller
                name="phone"
                control={control}
                rules={PHONE_VALIDATION_RULES_PATTERN}
                render={({ field }) => (
                  <Input
                    {...field}
                    variant={"solid"}
                    borderWidth={1}
                    color={"gray.800"}
                    _placeholder={{
                      color: "gray.400",
                    }}
                    borderColor={
                      errors.phone?.message ? "red.500" : borderColor
                    }
                    required
                  />
                )}
              />
            </InputGroup>
          </FormControl>

          <FormControl isInvalid={Boolean(errors.address?.message)}>
            <FormLabel htmlFor="address" fontWeight={"bold"}>
              Address
            </FormLabel>
            <Controller
              name="address"
              control={control}
              rules={{
                maxLength: 50,
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  variant={"solid"}
                  borderWidth={1}
                  color={"gray.800"}
                  _placeholder={{
                    color: "gray.400",
                  }}
                  borderColor={
                    errors.address?.message ? "red.500" : borderColor
                  }
                />
              )}
            />
          </FormControl>
        </Grid>
        <Box textAlign="right" p={4} mt={2} bg={BoxBgColor}>
          <Button
            isLoading={mutation.isLoading}
            type="submit"
            colorScheme="blue"
            p={5}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
