import {
  Box,
  Button,
  Grid,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTab,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMultiStyleConfig } from "@chakra-ui/system";
import { Control } from "react-hook-form";
import {
  BODY_LENGTH_CLIP_NAME_LABEL,
  BODY_LENGTH_CLIP_NAME_PLACEHOLDER,
  BODY_LENGTH_CLIP_OPTIONS,
  BODY_LENGTH_SCISSORS_CUT_OPTIONS,
  BODY_LENGTH_SPOTTING_OPTIONS,
  EAR_SHAPE_NAME_LABEL,
  EAR_SHAPE_NAME_PLACEHOLDER,
  EAR_SHAPE_OPTIONS,
  FACE_STYLE_NAME_LABEL,
  FACE_STYLE_NAME_PLACEHOLDER,
  FACE_STYLE_OPTIONS,
  FOOT_SHAPE_BATH_BASICS_OPTIONS,
  FOOT_SHAPE_NAME_LABEL,
  FOOT_SHAPE_NAME_PLACEHOLDER,
  FOOT_SHAPE_OPTIONS,
  LEG_LENGTH_CLIP_NAME_LABEL,
  LEG_LENGTH_CLIP_NAME_PLACEHOLDER,
  LEG_LENGTH_CLIP_OPTIONS,
  LEG_LENGTH_SCISSORS_CUT_OPTIONS,
  LEG_LENGTH_SPOTTING_OPTIONS,
  TAIL_SHAPE_NAME_LABEL,
  TAIL_SHAPE_NAME_PLACEHOLDER,
  TOAIL_SHAPE_OPTIONS,
} from "../lib/constants";
import { BeautySelectForm } from "./FormGroup";
import { IBookingUpdateVariables } from "../api";

interface IBeautySelectsProps {
  control: Control<any>;
  errors: any;
  reset: Function;
  getValues: Function;
  setValue: Function;
  updateBooking?: IBookingUpdateVariables[];
  guestBooking?: boolean;
}

type CustomTabProps = React.PropsWithChildren<{}>;

export default function BeautyCustomTabs({
  control,
  errors,
  reset,
  getValues,
  setValue,
  updateBooking,
  guestBooking,
}: IBeautySelectsProps) {
  const [tabIndex, setTabIndex] = useState(1);
  const tabKeys = ["목욕", "목욕+기본", "클리핑", "스포팅", "가위컷", "기타"];

  /* eslint-disable */
  useEffect(() => {
    if (updateBooking) {
      const beautyService = updateBooking[0].beauty_service;
      const index = tabKeys.indexOf(beautyService);
      if (index !== -1) {
        setTabIndex(index);
      }
      setValue("beauty_service", tabKeys[index]);
    }
  }, [updateBooking]);
  useEffect(() => {
    setValue("beauty_service", tabKeys[tabIndex]);
  }, []);

  const CustomTab = React.forwardRef<HTMLElement, CustomTabProps>(
    (props, ref) => {
      const tabProps = useTab({ ...props, ref });
      const isSelected = !!tabProps["aria-selected"];
      const styles = useMultiStyleConfig("Tabs", tabProps);
      return (
        <Button __css={styles.tab} {...tabProps} flex="1" whiteSpace="nowrap">
          <Box as="span" mr="2">
            {isSelected ? "😎" : "😐"}
          </Box>
          {tabProps.children}
        </Button>
      );
    }
  );
  return (
    <Tabs
      key={tabIndex}
      defaultIndex={tabIndex}
      onChange={(index) => {
        setTabIndex(index);

        const tempBookingTime = getValues("booking_time");
        const tempUserPets = getValues("user_pets");
        const pk = getValues("pk");
        const owner = getValues("owner");
        const newBeautyService = tabKeys[index];
        let name,
          phone,
          breed,
          weigth,
          gender,
          neutering,
          question6,
          question7,
          significant,
          condition,
          degreeOfTangles;
        if (guestBooking == true) {
          name = getValues("pet.name");
          phone = getValues("user.phone");
          breed = getValues("pet.breed");
          weigth = getValues("pet.weigth");
          gender = getValues("pet.gender");
          neutering = getValues("pet.neutering");
          question6 = getValues("pet.question6");
          question7 = getValues("pet.question7");
          significant = getValues("pet.significant");
          degreeOfTangles = getValues("booking.degree_of_tangles");
          condition = getValues("booking.condition");
        } else {
          degreeOfTangles = getValues("degree_of_tangles");
          condition = getValues("condition");
        }
        reset({
          pk: pk,
          beauty_service: tabKeys[index],
          face_style: "",
          body_length_clip: "",
          body_length_spotting: "",
          body_length_scissors_cut: "",
          leg_length_clip: "",
          leg_length_spotting: "",
          leg_length_scissors_cut: "",
          ear_shape: "",
          foot_shape: "",
          foot_shape_Bath_basics: "",
          tail_shape: "",
        });
        if (guestBooking == true) {
          setValue("pet.name", name);
          setValue("pet.owner", owner);
          setValue("user.phone", phone);
          setValue("pet.breed", breed);
          setValue("pet.weigth", weigth);
          setValue("pet.gender", gender);
          setValue("pet.neutering", neutering);
          setValue("pet.question6", question6);
          setValue("pet.question7", question7);
          setValue("pet.significant", significant);
          setValue("booking.degree_of_tangles", degreeOfTangles);
          setValue("booking.condition", condition);
        } else {
          setValue("degree_of_tangles", degreeOfTangles);
          setValue("condition", condition);
        }
        setValue("booking_time", tempBookingTime);
        setValue("user_pets", tempUserPets);
        setValue("beauty_service", newBeautyService);
      }}
    >
      <TabList>
        <Grid templateColumns={["repeat(3, 1fr)", null, "repeat(6, 1fr)"]}>
          <CustomTab>목욕</CustomTab>
          <CustomTab>목욕+기본</CustomTab>
          <CustomTab>클리핑</CustomTab>
          <CustomTab>스포팅</CustomTab>
          <CustomTab>가위컷</CustomTab>
          <CustomTab>기타</CustomTab>
        </Grid>
      </TabList>
      <TabPanels>
        <TabPanel>{tabIndex === 0 && "선택 없음"}</TabPanel>
        <TabPanel>
          {tabIndex === 1 && (
            <>
              <BeautySelectForm
                control={control}
                errors={errors}
                name="face_style"
                label={FACE_STYLE_NAME_LABEL}
                placeholder={FACE_STYLE_NAME_PLACEHOLDER}
                initialOptions={FACE_STYLE_OPTIONS}
                excludeKeys={[]}
                defaultValue="동글동글(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="foot_shape_Bath_basics"
                label={FOOT_SHAPE_NAME_LABEL}
                placeholder={`${FOOT_SHAPE_NAME_PLACEHOLDER}(목욕+기본)`}
                initialOptions={FOOT_SHAPE_BATH_BASICS_OPTIONS}
                excludeKeys={[]}
              />
            </>
          )}
        </TabPanel>
        <TabPanel>
          {tabIndex === 2 && (
            <>
              <BeautySelectForm
                control={control}
                errors={errors}
                name="face_style"
                label={FACE_STYLE_NAME_LABEL}
                placeholder={FACE_STYLE_NAME_PLACEHOLDER}
                initialOptions={FACE_STYLE_OPTIONS}
                excludeKeys={["NotSelected"]}
                defaultValue="동글동글(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="body_length_clip"
                label={BODY_LENGTH_CLIP_NAME_LABEL}
                placeholder={`${BODY_LENGTH_CLIP_NAME_PLACEHOLDER}(클리핑)`}
                initialOptions={BODY_LENGTH_CLIP_OPTIONS}
                excludeKeys={[]}
                defaultValue=""
                handleDataChange={(name, value) => {
                  setValue("leg_length_clip", value);
                }}
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="leg_length_clip"
                label={LEG_LENGTH_CLIP_NAME_LABEL}
                placeholder="다리길이는 몸길이와 같음"
                initialOptions={LEG_LENGTH_CLIP_OPTIONS}
                excludeKeys={[]}
                defaultValue=""
                handleDataChange={() => {}}
                disabled={true}
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="foot_shape"
                label={FOOT_SHAPE_NAME_LABEL}
                placeholder={FOOT_SHAPE_NAME_PLACEHOLDER}
                initialOptions={FOOT_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="없음(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="ear_shape"
                label={EAR_SHAPE_NAME_LABEL}
                placeholder={EAR_SHAPE_NAME_PLACEHOLDER}
                initialOptions={EAR_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="중간컷(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="tail_shape"
                label={TAIL_SHAPE_NAME_LABEL}
                placeholder={TAIL_SHAPE_NAME_PLACEHOLDER}
                initialOptions={TOAIL_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="중간컷(기본)"
              />
            </>
          )}
        </TabPanel>
        <TabPanel>
          {tabIndex === 3 && (
            <>
              <BeautySelectForm
                control={control}
                errors={errors}
                name="face_style"
                label={FACE_STYLE_NAME_LABEL}
                placeholder={FACE_STYLE_NAME_PLACEHOLDER}
                initialOptions={FACE_STYLE_OPTIONS}
                excludeKeys={["FaceMeme"]}
                defaultValue="동글동글(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="body_length_spotting"
                label={BODY_LENGTH_CLIP_NAME_LABEL}
                placeholder={`${BODY_LENGTH_CLIP_NAME_PLACEHOLDER}(스포팅)`}
                initialOptions={BODY_LENGTH_SPOTTING_OPTIONS}
                excludeKeys={[]}
                defaultValue="3_mm"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="leg_length_spotting"
                label={LEG_LENGTH_CLIP_NAME_LABEL}
                placeholder={`${LEG_LENGTH_CLIP_NAME_PLACEHOLDER}(스포팅)`}
                initialOptions={LEG_LENGTH_SPOTTING_OPTIONS}
                excludeKeys={[]}
                defaultValue=""
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="foot_shape"
                label={FOOT_SHAPE_NAME_LABEL}
                placeholder={FOOT_SHAPE_NAME_PLACEHOLDER}
                initialOptions={FOOT_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="없음(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="ear_shape"
                label={EAR_SHAPE_NAME_LABEL}
                placeholder={EAR_SHAPE_NAME_PLACEHOLDER}
                initialOptions={EAR_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="중간컷(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="tail_shape"
                label={TAIL_SHAPE_NAME_LABEL}
                placeholder={TAIL_SHAPE_NAME_PLACEHOLDER}
                initialOptions={TOAIL_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="중간컷(기본)"
              />
            </>
          )}
        </TabPanel>
        <TabPanel>
          {tabIndex === 4 && (
            <>
              <BeautySelectForm
                control={control}
                errors={errors}
                name="face_style"
                label={FACE_STYLE_NAME_LABEL}
                placeholder={FACE_STYLE_NAME_PLACEHOLDER}
                initialOptions={FACE_STYLE_OPTIONS}
                excludeKeys={["NotSelected"]}
                defaultValue="동글동글(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="body_length_scissors_cut"
                label={BODY_LENGTH_CLIP_NAME_LABEL}
                placeholder={`${BODY_LENGTH_CLIP_NAME_PLACEHOLDER}(가위컷)`}
                initialOptions={BODY_LENGTH_SCISSORS_CUT_OPTIONS}
                excludeKeys={[]}
                defaultValue="3_mm"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="leg_length_scissors_cut"
                label={LEG_LENGTH_CLIP_NAME_LABEL}
                placeholder={`${LEG_LENGTH_CLIP_NAME_PLACEHOLDER}(가위컷)`}
                initialOptions={LEG_LENGTH_SCISSORS_CUT_OPTIONS}
                excludeKeys={[]}
                defaultValue=""
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="foot_shape"
                label={FOOT_SHAPE_NAME_LABEL}
                placeholder={FOOT_SHAPE_NAME_PLACEHOLDER}
                initialOptions={FOOT_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="없음(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="ear_shape"
                label={EAR_SHAPE_NAME_LABEL}
                placeholder={EAR_SHAPE_NAME_PLACEHOLDER}
                initialOptions={EAR_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="중간컷(기본)"
              />
              <BeautySelectForm
                control={control}
                errors={errors}
                name="tail_shape"
                label={TAIL_SHAPE_NAME_LABEL}
                placeholder={TAIL_SHAPE_NAME_PLACEHOLDER}
                initialOptions={TOAIL_SHAPE_OPTIONS}
                excludeKeys={[]}
                defaultValue="중간컷(기본)"
              />
            </>
          )}
        </TabPanel>
        <TabPanel>{tabIndex === 5 && "선택 없음"}</TabPanel>
      </TabPanels>
    </Tabs>
  );
}
