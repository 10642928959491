import {
  AspectRatio,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";

interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function MapModal({ onClose, isOpen }: MapModalProps) {
  const ratio = useBreakpointValue({ base: 16 / 24, sm: 16 / 12 });
  return (
    <Modal size={"6xl"} onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>콩삼 위치</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AspectRatio ratio={ratio} mb={5}>
            <Box
              as="iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d961.9004454182151!2d127.14735305307353!3d35.81381271579691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35702499b5a6e2c1%3A0x3e3d5fa5498b5497!2z7L2p7IK8!5e0!3m2!1sko!2skr!4v1704535017455!5m2!1sko!2skr"
              border="0"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </AspectRatio>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
