import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  useColorMode,
} from "@chakra-ui/react";

interface Column {
  header: string;
  render: (item: any) => React.ReactNode; // 각 행의 데이터를 렌더링하는 함수
}

interface MyTableProps<T> {
  caption?: number;
  label: string;
  fetchNextPage?: () => void;
  hasNextPage?: boolean | undefined;
  isFetchingNextPage?: boolean;
  columns: Column[];
  data?: T[];
}

export const MyTable = <T,>({
  caption,
  label,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  columns,
  data,
}: MyTableProps<T>) => {
  const { colorMode } = useColorMode();
  return (
    <Table variant="simple" minWidth="full" fontSize={"sm"} color={"gray.500"}>
      <TableCaption>
        {label} Info - {caption}건
        {hasNextPage ? (
          <Button
            colorScheme="gray"
            variant="outline"
            w={"100%"}
            mt={3}
            onClick={fetchNextPage}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? "is Loding..."
              : hasNextPage
              ? `${label} 더보기`
              : `${label} End`}
          </Button>
        ) : null}
      </TableCaption>
      <Thead>
        <Tr>
          {columns.map((column, index) => (
            <Th pt={5} pb={5} fontSize={"sm"} key={index}>
              {column.header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data?.map((item, index) => (
          <Tr
            bg={
              index % 2 === 0
                ? colorMode === "dark"
                  ? "gray.700"
                  : "gray.50"
                : colorMode === "dark"
                ? "gray.800"
                : "white"
            }
            key={index}
          >
            {columns.map((column, colIndex) => (
              <Td whiteSpace="nowrap" key={colIndex}>
                {column.render(item)}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
