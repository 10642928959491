import { ReactNode } from "react";
import {
  Box,
  Container,
  Image,
  Stack,
  Text,
  VStack,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaInstagram, FaGithub } from "react-icons/fa";
import kongsamLogo from "../../img/kongsamLogo.png";
import { RiKakaoTalkLine } from "react-icons/ri";

export const Logo = () => {
  return <Image src={kongsamLogo} alt="kongsam logo" w="70px" />;
};
const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <Box
      mt={10}
      bg={useColorModeValue("#f7f7f7", "#1F2937")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Box
        bg={useColorModeValue("gray.50", "gray.900")}
        color={useColorModeValue("gray.700", "gray.200")}
      >
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <VStack spacing={1}>
            <Logo />
            <Text textColor={"gray.500"} fontSize={"sm"}>
              콩삼 강아지미용실
            </Text>
          </VStack>
          <Text>© 2021-{year} Kongsam. All rights reserved</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Github"}
              href={"https://github.com/bristolkon3802"}
            >
              <FaGithub />
            </SocialButton>
            <SocialButton
              label={"Instagram"}
              href={"https://www.instagram.com/eun_hye27/"}
            >
              <FaInstagram />
            </SocialButton>
            <SocialButton
              label={"KakaoOpenchat"}
              href={"https://open.kakao.com/o/sTz6DcWe"}
            >
              <RiKakaoTalkLine />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
