import {
  Box,
  Stack,
  Text,
  Card,
  Heading,
  CardBody,
  StackDivider,
  Flex,
  Button,
  Input,
  FormControl,
  HStack,
  FormLabel,
  Checkbox,
  NumberInput,
  NumberInputField,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IAccountItem } from "./KsStatisAccount";
import {
  IIsErrorMessage,
  ISuccessMessage,
  getAccount,
  putKsAccount,
} from "../../../api";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AxiosError } from "axios";
export default function KsStatisAccountList() {
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );

  const [editing, setEditing] = useState<number | null>(null);
  const { register, control, handleSubmit, setValue, reset } =
    useForm<IAccountItem>({});
  useEffect(() => {
    if (editing !== null) {
      const accountToEdit = getAccounts?.find(
        (account) => account.pk === editing
      );
      if (accountToEdit) {
        setValue("pk", accountToEdit.pk);
        setValue("account_number", accountToEdit.account_number);
        setValue("account_name", accountToEdit.account_name);
        setValue("amount", accountToEdit.amount);
        setValue("notes", accountToEdit.notes);
        setValue("frequently_used", accountToEdit.frequently_used);
      }
    } else {
      reset();
    }
  }, [editing, getAccounts, reset, setValue]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const updateExpenseAccountMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IAccountItem
  >(putKsAccount, {
    onSuccess: () => {
      toast({
        title: "결제수단 수정!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`, `expense-account`]);
      setEditing(null);
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description:
          typeof error.response?.data.error === "object"
            ? JSON.stringify(error.response?.data.error)
            : error.response?.data.error,
        status: "error",
      });
    },
  });
  const onUpdateSubmit = (data: IAccountItem) => {
    updateExpenseAccountMutation.mutate(data);
  };
  /* const deleteExpenseAccountMutation = useMutation(deleteKsAccount, {
    onSuccess: () => {
      toast({
        title: "결제수단 수정!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`, `expense-account`]);
      setEditing(null);
    },
  });
  const onDeleteSubmit = (pk: number) => {
    if (pk !== 0 && window.confirm(`결제수단을 삭제하시 겠습니까?`)) {
      deleteExpenseAccountMutation.mutate(pk);
    }
  }; */

  return (
    <Card mb={10}>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="4" color={"gray.500"}>
          {getAccounts &&
            getAccounts.map((account, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box w={"100%"}>
                  {editing === account.pk ? (
                    <>
                      <Box as={"form"} onSubmit={handleSubmit(onUpdateSubmit)}>
                        <FormControl mb={3}>
                          <FormLabel>
                            {account.account_name} - 정보 수정
                          </FormLabel>
                          <FormControl display="flex" alignItems="center">
                            <FormLabel
                              htmlFor={`frequently_used`}
                              mb="0"
                              fontSize={"sm"}
                              color={"gray.500"}
                            >
                              자주 사용하는 계좌
                            </FormLabel>
                            <Controller
                              name="frequently_used"
                              control={control}
                              render={({ field }) => (
                                <Checkbox
                                  size={"md"}
                                  isChecked={field.value}
                                  onChange={(e) =>
                                    field.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          </FormControl>
                          <HStack>
                            <Input
                              {...register(`account_number`)}
                              placeholder="계좌번호"
                            />
                            <Input
                              {...register(`account_name`)}
                              placeholder="계좌명"
                            />
                          </HStack>
                          <Controller
                            control={control}
                            name={`amount`}
                            render={({ field }) => (
                              <NumberInput
                                mt={2}
                                onChange={(valueAsString, valueAsNumber) =>
                                  field.onChange(valueAsNumber)
                                }
                                onBlur={field.onBlur}
                                value={field.value || ""}
                              >
                                <NumberInputField placeholder="통장의 잔액" />
                              </NumberInput>
                            )}
                          />
                          <Textarea
                            mt={2}
                            mb={3}
                            {...register(`notes`)}
                            placeholder="비고를 입력하세요"
                          />
                        </FormControl>
                        <Button
                          size={"sm"}
                          colorScheme="teal"
                          mr="2"
                          type="submit"
                        >
                          수정
                        </Button>
                        <Button
                          size={"sm"}
                          colorScheme="red"
                          onClick={() => setEditing(null)}
                        >
                          취소
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Flex align="center">
                        <Heading
                          size="xs"
                          textTransform="uppercase"
                          mr="2"
                          fontWeight={700}
                          fontSize={"lg"}
                          color={"gray.600"}
                        >
                          <Flex align="center">
                            <Text as="span" fontSize="lg">
                              {account.account_name}
                            </Text>
                            {account.frequently_used ? (
                              <Text as="span" fontSize="sm" ml="2">
                                ✅
                              </Text>
                            ) : (
                              <Text as="span" fontSize="sm" ml="2">
                                ❌
                              </Text>
                            )}
                          </Flex>
                        </Heading>
                        <Button
                          size="sm"
                          colorScheme="teal"
                          mr="2"
                          variant="link"
                          onClick={() =>
                            setEditing(account.pk ? account.pk : null)
                          }
                        >
                          수정
                        </Button>
                        {/* <Button
                          size="sm"
                          colorScheme="red"
                          variant="link"
                          onClick={() =>
                            onDeleteSubmit(account.pk ? account.pk : 0)
                          }
                        >
                          삭제
                        </Button> */}
                      </Flex>
                      <Text pt="1" fontSize="sm">
                        계좌번호 {account.account_number}
                      </Text>
                      <Text pt="1" fontSize="sm">
                        잔액{" "}
                        <Text as="span" color="gray" fontSize={"md"}>
                          {Number(account.amount).toLocaleString()}원
                        </Text>
                      </Text>
                      <Text pt="1" fontSize="sm">
                        특이사항 - {account.notes ? account.notes : "-"}
                      </Text>
                    </>
                  )}
                </Box>
              </Flex>
            ))}
        </Stack>
      </CardBody>
    </Card>
  );
}
