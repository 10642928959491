import { useQuery } from "@tanstack/react-query";
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IPet } from "../../../../types";
import { getPetsInfo } from "../../../../api";
import useUser from "../../../../lib/useUser";
import { useKsProfileMultipleDisclosure } from "../../../hook/useMultipleDisclosure";
import MyPesIntoModal from "./MyPesIntoModal";
import { useState } from "react";
import MyBookingCreateModal from "./MyBookingCreateModal";
import MyBookingEditModal from "./MyBookingEditModal";
import { Link } from "react-router-dom";

export default function MyPets() {
  const { user } = useUser();
  const disclosures = useKsProfileMultipleDisclosure();
  const { data: userPetList } = useQuery<IPet[]>(
    ["pets", "ks-pets", user?.username],
    getPetsInfo,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const [petPk, setPetPk] = useState(0);
  const [userPk, setUserPk] = useState(0);
  const [userUsername, setUsername] = useState("");
  const handlePetInfoOpenModal = (petPk: number) => {
    setPetPk(petPk);
    disclosures.MyPesIntoModal.onOpen();
  };
  const handlePetResCreateOpenModal = (petPk: number, userPk: number) => {
    setPetPk(petPk);
    setUserPk(userPk);
    disclosures.MyBookingCreateModal.onOpen();
  };
  const handlePetResEditOpenModal = (
    petPk: number,
    userPk: number,
    username: string
  ) => {
    setPetPk(petPk);
    setUserPk(userPk);
    setUsername(username);
    disclosures.MyBookingEditModal.onOpen();
  };

  return (
    <Container maxW="container.xl">
      <Flex wrap="wrap" justifyContent="center" alignItems="center">
        {userPetList?.map((pet) => (
          <Box
            key={pet.pk}
            maxW={"300px"}
            w={"full"}
            boxShadow={"2xl"}
            rounded={"lg"}
            p={4}
            m={10}
            textAlign={"center"}
          >
            <Avatar
              name={pet?.name}
              src={pet?.pet_avatar}
              size={"xl"}
              pos={"relative"}
              mb={6}
            />
            <HStack justifyContent="center" alignItems={"start"} spacing={8}>
              <VStack align="start" spacing={0}>
                <Text>Pet Name</Text>
                <Text fontSize={28} color={"gray.600"}>
                  {pet?.name}
                </Text>
              </VStack>
            </HStack>
            <HStack
              justifyContent="center"
              alignItems={"start"}
              spacing={4}
              mt={8}
            >
              <Button
                colorScheme="green"
                variant="solid"
                w="11rem"
                fontSize={"14px"}
                onClick={() => handlePetInfoOpenModal(pet.pk)}
              >
                펫 프로필
              </Button>
              <Link to={`/ksProfile/pet-edit-profile/${pet.pk}`}>
                <Button
                  colorScheme="green"
                  variant="outline"
                  fontSize={"14px"}
                  w="5rem"
                >
                  펫 편집
                </Button>
              </Link>
              {pet.is_Booking ? (
                <Button
                  colorScheme="green"
                  variant="outline"
                  w="11rem"
                  fontSize={"14px"}
                  onClick={() => {
                    handlePetResEditOpenModal(
                      pet.pk,
                      pet.owner.pk,
                      pet.owner.username
                    );
                  }}
                >
                  예약 수정
                </Button>
              ) : (
                <Button
                  colorScheme="green"
                  variant="outline"
                  w="11rem"
                  fontSize={"14px"}
                  onClick={() => {
                    if (pet.is_Booking) {
                      alert("예약이 이미 되어 있습니다.");
                    } else {
                      handlePetResCreateOpenModal(pet.pk, pet.owner.pk);
                    }
                  }}
                >
                  미용 예약
                </Button>
              )}
            </HStack>
          </Box>
        ))}
      </Flex>

      {disclosures.MyPesIntoModal.isOpen && petPk !== 0 && (
        <MyPesIntoModal
          isOpen={disclosures.MyPesIntoModal.isOpen}
          onClose={disclosures.MyPesIntoModal.onClose}
          petPk={petPk}
        />
      )}
      {disclosures.MyBookingCreateModal.isOpen &&
        petPk !== 0 &&
        userPk !== 0 && (
          <MyBookingCreateModal
            isOpen={disclosures.MyBookingCreateModal.isOpen}
            onClose={disclosures.MyBookingCreateModal.onClose}
            petPk={petPk}
            userPk={userPk}
          />
        )}
      {disclosures.MyBookingEditModal.isOpen &&
        petPk !== 0 &&
        userPk !== 0 &&
        userUsername !== "" && (
          <MyBookingEditModal
            isOpen={disclosures.MyBookingEditModal.isOpen}
            onClose={disclosures.MyBookingEditModal.onClose}
            petPk={petPk}
            userPk={userPk}
            username={userUsername}
          />
        )}
    </Container>
  );
}
