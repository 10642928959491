import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IUploadPetVariables,
  getEditPet,
  getQuestion,
  getUserPetDetail,
} from "../../api";
import { Controller, useForm } from "react-hook-form";
import {
  FormCheckboxGroup,
  FormInput,
  FormRadioGroup,
  FormSelect,
  FormTextarea,
} from "../FormGroup";
import {
  PET_BREED_OPTIONS,
  PET_GENDER_OPTIONS,
  PET_NEUTERING_OPTIONS,
  PET_QUESTION_OPTIONS,
  PET_WEIGTH_OPTIONS,
} from "../../lib/constants";
import { PiDogThin } from "react-icons/pi";
import { IQuestionProps } from "./PetCreateModal";
import { IPet } from "../../types";

interface IPetProfile {
  isOpen: boolean;
  onClose: () => void;
  userPetPk: number;
}

export default function PetEditModal({
  isOpen,
  onClose,
  userPetPk,
}: IPetProfile) {
  const bgColor = useColorModeValue("white", "whiteAlpha.100");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const queryClient = useQueryClient();

  const { data: selectedPet } = useQuery<IPet>(
    [`pets`, `${userPetPk}`],
    getUserPetDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const { data: question } = useQuery<IQuestionProps>(
    [`pets`, `question`],
    getQuestion,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IUploadPetVariables>();

  useEffect(() => {
    if (selectedPet) {
      setValue("pk", userPetPk);
      setValue("name", selectedPet.name);
      setValue("gender", selectedPet.gender);
      setValue("breed", selectedPet.breed);
      setValue("neutering", selectedPet.neutering);
      setValue("weigth", selectedPet.weigth);
      setValue("birthday", selectedPet.birthday);
      setValue("question1", selectedPet.question1);
      setValue("question2", selectedPet.question2);
      setValue("question3", selectedPet.question3);
      setValue("question4", selectedPet.question4);
      setValue("question5", selectedPet.question5);
      setValue("question6", selectedPet.question6);
      setValue("question7", selectedPet.question7);
      setValue("significant", selectedPet.significant);
    }
  }, [selectedPet, userPetPk, setValue]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const toast = useToast();
  const mutation = useMutation(getEditPet, {
    onSuccess: () => {
      toast({
        title: "수정되었습니다.",
        status: "success",
      });
      queryClient.refetchQueries([`pets`]);
      handleClose();
    },
  });

  const onSubmit = (data: IUploadPetVariables) => {
    const newData = {
      ...data,
      owner: 0,
      birthday: new Date(data.birthday).toISOString().split("T")[0],
      question6: data.question6.map((item) => item.pk),
      question7: data.question7.map((item) => item.pk),
    };
    mutation.mutate(newData as unknown as IUploadPetVariables);
  };

  return (
    <Modal
      size={"5xl"}
      key={selectedPet?.pk}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"} color={"gray.600"}>
          {selectedPet?.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={{ base: 3, md: 5 }}>
            {selectedPet && (
              <Box
                gridColumn={{ md: "3 / 4" }}
                mt={{ base: "20px", md: "0px" }}
                bg={bgColor}
                rounded={"lg"}
                border={"1px solid"}
                borderColor={borderColor}
                overflow={"hidden"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid
                  templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                  gap={6}
                  mt={3}
                  p={2}
                >
                  <FormInput
                    name="name"
                    control={control}
                    label="우리아이 이름"
                    errors={errors}
                    borderColor={borderColor}
                  />
                  <FormControl isInvalid={Boolean(errors.birthday?.message)}>
                    <FormLabel htmlFor="birthday" fontWeight={"bold"}>
                      생일
                    </FormLabel>
                    <Controller
                      name="birthday"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={field.value || ""}
                          type="date"
                        />
                      )}
                    />
                    {errors.birthday && (
                      <FormErrorMessage>
                        {errors.birthday.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormSelect
                    name="breed"
                    control={control}
                    label="품종"
                    options={PET_BREED_OPTIONS}
                    errors={errors}
                  />
                  <FormSelect
                    name="weigth"
                    control={control}
                    label="체중(kg)"
                    options={PET_WEIGTH_OPTIONS}
                    errors={errors}
                  />
                  <FormRadioGroup
                    name={"gender"}
                    control={control}
                    label={"성별"}
                    options={PET_GENDER_OPTIONS}
                    errors={errors}
                  />
                  <FormRadioGroup
                    name={"neutering"}
                    control={control}
                    label={"중성화"}
                    options={PET_NEUTERING_OPTIONS}
                    errors={errors}
                  />
                </Grid>

                <Box gap={6} p={2}>
                  <FormTextarea
                    name="significant"
                    control={control}
                    label="특이사항"
                    errors={errors}
                    placeholder="특이사항 입력"
                  />
                </Box>

                <Box p={2}>
                  <FormRadioGroup
                    name={"question1"}
                    control={control}
                    label={"성격이 예민한 편인가요?"}
                    options={PET_QUESTION_OPTIONS}
                    errors={errors}
                  />
                  <FormRadioGroup
                    name={"question2"}
                    control={control}
                    label={"가끔 물기도 하나요?"}
                    options={PET_QUESTION_OPTIONS}
                    errors={errors}
                    FormMtProps={{ mt: 10 }}
                  />
                  <FormRadioGroup
                    name={"question3"}
                    control={control}
                    label={"분리불안이 있나요?"}
                    options={PET_QUESTION_OPTIONS}
                    errors={errors}
                    FormMtProps={{ mt: 10 }}
                  />
                  <FormRadioGroup
                    name={"question4"}
                    control={control}
                    label={"미용 후 가려워한적이 있나요?"}
                    options={PET_QUESTION_OPTIONS}
                    errors={errors}
                    FormMtProps={{ mt: 10 }}
                  />
                  <FormRadioGroup
                    name={"question5"}
                    control={control}
                    label={"미용 후 불안해하거나 평소와 달랐나요?"}
                    options={PET_QUESTION_OPTIONS}
                    errors={errors}
                    FormMtProps={{ mt: 10 }}
                  />
                  <FormCheckboxGroup
                    name="question6"
                    control={control}
                    label="싫어하거나 예민한 부분을 체크해주세요."
                    options={question?.questionsix}
                    errors={errors}
                  />
                  <FormCheckboxGroup
                    name="question7"
                    control={control}
                    label="아픈곳을 체크해 주세요."
                    options={question?.questionseven}
                    errors={errors}
                  />
                </Box>
              </Box>
            )}
            <ButtonGroup w="100%" py={"7"}>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  w="50%"
                  mr={3}
                  size={"lg"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  isLoading={mutation.isLoading}
                  w="50%"
                  colorScheme="red"
                  variant="solid"
                  size={"lg"}
                  type="submit"
                >
                  펫 정보 수정
                </Button>
              </Flex>
            </ButtonGroup>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <PiDogThin />
              <Text>kongsam pet put</Text>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
