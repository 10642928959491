import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { PiDogThin } from "react-icons/pi";
import { getKongsamGraphStatistics } from "../../api";
import { Bar } from "react-chartjs-2";
import { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import KsStatisGraph2 from "./KsStatisGraph2";
import KsStatisGraph3 from "./KsStatisGraph3";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface month_yearProps {
  year?: string;
  month?: string;
  count: number;
}
interface GraphStatsDataProps {
  user_count: number;
  yearly_users: month_yearProps[];
  monthly_users: month_yearProps[];
  pet_count: number;
  yearly_pets: month_yearProps[];
  monthly_pets: month_yearProps[];
  grooming_re_user_pets_count: number;
}
interface IPetProfile {
  isOpen: boolean;
  onClose: () => void;
}
export default function KsStatisGraph({ isOpen, onClose }: IPetProfile) {
  const { data } = useQuery<GraphStatsDataProps>(
    [`users`, `graph-statistics`],
    getKongsamGraphStatistics,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const yearlyUserLabels = data?.yearly_users.map((item) =>
    item.year ? new Date(item.year).getFullYear() : ""
  );
  const monthlyUserData = data?.monthly_users
    .filter(
      (item) =>
        item.month && new Date(item.month).getFullYear() === selectedYear
    )
    .reduce((acc: Record<number, number>, cur) => {
      if (cur.month) {
        const month = new Date(cur.month).getMonth() + 1;
        acc[month] = cur.count;
      }
      return acc;
    }, {});

  const monthlyUserCounts = Array.from(
    { length: 12 },
    (_, i) => monthlyUserData?.[i + 1] || 0
  );
  const monthlyPetData = data?.monthly_pets
    .filter(
      (item) =>
        item.month && new Date(item.month).getFullYear() === selectedYear
    )
    .reduce((acc: Record<number, number>, cur) => {
      if (cur.month) {
        const month = new Date(cur.month).getMonth() + 1;
        acc[month] = cur.count;
      }
      return acc;
    }, {});
  const monthlyPetCounts = Array.from(
    { length: 12 },
    (_, i) => monthlyPetData?.[i + 1] || 0
  );
  const labels = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];
  const chartData = {
    labels,
    datasets: [
      {
        label: `${selectedYear} 월별 유저`,
        data: monthlyUserCounts,
        backgroundColor: "rgba(255,99,132,0.4)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
      },
      {
        label: `${selectedYear} 월별 펫`,
        data: monthlyPetCounts,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "년도별 유저/펫 등록 챠트",
      },
    },
  };

  return (
    <Modal size={"4xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Bar data={chartData} options={options} />
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            {yearlyUserLabels
              ?.filter((year): year is number => typeof year === "number")
              .sort((a, b) => a - b)
              .map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </Select>
          <KsStatisGraph2 />
          <KsStatisGraph3 />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"center"}
            mt={10}
          >
            <PiDogThin />
            <Text>kongsam</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
