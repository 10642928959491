import { useEffect, useMemo, useReducer } from "react";
import {
  Box,
  Flex,
  HStack,
  Select,
  Stat,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Sale } from "../KsStatistics";

export interface MonthlySale extends Sale {
  month: string;
  count: number;
  cade_payment: number; //카드
  cash_payment: number; //현금
  cash_receipts_count: number; //현금영수증 건
  cash_receipts_amount: number; //현금영수증 총 금액
  bank_receipts_count: number;
  bank_receipts_amount: number;
  bank_payment: number; //계좌
  donation_payment: number; //기부금
  discount_payment: number; //할인
}

export interface WonStatsDataProps {
  yearly_sales: Sale[];
  monthly_sales: MonthlySale[];
}

interface State {
  selectedYear: number;
  selectedMonth: number;
  selectedSales: number;
  selectedCade: number;
  selectedCash: number;
  selectedReceipt_cash: { count: number; amount: number };
  selectedReceipt_bank: { count: number; amount: number };
  selectedBank: number;
  selectedDonation: number;
  selectedDiscount: number;
}
type Action =
  | { type: "UPDATE_SALES"; payload: Partial<State> }
  | { type: "other_action" }; // 이 부분은 필요에 따라 다른 action type을 추가하시면 됩니다.

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "UPDATE_SALES":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}
export default function KsStatisYearlyMonthCard({
  data,
  type,
}: {
  data: WonStatsDataProps | undefined;
  type: "sales" | "count";
}) {
  const currentDate = useMemo(() => {
    const date = new Date();
    return { year: date.getFullYear(), month: date.getMonth() + 1 };
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    selectedYear: currentDate.year,
    selectedMonth: currentDate.month,
    selectedSales: 0,
    selectedCade: 0,
    selectedCash: 0,
    selectedReceipt_cash: { count: 0, amount: 0 },
    selectedReceipt_bank: { count: 0, amount: 0 },
    selectedBank: 0,
    selectedDonation: 0,
    selectedDiscount: 0,
  });

  useEffect(() => {
    const monthlySales = data?.monthly_sales.find(
      (sale) =>
        Number(sale.year) === state.selectedYear &&
        Number(sale.month) === state.selectedMonth
    );

    if (!monthlySales) {
      dispatch({
        type: "UPDATE_SALES",
        payload: {
          selectedSales: 0,
          selectedCade: 0,
          selectedCash: 0,
          selectedReceipt_cash: { count: 0, amount: 0 },
          selectedReceipt_bank: { count: 0, amount: 0 },
          selectedBank: 0,
          selectedDonation: 0,
          selectedDiscount: 0,
        },
      });
      return;
    }

    if (type === "sales") {
      dispatch({
        type: "UPDATE_SALES",
        payload: {
          selectedSales: monthlySales.total_sales,
          selectedCade: monthlySales.cade_payment,
          selectedCash: monthlySales.cash_payment,
          selectedReceipt_cash: {
            count: monthlySales.cash_receipts_count || 0,
            amount: monthlySales.cash_receipts_amount || 0,
          },
          selectedReceipt_bank: {
            count: monthlySales.bank_receipts_count || 0,
            amount: monthlySales.bank_receipts_amount || 0,
          },
          selectedBank: monthlySales.bank_payment,
          selectedDonation: monthlySales.donation_payment,
          selectedDiscount: monthlySales.discount_payment,
        },
      });
    } else if (type === "count") {
      dispatch({
        type: "UPDATE_SALES",
        payload: { selectedSales: monthlySales.count },
      });
    }
  }, [state.selectedYear, state.selectedMonth, data, type]);
  const TitleSelect = (
    <HStack>
      <Select
        value={state.selectedYear}
        onChange={(event) =>
          dispatch({
            type: "UPDATE_SALES",
            payload: { selectedYear: Number(event.target.value) },
          })
        }
        variant={"outline"}
        size={"xs"}
      >
        <option value={currentDate.year}>{currentDate.year}년</option>
        <option value={currentDate.year - 1}>{currentDate.year - 1}년</option>
      </Select>

      <Select
        value={state.selectedMonth.toString().padStart(2, "0")}
        onChange={(event) =>
          dispatch({
            type: "UPDATE_SALES",
            payload: { selectedMonth: Number(event.target.value) },
          })
        }
        variant={"outline"}
        size={"xs"}
      >
        {[...Array(12)].map((_, i) => (
          <option value={(i + 1).toString().padStart(2, "0")} key={i}>
            {(i + 1).toString().padStart(2, "0")}월
          </option>
        ))}
      </Select>
    </HStack>
  );
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"1"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      {TitleSelect}
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            <HStack>
              <Text fontSize={"md"}>
                {state.selectedYear}년{" "}
                {state.selectedMonth.toString().padStart(2, "0")}월 수입 -
              </Text>
              <Text fontSize={"md"} fontWeight={700} color={"blue.300"}>
                ₩{" "}
                {Number(
                  state.selectedSales - state.selectedDonation
                ).toLocaleString()}
                원
              </Text>
            </HStack>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              카드 - ₩ {Number(state.selectedCade).toLocaleString()}원
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              현금 - ₩ {Number(state.selectedCash).toLocaleString()}원
              (현금영수증 - 총{" "}
              {state.selectedReceipt_cash.count !== 0
                ? `${state.selectedReceipt_cash.count}건•${state.selectedReceipt_cash.amount}원`
                : 0}
              )
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              계좌 - ₩ {Number(state.selectedBank).toLocaleString()}원
              (계좌현금영수증 - 총{" "}
              {state.selectedReceipt_bank.count !== 0
                ? `${state.selectedReceipt_bank.count}건•${state.selectedReceipt_bank.amount}원`
                : 0}
              )
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              기부 - ₩ {Number(state.selectedDonation).toLocaleString()}원
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              할인 - ₩ {Number(state.selectedDiscount).toLocaleString()}원
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"blue.400"}>
              합산금액 - ₩ {Number(state.selectedSales).toLocaleString()}원
            </Text>
          </StatNumber>
        </Box>
      </Flex>
    </Stat>
  );
}
