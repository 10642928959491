import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { FaLock, FaUserNinja } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  ISuccessMessage,
  IIsErrorMessage,
  IUsernameLoginVariables,
  usernameLogIn,
} from "../../api";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import MyUserConfirmationModal from "./MyUserConfirmationModal";
import { useKsProfileMultipleDisclosure } from "../hook/useMultipleDisclosure";

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LoginModal({ onClose, isOpen }: LoginModalProps) {
  const disclosures_user = useKsProfileMultipleDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUsernameLoginVariables>();

  const toast = useToast();
  const quesryClient = useQueryClient();
  const navigate = useNavigate();
  const mutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IUsernameLoginVariables
  >(usernameLogIn, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      });
      onClose();
      quesryClient.refetchQueries(["me"]);
      reset();
      navigate("/ks-admin/calendar");
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
    },
  });
  const onSubmit = (loginData: IUsernameLoginVariables) => {
    mutation.mutate(loginData);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"xs"} color={"gray.500"}>
          콩삼 로그인
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={"form"} onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color={"gray.400"}>
                    <FaUserNinja />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.username?.message)}
                {...register("username", {
                  required: "사용자 이름을 입력해주세요.",
                })}
                variant={"filled"}
                placeholder="사용자"
                required
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color={"gray.400"}>
                    <FaLock />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                })}
                type="password"
                variant={"filled"}
                placeholder="비밀번호"
                required
              />
            </InputGroup>
          </VStack>
          <Button
            isLoading={mutation.isLoading}
            type="submit"
            colorScheme="teal"
            variant="ghost"
            mt={4}
            mb={1}
            w={"100%"}
          >
            콩삼 로그인 &rarr;
          </Button>

          <Button
            w={"100%"}
            mb={1}
            colorScheme="red"
            variant="ghost"
            onClick={() => disclosures_user.MyUserConfirmationModal.onOpen()}
          >
            <Text color="red.300">콩삼 회원 확인 &rarr;</Text>
          </Button>
          {disclosures_user.MyUserConfirmationModal.isOpen && (
            <MyUserConfirmationModal
              isOpen={disclosures_user.MyUserConfirmationModal.isOpen}
              onClose={disclosures_user.MyUserConfirmationModal.onClose}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
