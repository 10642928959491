import {
  Box,
  Button,
  CloseButton,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getKongsamUsersList } from "../../api";
import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrDocumentUser, GrUpdate } from "react-icons/gr";
import { FcDataConfiguration } from "react-icons/fc";
import { MdOutlinePets } from "react-icons/md";
import UserInfoModal from "../modal/UserInfoModal";
import UserEditModal from "../modal/UserEditModal";
import { RenderFlex } from "../../utils/RenderFlex";
import PetCreateModal from "../modal/PetCreateModal";
import BookingCreateModal from "../modal/BookingCreateModal";
import { IKongsamUsersListResponse, IUser } from "../../types";
import UserCreateModal from "../modal/UserCreateModal";
import PetInfoListModal from "../modal/PetInfoListModal";
import { useUserListMultipleDisclosure } from "../hook/useMultipleDisclosure";

export default function KsUserList() {
  const { colorMode } = useColorMode();
  const disclosures = useUserListMultipleDisclosure();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";

  const [searchTerm, setSearchTerm] = useState("");
  const [searchOption, setSearchOption] = useState("pname");
  const [query, setQuery] = useState({ option: "pname", term: "" });
  const [userName, setUserName] = useState("");
  const [petPk, setPetPk] = useState(0);

  const {
    data: usersListData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamUsersListResponse>(
    ["users", JSON.stringify(query)],
    getKongsamUsersList,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.page.next_link) {
          return undefined;
        }
        const url = new URL(lastPage.page.next_link);
        return url.searchParams.get("page");
      },
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const handleSearch = () => {
    setQuery({ option: searchOption, term: searchTerm });
  };
  const handleUserInfoOpenModal = (username: string) => {
    setUserName(username);
    disclosures.UserInfoModal.onOpen();
  };
  const handlePetInfoListOpenModal = (username: string) => {
    setUserName(username);
    disclosures.PetInfoListModal.onOpen();
  };
  const handleBookingCreateOpenModal = (pk: number) => {
    setPetPk(pk);
    disclosures.BookingCreateModal.onOpen();
  };
  const handlePetCreateOpenModal = (pk: number, username: string) => {
    setPetPk(pk);
    setUserName(username);
    disclosures.PetCreateModal.onOpen();
  };
  const handleUserEditOpenModal = (username: string) => {
    setUserName(username);
    disclosures.UserEditModal.onOpen();
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          mb={3}
          colorScheme="blue"
          onClick={() => disclosures.UserCreateModal.onOpen()}
        >
          유저 등록
        </Button>
      </Box>
      <Box
        boxShadow={`0px 0px 8px ${boxShadowColor}`}
        overflowX="auto"
        rounded="md"
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={1}
          p={2}
          align="stretch"
          w="100%"
        >
          <Select
            onChange={(e) => setSearchOption(e.target.value)}
            w={{ base: "100%", md: "22%" }}
          >
            <option value="pname">펫 이름</option>
            <option value="phone">연락처</option>
            <option value="name">유저 이름</option>
          </Select>
          <InputGroup>
            <Input
              placeholder="유저 검색"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            {searchTerm && (
              <InputRightElement>
                <CloseButton onClick={() => setSearchTerm("")} />
              </InputRightElement>
            )}
          </InputGroup>
          <Button
            onClick={handleSearch}
            colorScheme={"gray"}
            w={{ base: "100%", md: "22%" }}
          >
            유저 {usersListData?.pages[0]?.page.count}명 검색
          </Button>
        </Stack>
        {usersListData?.pages.map((page, i) =>
          page.content.map((item: IUser, index) => (
            <Box
              key={item.pk}
              bg={
                index % 2 === 0
                  ? colorMode === "dark"
                    ? "gray.700"
                    : "gray.50"
                  : colorMode === "dark"
                  ? "gray.800"
                  : "white"
              }
              p={3}
              shadow="md"
              borderWidth="1px"
            >
              <Flex justifyContent="space-between">
                <Box>
                  <Popover placement="bottom" isLazy>
                    <PopoverTrigger>
                      <IconButton
                        aria-label="More server options"
                        icon={<BsThreeDotsVertical />}
                        variant="solid"
                        w="fit-content"
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      w="fit-content"
                      _focus={{ boxShadow: "none" }}
                    >
                      <PopoverArrow />
                      <PopoverBody>
                        <Stack>
                          <Button
                            w="220px"
                            variant="ghost"
                            rightIcon={<GrDocumentUser />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            fontSize="sm"
                            onClick={() =>
                              handleUserInfoOpenModal(item.username)
                            }
                          >
                            {item.name} _INFO
                          </Button>
                          <Tooltip
                            label={
                              item.pet_count === 0 ? "등록된반려견 없음" : ""
                            }
                          >
                            <Button
                              w="220px"
                              variant="ghost"
                              rightIcon={<MdOutlinePets />}
                              justifyContent="space-between"
                              fontWeight="normal"
                              fontSize="sm"
                              onClick={() => {
                                item.pet_count !== 0 &&
                                  handlePetInfoListOpenModal(item.username);
                              }}
                            >
                              PET _INFO {item.pet_count}
                            </Button>
                          </Tooltip>
                          <Tooltip
                            label={
                              item.pet_count === 0 ? "등록된반려견 없음" : ""
                            }
                          >
                            <Button
                              w="220px"
                              variant="ghost"
                              colorScheme="blue"
                              rightIcon={<FcDataConfiguration />}
                              justifyContent="space-between"
                              fontWeight="normal"
                              fontSize="sm"
                              onClick={() => {
                                item.pet_count !== 0 &&
                                  handleBookingCreateOpenModal(item.pk);
                              }}
                            >
                              미용 예약 등록
                            </Button>
                          </Tooltip>
                          <Button
                            w="220px"
                            variant="ghost"
                            colorScheme="blue"
                            rightIcon={<FcDataConfiguration />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            fontSize="sm"
                            onClick={() => {
                              handlePetCreateOpenModal(item.pk, item.username);
                            }}
                          >
                            펫 등록
                          </Button>
                          <Button
                            w="220px"
                            variant="ghost"
                            rightIcon={<GrUpdate />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            fontSize="sm"
                            colorScheme="green"
                            onClick={() =>
                              handleUserEditOpenModal(item.username)
                            }
                          >
                            {item.name} _INFO 수정
                          </Button>
                        </Stack>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
                <Text fontSize="lg" fontWeight="bold" lineHeight="short">
                  {item.name}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" pt={2}>
                <Text
                  ml={2}
                  textTransform="uppercase"
                  fontSize="sm"
                  color="gray.500"
                  fontWeight="bold"
                >
                  연락처
                </Text>
                <Text>
                  {item?.phone
                    ? item.phone.substring(0, 3) +
                      "-" +
                      item.phone.substring(3, 7) +
                      "-" +
                      item.phone.substring(7, 11)
                    : ""}
                </Text>
              </Flex>
              <RenderFlex
                label={"주소"}
                value={item.address ? item.address : "-"}
              />
            </Box>
          ))
        )}
        {hasNextPage ? (
          <Button
            colorScheme="gray"
            variant="outline"
            w={"100%"}
            mt={3}
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? "is Loding..."
              : hasNextPage
              ? "Users Load More"
              : "Users List End"}
          </Button>
        ) : null}
        {disclosures.UserInfoModal.isOpen && (
          <UserInfoModal
            isOpen={disclosures.UserInfoModal.isOpen}
            onClose={disclosures.UserInfoModal.onClose}
            username={userName}
          />
        )}
        {disclosures.PetInfoListModal.isOpen && (
          <PetInfoListModal
            isOpen={disclosures.PetInfoListModal.isOpen}
            onClose={disclosures.PetInfoListModal.onClose}
            username={userName}
          />
        )}
        {disclosures.UserEditModal.isOpen && (
          <UserEditModal
            isOpen={disclosures.UserEditModal.isOpen}
            onClose={disclosures.UserEditModal.onClose}
            username={userName}
          />
        )}
        {disclosures.PetCreateModal.isOpen && (
          <PetCreateModal
            isOpen={disclosures.PetCreateModal.isOpen}
            onClose={disclosures.PetCreateModal.onClose}
            username={userName}
            petPk={petPk}
          />
        )}
        {disclosures.BookingCreateModal.isOpen && (
          <BookingCreateModal
            isOpen={disclosures.BookingCreateModal.isOpen}
            onClose={disclosures.BookingCreateModal.onClose}
            isHostUserPk={petPk}
          />
        )}
        {disclosures.UserCreateModal.isOpen && (
          <UserCreateModal
            isOpen={disclosures.UserCreateModal.isOpen}
            onClose={disclosures.UserCreateModal.onClose}
          />
        )}
      </Box>
    </>
  );
}
