import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  Wrap,
  useToast,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import { Controller, useForm } from "react-hook-form";
import { PiDogThin } from "react-icons/pi";
import { formatDate } from "../../lib/utils";
import { useCallback, useMemo, useReducer } from "react";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IBookingVariables,
  ISingUpVariables,
  IUploadPetVariables,
  getBookingDateTimeCheck,
  getQuestion,
  uploadBeautyBooking,
  uploadPet,
  userSignup,
} from "../../api";
import {
  CONDITION_LABEL,
  CONDITION_OPTIONS,
  DATE_FORMAT,
  DATE_SEPARATOR,
  DEGRRE_OF_TANGLES_LABEL,
  DEGRRE_OF_TANGLES_OPTIONS,
  PET_BREED_OPTIONS,
  PET_GENDER_OPTIONS,
  PET_NEUTERING_OPTIONS,
  PET_WEIGTH_OPTIONS,
} from "../../lib/constants";
import { differenceBy } from "lodash";
import { FormInput, FormRadioGroup, FormTextarea } from "../FormGroup";
import BeautyCustomTabs from "../BeautyCustomTabs";
import { IQuestionProps } from "./PetCreateModal";
import { reducer } from "./BookingCreateModal";
import { IQuestionSeven, IQuestionSix } from "../../types";

interface IGuestBookingProps {
  isOpen: boolean;
  onClose: () => void;
}
interface IGuestBookingVariables {
  beauty_service: string;
  face_style: string;
  body_length_clip: string;
  body_length_spotting: string;
  body_length_scissors_cut: string;
  leg_length_clip: string;
  leg_length_spotting: string;
  leg_length_scissors_cut: string;
  ear_shape: string;
  foot_shape: string;
  foot_shape_Bath_basics: string;
  tail_shape: string;
  degree_of_tangles: string;
  condition: string;
  booking_time: string;
  booking_host: boolean;
  pet: {
    name: string;
    breed: string;
    weigth: string;
    significant: string;
    gender: string;
    neutering: string;
    question6: IQuestionSix[];
    question7: IQuestionSeven[];
    birthday: string;
  };
  user: {
    name: string;
    username: string;
    password: string;
    phone: string;
  };
}

export default function NewGuestBookingResModal({
  isOpen,
  onClose,
}: IGuestBookingProps) {
  const INITIAL_FORM_VALUES = {
    pet: {
      name: "",
      breed: "",
      weigth: "",
      significant: "",
      question6: [],
      question7: [],
    },
    user: {
      phone: "010",
    },
  };

  const { data: questionData } = useQuery<IQuestionProps>(
    [`pets`, `question`],
    getQuestion,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<IGuestBookingVariables>({});

  const [state, dispatch] = useReducer(reducer, {
    dates: undefined,
    selectedTimeIndex: null,
  });

  const { data } = useQuery(
    ["datetime-check", state.dates],
    getBookingDateTimeCheck,
    {
      cacheTime: 0,
      enabled: state.dates !== undefined,
    }
  );

  const bookingDateTime = (data || []).map((item: any) => {
    const booking_time = item.booking_time;
    return { ...item, booking_time };
  });

  const handleDateChange = useCallback(
    (value: Value, event: React.MouseEvent<HTMLButtonElement>) => {
      const dates = Array.isArray(value)
        ? value.filter((date): date is Date => date !== null)
        : [value];
      dispatch({ type: "dateChange", dates });
    },
    [dispatch]
  );

  const bookedTimes = useMemo(
    () =>
      bookingDateTime?.map((booking: any) => ({
        time: `${booking.booking_time
          .split(DATE_FORMAT)[1]
          .split(DATE_SEPARATOR)[0]
          .substring(0, 5)}`,
      })),
    [bookingDateTime]
  );

  const allTimes = useMemo(() => {
    if (!state.dates) {
      return;
    }
    const hours = Array.from({ length: 11 }, (_, i) => i + 9);
    const minutes = ["00", "30"];
    const times: { time: string }[] = [];

    hours.forEach((hour) => {
      minutes.forEach((minute) => {
        if (
          (hour === 19 && minute === "30") ||
          (hour === 9 && minute === "00")
        ) {
          return;
        }
        times.push({
          time: `${hour.toString().padStart(2, "0")}:${minute}`,
        });
      });
    });

    return times;
  }, [state.dates]);

  const availableTimes = useMemo(() => {
    if (!state.dates || !allTimes) {
      return;
    }
    return differenceBy(allTimes, bookedTimes, "time");
  }, [state.dates, allTimes, bookedTimes]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const guestBookingMutation = useMutation(uploadBeautyBooking, {
    onSuccess: (data) => {
      toast({
        title: "예약완료!",
        status: "success",
        isClosable: true,
      });
      reset(INITIAL_FORM_VALUES);
      onClose();
      queryClient.refetchQueries([`users`]);
      queryClient.refetchQueries([`pets`]);
      queryClient.refetchQueries([`bookings`]);
    },
  });

  const guestUserMutation = useMutation(userSignup);
  const guestPetMutation = useMutation(uploadPet);

  const onSubmit = (data: IGuestBookingVariables) => {
    const userData = {
      name: `이름모름(${data.pet.name})`,
      username: data.user.phone,
      phone: data.user.phone,
      is_guest_user: true,
    };
    const petData = {
      name: data.pet.name,
      breed: data.pet.breed,
      weigth: data.pet.weigth,
      gender: data.pet.gender,
      neutering: data.pet.neutering,
      question6: data.pet.question6.map((item) => item.pk),
      question7: data.pet.question7.map((item) => item.pk),
      significant: data.pet.significant,
      birthday: new Date().toISOString().split("T")[0],
      username: data.user.phone,
      is_guest_user: true,
    };
    const bookingData = {
      beauty_service: data.beauty_service,
      face_style: data.face_style,
      body_length_clip: data.body_length_clip,
      body_length_spotting: data.body_length_spotting,
      body_length_scissors_cut: data.body_length_scissors_cut,
      leg_length_clip: data.leg_length_clip,
      leg_length_spotting: data.leg_length_spotting,
      leg_length_scissors_cut: data.leg_length_scissors_cut,
      ear_shape: data.ear_shape,
      foot_shape: data.foot_shape,
      foot_shape_Bath_basics: data.foot_shape_Bath_basics,
      tail_shape: data.tail_shape,
      degree_of_tangles: data.degree_of_tangles,
      condition: data.condition,
      booking_time: data.booking_time,
      booking_host: true,
    };

    guestUserMutation.mutate(userData as unknown as ISingUpVariables, {
      onSuccess: (serData) => {
        toast({
          title: "유저등록!",
        });
        const updatePetData = {
          ...petData,
          owner: serData.pk,
        };
        guestPetMutation.mutate(
          updatePetData as unknown as IUploadPetVariables,
          {
            onSuccess: (data) => {
              toast({
                title: "펫등록!",
              });
              const updatedBookingData = {
                ...bookingData,
                user_pets: [data.pk],
                owner: data.owner.pk,
              };
              guestBookingMutation.mutate(
                updatedBookingData as unknown as IBookingVariables
              );
            },
          }
        );
      },
    });
  };
  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => {
        reset(INITIAL_FORM_VALUES);
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"} color={"gray.600"}>
          신규 등록
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"4xl"}>
            <Grid
              templateColumns={["1fr", null, "1fr 1fr"]}
              gap={{ base: 0, md: 10, lg: 20 }}
            >
              <Calendar
                calendarType="US"
                onChange={handleDateChange}
                prev2Label={null}
                next2Label={null}
                minDate={new Date()}
                maxDate={new Date(Date.now() + 60 * 60 * 24 * 7 * 4 * 6 * 1000)}
                minDetail="month"
                formatDay={(locale, date) =>
                  date.toLocaleString("en", { day: "numeric" })
                }
                tileDisabled={({ date, view }) =>
                  view === "month" && date.getDay() === 0
                }
              />

              <FormControl mt={{ base: 20, full: 0 }}>
                <FormLabel htmlFor={"booking_time"} fontWeight={"bold"}>
                  예약시간 선택
                </FormLabel>
                <Controller
                  name="booking_time"
                  control={control}
                  rules={{ required: "예약날짜 또는 시간을 선택해주세요." }}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      value={state.selectedTime}
                      onChange={(value) => {
                        setValue("booking_time", value);
                      }}
                    >
                      <Grid templateColumns="repeat(4, 1fr)">
                        {availableTimes && availableTimes?.length > 0 ? (
                          availableTimes.map((timeObj, index) => (
                            <Box key={`time${index}`}>
                              <Button
                                as="div"
                                variant="outline"
                                cursor={"pointer"}
                                mb={3}
                                w={{ base: "80px", md: "100px" }}
                              >
                                <Radio
                                  isInvalid={Boolean(
                                    errors.booking_time?.message
                                  )}
                                  value={`${formatDate(state.dates?.[0])} ${
                                    timeObj.time
                                  }`}
                                >
                                  {timeObj.time}
                                </Radio>
                              </Button>
                            </Box>
                          ))
                        ) : (
                          <Button w="100%" colorScheme="red" isDisabled={true}>
                            예약 완료
                          </Button>
                        )}
                      </Grid>
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Stack spacing={5}>
              <Flex
                direction={{ base: "column", md: "row" }}
                justify="space-between"
              >
                <Stack flex="1" spacing={5} mr={5}>
                  <FormControl mt={{ base: 5, lg: 0 }}>
                    <FormLabel htmlFor={"user.phone"} fontWeight={"bold"}>
                      Guest Phone
                    </FormLabel>
                    <Controller
                      name="user.phone"
                      control={control}
                      defaultValue={"010"}
                      rules={{ required: "Gest Phone" }}
                      render={({ field }) => (
                        <InputGroup>
                          <InputLeftAddon children="+82" />
                          <Input
                            {...field}
                            type="tel"
                            placeholder="phone number"
                          />
                        </InputGroup>
                      )}
                    />
                  </FormControl>

                  <FormControl isInvalid={Boolean(errors?.pet?.breed?.message)}>
                    <FormLabel htmlFor={"pet.breed"} fontWeight={"bold"}>
                      품종
                    </FormLabel>
                    <Controller
                      name={"pet.breed"}
                      control={control}
                      rules={{ required: "품종을 선택해주세요." }}
                      render={({ field }) => (
                        <Select {...field} value={field.value || ""}>
                          <option value="" disabled>
                            품종을 선택해 주세요.
                          </option>
                          {PET_BREED_OPTIONS.map((breed, index) => (
                            <option key={index} value={breed.value}>
                              {breed.label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors && (
                      <FormErrorMessage>
                        {errors?.pet?.breed?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isInvalid={Boolean(errors?.pet?.weigth?.message)}
                  >
                    <FormLabel htmlFor={"pweigth"} fontWeight={"bold"}>
                      체중(kg)
                    </FormLabel>
                    <Controller
                      name={"pet.weigth"}
                      control={control}
                      rules={{ required: "체중을 선택해주세요." }}
                      render={({ field }) => (
                        <Select {...field} value={field.value || ""}>
                          <option value="" disabled>
                            체중을 선택해 주세요.
                          </option>
                          {PET_WEIGTH_OPTIONS.map((breed, index) => (
                            <option key={index} value={breed.value}>
                              {breed.label}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    {errors && (
                      <FormErrorMessage>
                        {errors?.pet?.weigth?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Stack>
                <Stack flex="1" spacing={5}>
                  <FormInput
                    name="pet.name"
                    control={control}
                    defaultValue={""}
                    label="Pet Name"
                    errors={errors}
                    FormMtProps={{ mt: { base: 5, lg: 0 } }}
                  />
                  <FormRadioGroup
                    name={"pet.gender"}
                    control={control}
                    label={"성별"}
                    defaultValue={"남아"}
                    options={PET_GENDER_OPTIONS}
                    errors={errors}
                  />

                  <FormRadioGroup
                    name={"pet.neutering"}
                    control={control}
                    label={"중성화"}
                    defaultValue={"중성화"}
                    options={PET_NEUTERING_OPTIONS}
                    errors={errors}
                  />
                </Stack>
              </Flex>

              <Flex
                direction={{ base: "column", md: "row" }}
                justify="space-between"
                mb={5}
              >
                <Stack flex="1" spacing={5} mr={5}>
                  <FormControl
                    mt={{ base: 5, lg: 0 }}
                    isInvalid={Boolean(errors?.pet?.question6?.message)}
                  >
                    <FormLabel htmlFor={"question6"} fontWeight={"bold"}>
                      싫어하거나 예민한 부분을 체크해주세요.
                    </FormLabel>
                    <Controller
                      control={control}
                      name={"pet.question6"}
                      defaultValue={[]}
                      rules={{
                        required: "싫어하거나 예민한 부분을 체크해주세요.",
                      }}
                      render={({ field }) => (
                        <CheckboxGroup
                          value={field.value.map((item: any) =>
                            item.pk.toString()
                          )}
                          onChange={(vals: string[]) => {
                            if (vals.includes("1")) {
                              field.onChange([{ pk: 1, name: "없음" }]);
                            } else {
                              field.onChange(
                                vals.map((val) =>
                                  questionData?.questionsix?.find(
                                    (option) => option.pk.toString() === val
                                  )
                                )
                              );
                            }
                          }}
                        >
                          <Wrap spacing="24px">
                            {questionData?.questionsix?.map((option) => (
                              <Checkbox
                                key={option.pk}
                                value={option.pk.toString()}
                              >
                                <Text fontSize="sm" whiteSpace="nowrap">
                                  {option.name}
                                </Text>
                              </Checkbox>
                            ))}
                          </Wrap>
                        </CheckboxGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack flex="1" spacing={5}>
                  <FormControl
                    mt={{ base: 5, lg: 0 }}
                    isInvalid={Boolean(errors?.pet?.question7?.message)}
                  >
                    <FormLabel htmlFor={"pet.question7"} fontWeight={"bold"}>
                      아픈곳을 체크해 주세요.
                    </FormLabel>
                    <Controller
                      control={control}
                      name={"pet.question7"}
                      defaultValue={[]}
                      rules={{
                        required: "아픈곳을 체크해 주세요.",
                      }}
                      render={({ field }) => (
                        <CheckboxGroup
                          value={field.value.map((item: any) =>
                            item.pk.toString()
                          )}
                          onChange={(vals: string[]) => {
                            if (vals.includes("1")) {
                              field.onChange([{ pk: 1, name: "없음" }]);
                            } else {
                              field.onChange(
                                vals.map((val) =>
                                  questionData?.questionseven?.find(
                                    (option) => option.pk.toString() === val
                                  )
                                )
                              );
                            }
                          }}
                        >
                          <Wrap spacing="24px">
                            {questionData?.questionseven?.map((option) => (
                              <Checkbox
                                key={option.pk}
                                value={option.pk.toString()}
                              >
                                <Text fontSize="sm" whiteSpace="nowrap">
                                  {option.name}
                                </Text>
                              </Checkbox>
                            ))}
                          </Wrap>
                        </CheckboxGroup>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Flex>

              <BeautyCustomTabs
                control={control}
                errors={errors}
                reset={reset}
                getValues={getValues}
                setValue={setValue}
                guestBooking={true}
              />

              <Flex
                direction={{ base: "column", md: "row" }}
                justify="space-between"
                mb={10}
              >
                <Stack flex="1" spacing={5} mr={5}>
                  <FormRadioGroup
                    name={"degree_of_tangles"}
                    control={control}
                    label={DEGRRE_OF_TANGLES_LABEL}
                    options={DEGRRE_OF_TANGLES_OPTIONS}
                    errors={errors}
                    defaultValue="약간"
                    FormMtProps={{ mt: 5, color: "red.300" }}
                  />
                </Stack>
                <Stack flex="1" spacing={5}>
                  <FormRadioGroup
                    name={"condition"}
                    control={control}
                    label={CONDITION_LABEL}
                    options={CONDITION_OPTIONS}
                    errors={errors}
                    defaultValue="중간"
                    FormMtProps={{ mt: 5, color: "red.300" }}
                  />
                </Stack>
              </Flex>

              <FormTextarea
                name="pet.significant"
                control={control}
                label="특이사항"
                placeholder="특이사항"
                errors={errors}
              />
            </Stack>

            <ButtonGroup mt={5} w="100%" py={"7"}>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  w="50%"
                  mr={3}
                  size={"lg"}
                  onClick={() => {
                    reset(INITIAL_FORM_VALUES);
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  w="50%"
                  colorScheme="red"
                  variant="solid"
                  size={"lg"}
                  type="submit"
                >
                  신규 등록
                </Button>
              </Flex>
            </ButtonGroup>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <PiDogThin />
              <Text>kongsam guest booking</Text>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
