import {
  Box,
  Input,
  Text,
  HStack,
  Flex,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { forwardRef, useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/room.css";
import { FaSearch } from "react-icons/fa";
import { BEAUTY_SERVICE_CHOICES } from "../../lib/constants";
import SelectiveSearchModal from "./SelectiveSearchModal";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

registerLocale("ko", ko);
interface IQuery {
  pname: string;
  start: string;
  end: string;
  breed: string;
  beauty: string;
}
const CustomInput = forwardRef<HTMLDivElement, any>(
  ({ value, onClick, customName }, ref) => (
    <Text onClick={onClick} ref={ref}>
      {customName}
      <br />
      {value ? format(new Date(value), "yyyy-MM-dd") : "날짜 추가"}
    </Text>
  )
);
export default function RoomSearchBar({
  handleQueryChange,
}: {
  handleQueryChange: (query: IQuery) => void;
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let buttonTransform;
  if (windowWidth <= 360) {
    buttonTransform = "translateX(-32px)";
  } else if (windowWidth <= 384) {
    buttonTransform = "translateX(-26px)";
  } else if (windowWidth <= 428) {
    buttonTransform = "translateX(-10px)";
  } else if (windowWidth <= 414) {
    buttonTransform = "translateX(-13px)";
  } else {
    buttonTransform = "translateX(0px)";
  }

  const location = useLocation();
  const isDisplayText = useBreakpointValue({ base: false, lg: true });

  const twoWeeksAgo = new Date();
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 28);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(twoWeeksAgo);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [isServiceOpen, setIsServiceOpen] = useState(false);

  // 선택한 미용 서비스와 품종을 저장할 상태
  const [selectedBeautyService, setSelectedBeautyService] = useState<
    keyof typeof BEAUTY_SERVICE_CHOICES | ""
  >("");
  const [selectedBreed, setSelectedBreed] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activeIndex !== null) {
        setActiveIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeIndex]);

  useEffect(() => {
    if (location.state) {
      setSearchTerm("");
      setSelectedBreed("");
      setSelectedBeautyService("");
    }
  }, [location.state]);

  const handleCancelledSearch = () => {
    const newQuery = {
      pname: searchTerm,
      end: startDate ? startDate.toISOString().split("T")[0] : "",
      start: endDate ? endDate.toISOString().split("T")[0] : "",
      breed: selectedBreed,
      beauty: selectedBeautyService,
    };
    handleQueryChange(newQuery);
  };

  return (
    <Flex
      mt={3}
      border="1px"
      borderColor="gray.300"
      boxShadow={"0px 0px 5px 3px rgba(1,10,0,0.1)"}
      fontSize={"sm"}
      fontWeight={700}
      borderRadius={"50px"}
      justifyContent="space-between"
    >
      <HStack
        pl={2}
        w={"120%"}
        justifyContent="flex-start"
        borderLeftRadius={"50px"}
        borderRightRadius={hoverIndex === 0 ? "50px" : "0"}
        bg={
          activeIndex === 0
            ? "white"
            : hoverIndex === 0
            ? "gray.100"
            : activeIndex === null
            ? "white"
            : "gray.100"
        }
        onMouseEnter={() => setHoverIndex(0)}
        onMouseLeave={() => setHoverIndex(null)}
        onClick={(e) => {
          e.stopPropagation();
          setActiveIndex(0);
        }}
        position="relative"
        overflow="hidden"
      >
        <Box fontSize={{ base: "11px", sm: "13px" }}>
          <Text paddingLeft={2}>펫이름</Text>
          <Input
            placeholder="펫이름검색"
            border="none"
            paddingLeft={2}
            fontSize={{ base: "xs", sm: "sm" }}
            _focus={{
              boxShadow: "none",
              outline: "none",
            }}
            h="auto"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleCancelledSearch();
              }
            }}
            sx={{
              "::placeholder": {
                fontSize: { base: "11px", sm: "13px" },
              },
            }}
          />
        </Box>
        {hoverIndex !== 0 && hoverIndex !== 1 && (
          <Box
            position="absolute"
            right={0}
            top="15%"
            bottom="15%"
            width="1px"
            bg="gray.400"
          />
        )}
      </HStack>
      <HStack
        pl={1}
        w={"100%"}
        cursor={"pointer"}
        borderLeftRadius={hoverIndex === 1 ? "50px" : "0"}
        borderRightRadius={hoverIndex === 1 ? "50px" : "0"}
        bg={
          activeIndex === 1
            ? "white"
            : hoverIndex === 1
            ? "gray.100"
            : activeIndex === null
            ? "white"
            : "gray.100"
        }
        onMouseEnter={() => setHoverIndex(1)}
        onMouseLeave={() => setHoverIndex(null)}
        onClick={(e) => {
          e.stopPropagation();
          setActiveIndex(1);
        }}
        position="relative"
      >
        <Box cursor={"pointer"} fontSize={{ base: "11px", sm: "13px" }}>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            customInput={<CustomInput customName={"시작"} />}
            maxDate={new Date()}
            locale="ko"
            className="custom-date-picker"
          />
        </Box>
        {hoverIndex !== 1 && hoverIndex !== 2 && (
          <Box
            position="absolute"
            right={0}
            top="15%"
            bottom="15%"
            width="1px"
            bg="gray.400"
          />
        )}
      </HStack>
      <HStack
        pl={1}
        w={"100%"}
        cursor={"pointer"}
        borderLeftRadius={hoverIndex === 2 ? "50px" : "0"}
        borderRightRadius={hoverIndex === 2 ? "50px" : "0"}
        bg={
          activeIndex === 2
            ? "white"
            : hoverIndex === 2
            ? "gray.100"
            : activeIndex === null
            ? "white"
            : "gray.100"
        }
        onMouseEnter={() => setHoverIndex(2)}
        onMouseLeave={() => setHoverIndex(null)}
        onClick={(e) => {
          e.stopPropagation();
          setActiveIndex(2);
        }}
        position="relative"
      >
        <Box fontSize={{ base: "11px", sm: "13px" }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<CustomInput customName={"종료"} />}
            maxDate={new Date()}
            locale="ko"
            className="custom-date-picker"
          />
        </Box>
        {hoverIndex !== 2 && hoverIndex !== 3 && (
          <Box
            position="absolute"
            right={0}
            top="15%"
            bottom="15%"
            width="1px"
            bg="gray.400"
          />
        )}
      </HStack>
      <HStack
        pl={1}
        w={{ base: "185%", sm: "160%" }}
        justifyContent="space-between"
        cursor={"pointer"}
        borderLeftRadius={hoverIndex === 3 ? "50px" : "0"}
        borderRightRadius={"50px"}
        bg={
          activeIndex === 3
            ? "white"
            : hoverIndex === 3
            ? "gray.100"
            : activeIndex === null
            ? "white"
            : "gray.100"
        }
        onMouseEnter={() => setHoverIndex(3)}
        onMouseLeave={() => setHoverIndex(null)}
        onClick={(e) => {
          e.stopPropagation();
          setActiveIndex(3);
          setIsServiceOpen(true);
        }}
        overflow="hidden"
      >
        <Box fontSize={{ base: "11px", sm: "13px" }}>
          <Text>선택검색</Text>
          <Text
            fontWeight={400}
            color={"gray.500"}
            style={{
              width: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {selectedBeautyService &&
              BEAUTY_SERVICE_CHOICES[selectedBeautyService]}
            {selectedBeautyService ? ", " : " "}
            {selectedBreed}
          </Text>
        </Box>
        <Button
          w={{ base: 50, lg: 100 }}
          h={{ base: 35, lg: 50 }}
          rounded={{ base: 35, lg: 50 }}
          bg={"red.300"}
          color={"white"}
          position="relative"
          transform={buttonTransform}
          _hover={{ bg: "red.400" }}
          onClick={(e) => {
            e.stopPropagation();
            setActiveIndex(null);
            handleCancelledSearch();
          }}
        >
          <Box
            position="absolute"
            right={{ base: 3, sm: 5 }}
            display="flex"
            alignItems="center"
          >
            <FaSearch />
            {isDisplayText && <Text ml={2}>검색</Text>}
          </Box>
        </Button>
      </HStack>

      {isServiceOpen && (
        <SelectiveSearchModal
          isServiceOpen={isServiceOpen}
          selectedBeautyService={selectedBeautyService}
          setSelectedBeautyService={setSelectedBeautyService}
          setIsServiceOpen={setIsServiceOpen}
          setSelectedBreed={setSelectedBreed}
          selectedBreed={selectedBreed}
        />
      )}
    </Flex>
  );
}
