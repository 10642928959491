import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { PiDogThin } from "react-icons/pi";
import { IPet } from "../../../../types";
import { getUserPetDetail } from "../../../../api";
import { ImagePreview } from "../../../../utils/ImagePreview";
import { formatDateWeigthAge } from "../../../../lib/utils";
import {
  convertBreed,
  convertGender,
  convertNeutering,
  convertQuestion,
  convertWeight,
} from "../../../../lib/constants";

interface IPetProfile {
  isOpen: boolean;
  onClose: () => void;
  petPk: number;
}

export default function MyPesIntoModal({
  isOpen,
  onClose,
  petPk,
}: IPetProfile) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const yellowColor = useColorModeValue("yellow.500", "yellow.300");
  const grayColor = useColorModeValue("gray.900", "gray.50");
  const whiteColor = useColorModeValue("white", "gray.900");

  const { data: petInfo } = useQuery<IPet>(
    [`pets`, `${petPk}`],
    getUserPetDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>
          {petInfo?.name}_no{petInfo?.pk} _INFO
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"2xl"} mt={10}>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={"header"}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                >
                  <ImagePreview
                    src={petInfo?.pet_avatar}
                    name={petInfo?.name}
                    FormMtProps={{ size: "xl" }}
                  />
                </Heading>
              </Box>
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={"column"}
                divider={<StackDivider borderColor={borderColor} />}
              >
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    Pet Details
                  </Text>
                  <List spacing={2}>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        아이이름:
                      </Text>{" "}
                      {petInfo?.name}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        성별:
                      </Text>{" "}
                      {convertGender(String(petInfo?.gender))}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        중성화:
                      </Text>{" "}
                      {convertNeutering(String(petInfo?.neutering))}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        품종:
                      </Text>{" "}
                      {convertBreed(String(petInfo?.breed))}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        체중:
                      </Text>{" "}
                      {convertWeight(String(petInfo?.weigth))}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        생일:
                      </Text>{" "}
                      {petInfo?.birthday} /{" "}
                      {formatDateWeigthAge(new Date(String(petInfo?.birthday)))}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        특이사항:
                      </Text>{" "}
                      {petInfo?.significant}
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    {petInfo?.name}_question
                  </Text>

                  <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
                    <Box>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>성격이 예민한 편인가요?</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {convertQuestion(String(petInfo?.question1))}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>가끔 물기도 하나요?</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {convertQuestion(String(petInfo?.question2))}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>분리불안이 있나요?</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {convertQuestion(String(petInfo?.question3))}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>미용 후 가려워한적이 있나요?</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {convertQuestion(String(petInfo?.question4))}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>미용 후 불안해하거나 평소와 달랐나요?</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {convertQuestion(String(petInfo?.question5))}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>싫어하거나 예민한 부분을 체크해주세요.</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {petInfo?.question6.map((q) => q.name).join(", ")}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>아픈곳을 체크해 주세요.</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {petInfo?.question7.map((q) => q.name).join(", ")}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  </SimpleGrid>
                </Box>
              </Stack>
              <Button
                rounded={"none"}
                w={"full"}
                mt={8}
                size={"lg"}
                py={"7"}
                bg={grayColor}
                color={whiteColor}
                textTransform={"uppercase"}
                _hover={{
                  transform: "translateY(2px)",
                  boxShadow: "lg",
                }}
                onClick={onClose}
              >
                Close
              </Button>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <PiDogThin />
                <Text>kongsam pet info</Text>
              </Stack>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
