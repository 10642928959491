import {
  Avatar,
  Box,
  Button,
  CloseButton,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { deletePet, getKongsamPetsList } from "../../api";
import { useState } from "react";
import { formatDateWeigthAge } from "../../lib/utils";
import { MdOutlinePets } from "react-icons/md";
import PetInfoModal from "../modal/PetInfoModal";
import PetEditModal from "../modal/PetEditModal";
import { RenderFlex } from "../../utils/RenderFlex";
import { IKongsamPetsListResponse, IPet } from "../../types";
import { FcDataConfiguration } from "react-icons/fc";
import BookingCreateModal from "../modal/BookingCreateModal";
import { usePetListMultipleDisclosure } from "../hook/useMultipleDisclosure";
import PetDoneListModal from "../modal/PetDoneListModal";

export default function KsPetList() {
  const { colorMode } = useColorMode();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";

  const [searchTerm, setSearchTerm] = useState("");
  const [searchOption, setSearchOption] = useState("name");
  const [query, setQuery] = useState({ option: "name", term: "" });
  const [petPk, setPetPk] = useState(0);
  const disclosures = usePetListMultipleDisclosure();

  const {
    data: petsListData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamPetsListResponse>(
    ["pets", JSON.stringify(query)],
    getKongsamPetsList,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.page.next_link) {
          return undefined;
        }
        const url = new URL(lastPage.page.next_link);
        return url.searchParams.get("page");
      },
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const handleSearch = () => {
    setQuery({ option: searchOption, term: searchTerm });
  };
  const handlePetInfoListOpenModal = (petPk: number) => {
    setPetPk(petPk);
    disclosures.PetInfoModal.onOpen();
  };
  const handlePetEditOpenModal = (petPk: number) => {
    setPetPk(petPk);
    disclosures.PetEditModal.onOpen();
  };
  const handleBookingCreateOpenModal = (pk: number) => {
    setPetPk(pk);
    disclosures.BookingCreateModal.onOpen();
  };
  const handlePetDoneListOpenModal = (pk: number) => {
    setPetPk(pk);
    disclosures.PetDoneListModal.onOpen();
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const petMutation = useMutation(deletePet, {
    onSuccess: () => {
      toast({
        title: "삭제 되었습니다.",
        status: "success",
      });
      queryClient.refetchQueries([`pets`]);
    },
  });
  const handlePetDelete = (petPk: number) => {
    if (window.confirm(`no${petPk}_ 펫 정보가 삭제됩니다.`)) {
      petMutation.mutate(petPk);
    }
  };
  return (
    <Box
      boxShadow={`0px 0px 8px ${boxShadowColor}`}
      overflowX="auto"
      rounded="md"
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={1}
        p={2}
        align="stretch"
        w="100%"
      >
        <Select
          onChange={(e) => setSearchOption(e.target.value)}
          w={{ base: "100%", md: "22%" }}
        >
          <option value="name">펫 이름</option>
          <option value="owner">유저 이름</option>
        </Select>
        <InputGroup>
          <Input
            placeholder="반려견 검색"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSearch();
              }
            }}
          />
          {searchTerm && (
            <InputRightElement>
              <CloseButton onClick={() => setSearchTerm("")} />
            </InputRightElement>
          )}
        </InputGroup>

        <Button
          onClick={handleSearch}
          colorScheme={"gray"}
          w={{ base: "100%", md: "22%" }}
        >
          반려견 {petsListData?.pages[0]?.page.count}펫 검색
        </Button>
      </Stack>
      {petsListData?.pages.map((page, i) =>
        page.content.map((item: IPet, index) => (
          <Box
            key={item.pk}
            bg={
              index % 2 === 0
                ? colorMode === "dark"
                  ? "gray.700"
                  : "gray.50"
                : colorMode === "dark"
                ? "gray.800"
                : "white"
            }
            p={3}
            shadow="md"
            borderWidth="1px"
          >
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Popover placement="bottom" isLazy>
                  <PopoverTrigger>
                    <Avatar
                      key={item?.pk}
                      name={item?.name}
                      src={item?.pet_avatar}
                      size={"md"}
                      pos={"relative"}
                      cursor={"pointer"}
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    w="fit-content"
                    _focus={{ boxShadow: "none" }}
                  >
                    <PopoverArrow />
                    <PopoverBody>
                      <Stack>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<MdOutlinePets />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => handlePetInfoListOpenModal(item.pk)}
                        >
                          {item.name} _INFO
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<MdOutlinePets />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          colorScheme="blue"
                          onClick={() => handlePetEditOpenModal(item.pk)}
                        >
                          {item.name} 수정
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<MdOutlinePets />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          colorScheme="red"
                          onClick={() => handlePetDelete(item.pk)}
                        >
                          {item.name} 삭제
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<MdOutlinePets />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            handleBookingCreateOpenModal(item.owner.pk);
                          }}
                        >
                          미용 예약 등록
                        </Button>
                        <Button
                          w="193px"
                          variant="ghost"
                          colorScheme="black"
                          rightIcon={<FcDataConfiguration />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => handlePetDoneListOpenModal(item.pk)}
                        >
                          {item.name} 미용이력
                        </Button>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
              <Text fontSize="lg" fontWeight="bold" lineHeight="short">
                {item.name}
              </Text>
            </Flex>
            <RenderFlex label={"유저"} value={item.owner.name} />
            <RenderFlex label={"품종"} value={item.breed} />
            <RenderFlex
              label={"성별"}
              value={item.gender ? item.gender : "-"}
            />
            <RenderFlex label={"몸무게"} value={item.weigth} />
            <RenderFlex
              label={"나이"}
              value={formatDateWeigthAge(new Date(item.birthday))}
            />
          </Box>
        ))
      )}
      {hasNextPage ? (
        <Button
          colorScheme="gray"
          variant="outline"
          w={"100%"}
          mt={3}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "is Loding..."
            : hasNextPage
            ? "Pets Load More"
            : "Pets List End"}
        </Button>
      ) : null}
      {disclosures.PetInfoModal.isOpen && (
        <PetInfoModal
          isOpen={disclosures.PetInfoModal.isOpen}
          onClose={disclosures.PetInfoModal.onClose}
          userPetPk={petPk}
        />
      )}
      {disclosures.PetEditModal.isOpen && (
        <PetEditModal
          isOpen={disclosures.PetEditModal.isOpen}
          onClose={disclosures.PetEditModal.onClose}
          userPetPk={petPk}
        />
      )}
      {disclosures.BookingCreateModal.isOpen && (
        <BookingCreateModal
          isOpen={disclosures.BookingCreateModal.isOpen}
          onClose={disclosures.BookingCreateModal.onClose}
          isHostUserPk={petPk}
        />
      )}
      {disclosures.PetDoneListModal.isOpen && (
        <PetDoneListModal
          isOpen={disclosures.PetDoneListModal.isOpen}
          onClose={disclosures.PetDoneListModal.onClose}
          userPetPk={petPk}
        />
      )}
    </Box>
  );
}
