import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IUserVariables, getEditMe, getKongsamUsernameDetail } from "../../api";
import { Controller, useForm } from "react-hook-form";

import { PiDogThin } from "react-icons/pi";
import {
  NAME_VALIDATION_RULES_PATTERN,
  PHONE_VALIDATION_RULES_PATTERN,
} from "../../lib/constants";

interface IPetProfile {
  isOpen: boolean;
  onClose: () => void;
  username: string;
}
export default function UserEditModal({
  isOpen,
  onClose,
  username,
}: IPetProfile) {
  const bgColor = useColorModeValue("white", "whiteAlpha.100");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const queryClient = useQueryClient();

  const { data: selectedUser } = useQuery<IUserVariables>(
    [`users`, `${username}`],
    getKongsamUsernameDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IUserVariables>();

  useEffect(() => {
    if (selectedUser) {
      setValue("pk", selectedUser.pk ?? 0);
      setValue("name", selectedUser.name ?? "");
      setValue("phone", selectedUser.phone ?? "");
      setValue("address", selectedUser.address ?? "");
      setValue("username", username);
    }
  }, [selectedUser, username, setValue]);

  const handleClose = () => {
    onClose();
    reset();
  };

  const toast = useToast();
  const mutation = useMutation(getEditMe, {
    onSuccess: () => {
      toast({
        title: "수정되었습니다.",
        status: "success",
      });
      queryClient.refetchQueries([`users`]);
      handleClose();
    },
  });

  const onSubmit = (data: IUserVariables) => {
    mutation.mutate(data);
  };

  return (
    <Modal key={selectedUser?.pk} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"} color={"gray.600"}>
          {selectedUser?.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={{ base: 6, md: 10 }}>
            {selectedUser && (
              <Box
                bg={bgColor}
                rounded={"lg"}
                border={"1px solid"}
                borderColor={borderColor}
                overflow={"hidden"}
                justifyContent={"center"}
                alignItems={"center"}
                p={3}
              >
                <FormControl isInvalid={Boolean(errors.name?.message)} mb={5}>
                  <FormLabel htmlFor="name" fontWeight={"bold"}>
                    Name
                  </FormLabel>
                  <Controller
                    name="name"
                    control={control}
                    rules={NAME_VALIDATION_RULES_PATTERN}
                    render={({ field }) => (
                      <Input
                        {...field}
                        variant={"solid"}
                        borderWidth={1}
                        color={"gray.800"}
                        _placeholder={{
                          color: "gray.400",
                        }}
                        borderColor={
                          errors.name?.message ? "red.500" : borderColor
                        }
                        required
                      />
                    )}
                  />
                </FormControl>
                <FormControl isInvalid={Boolean(errors.phone?.message)} mb={5}>
                  <FormLabel htmlFor="phone" fontWeight={"bold"}>
                    Phone
                  </FormLabel>
                  <InputGroup>
                    <InputLeftAddon children={"+82"} />
                    <Controller
                      name="phone"
                      control={control}
                      rules={PHONE_VALIDATION_RULES_PATTERN}
                      render={({ field }) => (
                        <Input
                          {...field}
                          variant={"solid"}
                          borderWidth={1}
                          color={"gray.800"}
                          _placeholder={{
                            color: "gray.400",
                          }}
                          borderColor={
                            errors.phone?.message ? "red.500" : borderColor
                          }
                          required
                        />
                      )}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl
                  isInvalid={Boolean(errors.address?.message)}
                  mb={3}
                >
                  <FormLabel htmlFor="address" fontWeight={"bold"}>
                    Address
                  </FormLabel>
                  <Controller
                    name="address"
                    control={control}
                    rules={{
                      maxLength: 50,
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        variant={"solid"}
                        borderWidth={1}
                        color={"gray.800"}
                        _placeholder={{
                          color: "gray.400",
                        }}
                        borderColor={
                          errors.address?.message ? "red.500" : borderColor
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
            )}
            <ButtonGroup w="100%" py={"7"}>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  w="50%"
                  mr={3}
                  size={"lg"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  w="50%"
                  colorScheme="red"
                  variant="solid"
                  size={"lg"}
                  type="submit"
                >
                  유저 정보 수정
                </Button>
              </Flex>
            </ButtonGroup>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <PiDogThin />
              <Text>kongsam user put</Text>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
