import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Select,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { BsPerson } from "react-icons/bs";
import { LuDog } from "react-icons/lu";
import { CiAlignBottom, CiBadgeDollar } from "react-icons/ci";
import { useQuery } from "@tanstack/react-query";
import { getKongsamStatistics } from "../../api";
import ProtectedPage from "../middleware/ProtectedPage";
import HostOnlyPage from "../middleware/HostOnlyPage";
import KsStatisYearlyMonthCard, {
  MonthlySale,
} from "./KsStatis/KsStatisYearlyMonthCard";
import KsStatisYearlyMonthResCard, {
  ResMonthlySale,
} from "./KsStatis/KsStatisYearlyMonthResCard";
import KsStatisYearlyVipCard, {
  OwnerYearlySales,
} from "./KsStatis/KsStatisYearlyVipCard";
import KsStatisYearlyMonthVipCard from "./KsStatis/KsStatisYearlyMonthVipCard";
import KsStatisOutlay from "./KsStatis/KsStatisOutlay";
import { useKsMultipleDisclosure } from "../hook/useMultipleDisclosure";
import KsStatisAccount from "./KsStatis/KsStatisAccount";
import KsStatisExpenseList from "./KsStatis/KsStatisExpenseList";
import KsStatisAccountChk from "./KsStatis/KsStatisAccountChk";
import KsStatisAccountTransfer from "./KsStatis/KsStatisAccountTransfer";
import KsStatisAccountSum from "./KsStatis/KsStatisAccountSum";
import { MdAutoGraph } from "react-icons/md";
import KsStatisGraph from "../modal/KsStatisGraph";
import KsStatisAccountCategoryChk from "./KsStatis/KsStatisAccountCategoryChk";

export interface StatsCardProps {
  title?: string;
  stat: number;
  serve_stat?: number;
  icon: ReactNode;
  titleSelect?: ReactNode;
  onClick?: () => void;
}

function StatsCard(props: StatsCardProps) {
  const { title, stat, serve_stat, icon, titleSelect, onClick } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"1"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }} pt={1}>
          <StatLabel fontSize={"xs"} color={"gray.700"} isTruncated>
            {!titleSelect ? title : titleSelect}
          </StatLabel>
          <StatNumber>
            <Text fontSize={"xl"} fontWeight={800} color={"gray.600"}>
              {Number(stat).toLocaleString()}
              {serve_stat ? (
                <span
                  style={{
                    color: "gray",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  {" "}
                  재방문-{serve_stat}
                </span>
              ) : (
                ""
              )}
            </Text>
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
          onClick={onClick}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export interface Sale {
  year: string;
  month?: string;
  total_sales: number;
  count: number;
}
export interface StatsDataProps {
  user_count: number;
  pet_count: number;
  grooming_re_user_pets_count: number;
  yearly_sales: Sale[];
  monthly_sales: MonthlySale[];
  res_monthly_sales: ResMonthlySale[];
  owner_yearly_sales: OwnerYearlySales[];
  owner_monthly_sales: OwnerYearlySales[];
}
function YearlySalesCard({
  data,
  type,
  icon,
}: {
  data: StatsDataProps | undefined;
  type: "sales" | "count";
  icon: ReactNode;
}) {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedSales, setSelectedSales] = useState(0);

  useEffect(() => {
    const yearlySales = data?.yearly_sales.find(
      (sale) => Number(sale.year) === selectedYear
    );
    if (yearlySales) {
      if (type === "sales") {
        setSelectedSales(yearlySales.total_sales);
      } else if (type === "count") {
        setSelectedSales(yearlySales.count);
      }
    } else {
      setSelectedSales(0);
    }
  }, [selectedYear, data, type]);
  const handleYearChange = (event: any) => {
    setSelectedYear(Number(event.target.value));
  };
  return (
    <StatsCard
      titleSelect={
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          variant={"unstyled"}
          size={"xs"}
        >
          <option value={currentYear}>
            {currentYear}년 {type === "sales" ? "총 수입" : "미용완료 총 건수"}
          </option>
          <option value={currentYear - 1}>
            {currentYear - 1}년{" "}
            {type === "sales" ? "총 수입" : "미용완료 총 건수"}
          </option>
        </Select>
      }
      stat={selectedSales || 0}
      icon={icon}
    />
  );
}

interface KsStatisticsProps {
  setSelectedButton?: (value: string) => void;
}
export default function KsStatistics({ setSelectedButton }: KsStatisticsProps) {
  const disclosures = useKsMultipleDisclosure();
  const { data } = useQuery<StatsDataProps>(
    [`users`, `statistics`],
    getKongsamStatistics,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  return (
    <ProtectedPage>
      <HostOnlyPage>
        <Box maxW="7xl" mx={"auto"} px={2}>
          <Flex justifyContent="flex-end" mb={1}>
            <IconButton
              aria-label="right-arrow"
              px={10}
              colorScheme="gray"
              size={"sm"}
              onClick={() => disclosures.KsStatisGraph.onOpen()}
            >
              <MdAutoGraph size={"22px"} color="black" />
            </IconButton>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 2, lg: 3 }}>
            {/* 유저 가입 수 */}
            <StatsCard
              title={"콩삼가족"}
              stat={data?.user_count || 0}
              icon={<BsPerson size={"2.5em"} />}
              onClick={() => setSelectedButton && setSelectedButton("users")}
            />
            {/* 펫 등록 수 */}
            <StatsCard
              title={"콩삼 반려동물 수"}
              stat={data?.pet_count || 0}
              serve_stat={data?.grooming_re_user_pets_count || 0}
              icon={<LuDog size={"2.5em"} />}
              onClick={() => setSelectedButton && setSelectedButton("pets")}
            />
            {/* 년도별 누적 수입 */}
            <YearlySalesCard
              data={data}
              type="sales"
              icon={<CiBadgeDollar size={"3em"} />}
            />
            {/* 년도별 미용 완료 수 */}
            <YearlySalesCard
              data={data}
              type="count"
              icon={<CiAlignBottom size={"3em"} />}
            />
            <KsStatisYearlyMonthCard data={data} type="sales" />
            <KsStatisYearlyMonthResCard data={data} type="sales" />
            <Box boxShadow="md" rounded="md" bg="white" mt={1}>
              <KsStatisYearlyVipCard data={data} />
            </Box>
            <Box boxShadow="md" rounded="md" bg="white" mt={1}>
              <KsStatisYearlyMonthVipCard data={data} />
            </Box>
          </SimpleGrid>
        </Box>
        <HStack justifyContent="space-between" rounded="md" mt={10} mb={3}>
          <HStack spacing={{ base: 1, sm: 2 }}>
            <Button
              size={{ base: "sm", sm: "md" }}
              fontSize={{ base: "xs", sm: "sm" }}
              color={"gray.600"}
              onClick={() => disclosures.KsStatisAccountTransfer.onOpen()}
            >
              콩삼이체
            </Button>
            <Button
              size={{ base: "sm", sm: "md" }}
              fontSize={{ base: "xs", sm: "sm" }}
              color={"gray.600"}
              onClick={() => disclosures.KsStatisAccount.onOpen()}
            >
              콩삼계좌등록
            </Button>
            <Button
              size={{ base: "sm", sm: "md" }}
              fontSize={{ base: "xs", sm: "sm" }}
              color={"gray.600"}
              onClick={() => disclosures.KsStatisOutlay.onOpen()}
            >
              지출내역등록
            </Button>
          </HStack>
          <Box>
            <KsStatisAccountSum />
          </Box>
        </HStack>
        {/* 결제수단 체크 리스트 */}
        <KsStatisAccountChk />
        {/* 카테고리 금액 리스트 */}
        <KsStatisAccountCategoryChk />
        {/* 지출기록부 */}
        <Box boxShadow="md" rounded="md" bg="white">
          <KsStatisExpenseList />
        </Box>
        {disclosures.KsStatisOutlay.isOpen && (
          <KsStatisOutlay
            isOpen={disclosures.KsStatisOutlay.isOpen}
            onClose={disclosures.KsStatisOutlay.onClose}
          />
        )}
        {disclosures.KsStatisAccount.isOpen && (
          <KsStatisAccount
            isOpen={disclosures.KsStatisAccount.isOpen}
            onClose={disclosures.KsStatisAccount.onClose}
          />
        )}
        {disclosures.KsStatisAccountTransfer.isOpen && (
          <KsStatisAccountTransfer
            isOpen={disclosures.KsStatisAccountTransfer.isOpen}
            onClose={disclosures.KsStatisAccountTransfer.onClose}
          />
        )}
        {disclosures.KsStatisGraph.isOpen && (
          <KsStatisGraph
            isOpen={disclosures.KsStatisGraph.isOpen}
            onClose={disclosures.KsStatisGraph.onClose}
          />
        )}
      </HostOnlyPage>
    </ProtectedPage>
  );
}
