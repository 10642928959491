import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import ProtectedPage from "../middleware/ProtectedPage";
import HostOnlyPage from "../middleware/HostOnlyPage";
import { PiDogThin } from "react-icons/pi";
import { getIsRoomCheck, uploadRoom } from "../../api";
import { IRoomPublic } from "../../types";

interface MyDoneModalProps {
  donePk?: number;
  isOpen: boolean;
  onClose: () => void;
}
interface IDoneIsRoomCheck {
  pk: number;
  is_room: boolean;
  owner: {
    pk: number;
    name: string;
  };
  user_pets: {
    pk: number;
    name: string;
  }[];
}
export default function RoomCreateModal({
  donePk,
  isOpen,
  onClose,
}: MyDoneModalProps) {
  const { register, handleSubmit, reset } = useForm<IRoomPublic>();

  const { data: doneIsRoomCheck } = useQuery<IDoneIsRoomCheck[]>(
    [`dones`, `rooms-check`],
    getIsRoomCheck,
    {
      retry: false,
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const roomReset = () => {
    reset({
      name: "",
      description: "",
    });
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation(uploadRoom, {
    onSuccess: () => {
      toast({
        title: "룸 등록 완료!",
        status: "success",
      });
      roomReset();
      queryClient.refetchQueries([`dones`]);
      queryClient.refetchQueries([`rooms`]);
      onClose();
    },
  });

  const onSubmit = (data: IRoomPublic) => {
    if (!data || !data.done || !data.done.pk) {
      alert("종료된 예약이 없습니다.");
      return;
    }
    mutation.mutate(data);
  };

  return (
    <ProtectedPage>
      <HostOnlyPage>
        <Modal
          size={"xl"}
          isOpen={isOpen}
          onClose={() => {
            roomReset();
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader fontSize={"sm"} color={"gray.600"}>
              room _no{donePk}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                {Array.isArray(doneIsRoomCheck) &&
                doneIsRoomCheck.length > 0 ? (
                  <FormControl>
                    <FormLabel>Room Owner</FormLabel>
                    <Select
                      {...register("done.pk", { required: true })}
                      defaultValue={donePk}
                      required
                    >
                      {doneIsRoomCheck.map((isroom, index) => (
                        <option key={index} value={isroom.pk}>
                          {`${isroom.owner.name} - ${isroom.user_pets[0].name}`}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Text color={"red"}>종료된 예약이 없습니다.</Text>
                )}
                <FormControl>
                  <FormLabel>Room Name</FormLabel>
                  <Input
                    {...register("name", { required: true })}
                    required
                    type="text"
                  />
                </FormControl>
                <FormControl mt={0}>
                  <FormLabel>Room Description</FormLabel>
                  <Textarea {...register("description", { required: true })} />
                </FormControl>
              </VStack>
              <ButtonGroup mt={5} w="100%" py={"7"}>
                <Flex w="100%" justifyContent="space-between">
                  <Button
                    colorScheme="teal"
                    variant="solid"
                    w="50%"
                    mr={3}
                    size={"lg"}
                    onClick={() => {
                      roomReset();
                      onClose();
                    }}
                  >
                    Close
                  </Button>

                  <Button
                    w="50%"
                    colorScheme="red"
                    variant="solid"
                    size={"lg"}
                    type="submit"
                  >
                    룸 등록
                  </Button>
                </Flex>
              </ButtonGroup>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <PiDogThin />
                <Text>kongsam room create</Text>
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </HostOnlyPage>
    </ProtectedPage>
  );
}
