import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { PiDogThin } from "react-icons/pi";
import { IKongsamPetDoneListResponse } from "../../types";
import { getPetDoneList } from "../../api";
import React from "react";

interface IPetProfile {
  isOpen: boolean;
  onClose: () => void;
  userPetPk: number;
}
export default function PetDoneListModal({
  isOpen,
  onClose,
  userPetPk,
}: IPetProfile) {
  const {
    data: petDoneList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamPetDoneListResponse>(
    [`dones`, `pet-dones`, `${userPetPk}`],
    getPetDoneList,
    {
      getNextPageParam: (lastPage) =>
        lastPage.page.next_link
          ? new URL(lastPage.page.next_link).searchParams.get("page")
          : undefined,
      staleTime: Infinity,
      cacheTime: 0,
    }
  );

  const renderPaymentInfo = (
    payment: number | undefined,
    paymentType: string
  ) => {
    return payment !== undefined && Math.floor(payment) !== 0
      ? `${paymentType} ${Math.floor(payment)}원 `
      : "";
  };
  return (
    <Modal size={"6xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>
          @{petDoneList && petDoneList.pages[0]?.content[0]?.owner.name}
          _done_list
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer mb={10}>
            <Table
              variant="striped"
              size={"sm"}
              color="gray.500"
              colorScheme="gray"
              fontWeight={600}
            >
              <TableCaption>
                {hasNextPage ? (
                  <Box display="flex" justifyContent="center">
                    <VStack>
                      <Button
                        variant="ghost"
                        colorScheme="blue"
                        size={"sm"}
                        onClick={() => fetchNextPage()}
                        disabled={!hasNextPage || isFetchingNextPage}
                      >
                        {isFetchingNextPage
                          ? "is Loding..."
                          : hasNextPage
                          ? "펫 미용완료 리스트 더보기"
                          : "마지막"}
                      </Button>
                    </VStack>
                  </Box>
                ) : (
                  "펫 미용완료 리스트"
                )}
              </TableCaption>
              <Thead>
                <Tr>
                  <Th pt={2} pb={2}>
                    #
                    {petDoneList &&
                      petDoneList.pages[0]?.content[0]?.user_pets[0]?.name}
                    - u/p
                  </Th>
                  <Th pt={2} pb={2}>
                    서비스 - 얼굴스타일
                  </Th>
                  <Th pt={2} pb={2}>
                    금액(원)
                  </Th>
                  <Th pt={2} pb={2}>
                    결제수단
                  </Th>
                  <Th pt={2} pb={2}>
                    미용내용
                  </Th>
                  <Th pt={2} pb={2}>
                    미용특이사항
                  </Th>
                  <Th pt={2} pb={2}>
                    관리자메모
                  </Th>
                  <Th pt={2} pb={2}>
                    미용예약일시
                  </Th>
                  <Th pt={2} pb={2}>
                    미용종료일시
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {petDoneList &&
                  petDoneList?.pages.map((list) =>
                    list.content.map((petDone) => (
                      <Tr key={petDone.pk}>
                        <Td>
                          no{petDone.pk} - {petDone.user_rating} /{" "}
                          {petDone.pet_rating}
                        </Td>
                        <Td>
                          {petDone.booking.beauty_service} -{" "}
                          {petDone.booking.face_style
                            ? petDone.booking.face_style
                            : "-"}
                          {petDone.booking.body_length_clip !== "없음" &&
                          petDone.booking.body_length_clip !== ""
                            ? `, ${petDone.booking.body_length_clip}`
                            : ""}
                          {petDone.booking.body_length_scissors_cut !==
                            "없음" &&
                          petDone.booking.body_length_scissors_cut !== ""
                            ? `, ${petDone.booking.body_length_scissors_cut}`
                            : ""}
                          {petDone.booking.body_length_spotting !== "없음" &&
                          petDone.booking.body_length_spotting !== ""
                            ? `, ${petDone.booking.body_length_spotting}`
                            : ""}
                          {petDone.booking.foot_shape !== "없음" &&
                          petDone.booking.foot_shape !== ""
                            ? `, ${petDone.booking.foot_shape}`
                            : ""}
                        </Td>
                        <Td>
                          {`₩ ${Math.floor(
                            petDone ? petDone?.payment_amount : 0
                          )}원`}
                        </Td>
                        <Td>
                          {[
                            renderPaymentInfo(petDone.cade_payment, "카드"),
                            renderPaymentInfo(petDone.cash_payment, "현금"),
                            renderPaymentInfo(petDone.bank_payment, "계좌"),
                            renderPaymentInfo(
                              petDone.donation_payment,
                              "기부금"
                            ),
                            renderPaymentInfo(petDone.discount_payment, "할인"),
                            petDone.cash_receipt ? "현금영수증" : "",
                            petDone.bank_receipt ? "계좌영수증" : "",
                          ]
                            .filter(Boolean)
                            .map((item, index) => (
                              <React.Fragment key={index}>
                                {item}
                                <br />
                              </React.Fragment>
                            ))}
                        </Td>
                        <Td style={{ whiteSpace: "normal", minWidth: "170px" }}>
                          {petDone.beauty_content
                            ? petDone.beauty_content
                            : "-"}
                        </Td>
                        <Td style={{ whiteSpace: "normal", minWidth: "170px" }}>
                          {petDone.special_notes ? petDone.special_notes : "-"}
                        </Td>
                        <Td style={{ whiteSpace: "normal", minWidth: "170px" }}>
                          {petDone.admin_memo ? petDone.admin_memo : "-"}
                        </Td>
                        <Td>{petDone.booking.booking_time_kst}</Td>
                        <Td>{petDone.end_date}</Td>
                      </Tr>
                    ))
                  )}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"center"}
          mb={2}
        >
          <PiDogThin />
          <Text>kongsam pet done list</Text>
        </Stack>
      </ModalContent>
    </Modal>
  );
}
