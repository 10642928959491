import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ProtectedPage from "../middleware/ProtectedPage";
import HostOnlyPage from "../middleware/HostOnlyPage";
import { createPhoto, getUploadURL, uploadImage } from "../../api";
import { PiDogThin } from "react-icons/pi";

interface IUploadForm {
  file: FileList;
}
interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}
interface IUploadModalProps {
  roomPk: string;
  isOpen: boolean;
  onClose: () => void;
}
/* 
  1.uploadURLMutation : id, url 만들기
  2.uploadImageMutation : id,url 받으면 업로드
  3.createPhotoMutation : 클라우드에 업드로후 룸에 사진 등록
  */
export default function UploadPhotos({
  isOpen,
  onClose,
  roomPk,
}: IUploadModalProps) {
  const { register, handleSubmit, watch, reset } = useForm<IUploadForm>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const createPhotoMutation = useMutation(createPhoto, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "룸 업로드 완료!",
      });
      queryClient.refetchQueries([`rooms`]);
      reset();
    },
  });
  const uploadImageMutation = useMutation(uploadImage, {
    onSuccess: ({ result }: any) => {
      if (roomPk) {
        createPhotoMutation.mutate({
          description: result.filename,
          file: `https://imagedelivery.net/S5Wxv27Ibwd3zt1OHkr4Yg/${result.id}/public`,
          roomPk,
        });
      }
      toast({
        status: "success",
        title: "클라우드 업로드 완료!",
      });
    },
  });
  const uploadURLMutation = useMutation(getUploadURL, {
    onSuccess: (data: IUploadURLResponse) => {
      uploadImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
      toast({
        status: "success",
        title: "id,url 생성 완료!",
      });
    },
  });
  const onSubmit = () => {
    uploadURLMutation.mutate();
  };

  return (
    <Modal
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>사진등록_{roomPk}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProtectedPage>
            <HostOnlyPage>
              <Box mt={10}>
                <Heading textAlign={"center"}>사진 업로드</Heading>
                <VStack spacing={5} mt={10} w={"100%"}>
                  <FormControl>
                    <Input {...register("file")} type="file" accept="image/*" />
                  </FormControl>
                </VStack>
              </Box>
            </HostOnlyPage>
          </ProtectedPage>

          <ButtonGroup mt={5} w="100%" py={"7"}>
            <Flex w="100%" justifyContent="space-between">
              <Button
                colorScheme="teal"
                variant="solid"
                w="50%"
                mr={3}
                size={"lg"}
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                isLoading={
                  uploadURLMutation.isLoading ||
                  uploadImageMutation.isLoading ||
                  createPhotoMutation.isLoading
                }
                w="50%"
                colorScheme="red"
                type="submit"
              >
                사진 업로드
              </Button>
            </Flex>
          </ButtonGroup>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam upload photos</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
