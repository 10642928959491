import {
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { PiDogThin } from "react-icons/pi";
import { createCalendar } from "../../api";
import { FormTextarea } from "../FormGroup";
import { TwitterPicker } from "react-color";
import { ICalendar } from "../../types";

interface ICalendarModalProps {
  selectedDate: any;
  isOpen: boolean;
  onClose: () => void;
}
export default function CalendarCreateModal({
  selectedDate,
  isOpen,
  onClose,
}: ICalendarModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICalendar>();

  const toast = useToast();
  const queryClient = useQueryClient();
  const createCalendarMutation = useMutation(createCalendar, {
    onSuccess: () => {
      toast({
        title: "일정 등록!",
        status: "success",
      });
      reset();
      onClose();
      queryClient.refetchQueries([`dones`, `calendar`]);
    },
  });
  const onSubmit = (data: ICalendar) => {
    createCalendarMutation.mutate(data);
  };
  return (
    <Modal
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>달력메모입력</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"xl"} mt={10}>
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정제목</FormLabel>
              <Controller
                name="title"
                control={control}
                defaultValue={""}
                render={({ field }) => <Input {...field} type="text" />}
              />
            </FormControl>
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정시작날짜</FormLabel>
              <Controller
                name="start_date"
                control={control}
                defaultValue={`${selectedDate.start}T00:00`}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정종료날짜</FormLabel>
              <Controller
                name="end_date"
                control={control}
                defaultValue={`${selectedDate.end}T00:00`}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
            <FormTextarea
              name="calendar_memo"
              control={control}
              label="일정내용"
              placeholder="일정내용"
              errors={errors}
              FormMtProps={{ mt: 10 }}
            />
            <FormControl mt={10}>
              <FormLabel fontWeight={"bold"}>일정 색상</FormLabel>
              <Controller
                name="cal_color"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TwitterPicker
                    color={field.value}
                    onChange={(updatedColor: any) =>
                      field.onChange(updatedColor.hex)
                    }
                  />
                )}
              />
            </FormControl>
            <ButtonGroup mt={5} w="100%" py={"7"}>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  colorScheme="teal"
                  variant="solid"
                  w="50%"
                  mr={3}
                  size={"lg"}
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  w="50%"
                  colorScheme="red"
                  variant="solid"
                  size={"lg"}
                  type="submit"
                >
                  일정 등록
                </Button>
              </Flex>
            </ButtonGroup>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <PiDogThin />
              <Text>kongsam calendar</Text>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
