import {
  Box,
  FormControl,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  useToast,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  Flex,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { PiDogThin } from "react-icons/pi";
import {
  FormCheckboxGroup,
  FormInput,
  FormSelect,
  FormTextarea,
} from "../FormGroup";
import { useState } from "react";
import {
  IIsErrorMessage,
  IResMessageProps,
  ISuccessMessage,
  createCallMsg,
  getCallMsg,
} from "../../api";
import { AxiosError } from "axios";
import CallForResEdit from "./CallForResEdit";
import {
  BEAUTY_SERVICE_CHOICES_OPTIONS,
  QUESTION_SEVEN_OPTIONS,
  QUESTION_SIX_OPTIONS,
} from "../../lib/constants";

interface ICallResModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function CallForRes({ isOpen, onClose }: ICallResModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<IResMessageProps>();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isInfoEdit, setInfoEdit] = useState(false);
  const [callData, setCallData] = useState<IResMessageProps[] | undefined>();

  const handleConfirmClick = () => {
    setIsConfirmed(true);
    setValue("call_time", "");
    setValue("description", "");
  };

  const toast = useToast();
  const createCallMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IResMessageProps
  >(createCallMsg, {
    onSuccess: () => {
      toast({
        title: "문의 되었습니다.",
        description: "확인후 연락드리겠습니다.",
        status: "success",
      });
      reset();
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        status: "info",
      });
    },
  });
  const createCall = (data: IResMessageProps) => {
    createCallMutation.mutate(data);
  };

  const callInfoMutation = useMutation(getCallMsg, {
    onSuccess: (data: IResMessageProps[]) => {
      reset();
      setInfoEdit(true);
      setCallData(data);
    },
    onError: () => {
      toast({
        title: "연락처와 이름(별칭)을 확인 해주세요.",
        status: "info",
      });
    },
  });
  const callInfo = (data: IResMessageProps) => {
    callInfoMutation.mutate(data);
  };
  return (
    <Modal
      size={"2xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(createCall)}>
        <ModalHeader fontSize={"sm"} color={"gray.600"}>
          {!isInfoEdit ? "예약 문의" : "예약 문의 수정"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {!isInfoEdit ? (
              <>
                <FormControl isInvalid={Boolean(errors?.phone?.message)}>
                  <FormLabel htmlFor={"phone"} fontWeight={"bold"}>
                    연락처
                  </FormLabel>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue={""}
                    rules={{
                      required: "연락처를 입력해주세요.",
                      minLength: 11,
                    }}
                    render={({ field }) => (
                      <InputGroup>
                        <InputLeftAddon children="+82" />
                        <Input
                          {...field}
                          type="tel"
                          placeholder="번호만 입력"
                        />
                      </InputGroup>
                    )}
                  />
                  {errors.phone && (
                    <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormInput
                  name="name"
                  control={control}
                  defaultValue={""}
                  label="이름(별칭)"
                  errors={errors}
                  FormMtProps={{ mt: 5 }}
                />
                {!isConfirmed && (
                  <>
                    <FormControl
                      mt={5}
                      isInvalid={Boolean(errors?.call_time?.message)}
                    >
                      <FormLabel fontWeight={"bold"}>미용 문의 일정</FormLabel>
                      <Controller
                        name="call_time"
                        control={control}
                        rules={{
                          required: "문의 일정을 입력해주세요.",
                        }}
                        defaultValue={""}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="datetime-local"
                            min={new Date().toISOString().substr(0, 16)}
                          />
                        )}
                      />
                      {errors.phone && (
                        <FormErrorMessage>
                          {errors.call_time?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    <FormSelect
                      name="msg_beauty_service"
                      control={control}
                      label="미용 서비스"
                      options={BEAUTY_SERVICE_CHOICES_OPTIONS}
                      errors={errors}
                      FormMtProps={{ mt: 10 }}
                    />
                    <FormCheckboxGroup
                      name="msg_question6"
                      control={control}
                      label="우리아이가 싫어하거나 예민한 부분을 체크해주세요."
                      options={QUESTION_SIX_OPTIONS}
                      errors={errors}
                    />
                    <FormCheckboxGroup
                      name="msg_question7"
                      control={control}
                      label="우리아이 아픈곳을 체크해 주세요."
                      options={QUESTION_SEVEN_OPTIONS}
                      errors={errors}
                    />
                    <FormTextarea
                      name="description"
                      control={control}
                      label="기타 문의 내용"
                      placeholder="반려견 이름, 견종, 나이, 몸무게, 미용 스타일 등을 적어주세요."
                      rules={{ required: true }}
                      errors={errors}
                      FormMtProps={{ mt: 10 }}
                    />
                  </>
                )}
                <Text fontSize="sm" color="gray.500" mt={10}>
                  "고객님의 편의를 위해 예약 문의를 빠르게 처리하기 위해
                  연락처와 이름(별칭) 정보를 수집하고 있습니다. 이 정보는
                  고객님께 보다 정확한 서비스를 제공하기 위한 목적으로만
                  사용됩니다. 연락처와 이름(별칭)을 입력해주시면, 저희가 빠르게
                  고객님의 예약 문의를 처리해드리겠습니다. 제공된 개인정보는
                  등록일로 부터 1주일 후 자동으로 삭제됩니다. 감사합니다."
                </Text>
                <Flex justifyContent={"space-between"} mb={5}>
                  {!isConfirmed ? (
                    <Box mt={10}>
                      <Button type="submit" colorScheme="blue">
                        예약 문의
                      </Button>
                    </Box>
                  ) : (
                    <Box mt={10}>
                      <Button
                        colorScheme="gray"
                        onClick={() => {
                          setIsConfirmed(false);
                          reset();
                        }}
                      >
                        취소
                      </Button>
                    </Box>
                  )}
                  {!isConfirmed ? (
                    <Box mt={10}>
                      <Button
                        colorScheme="gray"
                        onClick={() => {
                          handleConfirmClick();
                          reset();
                        }}
                      >
                        문의 건 조회
                      </Button>
                    </Box>
                  ) : (
                    <Box mt={10}>
                      <Button
                        colorScheme="blue"
                        onClick={() => callInfo(getValues())}
                      >
                        문의 확인
                      </Button>
                    </Box>
                  )}
                </Flex>
              </>
            ) : (
              <CallForResEdit callData={callData} onClose={onClose} />
            )}
          </Box>
          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam call</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
