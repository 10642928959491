import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { PiDogThin } from "react-icons/pi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ICategorytemProps,
  IExpenseProps,
  IIsErrorMessage,
  ISuccessMessage,
  deleteExpenseDetail,
  getAccount,
  getExpenseDetail,
  getOutlayCategory,
  putExpenseDetail,
} from "../../../api";
import { Controller, useForm } from "react-hook-form";
import { IAccountItem } from "./KsStatisAccount";
import { useEffect } from "react";
import { AxiosError } from "axios";

export interface IExpense {
  pk: number;
  category: {
    pk: number;
  };
  account: {
    pk: number;
  };
  amount: number;
  notes: string;
  income_expenditure_status: string;
  date: string;
  categoryPk?: number;
  accountPk?: number;
  cash_receipt: boolean;
}
interface KsExpenseModalProps {
  isOpen: boolean;
  onClose: () => void;
  expensePk: number;
}
export default function KsStatisExpenseInfo({
  isOpen,
  onClose,
  expensePk,
}: KsExpenseModalProps) {
  const { data: getCategory } = useQuery<ICategorytemProps[]>(
    [`expenses`, `expense-category`],
    getOutlayCategory,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const { data: getExpense } = useQuery<IExpense>(
    [`expenses`, `${expensePk}`],
    getExpenseDetail,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const { register, control, handleSubmit, setValue } = useForm<IExpenseProps>(
    {}
  );
  useEffect(() => {
    if (getExpense) {
      setValue("pk", getExpense.pk);
      setValue("category", getExpense.category.pk);
      setValue("account", getExpense.account.pk);
      setValue("amount", getExpense.amount);
      setValue("notes", getExpense.notes);
      setValue(
        "income_expenditure_status",
        getExpense.income_expenditure_status
      );
      setValue("date", getExpense.date);
      setValue("cash_receipt", getExpense.cash_receipt);
    }
  }, [getExpense, setValue]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const putExpenseMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IExpenseProps
  >(putExpenseDetail, {
    onSuccess: () => {
      toast({
        title: "수입/지출내역 등록!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description:
          typeof error.response?.data.error === "object"
            ? JSON.stringify(error.response?.data.error)
            : error.response?.data.error,
        status: "error",
      });
    },
  });
  const onSubmit = (data: IExpenseProps) => {
    if (data.cash_receipt) {
      data.notes = data.notes ? data.notes + ", 현금영수증" : "현금영수증";
    }
    putExpenseMutation.mutate(data);
  };

  const deleteExpenseMutation = useMutation(deleteExpenseDetail, {
    onSuccess: () => {
      toast({
        title: "삭제 성공!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`]);
      onClose();
    },
  });
  const deleteExpense = () => {
    deleteExpenseMutation.mutate(expensePk);
  };

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"xs"}>
          수입/지출 상세내역 _수정,삭제_{expensePk}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box as={"form"} onSubmit={handleSubmit(onSubmit)} color={"gray.500"}>
            <FormControl mb={3}>
              <Controller
                name={`cash_receipt`}
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox
                    color={"gray.600"}
                    isChecked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  >
                    <Text fontSize={"sm"} color={"gray.500"}>
                      현금영수증 발행
                    </Text>
                  </Checkbox>
                )}
              />
              <HStack>
                <Controller
                  control={control}
                  name={`account`}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mb={1}
                      fontSize={"sm"}
                      placeholder="수입/지출 계좌 선택"
                      required
                    >
                      {getAccounts &&
                        getAccounts.map((account) => (
                          <option key={account.pk} value={account.pk}>
                            {account.account_name}
                          </option>
                        ))}
                    </Select>
                  )}
                />

                <Controller
                  control={control}
                  name={`income_expenditure_status`}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mb={1}
                      fontSize={"sm"}
                      placeholder="계좌 선택"
                      required
                    >
                      <option value="지출">지출</option>
                      <option value="수입">수입</option>
                    </Select>
                  )}
                />
              </HStack>
              <HStack>
                <Controller
                  control={control}
                  name={`category`}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mb={1}
                      fontSize={"sm"}
                      placeholder="카테고리 선택"
                      required
                    >
                      {getCategory &&
                        getCategory.map((category) => (
                          <option key={category.pk} value={category.pk}>
                            {category.name}
                          </option>
                        ))}
                    </Select>
                  )}
                />
                <Controller
                  control={control}
                  name={`amount`}
                  render={({ field }) => (
                    <NumberInput
                      fontSize={"sm"}
                      onChange={(valueAsString, valueAsNumber) =>
                        field.onChange(valueAsNumber)
                      }
                      onBlur={field.onBlur}
                      value={field.value}
                    >
                      <NumberInputField />
                    </NumberInput>
                  )}
                />
              </HStack>

              <Textarea
                mt={1}
                fontSize={"sm"}
                {...register(`notes`)}
                placeholder="비고를 입력하세요"
              />
            </FormControl>

            <Input
              fontSize={"sm"}
              type="date"
              {...register("date", { required: true })}
              required
            />
            <Flex justifyContent={"space-between"} mb={5} mt={5}>
              <Button colorScheme="teal" fontSize={"sm"} type="submit">
                내역 수정
              </Button>
              <Button
                isLoading={deleteExpenseMutation.isLoading}
                colorScheme="red"
                fontSize={"sm"}
                onClick={() => deleteExpense()}
              >
                내역 삭제
              </Button>
            </Flex>
          </Box>
          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam expense</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
