import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { IResMessageProps, deleteCallMsg, putCallMsg } from "../../api";
import {
  FormCheckboxGroup,
  FormInput,
  FormSelect,
  FormTextarea,
} from "../FormGroup";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  BEAUTY_SERVICE_CHOICES_OPTIONS,
  QUESTION_SEVEN_OPTIONS,
  QUESTION_SIX_OPTIONS,
} from "../../lib/constants";

interface ICallInfoEditProps {
  callData?: IResMessageProps[];
  onClose: () => void;
}

export default function CallForResEdit({
  callData,
  onClose,
}: ICallInfoEditProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IResMessageProps>();

  useEffect(() => {
    if (callData) {
      setValue("pk", callData?.[0].pk);
      setValue("name", callData?.[0].name);
      setValue("phone", callData?.[0].phone);
      setValue("description", callData?.[0].description);
      let dateTimeString = callData?.[0].call_time.split("+")[0];
      setValue("call_time", dateTimeString);
      setValue("msg_beauty_service", callData?.[0].msg_beauty_service);
      setValue("msg_question6", callData?.[0].msg_question6);
      setValue("msg_question7", callData?.[0].msg_question7);
    }
  }, [callData, setValue]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const putCallMutation = useMutation(putCallMsg, {
    onSuccess: () => {
      toast({
        title: "수정 되었습니다.",
        description: "확인후 연락드리겠습니다.",
        status: "success",
      });
      queryClient.refetchQueries([`resmessages`]);
      reset();
      onClose();
    },
  });
  const putCall = (data: IResMessageProps) => {
    putCallMutation.mutate(data);
  };

  const deleteCallMutation = useMutation(deleteCallMsg, {
    onSuccess: () => {
      toast({
        title: "삭제 되었습니다.",
        status: "success",
      });
      queryClient.refetchQueries([`resmessages`]);
      onClose();
    },
  });
  const handleDelteCallMsg = () => {
    if (window.confirm("문의사항을 삭제하시겠습니까?")) {
      deleteCallMutation.mutate(Number(callData?.[0].pk));
    }
  };

  return (
    <Box>
      <FormControl isInvalid={Boolean(errors?.phone?.message)}>
        <FormLabel htmlFor={"phone"} fontWeight={"bold"}>
          연락처
        </FormLabel>
        <Controller
          name="phone"
          control={control}
          defaultValue={""}
          rules={{
            required: "연락처를 입력해주세요.",
            minLength: 11,
          }}
          render={({ field }) => (
            <InputGroup>
              <InputLeftAddon children="+82" />
              <Input {...field} type="tel" placeholder="번호만 입력" />
            </InputGroup>
          )}
        />
        {errors.phone && (
          <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormInput
        name="name"
        control={control}
        defaultValue={""}
        label="이름(별칭)"
        errors={errors}
        FormMtProps={{ mt: 5 }}
      />
      <FormControl mt={5} isInvalid={Boolean(errors?.call_time?.message)}>
        <FormLabel fontWeight={"bold"}>문의 일정</FormLabel>
        <Controller
          name="call_time"
          control={control}
          rules={{
            required: "문의 일정을 입력해주세요.",
          }}
          defaultValue={""}
          render={({ field }) => (
            <Input
              {...field}
              type="datetime-local"
              min={new Date().toISOString().substr(0, 16)}
            />
          )}
        />
        {errors.phone && (
          <FormErrorMessage>{errors.call_time?.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormSelect
        name="msg_beauty_service"
        control={control}
        label="미용 서비스"
        options={BEAUTY_SERVICE_CHOICES_OPTIONS}
        errors={errors}
        FormMtProps={{ mt: 10 }}
      />
      <FormCheckboxGroup
        name="msg_question6"
        control={control}
        label="우리아이가 싫어하거나 예민한 부분을 체크해주세요."
        options={QUESTION_SIX_OPTIONS}
        errors={errors}
      />
      <FormCheckboxGroup
        name="msg_question7"
        control={control}
        label="우리아이 아픈곳을 체크해 주세요."
        options={QUESTION_SEVEN_OPTIONS}
        errors={errors}
      />
      <FormTextarea
        name="description"
        control={control}
        label="기타 문의 내용"
        placeholder=""
        errors={errors}
        FormMtProps={{ mt: 5 }}
      />
      <Text fontSize="sm" color="gray.500" mt={10}>
        "고객님의 편의를 위해 예약 문의를 빠르게 처리하기 위해 연락처와
        이름(별칭) 정보를 수집하고 있습니다. 이 정보는 고객님께 보다 정확한
        서비스를 제공하기 위한 목적으로만 사용됩니다. 연락처와 이름(별칭)을
        입력해주시면, 저희가 빠르게 고객님의 예약 문의를 처리해드리겠습니다.
        제공된 개인정보는 등록일로 부터 1주일 후 자동으로 삭제됩니다.
        감사합니다."
      </Text>
      <Flex justifyContent={"space-between"} mb={5}>
        <Box mt={10}>
          <Button
            isLoading={putCallMutation.isLoading}
            onClick={handleSubmit(putCall)}
            colorScheme="blue"
          >
            예약 문의 수정
          </Button>
          <Button
            isLoading={deleteCallMutation.isLoading}
            onClick={() => handleDelteCallMsg()}
            colorScheme="red"
            ml={5}
          >
            예약 문의 삭제
          </Button>
        </Box>
        <Box mt={10}>
          <Button
            colorScheme="gray"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            닫기
          </Button>
        </Box>
      </Flex>
    </Box>
  );
}
