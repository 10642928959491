import { Box, Select } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getKongsamStatistics3 } from "../../api";

interface IMonthlySales {
  month: string;
  year: string;
  bathing_count: number;
  bathing_basic_count: number;
  clipping_count: number;
  spotting_count: number;
  scissor_cut_count: number;
  other_count: number;
}

export default function KsStatisGraph3() {
  const { data: statsData3 } = useQuery<IMonthlySales[]>(
    [`users`, `statistics3`],
    getKongsamStatistics3,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredData, setFilteredData] = useState<IMonthlySales[]>([]);

  useEffect(() => {
    const newFilteredData = statsData3?.filter(
      (item) => item.year.toString() === selectedYear.toString()
    );
    setFilteredData(newFilteredData || []);
  }, [selectedYear, statsData3]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: "미용완료 내역 분류",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "목욕",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.bathing_count : 0;
        }),
        backgroundColor: "rgba(231, 237, 129, 0.9)",
      },
      {
        label: "목욕+기본",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.bathing_basic_count : 0;
        }),
        backgroundColor: "rgba(249, 240, 163, 0.8)",
      },
      {
        label: "클리핑",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.clipping_count : 0;
        }),
        backgroundColor: "rgba(136, 224, 178, 0.8)",
      },
      {
        label: "스포팅",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.spotting_count : 0;
        }),
        backgroundColor: "rgba(247, 126, 126, 0.7)",
      },
      {
        label: "가위컷",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.scissor_cut_count : 0;
        }),
        backgroundColor: "rgba(203, 118, 255, 0.5)",
      },
      {
        label: "기타",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.other_count : 0;
        }),
        backgroundColor: "rgba(110, 199, 244, 0.6)",
      },
    ],
  };

  const availableYears = Array.from(
    new Set(statsData3?.map((item) => item.year))
  );

  return (
    <Box mt={5}>
      <Bar options={options} data={data} />
      <Select
        value={selectedYear}
        onChange={(e) => setSelectedYear(Number(e.target.value))}
      >
        {availableYears.map((year) => (
          <option key={year} value={year}>
            {year}년
          </option>
        ))}
      </Select>
    </Box>
  );
}
