import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { PiDogThin } from "react-icons/pi";
import { ImagePreview } from "../../../utils/ImagePreview";
import { formatDateTime } from "../../../lib/utils";
import { IUser } from "../../../types";
import { getKongsamUsernameDetail } from "../../../api";

interface MyResBookingModalProps {
  username: string;
  isOpen: boolean;
  onClose: () => void;
}
export default function KsInfoModal({
  username,
  isOpen,
  onClose,
}: MyResBookingModalProps) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const yellowColor = useColorModeValue("yellow.500", "yellow.300");
  const grayColor = useColorModeValue("gray.900", "gray.50");
  const whiteColor = useColorModeValue("white", "gray.900");

  const { data: userInfo } = useQuery<IUser>(
    [`users`, username],
    getKongsamUsernameDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"} key={userInfo?.pk}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>
          {userInfo?.username}_no{userInfo?.pk} _INFO
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container mt={10}>
            <Stack spacing={{ base: 6, md: 10 }}>
              <Box as={"header"}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                >
                  <ImagePreview
                    src={userInfo?.user_avatar}
                    name={userInfo?.name}
                    FormMtProps={{ size: "xl" }}
                  />
                </Heading>
              </Box>
              <Stack
                spacing={{ base: 4, sm: 6 }}
                direction={"column"}
                divider={<StackDivider borderColor={borderColor} />}
              >
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    User Details
                  </Text>
                  <List spacing={2}>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        username :
                      </Text>{" "}
                      {userInfo?.username}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        이름 :
                      </Text>{" "}
                      {userInfo?.name}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        연락처 :
                      </Text>{" "}
                      {userInfo?.phone
                        ? userInfo.phone.substring(0, 3) +
                          "-" +
                          userInfo.phone.substring(3, 7) +
                          "-" +
                          userInfo.phone.substring(7, 11)
                        : "-"}
                    </ListItem>
                    <ListItem>
                      <Text as={"span"} fontWeight={"bold"}>
                        주소 :
                      </Text>{" "}
                      {userInfo?.address}
                    </ListItem>
                  </List>
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    kongsam_data
                  </Text>
                  <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
                    <Box>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>최근접속일</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {formatDateTime(
                              new Date(String(userInfo?.last_login))
                            )}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>등록일</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>
                            {formatDateTime(
                              new Date(String(userInfo?.date_joined))
                            )}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex direction={{ base: "column", md: "row" }} mb={2}>
                        <Box flex={1}>
                          <Text>사용자</Text>
                        </Box>
                        <Box flex={1}>
                          <Text>{userInfo?.is_host ? "관리자" : "회원"}</Text>
                        </Box>
                      </Flex>
                    </Box>
                  </SimpleGrid>
                </Box>
              </Stack>

              <Button
                rounded={"none"}
                w={"full"}
                mt={8}
                size={"lg"}
                py={"7"}
                bg={grayColor}
                color={whiteColor}
                textTransform={"uppercase"}
                _hover={{
                  transform: "translateY(2px)",
                  boxShadow: "lg",
                }}
                onClick={onClose}
              >
                Close
              </Button>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <PiDogThin />
                <Text>kongsam user info</Text>
              </Stack>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
