import { useQuery } from "@tanstack/react-query";
import { getCall } from "../api";
import { IResMessage } from "../types";

export default function useCallMsg() {
  const { isLoading, data, isError } = useQuery<IResMessage[]>(
    ["resmessages"],
    getCall,
    {
      retry: false,
    }
  );
  return {
    callLoading: isLoading,
    callData: data,
    isLoggedIn: !isError,
    dataCount: data ? data.length : 0,
  };
}
