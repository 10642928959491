import { useQuery } from "@tanstack/react-query";
import { IPetPublic } from "../types";
import { getTodayBookings } from "../api";
import { ScrollingBox } from "../utils/RenderFlex";
import { KONGSAM_INFO_PHONE } from "../lib/constants";

interface ITodayBooking {
  pk: number;
  booking_time_kst: string;
  beauty_service: string;
  user_pets: IPetPublic[];
}
interface TodayTomorrowProps {
  today: ITodayBooking[];
  tomorrow: ITodayBooking[];
}
export default function ScrollingText() {
  const { data: todayBookings } = useQuery<TodayTomorrowProps>(
    [`bookings`, `today-bookings`],
    getTodayBookings,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const TextToday = todayBookings?.today
    ?.map((booking) => {
      const ownerPetNames = booking.user_pets[0].name;
      const beauty_service = booking.beauty_service;
      const time = booking.booking_time_kst?.split(" ")[1].slice(0, 5);
      return `⭐${time} ${beauty_service} ${ownerPetNames}`;
    })
    .join("  ");
  const TextTomorrow = todayBookings?.tomorrow
    ?.map((booking) => {
      const ownerPetNames = booking.user_pets[0].name;
      const beauty_service = booking.beauty_service;
      const time = booking.booking_time_kst?.split(" ")[1].slice(0, 5);
      return `⭐${time} ${beauty_service} ${ownerPetNames}`;
    })
    .join("  ");
  return (
    <>
      {TextToday ? (
        <ScrollingBox
          bgColor="yellow.50"
          mgTop={3}
        >{`오늘 예약 현황 - ${TextToday}`}</ScrollingBox>
      ) : (
        <ScrollingBox bgColor="yellow.50" mgTop={3}>
          {`1:1 애견미용 서비스(목욕,클리핑,스포팅,가위컷)로 반려견의 스트레스를 최소화해 편안함을 제공합니다. ❤️예약문의 ${KONGSAM_INFO_PHONE}❤️`}
        </ScrollingBox>
      )}
      {TextTomorrow ? (
        <ScrollingBox
          bgColor="blue.50"
          mgTop={1}
        >{`내일 예약 현황 - ${TextTomorrow}`}</ScrollingBox>
      ) : (
        <ScrollingBox bgColor="blue.50" mgTop={3}>
          {`콩삼 1인 애견미용실은 반려견의 건강과 아름다움을 증진하는 서비스를 제공합니다. ❤️예약문의 ${KONGSAM_INFO_PHONE}❤️`}
        </ScrollingBox>
      )}
    </>
  );
}
