import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userSignup } from "../../api";
import { AxiosError } from "axios";

interface userCreateModal {
  isOpen: boolean;
  onClose: () => void;
}

interface ISingUpForm {
  name: string;
  phone: string;
  username: string;
  address: string;
  is_guest_user: boolean;
  date_joined: string;
}

export default function UserCreateModal({ onClose, isOpen }: userCreateModal) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ISingUpForm>();

  const toast = useToast();
  const quesryClient = useQueryClient();

  const mutation = useMutation(userSignup, {
    onSuccess: () => {
      toast({
        title: "등록 성공!",
        status: "success",
      });
      onClose();
      quesryClient.refetchQueries(["me"]);
      reset();
    },
    onError: (error: AxiosError) => {
      const error_message = Object.values(error.response?.data as Object)[0];
      toast({
        title: error_message,
        status: "error",
      });
    },
  });

  const onSubmit = (data: ISingUpForm) => {
    mutation.mutate(data);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>사용자 등록</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl>
              <FormLabel fontWeight={"bold"}>이름</FormLabel>
              <Controller
                name="name"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => <Input {...field} type="text" />}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={"bold"}>사용자 이름</FormLabel>
              <Controller
                name="username"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => <Input {...field} type="text" />}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors?.phone?.message)}>
              <FormLabel htmlFor={"phone"} fontWeight={"bold"}>
                연락처
              </FormLabel>
              <Controller
                name="phone"
                control={control}
                defaultValue={""}
                rules={{
                  required: "연락처를 입력해주세요.",
                  minLength: 11,
                }}
                render={({ field }) => (
                  <Input {...field} type="tel" placeholder="번호만 입력" />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={"bold"}>주소</FormLabel>
              <Controller
                name="address"
                control={control}
                defaultValue={""}
                render={({ field }) => <Input {...field} type="text" />}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight={"bold"}>등록일</FormLabel>
              <Controller
                name="date_joined"
                control={control}
                defaultValue={""}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
            <FormControl>
              <Controller
                name="is_guest_user"
                control={control}
                defaultValue={true}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Checkbox
                    isChecked={value}
                    onChange={(e) => onChange(e.target.checked)}
                    onBlur={onBlur}
                    name={name}
                    ref={ref}
                  >
                    게스트 유저 확인
                  </Checkbox>
                )}
              />
            </FormControl>
          </VStack>
          <Button
            isLoading={mutation.isLoading}
            type="submit"
            mt={4}
            colorScheme="blue"
            w={"100%"}
          >
            콩삼등록
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
