import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FlexProps,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { formatDate, formatDateTime3 } from "../../lib/utils";
import { PiDogThin } from "react-icons/pi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISuccessMessage, createDoneRes, postKsExpenses } from "../../api";
import { FormTextarea, StarRatingDone } from "../FormGroup";
import { IDone } from "../../types";
import { AxiosError } from "axios";

interface IDoneResProps {
  isOpen: boolean;
  onClose: () => void;
  resBookingDateTime: string;
  doneData: {
    donePk: number;
    userName: string;
    userPetName: string;
  };
}
type PaymentValues = {
  cade_payment: number;
  cash_payment: number;
  bank_payment: number;
  donation_payment: number;
  discount_payment: number;
};

export default function DoneResCreateModal({
  isOpen,
  onClose,
  resBookingDateTime,
  doneData,
}: IDoneResProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<IDone>();

  const direction = useBreakpointValue<FlexProps["direction"]>({
    base: "column",
    md: "row",
  });
  const [showCadePayment, setShowCadePayment] = useState(false);
  const [showCashPayment, setShowCashPayment] = useState(false);
  const [showBankPayment, setShowBankPayment] = useState(false);
  const [showDonationPayment, setShowDonationPayment] = useState(false);
  const [showDiscountPayment, setShowDiscountPayment] = useState(false);

  const [rating, setRating] = useState(3);
  const [hover, setHover] = useState(0);
  const [petRating, setPetRating] = useState(3);
  const [petHover, setPetHover] = useState(0);
  useEffect(() => {
    setValue("pet_rating", petRating);
    setValue("user_rating", rating);
  }, [setValue, rating, petRating]);
  useEffect(() => {
    reset({
      pk: doneData.donePk,
    });
  }, [doneData, reset]);

  const values = watch();
  const calculatePaymentAmount = (values: PaymentValues) => {
    return (
      (Number(values.cade_payment) || 0) +
      (Number(values.cash_payment) || 0) +
      (Number(values.bank_payment) || 0) +
      (Number(values.donation_payment) || 0) -
      (Number(values.discount_payment) || 0)
    );
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const postKsExpensesMutation = useMutation(postKsExpenses, {
    onSuccess: () => {
      toast({
        title: "처리되었습니다.",
        status: "success",
      });
      reset();
      onClose();
      setShowCadePayment(false);
      setShowCashPayment(false);
      setShowBankPayment(false);
      setShowDonationPayment(false);
      setShowDiscountPayment(false);
      queryClient.refetchQueries([`bookings`]);
    },
  });
  const createResBookingDoneMutation = useMutation(createDoneRes, {
    onSuccess: (data: IDone) => {
      if (data.pk) {
        let items = [];
        if (data.cade_payment > 0) {
          items.push({
            amount: Number(data.cade_payment),
            notes: `${doneData.userName}(${doneData.userPetName})`,
            category: Number(5),
            account: Number(5),
            income_expenditure_status: "수입",
            cash_receipt: false,
          });
        }

        if (data.cash_payment > 0) {
          let note = `${doneData.userName}(${doneData.userPetName})`;
          if (data.cash_receipt) {
            note += ", 현금영수증";
          }
          items.push({
            amount: Number(data.cash_payment),
            notes: note,
            category: Number(5),
            account: Number(12),
            income_expenditure_status: "수입",
            cash_receipt: data.cash_receipt,
          });
        }

        if (data.bank_payment > 0) {
          let note = `${doneData.userName}(${doneData.userPetName})`;
          if (data.cash_receipt) {
            note += ", 계좌현금영수증";
          }
          items.push({
            amount: Number(data.bank_payment),
            notes: note,
            category: Number(5),
            account: Number(11),
            income_expenditure_status: "수입",
            cash_receipt: data.bank_receipt,
          });
        }

        if (data.donation_payment > 0) {
          items.push({
            amount: Number(data.donation_payment),
            notes: `${doneData.userName}(${doneData.userPetName})`,
            category: Number(5),
            account: Number(13),
            income_expenditure_status: "수입",
            cash_receipt: false,
          });
        }
        if (items.length > 0) {
          let newData = {
            items,
            date: formatDate(new Date(data.start_date)),
          };
          postKsExpensesMutation.mutate(newData);
        } else {
          reset();
          onClose();
          setShowCadePayment(false);
          setShowCashPayment(false);
          setShowBankPayment(false);
          setShowDonationPayment(false);
          setShowDiscountPayment(false);
          queryClient.refetchQueries([`bookings`]);
        }
      } else {
        alert("pk 값 없음. 새로고침 후 재시도 바랍니다.");
        return;
      }
    },
    onError: (error: AxiosError<ISuccessMessage>) => {
      const errorResponse = JSON.parse(error.request.response);
      const errorMessage = errorResponse.error.payment_amount[0];
      toast({
        title: error.request.response.error || error.response?.data.message,
        description: "payment_amount = " + errorMessage,
        status: "error",
      });
      reset();
      onClose();
      setShowCadePayment(false);
      setShowCashPayment(false);
      setShowBankPayment(false);
      setShowDonationPayment(false);
      setShowDiscountPayment(false);
      queryClient.refetchQueries([`bookings`]);
    },
  });

  const onSubmit = (data: IDone) => {
    createResBookingDoneMutation.mutate(data);
  };

  return (
    <Modal
      size={"6xl"}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
        setShowCadePayment(false);
        setShowCashPayment(false);
        setShowBankPayment(false);
        setShowDonationPayment(false);
        setShowDiscountPayment(false);
      }}
    >
      <ModalOverlay />
      <ModalContent as={"form"} onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"} color={"gray.600"}>
          beauty done _{doneData.userName}({doneData.userPetName})_no
          {doneData.donePk}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Controller
            name="pk"
            control={control}
            defaultValue={doneData.donePk}
            render={({ field }) => <Input type="hidden" {...field} />}
          />
          <VStack>
            <Button
              onClick={() => {
                setShowCadePayment(!showCadePayment);
                if (showCadePayment) {
                  setValue("cade_payment", 0);
                }
              }}
              alignSelf="flex-start"
              w={"250px"}
              mb={2}
            >
              {showCadePayment ? "카드 결제금액 취소" : "카드 결제금액 입력"}
            </Button>
            {showCadePayment && (
              <FormControl isInvalid={Boolean(errors?.cade_payment)} mb={5}>
                <Controller
                  name="cade_payment"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      step={5000}
                      {...field}
                      max={10000000}
                      onChange={(valueString) => {
                        const value = Number(valueString);
                        field.onChange(value);
                        setValue(
                          "payment_amount",
                          calculatePaymentAmount({
                            ...values,
                            cade_payment: value,
                          })
                        );
                      }}
                    >
                      <NumberInputField placeholder="카드 결제 금액 입력" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
              </FormControl>
            )}
            <Button
              onClick={() => {
                setShowCashPayment(!showCashPayment);
                if (showCashPayment) {
                  setValue("cash_payment", 0);
                  setValue("cash_receipt", false);
                }
              }}
              alignSelf="flex-start"
              w={"250px"}
              mb={2}
            >
              {showCashPayment ? "현금 결제금액 취소" : "현금 결제금액 입력"}
            </Button>
            {showCashPayment && (
              <FormControl isInvalid={Boolean(errors?.cash_payment)}>
                <Controller
                  name="cash_receipt"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      color={"gray.600"}
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    >
                      <Text fontSize={"sm"} color={"gray.500"}>
                        현금영수증 발행
                      </Text>
                    </Checkbox>
                  )}
                />
                <Controller
                  name="cash_payment"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      step={5000}
                      {...field}
                      onChange={(valueString) => {
                        const value = Number(valueString);
                        field.onChange(value);
                        setValue(
                          "payment_amount",
                          calculatePaymentAmount({
                            ...values,
                            cash_payment: value,
                          })
                        );
                      }}
                    >
                      <NumberInputField placeholder="현금 결제 금액 입력" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
              </FormControl>
            )}

            <Button
              onClick={() => {
                setShowBankPayment(!showBankPayment);
                if (showBankPayment) {
                  setValue("bank_payment", 0);
                  setValue("bank_receipt", false);
                }
              }}
              alignSelf="flex-start"
              w={"250px"}
              mb={2}
            >
              {showBankPayment ? "계좌 결제금액 취소" : "계좌 결제금액 입력"}
            </Button>
            {showBankPayment && (
              <FormControl isInvalid={Boolean(errors?.bank_payment)}>
                <Controller
                  name="bank_receipt"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      color={"gray.600"}
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    >
                      <Text fontSize={"sm"} color={"gray.500"}>
                        계좌 현금영수증 발행
                      </Text>
                    </Checkbox>
                  )}
                />
                <Controller
                  name="bank_payment"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      step={5000}
                      {...field}
                      onChange={(valueString) => {
                        const value = Number(valueString);
                        field.onChange(value);
                        setValue(
                          "payment_amount",
                          calculatePaymentAmount({
                            ...values,
                            bank_payment: value,
                          })
                        );
                      }}
                    >
                      <NumberInputField placeholder="계좌 결제 금액 입력" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
              </FormControl>
            )}
            <Button
              onClick={() => {
                setShowDonationPayment(!showDonationPayment);
                if (showDonationPayment) {
                  setValue("donation_payment", 0);
                }
              }}
              alignSelf="flex-start"
              w={"250px"}
              mb={2}
            >
              {showDonationPayment
                ? "기부금 결제금액 취소"
                : "기부금 결제금액 입력"}
            </Button>
            {showDonationPayment && (
              <FormControl isInvalid={Boolean(errors?.donation_payment)}>
                <Controller
                  name="donation_payment"
                  control={control}
                  render={({ field }) => (
                    <NumberInput
                      step={5000}
                      {...field}
                      onChange={(valueString) => {
                        const value = Number(valueString);
                        field.onChange(value);
                        setValue(
                          "payment_amount",
                          calculatePaymentAmount({
                            ...values,
                            donation_payment: value,
                          })
                        );
                      }}
                    >
                      <NumberInputField placeholder="기부금 결제 금액 입력" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
              </FormControl>
            )}
            <Button
              onClick={() => {
                setShowDiscountPayment(!showDiscountPayment);
                if (showDiscountPayment) {
                  setValue("discount_payment", 0);
                }
              }}
              alignSelf="flex-start"
              w={"250px"}
              mb={2}
            >
              {showDiscountPayment ? "할인 금액 취소" : "할인 금액 입력"}
            </Button>
            {showDiscountPayment && (
              <FormControl isInvalid={Boolean(errors?.discount_payment)}>
                <Controller
                  control={control}
                  name="discount_payment"
                  render={({ field }) => (
                    <NumberInput
                      step={5000}
                      {...field}
                      onChange={(valueString) => {
                        const value = Number(valueString);
                        field.onChange(value);
                        setValue(
                          "payment_amount",
                          calculatePaymentAmount({
                            ...values,
                            discount_payment: value,
                          })
                        );
                      }}
                    >
                      <NumberInputField placeholder="할인 금액 입력" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  )}
                />
              </FormControl>
            )}
          </VStack>
          <Flex gap={3}>
            <FormControl mt={5} mb={5}>
              <FormLabel fontWeight={"bold"}>총 결제금액</FormLabel>
              <Input
                name="payment_amount"
                type="number"
                value={
                  (Number(values.cade_payment) || 0) +
                  (Number(values.cash_payment) || 0) +
                  (Number(values.bank_payment) || 0) +
                  (Number(values.donation_payment) || 0) -
                  (Number(values.discount_payment) || 0)
                }
                readOnly
              />
            </FormControl>
          </Flex>
          <Flex gap={3} flexDirection={direction}>
            <FormControl mt={5}>
              <FormLabel fontSize="sm" fontWeight={"bold"}>
                미용종료일 시작(YYYY-MM-DD 00:00)
              </FormLabel>
              <Controller
                name="start_date"
                control={control}
                defaultValue={formatDateTime3(new Date(resBookingDateTime))}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
            <FormControl mt={5}>
              <FormLabel fontSize="sm" fontWeight={"bold"}>
                미용종료일 종료(YYYY-MM-DD 00:00)
              </FormLabel>
              <Controller
                name="end_date"
                control={control}
                defaultValue={formatDateTime3(new Date(resBookingDateTime))}
                render={({ field }) => (
                  <Input {...field} type="datetime-local" />
                )}
              />
            </FormControl>
          </Flex>
          <Flex w="100%">
            <Box w="50%" mt={10}>
              <VStack align="start" spacing={2}>
                <Text fontSize="sm" fontWeight={"bold"}>
                  펫 별점
                </Text>
                <HStack spacing={1}>
                  {[...Array(5)].map((_, index) => (
                    <StarRatingDone
                      key={index}
                      index={index}
                      rating={petRating}
                      hover={petHover}
                      setHover={setPetHover}
                      setRating={setPetRating}
                    />
                  ))}
                  <Controller
                    name="pet_rating"
                    control={control}
                    defaultValue={petRating}
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                </HStack>
              </VStack>
            </Box>
            <Box w="50%" mt={10}>
              <VStack align="start" spacing={2}>
                <Text fontSize="sm" fontWeight={"bold"}>
                  유저 별점
                </Text>
                <HStack spacing={1}>
                  {[...Array(5)].map((_, index) => (
                    <StarRatingDone
                      key={index}
                      index={index}
                      rating={rating}
                      hover={hover}
                      setHover={setHover}
                      setRating={setRating}
                    />
                  ))}
                  <Controller
                    name="user_rating"
                    control={control}
                    defaultValue={rating}
                    render={({ field }) => <input type="hidden" {...field} />}
                  />
                </HStack>
              </VStack>
            </Box>
          </Flex>
          <VStack>
            <FormTextarea
              name="beauty_content"
              control={control}
              label="미용내용"
              placeholder="미용내용"
              errors={errors}
              FormMtProps={{ mt: 10 }}
              rules={{ required: `미용내용을 입력해주세요.` }}
            />
            <FormTextarea
              name="special_notes"
              control={control}
              label="미용특이사항"
              placeholder="미용특이사항"
              errors={errors}
              FormMtProps={{ mt: 5 }}
              rules={{ required: `미용특이사항을 입력해주세요.` }}
            />
            <FormTextarea
              name="admin_memo"
              control={control}
              label="관리자메모"
              placeholder="관리자메모"
              errors={errors}
              FormMtProps={{ mt: 5 }}
            />
          </VStack>

          <ButtonGroup mt={5} w="100%" py={"7"}>
            <Flex w="100%" justifyContent="space-between">
              <Button
                colorScheme="teal"
                variant="solid"
                w="50%"
                mr={3}
                size={"lg"}
                onClick={() => {
                  reset();
                  onClose();
                  setShowCadePayment(false);
                  setShowCashPayment(false);
                  setShowBankPayment(false);
                  setShowDonationPayment(false);
                  setShowDiscountPayment(false);
                }}
              >
                Close
              </Button>
              <Button
                isLoading={
                  createResBookingDoneMutation.isLoading ||
                  postKsExpensesMutation.isLoading
                }
                w="50%"
                colorScheme="red"
                variant="solid"
                size={"lg"}
                type="submit"
              >
                미용완료
              </Button>
            </Flex>
          </ButtonGroup>
          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam res done</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
