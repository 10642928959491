import {
  Alert,
  AlertIcon,
  AlertStatus,
  Box,
  Button,
  Container,
  Grid,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IPetEditProps } from "./PetAccountInfo";
import { IStatusOk } from "../../../../types";
import { FormCheckboxGroup, FormRadioGroup } from "../../../FormGroup";
import {
  PET_QUESTION_OPTIONS,
  QUESTION_SEVEN_OPTIONS,
  QUESTION_SIX_OPTIONS,
} from "../../../../lib/constants";
import { useUserPetQuestionForm } from "../../../../lib/useUser";
import { IUploadPetVariables, getEditPet } from "../../../../api";
import { useState } from "react";

export default function PetQuestion({
  bgColor,
  bgGrayColor,
  borderColor,
  petInfo,
}: IPetEditProps) {
  const { control, handleSubmit, errors } = useUserPetQuestionForm(petInfo);
  const [status, setStatus] = useState<AlertStatus>();
  const [message, setMessage] = useState("");

  const queryClient = useQueryClient();
  const mutation = useMutation(getEditPet, {
    onSuccess: (data: IStatusOk) => {
      if (data.status === 400) {
        setMessage(data.ok);
        setStatus("error");
      } else {
        setMessage("프로필이 업데이트 되었습니다.");
        setStatus("success");
      }
      queryClient.refetchQueries(["me"]);
    },
  });

  const onQuestionSubmit = (data: IUploadPetVariables) => {
    if (petInfo) data.pk = petInfo?.pk;
    const newData = {
      ...data,
      question6: data.question6.map((item) => item.pk),
      question7: data.question7.map((item) => item.pk),
    };
    mutation.mutate(newData as unknown as IUploadPetVariables);
  };

  return (
    <Container maxW="container.xl" borderColor={borderColor} pb={5}>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "0.3fr 1fr 2fr 0.2fr",
        }}
        mt={10}
      >
        <Box gridColumn={{ md: "2 / 3" }}>
          <Heading fontSize={"xl"}>Pet Question List</Heading>
        </Box>
        <Box
          gridColumn={{ md: "3 / 4" }}
          mt={{ base: "20px", md: "0px" }}
          bg={bgColor}
          rounded={"lg"}
          border={"1px solid"}
          borderColor={borderColor}
          overflow={"hidden"}
          justifyContent={"center"}
          alignItems={"center"}
          as="form"
          onSubmit={handleSubmit(onQuestionSubmit)}
        >
          <Stack spacing={3}>
            {mutation.isLoading && (
              <Alert status="info">
                <AlertIcon />
                변경사항 저장 중...
              </Alert>
            )}
            {mutation.isSuccess && (
              <Alert status={status}>
                <AlertIcon />
                {message}
              </Alert>
            )}
          </Stack>
          <Box p={5}>
            <FormRadioGroup
              name={"question1"}
              control={control}
              label={"성격이 예민한 편인가요?"}
              options={PET_QUESTION_OPTIONS}
              errors={errors}
            />
            <FormRadioGroup
              name={"question2"}
              control={control}
              label={"가끔 물기도 하나요?"}
              options={PET_QUESTION_OPTIONS}
              errors={errors}
              FormMtProps={{ mt: 5 }}
            />
            <FormRadioGroup
              name={"question3"}
              control={control}
              label={"분리불안이 있나요?"}
              options={PET_QUESTION_OPTIONS}
              errors={errors}
              FormMtProps={{ mt: 5 }}
            />
            <FormRadioGroup
              name={"question4"}
              control={control}
              label={"미용 후 가려워한적이 있나요?"}
              options={PET_QUESTION_OPTIONS}
              errors={errors}
              FormMtProps={{ mt: 5 }}
            />
            <FormRadioGroup
              name={"question5"}
              control={control}
              label={"미용 후 불안해하거나 평소와 달랐나요?"}
              options={PET_QUESTION_OPTIONS}
              errors={errors}
              FormMtProps={{ mt: 5 }}
            />
            <FormCheckboxGroup
              name="question6"
              control={control}
              label="싫어하거나 예민한 부분을 체크해주세요."
              options={QUESTION_SIX_OPTIONS}
              errors={errors}
            />
            <FormCheckboxGroup
              name="question7"
              control={control}
              label="아픈곳을 체크해 주세요."
              options={QUESTION_SEVEN_OPTIONS}
              errors={errors}
            />
          </Box>
          <Box textAlign="right" p={4} mt={2} bg={bgGrayColor}>
            <Button
              isLoading={mutation.isLoading}
              type="submit"
              colorScheme="blue"
              p={5}
            >
              Question Save
            </Button>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
