/* kongsam */
export const KONGSAM_INFO_PHONE = "0507-1347-2781";

/* Pet 상수 코드 */
export const DEFAULT_STEP = 1;
export const DEFAULT_PROGRESS = 33.33;
export const NEXT_STEP_INCREMENT = 33.33;

/* RoomDetail 상수 코드 */
export const STARTING_HEIGHT = 75;
export const PAYLOAD_LENGTH_THRESHOLD = 100;
export const DEFAULT_IMAGE_URL =
  "https://avatars.githubusercontent.com/u/74123432?v=4";

/* BeautyBooking 상수 코드  */
export const TIME_START = 9;
export const TIME_END = 19;
export const DATE_FORMAT = "T";
export const DATE_SEPARATOR = "+";

export const FACE_STYLE_NAME_LABEL = "얼굴스타일";
export const FACE_STYLE_NAME_PLACEHOLDER = "얼굴스타일";
export const BODY_LENGTH_CLIP_NAME_LABEL = "몸길이";
export const BODY_LENGTH_CLIP_NAME_PLACEHOLDER = "몸길이";
export const LEG_LENGTH_CLIP_NAME_LABEL = "다리길이";
export const LEG_LENGTH_CLIP_NAME_PLACEHOLDER = "다리길이";
export const FOOT_SHAPE_NAME_LABEL = "발모양";
export const FOOT_SHAPE_NAME_PLACEHOLDER = "발모양";
export const EAR_SHAPE_NAME_LABEL = "귀모양";
export const EAR_SHAPE_NAME_PLACEHOLDER = "귀모양";
export const TAIL_SHAPE_NAME_LABEL = "꼬리모양";
export const TAIL_SHAPE_NAME_PLACEHOLDER = "꼬리모양";
export const DEGRRE_OF_TANGLES_LABEL = "엉킴정도";
export const CONDITION_LABEL = "컨디션";

export const FACE_STYLE_OPTIONS = [
  { key: "동글동글(기본)", label: "동글동글(기본)" },
  { key: "무슈타슈", label: "무슈타슈" },
  { key: "크라운", label: "크라운" },
  { key: "큰테디베어", label: "큰테디베어" },
  { key: "작은테디베어", label: "작은테디베어" },
  { key: "브로컬리", label: "브로컬리" },
  { key: "하이바", label: "하이바" },
  { key: "귀툭튀", label: "귀툭튀" },
  { key: "묶음머리", label: "묶음머리" },
  { key: "베이비컷", label: "베이비컷" },
  { key: "라이언컷", label: "라이언컷" },
  { key: "물개컷", label: "물개컷" },
  { key: "다듬기", label: "다듬기" },
  { key: "곰돌이컷", label: "곰돌이컷" },
  { key: "얼굴밈", label: "얼굴밈" },
  { key: "베들링턴컷", label: "베들링턴컷" },
  { key: "선택안함", label: "선택안함" },
];
export const EAR_SHAPE_OPTIONS = [
  { key: "귀밈", label: "귀밈" },
  { key: "타슬", label: "타슬" },
  { key: "가장짧게", label: "가장짧게" },
  { key: "중간컷(기본)", label: "중간컷(기본)" },
  { key: "끝만다듬기", label: "끝만다듬기" },
  { key: "손대지않음", label: "손대지않음" },
];

export const FOOT_SHAPE_BATH_BASICS_OPTIONS = [
  { key: "다듬기", label: "다듬기" },
  { key: "닭발", label: "닭발" },
];
export const FOOT_SHAPE_OPTIONS = [
  { key: "없음(기본)", label: "없음(기본)" },
  { key: "닭발", label: "닭발" },
  { key: "양말", label: "양말" },
  { key: "부츠", label: "부츠" },
  { key: "방울", label: "방울" },
];
export const TOAIL_SHAPE_OPTIONS = [
  { key: "꼬리밈", label: "꼬리밈" },
  { key: "다듬기", label: "다듬기" },
  { key: "최대한짧게", label: "최대한짧게" },
  { key: "중간컷(기본)", label: "중간컷(기본)" },
  { key: "손대지않음", label: "손대지않음" },
];
export const BODY_LENGTH_CLIP_OPTIONS = [
  { key: "2mm", label: "2mm" },
  { key: "3mm(기본)", label: "3mm(기본)" },
  { key: "5mm", label: "5mm" },
  { key: "1cm", label: "1cm" },
  { key: "2cm", label: "2cm" },
];
export const LEG_LENGTH_CLIP_OPTIONS = [
  { key: "2mm", label: "2mm" },
  { key: "3mm(기본)", label: "3mm(기본)" },
  { key: "5mm", label: "5mm" },
  { key: "1cm", label: "1cm" },
  { key: "2cm", label: "2cm" },
];
export const BODY_LENGTH_SPOTTING_OPTIONS = [
  { key: "3mm(기본)", label: "3mm(기본)" },
  { key: "5mm", label: "5mm" },
  { key: "1cm", label: "1cm" },
  { key: "2cm", label: "2cm" },
];
export const LEG_LENGTH_SPOTTING_OPTIONS = [
  { key: "2cm", label: "2cm" },
  { key: "가위컷-짧게", label: "가위컷-짧게" },
  { key: "가위컷-길게", label: "가위컷-길게" },
];
export const BODY_LENGTH_SCISSORS_CUT_OPTIONS = [
  { key: "짧게", label: "짧게" },
  { key: "보통", label: "보통" },
  { key: "다듬기", label: "다듬기" },
];
export const LEG_LENGTH_SCISSORS_CUT_OPTIONS = [
  { key: "짧게", label: "짧게" },
  { key: "보통", label: "보통" },
  { key: "다듬기", label: "다듬기" },
];
export const DEGRRE_OF_TANGLES_OPTIONS = [
  { value: "없음", label: "없음" },
  { value: "약간", label: "약간" },
  { value: "중간", label: "중간" },
  { value: "심함", label: "심함" },
];
export const CONDITION_OPTIONS = [
  { value: "지침", label: "지침" },
  { value: "약간", label: "약간" },
  { value: "중간", label: "중간" },
  { value: "좋음", label: "좋음" },
];
export const HOST_APPEROVAL_STATE_CHOICES = {
  예약: "예약",
  미용완료: "미용완료",
  예약취소: "예약취소",
  노쇼: "노쇼",
  예약확인중: "예약확인중",
};
export const BEAUTY_SERVICE_CHOICES = {
  목욕: "목욕",
  "목욕+기본": "목욕+기본",
  클리핑: "클리핑",
  스포팅: "스포팅",
  가위컷: "가위컷",
  기타: "기타",
};
export const PET_GENDER_OPTIONS = [
  { label: "남아", value: "남아" },
  { label: "여아", value: "여아" },
];
export const PET_NEUTERING_OPTIONS = [
  { label: "중성화", value: "중성화" },
  { label: "안함", value: "안함" },
  { label: "모름", value: "모름" },
];
export const PET_QUESTION_OPTIONS = [
  { label: "없음", value: "없음" },
  { label: "약간", value: "약간" },
  { label: "중간", value: "중간" },
  { label: "심함", value: "심함" },
  { label: "잘모름", value: "잘모름" },
];
export const PET_BREED_OPTIONS = [
  { label: "말티즈", value: "말티즈" },
  { label: "푸들", value: "푸들" },
  { label: "시츄", value: "시츄" },
  { label: "비숑", value: "비숑" },
  { label: "믹스견", value: "믹스견" },
  { label: "말티푸", value: "말티푸" },
  { label: "포메라니안", value: "포메라니안" },
  { label: "단모치와와", value: "단모치와와" },
  { label: "장모치와와", value: "장모치와와" },
  { label: "스피츠", value: "스피츠" },
  { label: "폼피츠", value: "폼피츠" },
  { label: "페키니즈", value: "페키니즈" },
  { label: "시바견", value: "시바견" },
  { label: "닥스훈트", value: "닥스훈트" },
  { label: "미니핀", value: "미니핀" },
  { label: "웰시코기", value: "웰시코기" },
  { label: "요크셔테리어", value: "요크셔테리어" },
  { label: "푸숑", value: "푸숑" },
  { label: "코카스파니엘", value: "코카스파니엘" },
  { label: "베들링턴테리어", value: "베들링턴테리어" },
  { label: "리트리버", value: "리트리버" },
  { label: "꼬똥드툴레아", value: "꼬똥드툴레아" },
  { label: "퍼그", value: "퍼그" },
  { label: "프렌치불독", value: "프렌치불독" },
  { label: "고양이", value: "고양이" },
  { label: "기타", value: "기타" },
];
export const PET_WEIGTH_OPTIONS = [
  { label: "1kg미만", value: "1kg미만" },
  { label: "1kg", value: "1kg" },
  { label: "1.5kg", value: "1.5kg" },
  { label: "2kg", value: "2kg" },
  { label: "2.5kg", value: "2.5kg" },
  { label: "3kg", value: "3kg" },
  { label: "3.5kg", value: "3.5kg" },
  { label: "4kg", value: "4kg" },
  { label: "4.5kg", value: "4.5kg" },
  { label: "5kg", value: "5kg" },
  { label: "5.5kg", value: "5.5kg" },
  { label: "6kg", value: "6kg" },
  { label: "6.5kg", value: "6.5kg" },
  { label: "7kg", value: "7kg" },
  { label: "7.5kg", value: "7.5kg" },
  { label: "8kg", value: "8kg" },
  { label: "9kg", value: "9kg" },
  { label: "10kg", value: "10kg" },
  { label: "11kg", value: "11kg" },
  { label: "12kg", value: "12kg" },
  { label: "13kg", value: "13kg" },
  { label: "14kg", value: "14kg" },
  { label: "15kg이상", value: "15kg이상" },
];
export const BEAUTY_SERVICE_CHOICES_OPTIONS = [
  { label: "목욕+기본", value: "목욕+기본" },
  { label: "클리핑", value: "클리핑" },
  { label: "스포팅", value: "스포팅" },
  { label: "가위컷", value: "가위컷" },
  { label: "기타", value: "기타" },
];
export const QUESTION_SEVEN_OPTIONS = [
  { pk: 1, name: "없음" },
  { pk: 2, name: "피부병" },
  { pk: 3, name: "알레르기" },
  { pk: 4, name: "아토피" },
  { pk: 5, name: "안과질환" },
  { pk: 6, name: "귓병" },
  { pk: 7, name: "쓸개골탈구" },
  { pk: 8, name: "심장병" },
  { pk: 9, name: "허리디스크" },
  { pk: 10, name: "기도협착증" },
  { pk: 11, name: "호르몬질환" },
];
export const QUESTION_SIX_OPTIONS = [
  { pk: 1, name: "없음" },
  { pk: 2, name: "얼굴" },
  { pk: 3, name: "발톱" },
  { pk: 4, name: "뒷다리" },
  { pk: 5, name: "꼬리" },
  { pk: 6, name: "귀" },
  { pk: 7, name: "앞발" },
  { pk: 8, name: "엉덩이" },
];

export const convertGender = (genderKey: string): string => {
  const genderMap: { [key: string]: string } = {
    male: "남아",
    female: "여아",
  };
  return genderMap[genderKey] || genderKey;
};
export const convertNeutering = (neuteringKey: string): string => {
  const neuteringMap: { [key: string]: string } = {
    neutering_yes: "중성화",
    neutering_no: "안함",
  };
  return neuteringMap[neuteringKey] || neuteringKey;
};
export const convertBreed = (breedKey: string): string => {
  const breedMap: { [breedKey: string]: string } = {
    말티즈: "말티즈",
    푸들: "푸들",
    시츄: "시츄",
    비숑: "비숑",
    믹스견: "믹스견",
    말티푸: "말티푸",
    포메라니안: "포메라니안",
    단모치와와: "단모치와와",
    장모치와와: "장모치와와",
    스피츠: "스피츠",
    폼피츠: "폼피츠",
    페키니즈: "페키니즈",
    시바견: "시바견",
    닥스훈트: "닥스훈트",
    미니핀: "미니핀",
    웰시코기: "웰시코기",
    요크셔테리어: "요크셔테리어",
    코카스파니엘: "코카스파니엘",
    베들링턴테리어: "베들링턴테리어",
    리트리버: "리트리버",
    꼬똥드툴레아: "꼬똥드툴레아",
    퍼그: "퍼그",
    프렌치불독: "프렌치불독",
    고양이: "고양이",
    기타: "기타",
  };
  return breedMap[breedKey] || breedKey;
};
export const convertWeight = (weightKey: string): string => {
  const weightMap: { [weightKey: string]: string } = {
    "1kg미만": "1kg미만",
    "1kg": "1kg",
    "1.5kg": "1.5kg",
    "2.kg": "2kg",
    "2.5kg": "2.5kg",
    "3kg": "3kg",
    "3.5kg": "3.5kg",
    "4kg": "4kg",
    "4.5kg": "4.5kg",
    "5kg": "5kg",
    "5.5kg": "5.5kg",
    "6kg": "6kg",
    "6.5kg": "6.5kg",
    "7kg": "7kg",
    "7.5kg": "7.5kg",
    "8kg": "8kg",
    "9kg": "9kg",
    "10kg": "10kg",
    "11kg": "11kg",
    "12kg": "12kg",
    "13kg": "13kg",
    "14kg": "14kg",
    "15kg이상": "15kg이상",
  };
  return weightMap[weightKey] || weightKey;
};
export const convertQuestion = (questionKey: string): string => {
  const questionMap: { [questionKey: string]: string } = {
    DoesntExist: "없음",
    ALittle: "약간",
    Middle: "중간",
    Severe: "심함",
    NotSure: "잘모름",
  };
  return questionMap[questionKey] || questionKey;
};

export const NAME_VALIDATION_RULES_PATTERN = {
  pattern: {
    value: /^[a-zA-Zㄱ-힣0-9]{2,100}$/i,
    message: "이름(별칭)은 한글,영문,숫자 2자 이상 입력해주세요.",
  },
};
export const PHONE_VALIDATION_RULES_PATTERN = {
  pattern: {
    value: /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/i,
    message: "연락처를 확인해 주세요.(숫자만 입력)",
  },
};
export const USERNAME_VALIDATION_RULES_PATTERN = {
  pattern: {
    value: /^[a-z][a-z0-9.@]{5,}$/i,
    message:
      "영문으로 시작하며, 6자리이상 영문,숫자,특수문자(.@)를 포함 합니다.",
  },
};
