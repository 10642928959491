import {
  Box,
  Button,
  Grid,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import Room from "../components/Room";
import { useInfiniteQuery } from "@tanstack/react-query";
import { IKongsamRoomsListResponse } from "../types";
import { getRooms } from "../api";
import ScrollingText from "../components/ScrollingText";
import RoomSearchBar from "../components/search/RoomSearchBar";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Home() {
  const location = useLocation();
  const [query, setQuery] = useState({
    pname: "",
    start: "",
    end: "",
    breed: "",
    beauty: "",
  });

  const handleQueryChange = useCallback((newQuery: any) => {
    setQuery(newQuery);
  }, []);

  useEffect(() => {
    if (location.state) {
      setQuery(location.state);
    } else {
      setQuery({
        pname: "",
        start: "",
        end: "",
        breed: "",
        beauty: "",
      });
    }
  }, [location.state]);

  const {
    isLoading,
    data: roomData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamRoomsListResponse>(["rooms", query], getRooms, {
    getNextPageParam: (lastPage) =>
      lastPage.page.next_link
        ? new URL(lastPage.page.next_link).searchParams.get("page")
        : undefined,
    staleTime: Infinity,
  });
  return (
    <>
      <RoomSearchBar handleQueryChange={handleQueryChange} />
      <ScrollingText />
      <Grid
        mt={3}
        gap={3}
        templateColumns={{
          base: "1fr 1fr",
          sm: "1fr 1fr",
          md: "1fr 1fr",
          lg: "repeat(3, 1fr)",
          xl: "repeat(4, 1fr)",
        }}
      >
        {isLoading ? (
          <Box>
            <Skeleton rounded={"2xl"} h={"370px"} mb={2} />
            <HStack justifyContent={"space-between"}>
              <Skeleton rounded={"lg"} width={"60%"} height={"4"} mb={1} />
              <Skeleton rounded={"lg"} width={"15%"} height={"4"} />
            </HStack>
          </Box>
        ) : null}
        {roomData?.pages
          .flatMap((page) => page.content)
          .map((room) => (
            <Room
              key={room.pk}
              pk={room.pk}
              imageUrls={room.photos.map((photo) => photo.file)}
              name={room.name}
            />
          ))}
      </Grid>
      {hasNextPage ? (
        <Box display="flex" justifyContent="center" mt={10}>
          <VStack>
            <Text as={"b"} fontSize={"lg"}>
              ❤️
            </Text>
            <Button
              py={5}
              px={10}
              size={"lg"}
              colorScheme="blue"
              variant="ghost"
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            >
              <Text fontSize={"sm"}>
                {isFetchingNextPage ? (
                  "is Loding..."
                ) : hasNextPage ? (
                  <span>콩삼 사진 더 보기 &rarr;</span>
                ) : (
                  <span>콩삼 사진 마지막 &rarr;</span>
                )}
              </Text>
            </Button>
          </VStack>
        </Box>
      ) : null}
    </>
  );
}
