import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IIsErrorMessage,
  IQuestion,
  ISuccessMessage,
  IUploadPetVariables,
  getQuestion,
  uploadPet,
} from "../../api";
import { PiDogThin } from "react-icons/pi";
import {
  FormCheckboxGroup,
  FormInput,
  FormRadioGroup,
  FormSelect,
  FormTextarea,
} from "../FormGroup";
import {
  PET_BREED_OPTIONS,
  PET_GENDER_OPTIONS,
  PET_NEUTERING_OPTIONS,
  PET_QUESTION_OPTIONS,
  PET_WEIGTH_OPTIONS,
} from "../../lib/constants";
import { AxiosError } from "axios";

export interface IQuestionProps {
  questionseven: IQuestion[];
  questionsix: IQuestion[];
}
interface IPetCreateModalProps {
  petPk: number;
  username: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function PetCreateModal({
  petPk,
  username,
  isOpen,
  onClose,
}: IPetCreateModalProps) {
  const { data: question } = useQuery<IQuestionProps>(
    [`pets`, `question`],
    getQuestion,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUploadPetVariables>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IUploadPetVariables
  >(uploadPet, {
    onSuccess: () => {
      toast({
        title: "등록되었습니다.",
        status: "success",
      });
      reset();
      queryClient.refetchQueries([`pets`]);
      queryClient.refetchQueries([`users`]);
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
    },
  });

  const onSubmit = (data: IUploadPetVariables) => {
    const newData = {
      ...data,
      owner: petPk,
      birthday: new Date(data.birthday).toISOString().split("T")[0],
      question6: data.question6.map((item) => item.pk),
      question7: data.question7.map((item) => item.pk),
    };
    mutation.mutate(newData as unknown as IUploadPetVariables);
  };
  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => {
        reset();
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>{username} 펫 등록</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <Box borderWidth="1px" rounded="lg" w={"100%"} p={3}>
              <FormInput
                name="name"
                control={control}
                label="우리아이 이름"
                defaultValue=""
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormControl mt={5} isInvalid={Boolean(errors.birthday?.message)}>
                <FormLabel htmlFor="birthday" fontWeight={"bold"}>
                  생일
                </FormLabel>
                <Controller
                  name="birthday"
                  control={control}
                  rules={{ required: "생일을 입력해주세요." }}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="date"
                      max={new Date().toISOString().split("T")[0]}
                    />
                  )}
                />
                {errors.birthday && (
                  <FormErrorMessage>{errors.birthday.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormRadioGroup
                name={"gender"}
                control={control}
                label={"성별"}
                defaultValue={"남아"}
                options={PET_GENDER_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormRadioGroup
                name={"neutering"}
                control={control}
                label={"중성화"}
                defaultValue={"중성화"}
                options={PET_NEUTERING_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormSelect
                name="weigth"
                control={control}
                label="체중(kg)"
                options={PET_WEIGTH_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormSelect
                name="breed"
                control={control}
                label="품종"
                options={PET_BREED_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormRadioGroup
                name={"question1"}
                control={control}
                label={"성격이 예민한 편인가요?"}
                defaultValue={"없음"}
                options={PET_QUESTION_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormRadioGroup
                name={"question2"}
                control={control}
                label={"가끔 물기도 하나요?"}
                defaultValue={"없음"}
                options={PET_QUESTION_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormRadioGroup
                name={"question3"}
                control={control}
                label={"분리불안이 있나요?"}
                defaultValue={"없음"}
                options={PET_QUESTION_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormRadioGroup
                name={"question4"}
                control={control}
                label={"미용 후 가려워한적이 있나요?"}
                defaultValue={"없음"}
                options={PET_QUESTION_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormRadioGroup
                name={"question5"}
                control={control}
                label={"미용 후 불안해하거나 평소와 달랐나요?"}
                defaultValue={"없음"}
                options={PET_QUESTION_OPTIONS}
                errors={errors}
                FormMtProps={{ mt: 5 }}
              />
              <FormCheckboxGroup
                name="question6"
                control={control}
                label="싫어하거나 예민한 부분을 체크해주세요."
                options={question?.questionsix}
                errors={errors}
              />
              <FormCheckboxGroup
                name="question7"
                control={control}
                label="아픈곳을 체크해 주세요."
                options={question?.questionseven}
                errors={errors}
              />
              <FormTextarea
                name="significant"
                control={control}
                label="특이사항"
                placeholder="우리아이 특이사항"
                errors={errors}
                FormMtProps={{ mt: 10, mb: 5 }}
              />
            </Box>
          </HStack>
          <ButtonGroup mt={5} w="100%" py={"7"}>
            <Flex w="100%" justifyContent="space-between">
              <Button
                colorScheme="teal"
                variant="solid"
                w="50%"
                mr={3}
                size={"lg"}
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                w="50%"
                isLoading={mutation.isLoading}
                colorScheme="red"
                variant="solid"
                size={"lg"}
                type="submit"
              >
                펫 등록
              </Button>
            </Flex>
          </ButtonGroup>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam pet create</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
