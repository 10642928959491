import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../lib/useUser";

interface IProtectedPageProps {
  children: React.ReactNode;
}

/* 사용자가 로그인 상태인지 확인 */
export default function ProtectedPage({ children }: IProtectedPageProps) {
  const { isLoggedIn, userLoading } = useUser();

  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoading) {
      if (!isLoggedIn) {
        navigate("/room");
      }
    }
  }, [isLoggedIn, userLoading, navigate]);
  return <>{children}</>;
}
