import { Avatar } from "@chakra-ui/react";

interface IImagePreviewProps {
  src?: string | undefined;
  name?: string | undefined;
  FormMtProps?: React.ComponentPropsWithoutRef<typeof Avatar>;
}
export const ImagePreview: React.FC<IImagePreviewProps> = ({
  src,
  name,
  FormMtProps,
}) => {
  return <Avatar src={src} name={name} {...FormMtProps} />;
};
