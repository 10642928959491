import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  IconButton,
  HStack,
  Textarea,
  NumberInput,
  NumberInputField,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Select,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { PiDogThin } from "react-icons/pi";
import KsStatisOutlayCategory from "./KsStatisOutlayCategory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ICategorytemProps,
  IIsErrorMessage,
  ISuccessMessage,
  getAccount,
  getOutlayCategory,
  postKsExpenses,
} from "../../../api";
import { IAccountItem } from "./KsStatisAccount";
import { AxiosError } from "axios";

export interface IOutlayItem {
  pk?: number;
  category: string | number;
  amount: number;
  notes: string;
  account: string | number;
  income_expenditure_status: string;
  cash_receipt: boolean;
}
export interface IOutlay {
  items: IOutlayItem[];
  date: string;
}
interface KsOutlayModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function KsStatisOutlay({
  isOpen,
  onClose,
}: KsOutlayModalProps) {
  const { data: getCategory } = useQuery<ICategorytemProps[]>(
    [`expenses`, `expense-category`],
    getOutlayCategory,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );

  const { register, control, handleSubmit, reset } = useForm<IOutlay>({
    defaultValues: {
      items: [
        {
          category: "",
          amount: 0,
          account: "",
          notes: "",
          income_expenditure_status: "",
          cash_receipt: false,
        },
      ],
      date: "",
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const toast = useToast();
  const queryClient = useQueryClient();
  const expenseMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IOutlay
  >(postKsExpenses, {
    onSuccess: () => {
      toast({
        title: "수입/지출내역 등록!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`]);
      reset();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description:
          typeof error.response?.data.error === "object"
            ? JSON.stringify(error.response?.data.error)
            : error.response?.data.error,
        status: "error",
      });
    },
  });
  const onSubmit = (data: IOutlay) => {
    const updatedItems = data.items.map((item) => {
      if (item.cash_receipt) {
        return {
          ...item,
          notes: item.notes ? item.notes + ", 현금영수증" : "현금영수증",
        };
      } else {
        return item;
      }
    });
    const updatedData = {
      ...data,
      items: updatedItems,
    };
    //console.log(updatedData);
    expenseMutation.mutate(updatedData);
  };

  return (
    <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>수입/지출 내역등록</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <KsStatisOutlayCategory categoryData={getCategory} />
          <Box mt={10} as={"form"} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((item, index) => (
              <FormControl key={item.id} mb={3}>
                <HStack mb={1}>
                  <IconButton
                    variant="outline"
                    colorScheme="teal"
                    aria-label="Add category"
                    fontSize="15px"
                    icon={<MinusIcon />}
                    size={"xs"}
                    mb={2}
                    onClick={() => remove(index)}
                  />
                  <FormLabel fontSize={"md"} color={"gray.500"}>
                    수입/지출 입력 {index + 1}
                  </FormLabel>
                </HStack>
                <Controller
                  name={`items.${index}.cash_receipt`}
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      color={"gray.600"}
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    >
                      <Text fontSize={"sm"} color={"gray.500"}>
                        현금영수증 발행
                      </Text>
                    </Checkbox>
                  )}
                />
                <HStack>
                  <Controller
                    control={control}
                    name={`items.${index}.account`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        mb={1}
                        fontSize={"sm"}
                        color={"gray.500"}
                        placeholder="계좌 선택"
                        required
                      >
                        {getAccounts &&
                          getAccounts.map((account) => (
                            <option key={account.pk} value={account.pk}>
                              {account.account_name}
                            </option>
                          ))}
                      </Select>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`items.${index}.income_expenditure_status`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        mb={1}
                        fontSize={"sm"}
                        color={"gray.500"}
                        placeholder="수입/지출 선택"
                        required
                      >
                        <option value="지출">지출</option>
                        <option value="수입">수입</option>
                      </Select>
                    )}
                  />
                </HStack>
                <HStack>
                  <Controller
                    control={control}
                    name={`items.${index}.category`}
                    render={({ field }) => (
                      <Select
                        {...field}
                        mb={1}
                        fontSize={"sm"}
                        color={"gray.500"}
                        placeholder="카테고리 선택"
                        required
                      >
                        {getCategory &&
                          getCategory.map((category) => (
                            <option key={category.pk} value={category.pk}>
                              {category.name}
                            </option>
                          ))}
                      </Select>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`items.${index}.amount`}
                    render={({ field }) => (
                      <NumberInput
                        onChange={(valueAsString, valueAsNumber) =>
                          field.onChange(valueAsNumber)
                        }
                        onBlur={field.onBlur}
                        value={field.value}
                      >
                        <NumberInputField />
                      </NumberInput>
                    )}
                  />
                </HStack>

                <Textarea
                  mt={1}
                  mb={3}
                  fontSize={"sm"}
                  {...register(`items.${index}.notes`)}
                  placeholder="비고를 입력하세요"
                />
              </FormControl>
            ))}

            <Button
              leftIcon={<AddIcon aria-label="Add account" fontSize="15px" />}
              colorScheme="teal"
              variant="outline"
              size={"sm"}
              onClick={() =>
                append({
                  category: "",
                  amount: 0,
                  account: "",
                  notes: "",
                  income_expenditure_status: "",
                  cash_receipt: false,
                })
              }
            >
              내역 추가
            </Button>

            <FormControl mt={5}>
              <FormLabel fontSize={"sm"} color={"gray.500"}>
                수입/지출 일자
              </FormLabel>
              <Input
                type="date"
                fontSize={"sm"}
                color={"gray.500"}
                {...register("date", { required: true })}
                required
              />
            </FormControl>

            <Button mt={4} colorScheme="teal" type="submit">
              내역 등록
            </Button>
          </Box>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam outlay</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
