/* 2020-02-02 */
export const formatDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

/* 2020-02-02 오전 2:00 */
export const formatDateTime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "오후" : "오전";

  // 12시간제로 변환
  hours = hours % 12;
  hours = hours ? hours : 12; // 0시는 12시로 표시

  return `${year}-${month}-${day} ${ampm} ${hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

/* 2020-2-2 2:00 */
export const formatDateTime2 = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  let hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}-${month}-${day} ${hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

/* 2020-02-02(월) 02:00 */
export const formatDateTime3 = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

/* 2020-02-02 월 02:00 */
export const formatDateTime4 = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const days = ["일", "월", "화", "수", "목", "금", "토"];
  const dayOfWeek = date.getDay();
  const dayName = days[dayOfWeek];

  return `${year}-${month}-${day} ${dayName} ${hours}:${minutes}`;
};

/* 2020-02-02 월 02:00:00 */
export const formatDateTime5 = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  let hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

/* 예약일(2020-02-02) - 현재일(2020-02-04) = diffDays(2)  */
export const formatDateDiffTime = (date: Date) => {
  const bookingDate = new Date(date).getTime();
  const now = new Date().getTime();
  const diffTime = Math.abs(now - bookingDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

/* 생일정보를 이용한 나이, 개월수  */
export const formatDateWeigthAge = (date: Date) => {
  if (!date) {
    return `정보없음`;
  }
  const birthday = new Date(date);
  const now = new Date();
  let years = now.getFullYear() - birthday.getFullYear();
  let months = now.getMonth() - birthday.getMonth();
  // 생일이 지났는지 확인
  if (now.getDate() < birthday.getDate()) {
    months--;
  }
  // 월 계산이 음수일 경우
  if (months < 0) {
    years--;
    months += 12;
  }
  if (years === 0) {
    return ` ${months}개월`;
  }
  return `${years}년 ${months}개월`;
};
/* 현재 년도의 월의 1일 */
export const getFirstDayOfMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDay = new Date(Date.UTC(year, month, 1))
    .toISOString()
    .substring(0, 10);
  return firstDay;
};
/* 현재 년도의 월의 마지막 일 */
export const getLastDayOfMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const lastDay = new Date(Date.UTC(year, month + 1, 0))
    .toISOString()
    .substring(0, 10);
  return lastDay;
};
