import {
  Alert,
  AlertIcon,
  AlertStatus,
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import {
  FormInput,
  FormRadioGroup,
  FormSelect,
  FormTextarea,
} from "../../../FormGroup";
import {
  PET_BREED_OPTIONS,
  PET_GENDER_OPTIONS,
  PET_NEUTERING_OPTIONS,
  PET_WEIGTH_OPTIONS,
} from "../../../../lib/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IUploadPetVariables, getEditPet } from "../../../../api";
import { useState } from "react";
import { useUserPetForm } from "../../../../lib/useUser";
import { IStatusOk } from "../../../../types";

export interface IPetEditProps {
  bgColor: string;
  bgGrayColor: string;
  borderColor: string;
  petInfo: IUploadPetVariables | undefined;
}
export default function PetAccountInfo({
  bgColor,
  bgGrayColor,
  borderColor,
  petInfo,
}: IPetEditProps) {
  const [status, setStatus] = useState<AlertStatus>();
  const [message, setMessage] = useState("");
  const { control, handleSubmit, errors } = useUserPetForm(petInfo);

  const queryClient = useQueryClient();
  const mutation = useMutation(getEditPet, {
    onSuccess: (data: IStatusOk) => {
      if (data.status === 400) {
        setMessage(data.ok);
        setStatus("error");
      } else {
        setMessage("프로필이 업데이트 되었습니다.");
        setStatus("success");
      }
      queryClient.refetchQueries(["me"]);
    },
  });
  const onSubmit = (data: IUploadPetVariables) => {
    if (petInfo) data.pk = petInfo?.pk;
    mutation.mutate(data);
  };
  return (
    <Container maxW="container.xl" borderColor={borderColor}>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "0.3fr 1fr 2fr 0.2fr",
        }}
        mt={10}
      >
        <Box gridColumn={{ md: "2 / 3" }}>
          <Heading fontSize={"xl"}>Pet Account Information</Heading>
        </Box>
        <Box
          gridColumn={{ md: "3 / 4" }}
          mt={{ base: "20px", md: "0px" }}
          bg={bgColor}
          rounded={"lg"}
          border={"1px solid"}
          borderColor={borderColor}
          overflow={"hidden"}
          justifyContent={"center"}
          alignItems={"center"}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack spacing={3}>
            {mutation.isLoading && (
              <Alert status="info">
                <AlertIcon />
                변경사항 저장 중...
              </Alert>
            )}
            {mutation.isSuccess && (
              <Alert status={status}>
                <AlertIcon />
                {message}
              </Alert>
            )}
          </Stack>
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6} p={5}>
            <FormInput
              name="name"
              control={control}
              label="우리아이 이름"
              errors={errors}
              borderColor={borderColor}
            />
            <FormControl isInvalid={Boolean(errors.birthday?.message)}>
              <FormLabel htmlFor="birthday" fontWeight={"bold"}>
                생일
              </FormLabel>
              <Controller
                name="birthday"
                control={control}
                render={({ field }) => (
                  <Input {...field} value={field.value || ""} type="date" />
                )}
              />
              {errors.birthday && (
                <FormErrorMessage>{errors.birthday.message}</FormErrorMessage>
              )}
            </FormControl>
            <FormSelect
              name="breed"
              control={control}
              label="품종"
              options={PET_BREED_OPTIONS}
              errors={errors}
            />
            <FormSelect
              name="weigth"
              control={control}
              label="체중(kg)"
              options={PET_WEIGTH_OPTIONS}
              errors={errors}
            />
            <FormRadioGroup
              name={"gender"}
              control={control}
              label={"성별"}
              options={PET_GENDER_OPTIONS}
              errors={errors}
            />
            <FormRadioGroup
              name={"neutering"}
              control={control}
              label={"중성화"}
              options={PET_NEUTERING_OPTIONS}
              errors={errors}
            />
          </Grid>

          <Box gap={6} p={5}>
            <FormTextarea
              name="significant"
              control={control}
              label="특이사항"
              errors={errors}
              placeholder="특이사항 입력"
            />
          </Box>
          <Box textAlign="right" p={4} mt={2} bg={bgGrayColor}>
            <Button
              isLoading={mutation.isLoading}
              type="submit"
              colorScheme="blue"
              p={5}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
