import { Container, useColorModeValue } from "@chakra-ui/react";
import ProtectedPage from "../../middleware/ProtectedPage";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { IUploadPetVariables, getUserPetDetail } from "../../../api";
import PetAccountInfo from "./petedit/PetAccountInfo";
import PetQuestion from "./petedit/PetQuestion";
import PetAvatar from "./petedit/PetAvatar";

export default function KsPetEditProfile() {
  const { petPk } = useParams();
  const bgColor = useColorModeValue("white", "whiteAlpha.100");
  const bgGrayColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const { data: selectedPet } = useQuery<IUploadPetVariables>(
    [`pets`, `${petPk}`],
    getUserPetDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  return (
    <ProtectedPage>
      <Container maxW="container.xl" borderColor={borderColor} pb={5}>
        <PetAccountInfo
          bgColor={bgColor}
          bgGrayColor={bgGrayColor}
          borderColor={borderColor}
          petInfo={selectedPet}
        />

        <PetQuestion
          bgColor={bgColor}
          bgGrayColor={bgGrayColor}
          borderColor={borderColor}
          petInfo={selectedPet}
        />

        <PetAvatar
          bgColor={bgColor}
          bgGrayColor={bgGrayColor}
          borderColor={borderColor}
          petInfo={selectedPet}
        />
      </Container>
    </ProtectedPage>
  );
}
