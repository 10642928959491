import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { deltedMediasPhoto, deltedPhoto, getRoom } from "../api";
import {
  AspectRatio,
  Box,
  Button,
  ButtonGroup,
  Center,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { FcPicture } from "react-icons/fc";
import { FaAudioDescription } from "react-icons/fa";
import ServiceResBooking from "../components/ServiceResBooking";
import { MdOutlineAutoDelete } from "react-icons/md";
import useUser, { processURL } from "../lib/useUser";
import { formatDateTime3 } from "../lib/utils";
import { ArrowForwardIcon } from "@chakra-ui/icons";

export default function RoomDetail() {
  const { roomPk } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();
  const { isLoading, data: roomInfo } = useQuery([`rooms`, roomPk], getRoom, {
    cacheTime: 0,
    staleTime: Infinity,
  });

  const numPhotos = roomInfo?.photos.length || 0;
  let templateRowMd = "2";
  if (numPhotos <= 2) {
    templateRowMd = "1";
  }
  let templateRowBase = "4";
  if (numPhotos === 1) {
    templateRowBase = "1";
  } else if (numPhotos === 2) {
    templateRowBase = "2";
  } else if (numPhotos === 3) {
    templateRowBase = "3";
  }

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const deleteMediasImgMutation = useMutation(deltedMediasPhoto, {
    onSuccess: () => {
      toast({
        title: "삭제 완료!",
        status: "success",
      });
      queryClient.refetchQueries([`rooms`]);
    },
  });
  const deleteImgMutation = useMutation(deltedPhoto);
  const onRoomDeleteClick = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    imgUrls: string,
    imgPk: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm("사진을 삭제하시겠습니까?")) {
      let imgUrl;
      if (typeof imgUrls === "string") {
        imgUrl = processURL(imgUrls);
      } else if (imgUrls) {
        imgUrl = imgUrls;
      }
      if (imgUrl !== false && Array.isArray(imgUrl) && imgUrl.length > 0) {
        const lastValue = imgUrl[imgUrl.length - 1];
        if (lastValue === "public") {
          const imgID = imgUrl[1];
          deleteImgMutation.mutate({ imgID });
        }
      }
      deleteMediasImgMutation.mutate(imgPk);
    }
  };
  const onClickBtn = () => {
    navigate(-1);
  };
  return (
    <Box ml={2} mr={2}>
      <HStack justifyContent={"space-between"} mt={5}>
        <Skeleton
          h={"43px"}
          w={"70%"}
          display="flex"
          alignItems="center"
          isLoaded={!isLoading}
        >
          <VStack align="start">
            <HStack color={"gray.600"} fontSize={"sm"} fontWeight={"600"}>
              <Text>
                {formatDateTime3(new Date(roomInfo?.done?.start_date))}
              </Text>
              <Button
                rightIcon={<ArrowForwardIcon />}
                variant="link"
                onClick={onClickBtn}
                color={"gray.400"}
              >
                <Text color={"gray.400"} fontSize={"sm"} fontWeight={"600"}>
                  Go back
                </Text>
              </Button>
            </HStack>
            <Heading
              fontSize={{ base: "lg", md: "2xl" }}
              color={"gray.600"}
              fontWeight={"600"}
            >
              {roomInfo?.name}
            </Heading>
          </VStack>
        </Skeleton>
        <ButtonGroup size="lg" isAttached variant="link">
          <IconButton
            aria-label="Add to friends"
            onClick={onOpen}
            icon={<FcPicture size={40} color="blue" />}
          />
        </ButtonGroup>
      </HStack>

      <Grid
        templateRows={{
          base: `repeat(${templateRowBase}, 1fr)`,
          md: `repeat(${templateRowMd}, 1fr)`,
        }}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={2}
        mt={5}
      >
        {[0, 1, 2, 3].map(
          (index) =>
            roomInfo?.photos[index]?.file && (
              <GridItem rowSpan={1} colSpan={1} key={index} position="relative">
                <AspectRatio ratio={1}>
                  <Image
                    src={roomInfo?.photos[index]?.file}
                    w="100%"
                    objectFit="cover"
                    rounded={"xl"}
                    overflow={"hidden"}
                    onError={(e) => {
                      (e.target as HTMLImageElement).style.display = "none";
                    }}
                  />
                </AspectRatio>
                {user?.is_host && (
                  <Button
                    position="absolute"
                    top="5px"
                    right="5px"
                    isLoading={
                      deleteMediasImgMutation.isLoading ||
                      deleteImgMutation.isLoading
                    }
                    variant={"unstyled"}
                    cursor={"pointer"}
                    color={"white"}
                    pl={3}
                    pt={1}
                    onClick={(event) =>
                      onRoomDeleteClick(
                        event,
                        roomInfo?.photos[index]?.file,
                        roomInfo?.photos[index]?.pk
                      )
                    }
                  >
                    <MdOutlineAutoDelete size={"25px"} />
                  </Button>
                )}
              </GridItem>
            )
        )}
      </Grid>

      <HStack mt={10}>
        <VStack>
          <Skeleton isLoaded={!isLoading} w={"100%"}>
            <Heading mt={10} fontSize={"lg"} display="flex" alignItems="center">
              <FaAudioDescription size={"30px"} />
              <Text ml={1} color={"gray.600"}>
                Description
              </Text>
            </Heading>
            <HStack justifyContent={"flex-start"} w={"100%"} mt={3}>
              <Text color={"gray.800"} fontWeight={"600"} fontSize={"lg"}>
                {roomInfo?.description}
              </Text>
            </HStack>
            {roomInfo?.done && (
              <>
                <Heading
                  mt={10}
                  fontSize={"2xl"}
                  display="flex"
                  alignItems="center"
                >
                  <Text color={"gray.600"} fontWeight={"600"} fontSize={"lx"}>
                    {roomInfo?.done.booking.beauty_service}
                  </Text>
                </Heading>
                <VStack justifyContent={"flex-start"} w={"100%"} mt={3} mb={50}>
                  {roomInfo?.done && (
                    <Box w={"400px"}>
                      <ServiceResBooking booking={roomInfo?.done.booking} />
                    </Box>
                  )}
                </VStack>
              </>
            )}
          </Skeleton>
        </VStack>
      </HStack>

      <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ backdropFilter: "blur(5px)" }} />
        <ModalContent mt={0} bg={"rgba(244, 244, 244, 0.1)"}>
          <ModalHeader fontSize={"sm"}></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={3} overflowY="auto">
              {roomInfo?.photos.map((photo: any, index: number) => (
                <Center key={index} position="relative">
                  <Image
                    w="100%"
                    h="100%"
                    objectFit="cover"
                    rounded={"xl"}
                    overflow={"hidden"}
                    src={photo.file}
                  />
                  {user?.is_host && (
                    <Button
                      position="absolute"
                      top="5px"
                      right="5px"
                      isLoading={
                        deleteMediasImgMutation.isLoading ||
                        deleteImgMutation.isLoading
                      }
                      variant={"unstyled"}
                      cursor={"pointer"}
                      color={"white"}
                      pl={3}
                      pt={1}
                      onClick={(event) =>
                        onRoomDeleteClick(
                          event,
                          roomInfo?.photos[index]?.file,
                          roomInfo?.photos[index]?.pk
                        )
                      }
                    >
                      <MdOutlineAutoDelete size={"25px"} />
                    </Button>
                  )}
                </Center>
              ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
