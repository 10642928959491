import {
  Button,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Sale } from "../KsStatistics";
import { useState } from "react";

export interface OwnerYearlySales extends Sale {
  owner__name: string;
  user_pets__name: string;
  pet_count: number;
}

export interface VipStatsDataProps {
  yearly_sales: Sale[];
  owner_yearly_sales: OwnerYearlySales[];
}

export default function KsStatisYearlyVipCard({
  data,
}: {
  data: VipStatsDataProps | undefined;
}) {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [itemsToShow, setItemsToShow] = useState(5);

  const handleYearChange = (event: any) => {
    setSelectedYear(Number(event.target.value));
  };

  const showMore = () => {
    setItemsToShow(itemsToShow + 5); // "더보기" 버튼을 클릭하면 항목 수를 5개씩 늘림
  };

  const filteredData = data?.owner_yearly_sales.filter(
    (item) => Number(item.year) === selectedYear
  );
  return (
    <>
      <Select
        value={selectedYear}
        onChange={handleYearChange}
        fontSize={"13px"}
        fontWeight={600}
      >
        <option value={currentYear}>{`${currentYear}년 미용 VIP 순위`}</option>
        <option value={currentYear - 1}>{`${
          currentYear - 1
        }년 미용 VIP 순위`}</option>
      </Select>
      <TableContainer>
        <Table
          variant="striped"
          size={"sm"}
          color="gray.500"
          colorScheme="gray"
          fontWeight={600}
        >
          <TableCaption>
            {selectedYear} 년 미용 완료 VIP 순위{" "}
            {filteredData &&
              itemsToShow < filteredData.length && ( // 더 보여줄 항목이 있으면 "더보기" 버튼을 보여줌
                <Button
                  size={"xs"}
                  onClick={showMore}
                  px={5}
                  color={"blue.300"}
                >
                  더보기
                </Button>
              )}
          </TableCaption>
          <Thead>
            <Tr>
              <Th pt={2} pb={2}>
                순위
              </Th>
              <Th pt={2} pb={2}>
                유저이름
              </Th>
              <Th pt={2} pb={2}>
                펫 이름
              </Th>
              <Th pt={2} pb={2}>
                년도별
              </Th>
              <Th pt={2} pb={2} isNumeric>
                펫미용 수
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.slice(0, itemsToShow).map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}위</Td>
                <Td>{item.owner__name}</Td>
                <Td>{item.user_pets__name}</Td>
                <Td>{item.year}</Td>
                <Td isNumeric>{item.pet_count}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
