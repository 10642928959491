import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUser from "../../lib/useUser";

interface IProtectedPageProps {
  children: React.ReactNode;
}

export default function HostOnlyPage({ children }: IProtectedPageProps) {
  const { userLoading, user } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoading) {
      if (!user?.is_host) {
        navigate("/room");
      }
    }
  }, [userLoading, user, navigate]);
  return <>{children}</>;
}
