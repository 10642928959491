import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { useQuery } from "@tanstack/react-query";
import { getKSDoneList } from "../../api";
import "../../styles/KsCalendar.css";
import { useState } from "react";
import { formatDate } from "../../lib/utils";
import { format } from "date-fns";
import CalendarCreateModal from "../modal/CalendarCreateModal";
import CalendarEditModal from "../modal/CalendarEditModal";
import HostOnlyPage from "../middleware/HostOnlyPage";
import ProtectedPage from "../middleware/ProtectedPage";
import DoneInfoModal from "../modal/DoneInfoModal";
import ResBookingInfoModal from "../modal/ResBookingInfoModal";
import { useCalendarMultipleDisclosure } from "../hook/useMultipleDisclosure";

interface ICalendarProps {
  title: string;
  start: string;
  end?: string;
}
interface ICalendarDateProps {
  start: string;
  end: string;
}
export default function KsCalendar() {
  const [dateRange, setDateRange] = useState<ICalendarDateProps>({
    start: format(new Date(), "yyyy-MM-dd"),
    end: format(new Date(), "yyyy-MM-dd"),
  });
  const formattedStart = dateRange.start
    ? formatDate(new Date(dateRange.start))
    : undefined;
  const formattedEnd = dateRange.end
    ? formatDate(new Date(dateRange.end))
    : undefined;
  const handleDatesSet = ({
    startStr,
    endStr,
  }: {
    startStr: string;
    endStr: string;
  }) => {
    setDateRange({ start: startStr, end: endStr });
  };

  const { data: calendarData } = useQuery<ICalendarProps[]>(
    [`dones`, `calendar`, formattedStart, formattedEnd],
    getKSDoneList,
    {
      enabled: !!formattedStart && !!formattedEnd,
      cacheTime: 0,
      staleTime: Infinity,
      retry: false,
    }
  );

  const [selectedDate, setSelectedDate] = useState<{
    start: string | null;
    end: string | null;
  }>({ start: null, end: null });
  const [calendarPk, setCalendarPk] = useState(0);
  const disclosures = useCalendarMultipleDisclosure();

  const handleEventClick = ({ event }: any) => {
    const calendarPk = `${event.groupId}`;
    const donePK = `${event.id}`;
    const bookingPk = `${event.extendedProps.pk}`;
    if (donePK === "0") return;
    if (calendarPk) {
      setCalendarPk(Number(calendarPk));
      disclosures.CalendarEditModal.onOpen();
    } else if (donePK) {
      setCalendarPk(Number(donePK));
      disclosures.DoneInfoModal.onOpen();
    } else if (bookingPk) {
      setCalendarPk(Number(bookingPk));
      disclosures.ResBookingInfoModal.onOpen();
    }
  };
  return (
    <ProtectedPage>
      <HostOnlyPage>
        <FullCalendar
          height={"auto"}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={"dayGridMonth"}
          headerToolbar={{
            left: "prev",
            center: "title",
            right: "next",
          }}
          locale={"en"}
          firstDay={0}
          titleFormat={function (date) {
            return date.date.year + "년 " + (date.date.month + 1) + "월";
          }}
          events={calendarData}
          eventClick={handleEventClick} // 일정등록시 수정, 삭제 가능
          datesSet={handleDatesSet} // 이벤트 start, end 에따라 데이터 로드
          editable={true} // 사용자의 수정 가능 여부 (이벤트 추가/수정, 드래그 앤 드롭 활성화)
          //selectable={true} // 사용자의 날짜 선택 여부
          selectMirror={true} // 사용자의 시간 선택시 time 표시 여부
          //weekends={true} // 주말 표시 여부
          //navLinks={true} // 달력의 날짜 클릭시 일이나 주단위 보여주는 화면으로 넘어감
          expandRows={true} //화면에 맞게 높이 재설정
          /* navLinkHint={"클릭시 해당 날짜로 이동합니다."} */ // 날짜에 호버시 힌트 문구
          dateClick={(info) => {
            setSelectedDate({
              start: info.dateStr,
              end: info.dateStr,
            });
            disclosures.CalendarCreateModal.onOpen();
          }}
          select={(info) => {
            setSelectedDate({
              start: info.startStr,
              end: info.endStr,
            });
            disclosures.CalendarCreateModal.onOpen();
          }}
        />
        {disclosures.CalendarEditModal.isOpen && (
          <CalendarEditModal
            isOpen={disclosures.CalendarEditModal.isOpen}
            onClose={disclosures.CalendarEditModal.onClose}
            groupPk={calendarPk}
          />
        )}
        {disclosures.CalendarCreateModal.isOpen && (
          <CalendarCreateModal
            isOpen={disclosures.CalendarCreateModal.isOpen}
            onClose={disclosures.CalendarCreateModal.onClose}
            selectedDate={selectedDate}
          />
        )}
        {disclosures.DoneInfoModal.isOpen && (
          <DoneInfoModal
            isOpen={disclosures.DoneInfoModal.isOpen}
            onClose={disclosures.DoneInfoModal.onClose}
            donePk={calendarPk}
          />
        )}
        {disclosures.ResBookingInfoModal.isOpen && (
          <ResBookingInfoModal
            isOpen={disclosures.ResBookingInfoModal.isOpen}
            onClose={disclosures.ResBookingInfoModal.onClose}
            bookingPk={calendarPk}
          />
        )}
      </HostOnlyPage>
    </ProtectedPage>
  );
}
