import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  StackDivider,
  Text,
  useToast,
} from "@chakra-ui/react";
import { KsStatisOutlayCategoryProps } from "./KsStatisOutlayCategory";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  ICategorytemProps,
  deleteKsOutlayCategory,
  putKsOutlayCategory,
} from "../../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export default function KsStatisOutlayCategoryPutDel({
  categoryData,
}: KsStatisOutlayCategoryProps) {
  const [editing, setEditing] = useState<number | null>(null);
  const { register, handleSubmit, setValue, reset } =
    useForm<ICategorytemProps>();

  useEffect(() => {
    if (editing !== null) {
      const categoryToEdit = categoryData?.find(
        (category) => category.pk === editing
      );
      if (categoryToEdit) {
        setValue("pk", categoryToEdit.pk);
        setValue("name", categoryToEdit.name);
        setValue("notes", categoryToEdit.notes);
      }
    } else {
      reset();
    }
  }, [editing, categoryData, reset, setValue]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const updateCategoryMutation = useMutation(putKsOutlayCategory, {
    onSuccess: () => {
      toast({
        title: "결제수단 등록!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`, `expense-category`]);
      setEditing(null);
    },
  });
  const onUpdateSubmit = (data: ICategorytemProps) => {
    updateCategoryMutation.mutate(data);
  };

  const deleteCategoryMutation = useMutation(deleteKsOutlayCategory, {
    onSuccess: () => {
      toast({
        title: "결제수단 등록!",
        status: "success",
      });
      queryClient.refetchQueries([`expenses`, `expense-category`]);
      setEditing(null);
    },
  });
  const onDeleteSubmit = (pk: number) => {
    if (pk !== 0 && window.confirm(`카테고리를 삭제하시 겠습니까?`)) {
      deleteCategoryMutation.mutate(pk);
    }
  };
  return (
    <Card mb={10}>
      <CardBody>
        <Stack divider={<StackDivider />} spacing="1">
          {categoryData &&
            categoryData.map((category, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box w={"100%"} p={1}>
                  {editing === category.pk ? (
                    <>
                      <Box as={"form"} onSubmit={handleSubmit(onUpdateSubmit)}>
                        <FormControl mt={3} mb={3}>
                          <Input
                            mb={1}
                            fontSize={"sm"}
                            color={"gray.500"}
                            type="text"
                            {...register("name", {
                              required: true,
                            })}
                            placeholder="카테고리의 이름을 입력하세요"
                            required
                          />
                          <Input
                            fontSize={"sm"}
                            color={"gray.500"}
                            {...register("notes")}
                            placeholder="비고를 입력하세요"
                          />
                        </FormControl>
                        <Button
                          size={"sm"}
                          colorScheme="teal"
                          mr="2"
                          type="submit"
                        >
                          수정
                        </Button>
                        <Button
                          size={"sm"}
                          colorScheme="red"
                          onClick={() => setEditing(null)}
                        >
                          취소
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Flex alignItems="center">
                        <Heading
                          size="xs"
                          textTransform="uppercase"
                          mr="2"
                          fontWeight={700}
                          fontSize={"md"}
                          color={"gray.500"}
                        >
                          {category.name}
                        </Heading>
                        <Button
                          size="sm"
                          colorScheme="teal"
                          mr="2"
                          variant="link"
                          onClick={() =>
                            setEditing(category.pk ? category.pk : null)
                          }
                        >
                          수정
                        </Button>
                        {category.pk !== 5 && (
                          <Button
                            size="sm"
                            colorScheme="red"
                            variant="link"
                            onClick={() =>
                              onDeleteSubmit(category.pk ? category.pk : 0)
                            }
                          >
                            삭제
                          </Button>
                        )}
                      </Flex>
                      <Text pt="2" fontSize="xs" color={"gray.500"}>
                        {category.notes ? category.notes : "-"}
                      </Text>
                    </>
                  )}
                </Box>
              </Flex>
            ))}
        </Stack>
      </CardBody>
    </Card>
  );
}
