import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HiOutlineEyeOff } from "react-icons/hi";
import { HiOutlineEye } from "react-icons/hi2";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  IUserEditPassVariables,
  getEditPassword,
  logOut,
} from "../../../../api";

interface IColorProps {
  borderColor: string;
  InputBorderColor: string;
  BoxBgColor: string;
  bgColor: string;
}

export default function UserPassword({
  borderColor,
  InputBorderColor,
  BoxBgColor,
  bgColor,
}: IColorProps) {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordChk, setShowNewPasswordChk] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IUserEditPassVariables>();

  const oldPassword = watch("old_password");
  const newPassword = watch("new_password");

  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const logOutMutation = useMutation(logOut);
  const mutation = useMutation(getEditPassword, {
    onSuccess: (data) => {
      toast({
        title: "비밀번호가 변경 되었습니다.!",
        description: "새로운 비밀번호로 로그인해주세요.",
        status: "success",
      });
      logOutMutation.mutate();
      queryClient.refetchQueries(["me"]);
      navigate(`/`);
    },
  });
  const onSubmit = (data: IUserEditPassVariables) => {
    mutation.mutate(data);
  };

  return (
    <Box mb={5}>
      <Divider />
      <Grid templateColumns={{ base: "1fr", md: "0.5fr 1fr 2fr 0.5fr" }} mt={5}>
        <Box gridColumn={{ md: "2 / 3" }}>
          <Heading fontSize={"xl"}>Password</Heading>
        </Box>
        <Box
          gridColumn={{ md: "3 / 4" }}
          mt={{ base: "20px", md: "0px" }}
          bg={bgColor}
          rounded={"lg"}
          border={"1px solid"}
          borderColor={borderColor}
          overflow={"hidden"}
          justifyContent={"center"}
          alignItems={"center"}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid templateColumns={"1fr"} gap={6} p={5}>
            <FormControl isInvalid={Boolean(errors.old_password?.message)}>
              <FormLabel htmlFor="old_password" fontWeight={"bold"}>
                Password
              </FormLabel>
              <InputGroup>
                <Controller
                  name="old_password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant={"solid"}
                      borderWidth={1}
                      color={"gray.800"}
                      _placeholder={{ color: "gray.400" }}
                      borderColor={
                        errors.old_password?.message
                          ? "red.500"
                          : InputBorderColor
                      }
                      type={showOldPassword ? "text" : "password"}
                    />
                  )}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowOldPassword((showOldPassword) => !showOldPassword)
                    }
                  >
                    {showOldPassword ? <HiOutlineEye /> : <HiOutlineEyeOff />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.new_password?.message)}>
              <FormLabel htmlFor="new_password" fontWeight={"bold"}>
                New Password
              </FormLabel>
              <InputGroup>
                <Controller
                  name="new_password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      value !== oldPassword ||
                      "현재 비밀번호와 동일한 번호입니다.",
                    pattern: {
                      value:
                        /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,128}$/i,
                      message:
                        "비밀번호는 영문,숫자,특수문자 조합으로 이루어진 8자이상 입니다.",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant={"solid"}
                      borderWidth={1}
                      color={"gray.800"}
                      _placeholder={{ color: "gray.400" }}
                      borderColor={
                        errors.new_password?.message
                          ? "red.500"
                          : InputBorderColor
                      }
                      type={showNewPassword ? "text" : "password"}
                    />
                  )}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowNewPassword((showNewPassword) => !showNewPassword)
                    }
                  >
                    {showNewPassword ? <HiOutlineEye /> : <HiOutlineEyeOff />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl isInvalid={Boolean(errors.new_password_chk?.message)}>
              <FormLabel htmlFor="new_password_chk" fontWeight={"bold"}>
                New Password Check
              </FormLabel>
              <InputGroup>
                <Controller
                  name="new_password_chk"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) =>
                      value === newPassword ||
                      "새로운 비밀번호가 일치하지 않습니다.",
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      variant={"solid"}
                      borderWidth={1}
                      color={"gray.800"}
                      _placeholder={{ color: "gray.400" }}
                      borderColor={
                        errors.new_password_chk?.message
                          ? "red.500"
                          : InputBorderColor
                      }
                      type={showNewPasswordChk ? "text" : "password"}
                    />
                  )}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowNewPasswordChk(
                        (showNewPasswordChk) => !showNewPasswordChk
                      )
                    }
                  >
                    {showNewPasswordChk ? (
                      <HiOutlineEye />
                    ) : (
                      <HiOutlineEyeOff />
                    )}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </Grid>
          <Text color="red.500" textAlign={"center"} fontSize="sm">
            {errors.old_password?.message}
          </Text>
          <Text color="red.500" textAlign={"center"} fontSize="sm">
            {errors.new_password?.message}
          </Text>
          <Text color="red.500" textAlign={"center"} fontSize="sm">
            {errors.new_password_chk?.message}
          </Text>
          {mutation.isError ? (
            <Text color="red.500" textAlign={"center"} fontSize="sm">
              현재 비밀번호를 정확히 입력해주세요.
            </Text>
          ) : null}

          <Box textAlign="right" p={4} mt={2} bg={BoxBgColor}>
            <HStack justifyContent={"end"}>
              <Text color={"red"} fontWeight={"bold"}>
                로그인 비밀번호를 변경합니다.
              </Text>
              <Button
                isLoading={mutation.isLoading}
                type="submit"
                colorScheme="blue"
                p={5}
              >
                Change Password
              </Button>
            </HStack>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
}
