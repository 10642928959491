import { Box, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import { IAccountItem } from "./KsStatisAccount";
import { getAccount } from "../../../api";
import { useQuery } from "@tanstack/react-query";

export default function KsStatisAccountSum() {
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const totalAmount = getAccounts
    ? getAccounts.reduce((sum, account) => {
        if (
          ["카드결제입금계좌", "계좌이체입금계좌", "현금수입"].includes(
            account.account_name
          )
        ) {
          return sum + Number(account.amount);
        } else {
          return sum;
        }
      }, 0)
    : 0;
  return (
    <Box w={"100%"}>
      <Stat px={{ base: 2, md: 4 }} py={"1"} borderColor={"gray.500"}>
        <StatLabel fontSize={{ base: "xs", sm: "sm" }} color={"gray.500"}>
          총 합계
        </StatLabel>
        <StatNumber
          fontSize={{ base: "sm", sm: "xl" }}
          fontWeight={700}
          color={"gray.600"}
        >
          {totalAmount.toLocaleString()}원
        </StatNumber>
      </Stat>
    </Box>
  );
}
