import { useInfiniteQuery } from "@tanstack/react-query";
import { Box, Text, Tooltip, useColorMode } from "@chakra-ui/react";
import useUser from "../../../../lib/useUser";
import { MyTable } from "../../../../utils/MyTable";
import { IBooking, IKongsamBookingListResponse2 } from "../../../../types";
import { getKsBookingHistoryUsername } from "../../../../api";

export interface BeautyChoicesMap {
  [key: string]: string;
}
export default function MyBookingList() {
  const { user } = useUser();
  const { colorMode } = useColorMode();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";

  const { data: bookingList } = useInfiniteQuery<IKongsamBookingListResponse2>(
    [`bookings`, `${user?.username}`, `booking-history`],
    getKsBookingHistoryUsername,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <Box
      boxShadow={`0px 0px 8px ${boxShadowColor}`}
      overflowX="auto"
      rounded="md"
      mt={10}
    >
      <MyTable<IBooking>
        label="State"
        caption={bookingList?.pages[0]?.page.count}
        columns={[
          {
            header: "예약날짜",
            render: (item: IBooking) => <Text>{item.booking_time_kst}</Text>,
          },
          {
            header: "아이이름",
            render: (item: IBooking) => (
              <Text>{item.user_pets.map((pet) => pet.name).join(", ")}</Text>
            ),
          },
          {
            header: "SERVICE",
            render: (item: IBooking) => <Text>{item.beauty_service}</Text>,
          },
          {
            header: "예약상태",
            render: (item: IBooking) => <Text>{item.host_approval_state}</Text>,
          },
          {
            header: "special notes",
            render: (item: IBooking) => (
              <Tooltip
                label={item.done_notes?.special_notes}
                placement="top"
                hasArrow
              >
                <Text isTruncated w={"3xs"}>
                  {item.done_notes?.special_notes
                    ? item.done_notes?.special_notes
                    : "-"}
                </Text>
              </Tooltip>
            ),
          },
        ]}
        data={
          bookingList ? bookingList.pages.flatMap((page) => page.content) : []
        }
      />
    </Box>
  );
}
