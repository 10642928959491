import { StarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  Wrap,
} from "@chakra-ui/react";
import { useState } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";

interface IInfoItemProps {
  title: string;
  value: string;
  boxProps?: React.ComponentPropsWithoutRef<typeof Box>;
}

interface IInfoListProps {
  title: string;
  list: { name: string }[];
}

export function InfoItem({ title, value, boxProps }: IInfoItemProps) {
  return (
    <Box {...boxProps}>
      <Heading size="xs" textTransform="uppercase">
        {title}
      </Heading>
      <Text pt="2" fontSize="lg">
        {value}
      </Text>
    </Box>
  );
}

export function InfoList({ title, list }: IInfoListProps) {
  return (
    <Box>
      <Heading size="xs" textTransform="uppercase">
        {title}
      </Heading>
      {list.map((item, index) => (
        <Text key={index} pt="2" fontSize="lg">
          {item.name}
        </Text>
      ))}
    </Box>
  );
}

/* 라디오 컴포넌트 */
interface IFormRadioGroupProps {
  name: string;
  control: Control<any>;
  label: string;
  options: Array<{
    label: string;
    value: string | number;
    is_beauty?: boolean;
  }>;
  [key: string]: any;
  errors: any;
  FormMtProps?: React.ComponentPropsWithoutRef<typeof Box>;
  defaultValue?: string;
}
export const FormRadioGroup: React.FC<IFormRadioGroupProps> = ({
  name,
  control,
  label,
  options,
  errors,
  FormMtProps,
  defaultValue,
}) => {
  return (
    <FormControl {...FormMtProps} isInvalid={Boolean(errors[name]?.message)}>
      <FormLabel htmlFor={name} fontWeight={"bold"}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: `${label}` }}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup {...field} key={field.value}>
            <HStack flexWrap="wrap">
              {options?.map((option, index) => (
                <Button
                  key={index}
                  as="div"
                  variant="outline"
                  cursor={"pointer"}
                  p={"7px"}
                  bg={option.is_beauty ? "gray.300" : ""}
                  style={{
                    pointerEvents: option.is_beauty ? "none" : "auto",
                  }}
                >
                  <Radio key={index} value={String(option.value)}>
                    <Text fontSize="xs" whiteSpace="nowrap">
                      {option.label}
                    </Text>
                  </Radio>
                </Button>
              ))}
            </HStack>
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

/* 체크박스 컴포넌트 */
interface IQuestion {
  pk: number;
  name: string;
}

interface IFormCheckboxGroup {
  name: string;
  control: any;
  label: string;
  options?: IQuestion[];
  errors: any;
  FormMtProps?: React.ComponentPropsWithoutRef<typeof Box>;
}
export const FormCheckboxGroup: React.FC<IFormCheckboxGroup> = ({
  name,
  control,
  label,
  options,
  errors,
  FormMtProps,
}) => {
  return (
    <FormControl
      {...FormMtProps}
      mt={10}
      isInvalid={Boolean(errors[name]?.message)}
    >
      <FormLabel htmlFor={name} fontWeight={"bold"}>
        {label}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={[]}
        rules={{ required: `${label}` }}
        render={({ field }) => (
          <CheckboxGroup
            value={
              Array.isArray(field.value)
                ? field.value.map((item: any) => item.pk.toString())
                : []
            }
            onChange={(vals: string[]) => {
              if (vals.includes("1")) {
                field.onChange([{ pk: 1, name: "없음" }]);
              } else {
                field.onChange(
                  vals
                    .map((val) =>
                      options?.find((option) => option.pk.toString() === val)
                    )
                    .filter(Boolean)
                );
              }
            }}
          >
            <Wrap spacing="24px">
              {options?.map((option) => (
                <Checkbox key={option.pk} value={option.pk.toString()}>
                  <Text fontSize="sm" whiteSpace="nowrap">
                    {option.name}
                  </Text>
                </Checkbox>
              ))}
            </Wrap>
          </CheckboxGroup>
        )}
      />
    </FormControl>
  );
};

/* input 컴포넌트  */
interface IFormInputProps {
  name: string;
  control: Control<any>;
  label: string;
  errors: any;
  borderColor?: any;
  FormMtProps?: React.ComponentPropsWithoutRef<typeof Box>;
  defaultValue?: any;
}
export const FormInput: React.FC<IFormInputProps> = ({
  name,
  control,
  label,
  errors,
  borderColor,
  FormMtProps,
  defaultValue,
}) => {
  return (
    <FormControl {...FormMtProps} isInvalid={Boolean(errors[name]?.message)}>
      <FormLabel htmlFor={name} fontWeight={"bold"}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: `${label}` }}
        render={({ field }) => (
          <Input
            {...field}
            variant={"solid"}
            borderWidth={1}
            color={"gray.800"}
            _placeholder={{
              color: "gray.400",
            }}
            borderColor={errors[name]?.message ? "red.500" : borderColor}
            required
          />
        )}
      />
      {errors[name] && (
        <FormErrorMessage>{errors[name].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

/* 셀렉박스 컴포넌트 */
interface IFormSelectProps {
  name: string;
  control: Control<any>;
  label: string;
  options: Array<{ label: string; value: string | number }>;
  errors: any;
  FormMtProps?: React.ComponentPropsWithoutRef<typeof Box>;
}
export const FormSelect: React.FC<IFormSelectProps> = ({
  name,
  control,
  label,
  options,
  errors,
  FormMtProps,
}) => {
  return (
    <FormControl {...FormMtProps} isInvalid={Boolean(errors[name]?.message)}>
      <FormLabel htmlFor={name} fontWeight={"bold"}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: `${label}` }}
        render={({ field }) => (
          <Select {...field} value={field.value || ""}>
            <option value="" disabled>
              {label} 선택해 주세요.
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
      {errors[name] && (
        <FormErrorMessage>{errors[name].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

interface IBeautySelectsProps {
  control: Control<any>;
  name: string;
  placeholder: string;
  label: string;
  initialOptions: { key: string; label: string }[]; //옵션 추가 제거
  excludeKeys: string[];
  defaultValue?: string;
  errors: any;
  handleDataChange?: (name: string, value: any) => void;
  disabled?: boolean;
}
export function BeautySelectForm({
  control,
  errors,
  name,
  placeholder,
  label,
  excludeKeys,
  initialOptions,
  defaultValue,
  handleDataChange,
  disabled,
}: IBeautySelectsProps) {
  const [options] = useState(
    initialOptions?.filter((option) => !excludeKeys.includes(option.key))
  );
  return (
    <>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <Controller
          control={control}
          name={name}
          rules={{ required: `${label}을 선택해주세요.` }}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Select
              {...field}
              isInvalid={Boolean(errors[name]?.message)}
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => {
                field.onChange(e);
                if (handleDataChange) {
                  handleDataChange(name, e.target.value);
                }
              }}
            >
              {options.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.label}
                </option>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </>
  );
}

/* 텍스트에리어 컴포넌트 */
interface IFormTextareaProps {
  name: string;
  control: Control<any>;
  label: string;
  errors: any;
  FormMtProps?: React.ComponentPropsWithoutRef<typeof Box>;
  placeholder?: string;
  rules?: ControllerProps<any>["rules"];
}
export const FormTextarea: React.FC<IFormTextareaProps> = ({
  name,
  control,
  label,
  errors,
  FormMtProps,
  placeholder,
  rules,
}) => {
  return (
    <FormControl {...FormMtProps} isInvalid={Boolean(errors[name]?.message)}>
      <FormLabel htmlFor={name} fontWeight={"bold"}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Textarea
            fontSize={"14px"}
            {...field}
            value={field.value || ""}
            placeholder={placeholder}
          />
        )}
      />
      {errors[name] && (
        <FormErrorMessage>{errors[name].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

interface StarProps {
  index: number;
  rating: number;
  hover: number;
  setHover: React.Dispatch<React.SetStateAction<number>>;
  setRating: React.Dispatch<React.SetStateAction<number>>;
  setValue?: any;
  fieldName?: string;
}
export function StarRatingDone({
  index,
  rating,
  hover,
  setHover,
  setRating,
}: StarProps) {
  const filled = index < (hover || rating);
  return (
    <Icon
      as={StarIcon}
      boxSize={6}
      cursor={"pointer"}
      color={filled ? "yellow.400" : "gray.300"}
      _hover={{ color: "yellow.600" }}
      onMouseEnter={() => setHover(index + 1)}
      onMouseLeave={() => setHover(0)}
      onClick={() => setRating(index + 1)}
    />
  );
}
