import {
  Box,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  useBreakpointValue,
} from "@chakra-ui/react";
import { getCategoryAccount } from "../../../api";
import { useQuery } from "@tanstack/react-query";

interface ICategoryAccountItem {
  category_name: string;
  total_amount: string;
  year: number;
  month: string;
}
export default function KsStatisAccountCategoryChk() {
  const { data: getCategoryAccounts } = useQuery<ICategoryAccountItem[]>(
    [`expenses`, `expense-account`, `category`],
    getCategoryAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const flexBasis = useBreakpointValue({
    base: "32%",
    md: getCategoryAccounts
      ? `${100 / getCategoryAccounts.length - 0.5}%`
      : "100%",
  });
  return (
    <HStack spacing={1.5} wrap="wrap" mb={3}>
      {getCategoryAccounts &&
        getCategoryAccounts.map((account, index) => (
          <Box key={index} flexBasis={flexBasis} w={"100%"}>
            <Stat
              px={{ base: 2, md: 4 }}
              py={"1"}
              border={"1px solid"}
              borderColor={"gray.500"}
              rounded={"lg"}
              overflow={"hidden"}
              whiteSpace={"nowrap"}
            >
              <StatLabel fontSize={{ base: "xs", md: "sm" }} color={"gray.500"}>
                {account.category_name}
              </StatLabel>
              <StatNumber fontSize={"lg"} fontWeight={700} color={"gray.600"}>
                {Number(account.total_amount).toLocaleString()}원
              </StatNumber>
            </Stat>
          </Box>
        ))}
    </HStack>
  );
}
