import {
  Box,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PiDogThin } from "react-icons/pi";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getBookingDetail } from "../../api";
import ServiceResBooking from "../ServiceResBooking";
import { formatDateTime } from "../../lib/utils";
import { InfoRow } from "../../utils/RenderFlex";
import { IBooking } from "../../types";

interface MyResBookingModalProps {
  bookingPk: number;
  isOpen: boolean;
  onClose: () => void;
}
export default function ResBookingInfoModal({
  bookingPk,
  isOpen,
  onClose,
}: MyResBookingModalProps) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const yellowColor = useColorModeValue("yellow.500", "yellow.300");

  const { data: booking } = useQuery<IBooking>(
    [`bookings`, bookingPk],
    getBookingDetail,
    {
      cacheTime: 0,
    }
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>예약정보</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"2xl"}>
            {Array.isArray(booking) && booking.length > 0 && (
              <Stack spacing={{ base: 6, md: 10 }}>
                <Stack
                  spacing={{ base: 4, sm: 6 }}
                  direction={"column"}
                  divider={<StackDivider borderColor={borderColor} />}
                >
                  <Box>
                    <Text
                      fontSize={{ base: "16px", lg: "18px" }}
                      color={yellowColor}
                      fontWeight={"500"}
                      textTransform={"uppercase"}
                      mb={"4"}
                    >
                      Res Booking
                    </Text>
                    {booking[0].user_pets.map((pet: any) => (
                      <React.Fragment key={pet.pk}>
                        <InfoRow label="펫 이름" value={pet?.name} />
                      </React.Fragment>
                    ))}
                    <InfoRow
                      label="예약시간"
                      value={formatDateTime(new Date(booking[0].booking_time))}
                    />
                  </Box>
                  <Box>
                    <Text
                      fontSize={{ base: "16px", lg: "18px" }}
                      color={yellowColor}
                      fontWeight={"500"}
                      textTransform={"uppercase"}
                      mb={"4"}
                    >
                      Product Details - {booking[0].beauty_service}
                    </Text>
                    <ServiceResBooking booking={booking[0]} />
                    <InfoRow
                      label="엉킴 정도"
                      value={booking[0].degree_of_tangles}
                    />
                    <InfoRow label="컨디션" value={booking[0].condition} />
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <PiDogThin />
                  <Text>kongsam res info</Text>
                </Stack>
              </Stack>
            )}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
