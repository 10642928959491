import {
  Button,
  HStack,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { OwnerYearlySales } from "./KsStatisYearlyVipCard";
import { useState } from "react";
import { Sale } from "../KsStatistics";

export interface VipStatsDataProps {
  yearly_sales: Sale[];
  owner_monthly_sales: OwnerYearlySales[];
}

export default function KsStatisYearlyMonthVipCard({
  data,
}: {
  data: VipStatsDataProps | undefined;
}) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [itemsToShow, setItemsToShow] = useState(5);

  const handleYearChange = (event: any) => {
    setSelectedYear(Number(event.target.value));
  };

  const handleMonthChange = (event: any) => {
    setSelectedMonth(Number(event.target.value));
  };

  const showMore = () => {
    setItemsToShow(itemsToShow + 5); // "더보기" 버튼을 클릭하면 항목 수를 5개씩 늘림
  };

  const filteredData = data?.owner_monthly_sales.filter(
    (item) =>
      Number(item.year) === selectedYear && Number(item.month) === selectedMonth
  );

  const months = Array.from({ length: 12 }, (_, i) => i + 1);

  return (
    <>
      <HStack>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          fontSize={"14px"}
          fontWeight={600}
          color={"gray.600"}
        >
          <option
            value={currentYear}
          >{`${currentYear}년 미용 VIP 순위`}</option>
          <option value={currentYear - 1}>{`${
            currentYear - 1
          }년 미용 VIP 순위`}</option>
        </Select>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          fontSize={"13px"}
          fontWeight={600}
        >
          {months.map((month) => (
            <option key={month} value={month}>{`${month}월`}</option>
          ))}
        </Select>
      </HStack>
      <TableContainer>
        <Table
          variant="striped"
          size={"sm"}
          color="gray.500"
          colorScheme="gray"
          fontWeight={600}
        >
          <TableCaption>
            {selectedYear}년 {selectedMonth}월 미용 완료 VIP 순위{" "}
            {filteredData &&
              itemsToShow < filteredData.length && ( // 더 보여줄 항목이 있으면 "더보기" 버튼을 보여줌
                <Button
                  size={"xs"}
                  onClick={showMore}
                  px={5}
                  color={"blue.300"}
                >
                  더보기
                </Button>
              )}
          </TableCaption>
          <Thead>
            <Tr>
              <Th pt={2} pb={2}>
                순위
              </Th>
              <Th pt={2} pb={2}>
                유저이름
              </Th>
              <Th pt={2} pb={2}>
                펫 이름
              </Th>
              <Th pt={2} pb={2}>
                년•월 별
              </Th>
              <Th pt={2} pb={2} isNumeric>
                펫미용 수
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData?.slice(0, itemsToShow).map((item, index) => (
              <Tr key={index}>
                <Td>{index + 1}위</Td>
                <Td>{item.owner__name}</Td>
                <Td>{item.user_pets__name}</Td>
                <Td>
                  {item.year}년 {item.month}월
                </Td>
                <Td isNumeric>{item.pet_count}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
