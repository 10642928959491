import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import Home from "./routes/Home";
import NotFound from "./routes/NotForm";
import KsAdminDashBoard from "./components/ksadmin/KsAdminDashBoard";
import KsStatistics from "./components/ksadmin/KsStatistics";
import KsCalendar from "./components/ksadmin/KsCalendar";
import RoomDetail from "./routes/RoomDetail";
import Sitemap from "./routes/Sitemap";
import Help from "./components/Help";
import KsProfileDashbord from "./components/profile/ksprofile/KsProfileDashbord";
import KsUserEditProfile from "./components/profile/ksprofile/KsUserEditProfile";
import KsPetEditProfile from "./components/profile/ksprofile/KsPetEditProfile";

const router = createBrowserRouter([
  {
    path: "kongsam-my-sitemap.xml",
    element: <Sitemap />,
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "room",
        element: <Home />,
      },
      {
        path: "rooms/:roomPk",
        element: <RoomDetail />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        path: "ks-admin",
        children: [
          {
            path: "",
            element: <KsAdminDashBoard />,
          },
          {
            path: "statistics",
            element: <KsStatistics />,
          },
          {
            path: "calendar",
            element: <KsCalendar />,
          },
        ],
      },
      {
        path: "ksProfile",
        children: [
          {
            path: "",
            element: <KsProfileDashbord />,
          },
          {
            path: "user-edit-profile",
            element: <KsUserEditProfile />,
          },
          {
            path: "pet-edit-profile/:petPk",
            element: <KsPetEditProfile />,
          },
        ],
      },
    ],
  },
]);

export default router;
