import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { PiDogThin } from "react-icons/pi";
import ProtectedPage from "../middleware/ProtectedPage";
import Calendar from "react-calendar";
import "../../styles/calendar.css";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IBookingUpdateVariables,
  IIsErrorMessage,
  ISuccessMessage,
  getBookingDateTimeCheck,
  getBookingDetail,
  putBooking,
} from "../../api";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { differenceBy } from "lodash";
import { Controller, FieldError, useForm } from "react-hook-form";
import { formatDate, formatDateTime3 } from "../../lib/utils";
import {
  CONDITION_LABEL,
  CONDITION_OPTIONS,
  DATE_FORMAT,
  DATE_SEPARATOR,
  DEGRRE_OF_TANGLES_LABEL,
  DEGRRE_OF_TANGLES_OPTIONS,
} from "../../lib/constants";
import UsersPetsList from "../UsersPetsList";
import { FormRadioGroup } from "../FormGroup";
import { AxiosError } from "axios";
import BeautyCustomTabs from "../BeautyCustomTabs";

interface IBookingEditModalProps {
  bookingPk: number;
  isOpen: boolean;
  onClose: () => void;
}
function reducer(state: any, action: any) {
  switch (action.type) {
    case "dateChange":
      return { ...state, dates: action.dates };
    case "selectTime":
      return { ...state, selectedTimeIndex: action.index };
    default:
      throw new Error();
  }
}
export default function BookingEditModal({
  bookingPk,
  isOpen,
  onClose,
}: IBookingEditModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<IBookingUpdateVariables>({});

  const { data: updateBooking } = useQuery<IBookingUpdateVariables[]>(
    [`bookings`, bookingPk],
    getBookingDetail,
    {
      retry: false,
      cacheTime: 0,
      enabled: !!bookingPk,
    }
  );
  useEffect(() => {
    if (updateBooking) {
      setValue("pk", Number(bookingPk));
      setValue(
        "degree_of_tangles",
        updateBooking[0].degree_of_tangles || "약간"
      );
      setValue("condition", updateBooking[0].condition || "중간");
      dispatch({
        type: "dateChange",
        dates: [new Date(updateBooking[0].booking_time)],
      });
      dispatch({
        type: "selectTime",
        index: updateBooking[0].booking_time.split("T")[1].substring(0, 5),
      });
      setValue(
        "booking_time",
        formatDateTime3(new Date(updateBooking[0].booking_time))
      );
      setValue("face_style", updateBooking[0].face_style);
      setValue("body_length_clip", updateBooking[0].body_length_clip);
      setValue("body_length_spotting", updateBooking[0].body_length_spotting);
      setValue(
        "body_length_scissors_cut",
        updateBooking[0].body_length_scissors_cut
      );
      setValue("leg_length_clip", updateBooking[0].leg_length_clip);
      setValue("leg_length_spotting", updateBooking[0].leg_length_spotting);
      setValue(
        "leg_length_scissors_cut",
        updateBooking[0].leg_length_scissors_cut
      );
      setValue("ear_shape", updateBooking[0].ear_shape);
      setValue("foot_shape", updateBooking[0].foot_shape);
      setValue(
        "foot_shape_Bath_basics",
        updateBooking[0].foot_shape_Bath_basics
      );
      setValue("tail_shape", updateBooking[0].tail_shape);
    }
  }, [updateBooking, bookingPk, setValue]);

  /* 등록된 예약을 확인 후 예약 가능한 시간 안내 */
  const [state, dispatch] = useReducer(reducer, {
    dates: updateBooking
      ? [new Date(updateBooking[0].booking_time)]
      : undefined,
    selectedTimeIndex: updateBooking
      ? updateBooking[0].booking_time.split("T")[1].substring(0, 5)
      : null,
  });

  const { data } = useQuery(
    ["datetime-check", state.dates],
    getBookingDateTimeCheck,
    {
      cacheTime: 0,
      enabled: state.dates !== undefined,
    }
  );

  const bookingDateTime = (data || []).map((item: any) => {
    const booking_time = item.booking_time;
    return { ...item, booking_time };
  });

  const handleDateChange = useCallback(
    (value: Value, event: React.MouseEvent<HTMLButtonElement>) => {
      const dates = Array.isArray(value)
        ? value.filter((date): date is Date => date !== null)
        : [value];
      dispatch({ type: "dateChange", dates });
      dispatch({ type: "selectTime", index: 0 });
    },
    [dispatch]
  );

  const bookedTimes = useMemo(
    () =>
      bookingDateTime?.map((booking: any) => ({
        time: `${booking.booking_time
          .split(DATE_FORMAT)[1]
          .split(DATE_SEPARATOR)[0]
          .substring(0, 5)}`,
      })),
    [bookingDateTime]
  );

  const allTimes = useMemo(() => {
    if (!state.dates) {
      return;
    }
    const hours = Array.from({ length: 11 }, (_, i) => i + 9);
    const minutes = ["00", "30"];
    const times: { time: string }[] = [];

    hours.forEach((hour) => {
      minutes.forEach((minute) => {
        if (
          (hour === 19 && minute === "30") ||
          (hour === 9 && minute === "00")
        ) {
          return;
        }
        times.push({
          time: `${hour.toString().padStart(2, "0")}:${minute}`,
        });
      });
    });

    return times;
  }, [state.dates]);

  const availableTimes = useMemo(() => {
    if (!state.dates || !allTimes) {
      return;
    }
    let times = differenceBy(allTimes, bookedTimes, "time");
    if (
      state.selectedTimeIndex &&
      !times.find((time) => time.time === state.selectedTimeIndex)
    ) {
      times = [...times, { time: state.selectedTimeIndex }];
    }
    return times;
  }, [state.dates, allTimes, bookedTimes, state.selectedTimeIndex]);

  const [selectedUserPet, setSelectedUserPet] = useState<{
    userId: number;
    petId: number;
  } | null>(null);
  useEffect(() => {
    if (updateBooking) {
      const userId = updateBooking[0].owner.pk;
      const petId = updateBooking[0].user_pets[0].pk;
      setSelectedUserPet({ userId, petId });
    }
  }, [updateBooking]);

  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IBookingUpdateVariables
  >(putBooking, {
    onSuccess: () => {
      toast({
        title: "수정완료",
        status: "success",
      });
      queryClient.refetchQueries([`bookings`]);
      onClose();
    },
    onError: (error) => {
      alert(error.response?.data.error);
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
    },
  });

  const onSubmit = (data: IBookingUpdateVariables) => {
    const userId = selectedUserPet?.userId;
    const petId = selectedUserPet?.petId;
    if (petId !== undefined) {
      data.user_pets = [
        {
          pk: petId,
        },
      ];
    }
    if (!data.owner) {
      data.owner = {
        pk: userId || 0,
      };
    } else {
      data.owner.pk = userId || 0;
    }

    // 최초 로딩시 불러온 값
    if (updateBooking) {
      data.old_owner = updateBooking[0].owner.pk;
      data.old_user_pets = updateBooking[0].user_pets.map((pet) => pet.pk);
    }
    //alert(JSON.stringify(data, null, 2));
    mutation.mutate(data);
  };
  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>콩삼 예약 수정 _{bookingPk}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <ProtectedPage>
              <VStack>
                <Container maxW="container.xl">
                  <Heading textAlign={"center"}>미용 예약 수정</Heading>
                  <Grid
                    templateColumns={["1fr", null, "1fr 1fr"]}
                    gap={{ base: 0, md: 10, lg: 20 }}
                    mt={10}
                  >
                    <Calendar
                      calendarType="US"
                      value={state.dates ? state.dates[0] : undefined}
                      onChange={handleDateChange}
                      prev2Label={null}
                      next2Label={null}
                      minDate={new Date()}
                      maxDate={
                        new Date(Date.now() + 60 * 60 * 24 * 7 * 4 * 6 * 1000)
                      }
                      minDetail="month"
                      formatDay={(locale, date) =>
                        date.toLocaleString("en", { day: "numeric" })
                      }
                      tileDisabled={({ date, view }) =>
                        view === "month" && date.getDay() === 0
                      }
                    />
                    <FormControl mt={{ base: 20, full: 0 }}>
                      <FormLabel htmlFor={"booking_time"} fontWeight={"bold"}>
                        예약시간 선택
                      </FormLabel>
                      <Controller
                        name="booking_time"
                        control={control}
                        rules={{
                          required: "예약날짜 또는 시간을 선택해주세요.",
                        }}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            value={state.selectedTimeIndex}
                            onChange={(value) => {
                              setValue(
                                "booking_time",
                                `${formatDate(state.dates?.[0])} ${value}`
                              );
                              dispatch({ type: "selectTime", index: value });
                            }}
                          >
                            <Grid templateColumns="repeat(4, 1fr)">
                              {availableTimes?.map((timeObj, index) => (
                                <Box key={`time${index}`}>
                                  <Button
                                    as="div"
                                    variant="outline"
                                    cursor={"pointer"}
                                    mb={3}
                                    w={{ base: "80px", md: "100px" }}
                                    isDisabled={bookedTimes?.some(
                                      (bookedTime: { time: string }) =>
                                        bookedTime.time === timeObj.time
                                    )}
                                  >
                                    <Radio
                                      isInvalid={Boolean(
                                        errors.booking_time?.message
                                      )}
                                      value={timeObj.time}
                                    >
                                      {timeObj.time}
                                    </Radio>
                                  </Button>
                                </Box>
                              ))}
                            </Grid>
                          </RadioGroup>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Box mt={10}>
                    <FormLabel fontWeight={"bold"}>
                      유저와 반려견 선택
                    </FormLabel>
                    <UsersPetsList
                      setSelectedUserPet={setSelectedUserPet}
                      selectedUserPet={selectedUserPet}
                    />
                  </Box>

                  <Box mt={10}>
                    <BeautyCustomTabs
                      control={control}
                      errors={errors}
                      reset={reset}
                      getValues={getValues}
                      setValue={setValue}
                      updateBooking={updateBooking}
                    />
                  </Box>
                  <FormRadioGroup
                    name={"degree_of_tangles"}
                    control={control}
                    label={DEGRRE_OF_TANGLES_LABEL}
                    options={DEGRRE_OF_TANGLES_OPTIONS}
                    errors={errors}
                    defaultValue="약간"
                    FormMtProps={{ mt: 10 }}
                  />
                  <FormRadioGroup
                    name={"condition"}
                    control={control}
                    label={CONDITION_LABEL}
                    options={CONDITION_OPTIONS}
                    errors={errors}
                    defaultValue="중간"
                    FormMtProps={{ mt: 10 }}
                  />
                  <Text
                    color="red.500"
                    textAlign={"center"}
                    fontSize="sm"
                    mt={10}
                  >
                    {errors.booking_time?.message}
                  </Text>
                  <Text
                    color="red.500"
                    textAlign={"center"}
                    fontSize="sm"
                    mt={3}
                  >
                    {
                      (Object.values(errors || {}) as FieldError[]).filter(
                        (error) => error.message
                      )[0]?.message
                    }
                  </Text>
                </Container>
              </VStack>
            </ProtectedPage>
          </HStack>
          <ButtonGroup w="100%" py={"7"}>
            <Flex w="100%" justifyContent="space-between">
              <Button
                colorScheme="teal"
                variant="solid"
                w="50%"
                mr={3}
                size={"lg"}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                w="50%"
                isLoading={mutation.isLoading}
                colorScheme="red"
                variant="solid"
                size={"lg"}
                type="submit"
              >
                미용 예약 수정
              </Button>
            </Flex>
          </ButtonGroup>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam booking edit</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
