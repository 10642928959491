import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  useColorMode,
} from "@chakra-ui/react";
import { useState } from "react";
import ProtectedPage from "../../middleware/ProtectedPage";
import MyPets from "./dashbordlist/MyPets";
import MyBookingList from "./dashbordlist/MyBookingList";
import MyPaymentList from "./dashbordlist/MyPaymentList";

export default function KsProfileList() {
  const { colorMode } = useColorMode();

  const [selectedButton, setSelectedButton] = useState("DeshBoard");

  const getButtonBg = (
    isSelected: boolean,
    colorMode: "light" | "dark"
  ): string =>
    isSelected
      ? colorMode === "dark"
        ? "gray.600"
        : "gray.300"
      : colorMode === "dark"
      ? "gray.700"
      : "white";
  const COMPONENT_MAP: { [key: string]: JSX.Element | string } = {
    pets: <MyPets />,
    grooming: <MyBookingList />,
    payment: <MyPaymentList />,
    DeshBoard: <MyPets />,
  };
  return (
    <ProtectedPage>
      <Container maxW="container.xl">
        <Flex justifyContent="center">
          <ButtonGroup>
            <Button
              variant={selectedButton === "pets" ? "solid" : "outline"}
              bg={getButtonBg(selectedButton === "pets", colorMode)}
              onClick={() => setSelectedButton("pets")}
              fontSize={"sm"}
              color={"gray.600"}
              p={2}
            >
              우리아이 목록
            </Button>
            <Button
              ml={3}
              p={2}
              color={"gray.600"}
              fontSize={"sm"}
              variant={selectedButton === "grooming" ? "solid" : "outline"}
              bg={getButtonBg(selectedButton === "grooming", colorMode)}
              onClick={() => setSelectedButton("grooming")}
            >
              미용예약 확인 목록
            </Button>
            <Button
              ml={3}
              p={2}
              color={"gray.600"}
              fontSize={"sm"}
              variant={selectedButton === "payment" ? "solid" : "outline"}
              bg={getButtonBg(selectedButton === "payment", colorMode)}
              onClick={() => setSelectedButton("payment")}
            >
              미용 결제 내역
            </Button>
          </ButtonGroup>
        </Flex>
        <Box>{COMPONENT_MAP[selectedButton] || COMPONENT_MAP.DeshBoard}</Box>
      </Container>
    </ProtectedPage>
  );
}
