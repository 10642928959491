import { Box, Flex, Text } from "@chakra-ui/react";

interface IRenderFlex {
  label: string;
  value?: string | number | JSX.Element;
}
export const RenderFlex: React.FC<IRenderFlex> = ({ label, value }) => (
  <Flex justifyContent="space-between" pt={1}>
    <Text
      ml={2}
      textTransform="uppercase"
      fontSize="sm"
      color="gray.500"
      fontWeight="bold"
    >
      {label}
    </Text>
    <Text>{value}</Text>
  </Flex>
);

export const InfoRow: React.FC<IRenderFlex> = ({ label, value }) => (
  <Flex mb={2}>
    <Box flex={1}>
      <Text color={"gray.600"}>{label}</Text>
    </Box>
    <Box flex={1}>
      <Text fontWeight={"bold"} color={"gray.600"}>
        {value}
      </Text>
    </Box>
  </Flex>
);

type ScrollingBoxProps = {
  children: React.ReactNode;
  bgColor: string;
  mgTop: number;
};
export const ScrollingBox: React.FC<ScrollingBoxProps> = ({
  children,
  bgColor,
  mgTop,
}) => {
  return (
    <Box
      mt={mgTop}
      p={1}
      as="div"
      whiteSpace="nowrap"
      overflow="hidden"
      backgroundColor={bgColor}
      color="gray.500"
      fontWeight={400}
      fontSize={{ base: "xs", sm: "sm" }}
      rounded={5}
      css={{
        "&::after": {
          content: `"${children}"`,
          display: "inline-block",
          position: "relative",
          width: "max-content",
          whiteSpace: "nowrap",
          paddingInlineStart: "100%",
          animation: "scrolling 25s linear infinite",
        },
        "@keyframes scrolling": {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(-100%)" },
        },
      }}
    />
  );
};
