import {
  Box,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { PiDogThin } from "react-icons/pi";
import { getKongsamUsernameDetail } from "../../api";
import { formatDateTime } from "../../lib/utils";
import { InfoRow } from "../../utils/RenderFlex";
import { IUser } from "../../types";

interface MyResBookingModalProps {
  username: string;
  isOpen: boolean;
  onClose: () => void;
}

export default function UserInfoModal({
  username,
  isOpen,
  onClose,
}: MyResBookingModalProps) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const yellowColor = useColorModeValue("yellow.500", "yellow.300");

  const { data: userInfo } = useQuery<IUser>(
    [`users`, username],
    getKongsamUsernameDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  return (
    <Modal isOpen={isOpen} onClose={onClose} key={userInfo?.pk}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>
          {userInfo?.username}_no{userInfo?.pk} _INFO
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"2xl"}>
            <Stack spacing={{ base: 3, md: 5 }}>
              <Stack
                spacing={{ base: 2, sm: 3 }}
                direction={"column"}
                divider={<StackDivider borderColor={borderColor} />}
              >
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    User Details
                  </Text>
                  <InfoRow label="이름" value={userInfo?.name} />
                  <InfoRow label="ID" value={userInfo?.username} />
                  <InfoRow
                    label="연락처"
                    value={
                      userInfo?.phone
                        ? userInfo.phone.substring(0, 3) +
                          "-" +
                          userInfo.phone.substring(3, 7) +
                          "-" +
                          userInfo.phone.substring(7, 11)
                        : "-"
                    }
                  />
                  <InfoRow
                    label="주소"
                    value={userInfo?.address ? userInfo?.address : "-"}
                  />
                </Box>
                <Box>
                  <Text
                    fontSize={{ base: "16px", lg: "18px" }}
                    color={yellowColor}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                    mb={"4"}
                  >
                    kongsam_data
                  </Text>
                  <SimpleGrid columns={{ base: 1, md: 1 }} spacing={10}>
                    <Box>
                      <InfoRow
                        label="등록일"
                        value={formatDateTime(
                          new Date(String(userInfo?.date_joined))
                        )}
                      />
                      <InfoRow
                        label="최근접속일"
                        value={formatDateTime(
                          new Date(
                            String(
                              userInfo?.last_login ? userInfo?.last_login : "0"
                            )
                          )
                        )}
                      />
                      <InfoRow
                        label="is_host"
                        value={userInfo?.is_host ? "관리자" : "일반사용자"}
                      />
                    </Box>
                  </SimpleGrid>
                </Box>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
              >
                <PiDogThin />
                <Text>kongsam user info</Text>
              </Stack>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
