import { Container, useColorModeValue } from "@chakra-ui/react";
import ProtectedPage from "../../middleware/ProtectedPage";
import UserAccountInfo from "./useredit/UserAccountInfo";
import useUser from "../../../lib/useUser";
import UserAvatar from "./useredit/UserAvatar";
import UserPassword from "./useredit/UserPassword";

export default function KsEditProfile() {
  const { user } = useUser();
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const InputBorderColor = useColorModeValue("gray.300", "gray.700");
  const BoxBgColor = useColorModeValue("gray.50", "gray.700");
  const bgColor = useColorModeValue("white", "whiteAlpha.100");

  return (
    <ProtectedPage>
      <Container maxW="container.xl" borderColor={borderColor} pb={5}>
        <UserAccountInfo
          userInfo={user}
          borderColor={borderColor}
          InputBorderColor={InputBorderColor}
          BoxBgColor={BoxBgColor}
          bgColor={bgColor}
        />

        {/* <UserUsername
          borderColor={borderColor}
          BoxBgColor={BoxBgColor}
          bgColor={bgColor}
          user_name={user?.username}
        /> */}

        <UserPassword
          borderColor={borderColor}
          InputBorderColor={InputBorderColor}
          BoxBgColor={BoxBgColor}
          bgColor={bgColor}
        />

        <UserAvatar
          name={user?.name}
          user_avatar={user?.user_avatar}
          user_name={user?.username}
        />

        {/* Deleted */}
        {/* <UserDelete user_avatar={user?.user_avatar} user_pets={user?.user_pets} /> */}
      </Container>
    </ProtectedPage>
  );
}
