import { IBooking } from "../types";
import { InfoRow } from "../utils/RenderFlex";

interface ISerService {
  booking: IBooking;
}

export default function ServiceResBooking({ booking }: ISerService) {
  switch (booking.beauty_service) {
    case "목욕":
    case "기타":
      return <InfoRow label="기본 서비스" value={""} />;

    case "목욕+기본":
      return (
        <>
          <InfoRow label="얼굴스타일" value={booking.face_style} />
          <InfoRow label="발모양" value={booking.foot_shape_Bath_basics} />
        </>
      );

    case "클리핑":
      return (
        <>
          <InfoRow label="얼굴스타일" value={booking.face_style} />
          <InfoRow label="몸길이(클리핑)" value={booking.body_length_clip} />
          <InfoRow label="다리길이" value={booking.leg_length_clip} />
          <InfoRow label="발모양" value={booking.foot_shape} />
          <InfoRow label="귀모양" value={booking.ear_shape} />
          <InfoRow label="꼬리모양" value={booking.tail_shape} />
        </>
      );
    case "스포팅":
      return (
        <>
          <InfoRow label="얼굴스타일" value={booking.face_style} />
          <InfoRow
            label="몸길이(스포팅)"
            value={booking.body_length_spotting}
          />
          <InfoRow label="다리길이" value={booking.leg_length_spotting} />
          <InfoRow label="발모양" value={booking.foot_shape} />
          <InfoRow label="귀모양" value={booking.ear_shape} />
          <InfoRow label="꼬리모양" value={booking.tail_shape} />
        </>
      );

    case "가위컷":
      return (
        <>
          <InfoRow label="얼굴스타일" value={booking.face_style} />
          <InfoRow
            label="몸길이(가위컷)"
            value={booking.body_length_scissors_cut}
          />
          <InfoRow label="다리길이" value={booking.leg_length_scissors_cut} />
          <InfoRow label="발모양" value={booking.foot_shape} />
          <InfoRow label="귀모양" value={booking.ear_shape} />
          <InfoRow label="꼬리모양" value={booking.tail_shape} />
        </>
      );

    default:
      return null;
  }
}
