import {
  Box,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { formatDateTime } from "../../lib/utils";
import { PiDogThin } from "react-icons/pi";
import { getKongsamDoneDetail } from "../../api";
import { InfoRow } from "../../utils/RenderFlex";
import { IDone } from "../../types";
import React from "react";

interface MyDoneModalProps {
  donePk: number;
  isOpen: boolean;
  onClose: () => void;
}

export default function DoneInfoModal({
  donePk,
  isOpen,
  onClose,
}: MyDoneModalProps) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const yellowColor = useColorModeValue("yellow.500", "yellow.300");

  const { data: doneInfo } = useQuery<IDone>(
    [`dones`, donePk],
    getKongsamDoneDetail,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const renderPaymentInfo = (
    payment: number | undefined,
    paymentType: string
  ) => {
    return payment !== undefined && Math.floor(payment) !== 0
      ? `${paymentType} ${Math.floor(payment)}원 `
      : "";
  };
  const queryClient = useQueryClient();
  const handleClose = () => {
    queryClient.removeQueries([`dones`, donePk]);
    onClose();
  };
  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"sm"}>{doneInfo?.owner.name} _INFO</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container maxW={"xl"}>
            <Stack
              spacing={{ base: 2, sm: 3 }}
              direction={"column"}
              divider={<StackDivider borderColor={borderColor} />}
            >
              <Box>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={yellowColor}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={"1"}
                >
                  User Details
                </Text>
                <InfoRow label="이름" value={doneInfo?.owner.name} />
                <InfoRow label="ID" value={doneInfo?.owner.username} />
                <InfoRow label="별점" value={doneInfo?.user_rating} />
              </Box>
              <Box>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={yellowColor}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={"1"}
                >
                  Pet Details
                </Text>
                <InfoRow label="이름" value={doneInfo?.user_pets[0].name} />
                <InfoRow label="별점" value={doneInfo?.pet_rating} />
              </Box>
              <Box>
                <Text
                  fontSize={{ base: "16px", lg: "18px" }}
                  color={yellowColor}
                  fontWeight={"500"}
                  textTransform={"uppercase"}
                  mb={"1"}
                >
                  Done Details
                </Text>
                <InfoRow
                  label="이름"
                  value={formatDateTime(
                    new Date(String(doneInfo?.booking.booking_time_kst))
                  )}
                />
                <InfoRow
                  label="미용 서비스"
                  value={doneInfo?.booking.beauty_service}
                />
                <InfoRow
                  label="서비스 내용"
                  value={`${doneInfo?.booking.face_style} ${
                    doneInfo?.booking.body_length_clip !== "없음" &&
                    doneInfo?.booking.body_length_clip !== ""
                      ? "," + doneInfo?.booking.body_length_clip
                      : ""
                  }${
                    doneInfo?.booking.body_length_scissors_cut !== "없음" &&
                    doneInfo?.booking.body_length_scissors_cut !== ""
                      ? "," + doneInfo?.booking.body_length_scissors_cut
                      : ""
                  }${
                    doneInfo?.booking.body_length_spotting !== "없음" &&
                    doneInfo?.booking.body_length_spotting !== ""
                      ? "," + doneInfo?.booking.body_length_spotting
                      : ""
                  } ${
                    doneInfo?.booking.foot_shape !== "없음" &&
                    doneInfo?.booking.foot_shape !== ""
                      ? "," + doneInfo?.booking.foot_shape
                      : ""
                  }`}
                />
                <InfoRow
                  label="결제금액"
                  value={
                    <>
                      {`₩ ${Math.floor(
                        doneInfo ? doneInfo?.payment_amount : 0
                      )}원`}
                      {[
                        renderPaymentInfo(doneInfo?.cade_payment, "카드"),
                        doneInfo?.cash_receipt ? "현금영수증" : "",
                        renderPaymentInfo(doneInfo?.cash_payment, "현금"),
                        renderPaymentInfo(doneInfo?.bank_payment, "계좌"),
                        renderPaymentInfo(doneInfo?.donation_payment, "기부금"),
                        renderPaymentInfo(doneInfo?.discount_payment, "할인"),
                      ]
                        .filter(Boolean)
                        .map((item, index) => (
                          <React.Fragment key={index}>
                            <br />
                            {item}
                          </React.Fragment>
                        ))}
                    </>
                  }
                />
                <InfoRow
                  label="미용내용"
                  value={
                    doneInfo?.beauty_content ? doneInfo?.beauty_content : "-"
                  }
                />
                <InfoRow
                  label="미용특이사항"
                  value={
                    doneInfo?.special_notes ? doneInfo?.special_notes : "-"
                  }
                />
                <InfoRow
                  label="관리자메모"
                  value={doneInfo?.admin_memo ? doneInfo?.admin_memo : "-"}
                />
              </Box>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent={"center"}
            >
              <PiDogThin />
              <Text>kongsam done info</Text>
            </Stack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
