import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { useState } from "react";
import KsUserList from "./KsUserList";
import KsPetList from "./KsPetList";
import KsResList from "./KsResList";
import KsDoneList from "./KsDoneList";
import KsCancelleList from "./KsCancelleList";
import KsStatistics from "./KsStatistics";
import { MdDashboardCustomize } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { LuDog } from "react-icons/lu";
import { TfiAlarmClock, TfiSettings } from "react-icons/tfi";
import { TbClockCancel } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import KsCalendar from "./KsCalendar";

export default function KsAdminDashBoard() {
  const { colorMode } = useColorMode();
  const isLargerThanMD = useBreakpointValue({ base: false, md: true });
  const [selectedButton, setSelectedButton] = useState("KSAdmin");
  const getButtonBg = (
    isSelected: boolean,
    colorMode: "light" | "dark"
  ): string =>
    isSelected
      ? colorMode === "dark"
        ? "gray.600"
        : "gray.300"
      : colorMode === "dark"
      ? "gray.700"
      : "white";

  const COMPONENT_MAP: { [key: string]: JSX.Element | string } = {
    pets: <KsPetList />,
    users: <KsUserList />,
    reservations: <KsResList />,
    reservations_end: <KsDoneList />,
    reservations_cancelled_noshow_end: <KsCancelleList />,
    /* user_login_log: <KsUserLogList />, */
    calendar: <KsCalendar />,
    KSAdmin: <KsStatistics setSelectedButton={setSelectedButton} />,
  };

  return (
    <>
      <Flex justifyContent="center">
        <Box mt={5}>
          {isLargerThanMD ? (
            <ButtonGroup>
              <Button
                p={4}
                fontSize={"14px"}
                variant={selectedButton === "KSAdmin" ? "solid" : "outline"}
                bg={getButtonBg(selectedButton === "KSAdmin", colorMode)}
                onClick={() => setSelectedButton("KSAdmin")}
              >
                데시보드
              </Button>
              <Button
                ml={3}
                p={4}
                fontSize={"14px"}
                variant={selectedButton === "pets" ? "solid" : "outline"}
                bg={getButtonBg(selectedButton === "pets", colorMode)}
                onClick={() => setSelectedButton("pets")}
              >
                펫목록
              </Button>
              <Button
                p={4}
                fontSize={"14px"}
                variant={selectedButton === "users" ? "solid" : "outline"}
                bg={getButtonBg(selectedButton === "users", colorMode)}
                onClick={() => setSelectedButton("users")}
              >
                유저목록
              </Button>
              <Button
                ml={3}
                p={4}
                fontSize={"14px"}
                variant={
                  selectedButton === "reservations" ? "solid" : "outline"
                }
                bg={getButtonBg(selectedButton === "reservations", colorMode)}
                onClick={() => setSelectedButton("reservations")}
              >
                미용예약목록
              </Button>
              <Button
                ml={3}
                p={4}
                fontSize={"14px"}
                variant={
                  selectedButton === "reservations_end" ? "solid" : "outline"
                }
                bg={getButtonBg(
                  selectedButton === "reservations_end",
                  colorMode
                )}
                onClick={() => setSelectedButton("reservations_end")}
              >
                미용종료목록
              </Button>
              <Button
                ml={3}
                p={4}
                fontSize={"14px"}
                variant={
                  selectedButton === "reservations_cancelled_noshow_end"
                    ? "solid"
                    : "outline"
                }
                bg={getButtonBg(
                  selectedButton === "reservations_cancelled_noshow_end",
                  colorMode
                )}
                onClick={() =>
                  setSelectedButton("reservations_cancelled_noshow_end")
                }
              >
                예약전체목록
              </Button>
              <Button
                ml={3}
                p={4}
                fontSize={"14px"}
                variant={selectedButton === "calendar" ? "solid" : "outline"}
                bg={getButtonBg(selectedButton === "calendar", colorMode)}
                onClick={() => setSelectedButton("calendar")}
              >
                캘린더
              </Button>
              {/* <Button
                ml={3}
                p={4}
                fontSize={"14px"}
                variant={
                  selectedButton === "user_login_log" ? "solid" : "outline"
                }
                bg={getButtonBg(selectedButton === "user_login_log", colorMode)}
                onClick={() => setSelectedButton("user_login_log")}
              >
                User Logs
              </Button> */}
            </ButtonGroup>
          ) : (
            <>
              <IconButton
                aria-label="dash board"
                size={"md"}
                icon={<MdDashboardCustomize size={"20px"} name="KSAdmin" />}
                bg={getButtonBg(selectedButton === "KSAdmin", colorMode)}
                onClick={() => setSelectedButton("KSAdmin")}
              />
              <IconButton
                ml={2}
                aria-label="pets"
                size={"md"}
                icon={<LuDog size={"20px"} name="pets" />}
                bg={getButtonBg(selectedButton === "pets", colorMode)}
                onClick={() => setSelectedButton("pets")}
              />
              <IconButton
                ml={2}
                aria-label="users"
                size={"md"}
                icon={<BsPerson size={"20px"} name="users" />}
                bg={getButtonBg(selectedButton === "users", colorMode)}
                onClick={() => setSelectedButton("users")}
              />
              <IconButton
                ml={2}
                aria-label="reservations"
                size={"md"}
                icon={<TfiAlarmClock size={"20px"} name="reservations" />}
                bg={getButtonBg(selectedButton === "reservations", colorMode)}
                onClick={() => setSelectedButton("reservations")}
              />
              <IconButton
                ml={2}
                aria-label="reservations_end"
                size={"md"}
                icon={<TfiSettings size={"20px"} name="reservations_end" />}
                bg={getButtonBg(
                  selectedButton === "reservations_end",
                  colorMode
                )}
                onClick={() => setSelectedButton("reservations_end")}
              />
              <IconButton
                ml={2}
                aria-label="reservations_cancelled_noshow_end"
                size={"md"}
                icon={
                  <TbClockCancel
                    size={"20px"}
                    name="reservations_cancelled_noshow_end"
                  />
                }
                bg={getButtonBg(
                  selectedButton === "reservations_cancelled_noshow_end",
                  colorMode
                )}
                onClick={() =>
                  setSelectedButton("reservations_cancelled_noshow_end")
                }
              />
              <IconButton
                ml={2}
                aria-label="calendar"
                size={"md"}
                icon={<CiCalendarDate size={"20px"} name="calendar" />}
                bg={getButtonBg(selectedButton === "calendar", colorMode)}
                onClick={() => setSelectedButton("calendar")}
              />
              {/* <IconButton
                ml={2}
                aria-label="user_login_log"
                size={"md"}
                icon={<VscRuby size={"20px"} name="user_login_log" />}
                bg={getButtonBg(selectedButton === "user_login_log", colorMode)}
                onClick={() => setSelectedButton("user_login_log")}
              /> */}
            </>
          )}
        </Box>
      </Flex>
      <Box mt={3} mb={5}>
        {COMPONENT_MAP[selectedButton] || COMPONENT_MAP.KSAdmin}
      </Box>
    </>
  );
}
