import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ImagePreview } from "../../../../utils/ImagePreview";
import {
  createPetAvatarPhoto,
  deltedPhoto,
  getUploadURL,
  uploadAvatarImage,
} from "../../../../api";
import { processURL } from "../../../../lib/useUser";
import { IPetEditProps } from "./PetAccountInfo";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

interface IUploadForm {
  file: string;
}
interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}
interface IDeleteResponse {
  result: boolean;
}
export default function PetAvatar({
  bgColor,
  bgGrayColor,
  borderColor,
  petInfo,
}: IPetEditProps) {
  const navigate = useNavigate();
  const { register, setValue, handleSubmit, watch } = useForm<IUploadForm>();
  const [previewImage, setPreviewImage] = useState(petInfo?.pet_avatar);
  const [filePetOnOff, setFilePetOnOff] = useState(false);

  const handlePetFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      setValue("file", file);
      setFilePetOnOff(true);
    }
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const createPetAvatarPhotoMutation = useMutation(createPetAvatarPhoto, {
    onSuccess: () => {
      toast({
        status: "success",
        title: "아바타등록 완료!",
      });
      queryClient.refetchQueries([`pets`]);
    },
  });
  const uploadPetAvatarImageMutation = useMutation(uploadAvatarImage, {
    onSuccess: ({ result }: any) => {
      if (petInfo?.pk) {
        createPetAvatarPhotoMutation.mutate({
          pet_avatar: `https://imagedelivery.net/S5Wxv27Ibwd3zt1OHkr4Yg/${result.id}/public`,
          petPK: petInfo?.pk,
        });
      }
    },
  });
  const uploadPetURLMutation = useMutation(getUploadURL, {
    onSuccess: (data: IUploadURLResponse) => {
      uploadPetAvatarImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
    },
  });

  const deletePetImgMutation = useMutation(deltedPhoto, {
    onSuccess: (data: IDeleteResponse) => {
      if (data.result && petInfo?.pk) {
        uploadPetURLMutation.mutate(); // 아바타 삭제 후 재등록 과정에서 `createPetAvatarPhotoMutation.mutate` 호출 제거
      }
    },
  });

  const onAvatarSubmit = (data: any) => {
    if (filePetOnOff === true) {
      const url = processURL(petInfo?.pet_avatar);
      if (url === false) {
        uploadPetURLMutation.mutate();
      }
      if (Array.isArray(url) && url.length > 0) {
        const lastValue = url[url.length - 1];
        if (lastValue !== "public") {
          uploadPetURLMutation.mutate();
        } else {
          const imgID = url[1];
          deletePetImgMutation.mutate({ imgID });
        }
      }
    }
  };
  const onClickBtn = () => {
    navigate(-1);
  };
  return (
    <Container maxW="container.xl" borderColor={borderColor}>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "0.3fr 1fr 2fr 0.2fr",
        }}
        mt={5}
      >
        <Box gridColumn={{ md: "2 / 3" }}>
          <Heading fontSize={"xl"}>Pet Avatar</Heading>
        </Box>
        <Box
          gridColumn={{ md: "3 / 4" }}
          mt={{ base: "20px", md: "0px" }}
          bg={bgColor}
          rounded={"lg"}
          border={"1px solid"}
          borderColor={borderColor}
          overflow={"hidden"}
          justifyContent={"center"}
          alignItems={"center"}
          as="form"
          onSubmit={handleSubmit(onAvatarSubmit)}
        >
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6} p={5}>
            <FormControl>
              <FormLabel htmlFor="file" fontWeight={"bold"}>
                Avatar
              </FormLabel>
              <Stack direction="row">
                <ImagePreview
                  src={previewImage || petInfo?.pet_avatar}
                  name={petInfo?.name}
                  FormMtProps={{ size: "lg" }}
                />
                <Input
                  {...register("file")}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="hiddenPetFileInput"
                  onChange={handlePetFileChange}
                />
                <Button
                  as="label"
                  htmlFor="hiddenPetFileInput"
                  border={"1px"}
                  cursor={"pointer"}
                  ml={4}
                  _hover={{
                    backgroundColor: "initial",
                    borderColor: "initial",
                    color: "initial",
                  }}
                >
                  Choose photo
                </Button>
              </Stack>
            </FormControl>
          </Grid>
          <Box textAlign="right" p={4} mt={2} bg={bgGrayColor}>
            <Button
              isLoading={
                createPetAvatarPhotoMutation.isLoading ||
                uploadPetAvatarImageMutation.isLoading ||
                uploadPetURLMutation.isLoading ||
                deletePetImgMutation.isLoading
              }
              type="submit"
              colorScheme="blue"
              p={5}
            >
              Pet Avatar Save
            </Button>
          </Box>
          <Box
            p={4}
            textAlign="right"
            bg={useColorModeValue("gray.50", "gray.700")}
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              variant="link"
              onClick={onClickBtn}
            >
              <Text>Go back</Text>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
