import { Box, Select } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getKongsamStatistics2 } from "../../api";

interface IMonthlySales {
  bank_payment: number;
  cade_payment: number;
  cash_payment: number;
  donation_payment: number;
  month: string;
  year: string;
}

export default function KsStatisGraph2() {
  const { data: statsData2 } = useQuery<IMonthlySales[]>(
    [`users`, `statistics2`],
    getKongsamStatistics2,
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredData, setFilteredData] = useState<IMonthlySales[]>([]);

  useEffect(() => {
    const newFilteredData = statsData2?.filter(
      (item) => item.year.toString() === selectedYear.toString()
    );
    setFilteredData(newFilteredData || []);
  }, [selectedYear, statsData2]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: "수입 내역 분류",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "카드",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.cade_payment : 0;
        }),
        backgroundColor: "rgba(255,99,132,0.4)",
      },
      {
        label: "현금",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.cash_payment : 0;
        }),
        backgroundColor: "rgba(75,192,192,0.4)",
      },
      {
        label: "계좌",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.bank_payment : 0;
        }),
        backgroundColor: "rgba(53, 162, 235,0.4)",
      },
      {
        label: "기부",
        data: labels.map((label) => {
          const month = labels.indexOf(label) + 1;
          const item = filteredData.find(
            (item) => parseInt(item.month) === month
          );
          return item ? item.donation_payment : 0;
        }),
        backgroundColor: "rgba(111, 235, 53, 0.4)",
      },
    ],
  };

  const availableYears = Array.from(
    new Set(statsData2?.map((item) => item.year))
  );

  return (
    <Box mt={5}>
      <Bar options={options} data={data} />
      <Select
        value={selectedYear}
        onChange={(e) => setSelectedYear(Number(e.target.value))}
      >
        {availableYears.map((year) => (
          <option key={year} value={year}>
            {year}년
          </option>
        ))}
      </Select>
    </Box>
  );
}
