import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  deltedPhoto,
  getEditMe,
  getUploadURL,
  uploadAvatarImage,
} from "../../../../api";
import { processURL } from "../../../../lib/useUser";
import { ImagePreview } from "../../../../utils/ImagePreview";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

interface IUserAvatarEditProps {
  name?: string;
  user_avatar?: string;
  user_name?: string;
}
interface IUploadForm {
  file: string;
}
interface IUploadURLResponse {
  id: string;
  uploadURL: string;
}
interface IDeleteResponse {
  result: boolean;
}

export default function UserAvatar({
  name,
  user_avatar,
  user_name,
}: IUserAvatarEditProps) {
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm<IUploadForm>();
  const [previewImage, setPreviewImage] = useState(user_avatar);
  const [fileOnOff, setFileOnOff] = useState(false);
  const [isAvatarUpdated, setIsAvatarUpdated] = useState(false);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      setValue("file", file);
      setFileOnOff(true);
    }
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const createAvatarPhotoMutation = useMutation(getEditMe, {
    onSuccess: () => {
      if (!isAvatarUpdated) {
        toast({
          status: "success",
          title: "아바타등록 완료!",
        });
        setIsAvatarUpdated(true);
      }
      queryClient.refetchQueries(["me"]);
    },
  });
  const uploadAvatarImageMutation = useMutation(uploadAvatarImage, {
    onSuccess: ({ result }: any) => {
      if (user_name) {
        createAvatarPhotoMutation.mutate({
          username: user_name,
          user_avatar: `https://imagedelivery.net/S5Wxv27Ibwd3zt1OHkr4Yg/${result.id}/public`,
        });
      }
    },
  });
  const uploadURLMutation = useMutation(getUploadURL, {
    onSuccess: (data: IUploadURLResponse) => {
      uploadAvatarImageMutation.mutate({
        uploadURL: data.uploadURL,
        file: watch("file"),
      });
    },
  });

  const deleteImgMutation = useMutation(deltedPhoto, {
    onSuccess: (data: IDeleteResponse) => {
      if (data.result && user_name) {
        createAvatarPhotoMutation.mutate({
          username: user_name,
          user_avatar: "",
        });
      }
      uploadURLMutation.mutate();
    },
  });

  const onSubmit = () => {
    setIsAvatarUpdated(false);
    if (fileOnOff === true) {
      const url = processURL(user_avatar);
      if (url === false) {
        // 일반회원 아바타 없는 경우
        uploadURLMutation.mutate();
      }
      if (Array.isArray(url) && url.length > 0) {
        const lastValue = url[url.length - 1];
        if (lastValue !== "public") {
          // 소셜로그인 아바타는 있는경우
          // 1.이미지 등록  2.유저 이미지 업데이트
          uploadURLMutation.mutate();
        } else {
          // 이미지등록된경우
          // 1. 등록된 이미지 삭제  2.이미지 등록 3. 유저 이미지 업데이트
          const imgID = url[1];
          deleteImgMutation.mutate({ imgID });
        }
      }
    }
  };
  const onClickBtn = () => {
    navigate(-1);
  };
  return (
    <>
      <Divider />
      <Grid templateColumns={{ base: "1fr", md: "0.5fr 1fr 2fr 0.5fr" }} mt={5}>
        <Box gridColumn={{ md: "2 / 3" }}>
          <Heading fontSize={"xl"}>Profile</Heading>
        </Box>
        <Box
          gridColumn={{ md: "3 / 4" }}
          mt={{ base: "20px", md: "0px" }}
          bg={useColorModeValue("white", "whiteAlpha.100")}
          rounded={"lg"}
          border={"1px solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          overflow={"hidden"}
          justifyContent={"center"}
          alignItems={"center"}
          as={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid templateColumns={"1fr 1fr"} gap={6} p={5}>
            <FormControl>
              <FormLabel htmlFor="file" fontWeight={"bold"}>
                Avatar
              </FormLabel>
              <Stack direction="row">
                <ImagePreview src={previewImage || user_avatar} name={name} />
                <Input
                  {...register("file")}
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="hiddenFileInput"
                  onChange={handleFileChange}
                />
                <Button
                  as="label"
                  htmlFor="hiddenFileInput"
                  border={"1px"}
                  cursor={"pointer"}
                  ml={4}
                  bg={useColorModeValue("white", "gray.800")}
                  _hover={{
                    backgroundColor: "initial",
                    borderColor: "initial",
                    color: "initial",
                  }}
                >
                  Choose photo
                </Button>
              </Stack>
            </FormControl>
          </Grid>
          <Box
            textAlign="right"
            p={4}
            mt={2}
            bg={useColorModeValue("gray.50", "gray.700")}
          >
            <Button
              isLoading={
                createAvatarPhotoMutation.isLoading ||
                uploadAvatarImageMutation.isLoading ||
                uploadURLMutation.isLoading
              }
              type="submit"
              colorScheme="blue"
              p={5}
            >
              Upload & Save
            </Button>
          </Box>
          <Box
            p={4}
            textAlign="right"
            bg={useColorModeValue("gray.50", "gray.700")}
          >
            <Button
              rightIcon={<ArrowForwardIcon />}
              variant="link"
              onClick={onClickBtn}
            >
              <Text>Go back</Text>
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
}
