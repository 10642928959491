import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import { MdOutlineAutoDelete } from "react-icons/md";
import useUser, { processURL } from "../lib/useUser";
import UploadPhotos from "./modal/UploadPhotos";
import { useCallback, useState } from "react";
import Slider from "react-slick";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import "../styles/room.css";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRoom, deltedPhoto } from "../api";

interface IRoomProps {
  pk: number;
  imageUrls: string[];
  name: string;
}

export default function Room({ pk, imageUrls, name }: IRoomProps) {
  const { user } = useUser();
  const {
    isOpen: isUpdateRoomOpen,
    onClose: onUpdateRoomClose,
    onOpen: onUpdateRoomOpen,
  } = useDisclosure();
  const onCameraClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onUpdateRoomOpen();
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const roomMutation = useMutation(deleteRoom, {
    onSuccess: () => {
      toast({
        title: "룸이 삭제 완료!",
        status: "success",
      });
      queryClient.refetchQueries([`rooms`]);
    },
  });
  const deleteImgMutation = useMutation(deltedPhoto);
  const onRoomDeleteClick = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    roomPk: number,
    imgUrls: string[]
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      window.confirm(
        "룸정보 or 등록된사진 정보가 삭제 됩니다. 삭제하시겠습니까?"
      )
    ) {
      imgUrls?.forEach((img) => {
        let imgUrl;
        if (typeof img === "string") {
          imgUrl = processURL(img);
        } else if (img) {
          imgUrl = img;
        }
        if (imgUrl !== false && Array.isArray(imgUrl) && imgUrl.length > 0) {
          const lastValue = imgUrl[imgUrl.length - 1];
          if (lastValue === "public") {
            const imgID = imgUrl[1];
            deleteImgMutation.mutate({ imgID });
          }
        }
      });
      roomMutation.mutate(roomPk);
    }
  };

  const bg = useColorModeValue(
    "rgba(255,255,255,0.8)",
    "rgba(255,255,255,0.8)"
  );
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [rightClicked, setRightClicked] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slider, setSlider] = useState<Slider | null>(null);
  const handleLeftClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      slider?.slickPrev();
      if (currentSlideIndex === imageUrls.length - 1) {
        setRightClicked(false);
      }
    },
    [slider, currentSlideIndex, imageUrls.length]
  );
  const handleRightClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      slider?.slickNext();
      setRightClicked(true);
    },
    [slider]
  );
  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: number) => setCurrentSlideIndex(current),
  };
  const onRoomDetail = () => {
    navigate(`/rooms/${pk}`);
  };
  return (
    <Box
      onClick={onRoomDetail}
      cursor={"pointer"}
      h={{ base: "330px", md: "390px" }}
      position={"relative"}
      overflow={"hidden"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Left Icon */}
      {isHovered && rightClicked && currentSlideIndex > 0 && (
        <IconButton
          aria-label="left-arrow"
          bg={bg}
          borderRadius="full"
          position="absolute"
          left={"10px"}
          top={{ base: "160px", md: "185px" }}
          transform={"translateY(-50%)"}
          zIndex={2}
          style={{ opacity: 0.7 }}
          _hover={{ bg: "white" }}
          onClick={handleLeftClick}
        >
          <AiOutlineLeft color="black" />
        </IconButton>
      )}

      {/* Right Icon */}
      {isHovered && (
        <IconButton
          aria-label="right-arrow"
          bg={bg}
          borderRadius="full"
          position="absolute"
          right={"10px"}
          top={{ base: "160px", md: "185px" }}
          transform={"translateY(-50%)"}
          zIndex={2}
          style={{ opacity: 0.7 }}
          _hover={{ bg: "white" }}
          onClick={handleRightClick}
        >
          <AiOutlineRight color="black" />
        </IconButton>
      )}

      {/* Slider */}
      <Slider
        {...settings}
        ref={(slider) => setSlider(slider)}
        className="custom-slider"
      >
        {imageUrls.length > 0 ? (
          imageUrls.map((url, index) => (
            <Box
              key={index}
              overflow={"hidden"}
              rounded={"3xl"}
              position="relative"
              bg="gray.100"
              width="100%"
              h={{ base: "330px", md: "390px" }}
            >
              <Image
                src={url}
                alt={`Slide image ${index}`}
                objectFit="cover"
                width="100%"
                h={{ base: "330px", md: "390px" }}
              />
              {user?.is_host && (
                <HStack
                  position={"absolute"}
                  w={"100%"}
                  top={0}
                  right={0}
                  h="100%"
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                >
                  <Button
                    isLoading={
                      roomMutation.isLoading || deleteImgMutation.isLoading
                    }
                    variant={"unstyled"}
                    cursor={"pointer"}
                    color={"white"}
                    pl={3}
                    pt={1}
                    onClick={(event) => onRoomDeleteClick(event, pk, imageUrls)}
                  >
                    <MdOutlineAutoDelete size={"25px"} />
                  </Button>
                  <Button
                    variant={"unstyled"}
                    cursor={"pointer"}
                    color={"white"}
                    onClick={onCameraClick}
                  >
                    <FaCamera size={"20px"} />
                  </Button>
                </HStack>
              )}
            </Box>
          ))
        ) : (
          <Box
            minH={{ base: "330px", md: "390px" }}
            overflow={"hidden"}
            rounded={"3xl"}
            position="relative"
            bg="gray.100"
          >
            {user?.is_host && (
              <>
                <HStack
                  position={"absolute"}
                  w={"100%"}
                  top={0}
                  right={0}
                  h="100%"
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                >
                  <Button
                    isLoading={
                      roomMutation.isLoading || deleteImgMutation.isLoading
                    }
                    variant={"unstyled"}
                    cursor={"pointer"}
                    color={"white"}
                    pl={3}
                    pt={1}
                    onClick={(event) => onRoomDeleteClick(event, pk, imageUrls)}
                  >
                    <MdOutlineAutoDelete size={"25px"} />
                  </Button>
                  <Button
                    variant={"unstyled"}
                    cursor={"pointer"}
                    color={"white"}
                    onClick={onCameraClick}
                  >
                    <FaCamera size={"20px"} />
                  </Button>
                </HStack>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Text fontSize={"lg"} fontWeight={600} mt={1}>
                    {name}
                  </Text>
                </Box>
              </>
            )}
          </Box>
        )}
      </Slider>
      {isUpdateRoomOpen && (
        <UploadPhotos
          isOpen={isUpdateRoomOpen}
          onClose={onUpdateRoomClose}
          roomPk={String(pk)}
        />
      )}
    </Box>
  );
}
