import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Divider,
  Select,
} from "@chakra-ui/react";
import { BEAUTY_SERVICE_CHOICES, PET_BREED_OPTIONS } from "../../lib/constants";

interface ISelectiveSearchModalProps {
  isServiceOpen: boolean;
  selectedBeautyService: keyof typeof BEAUTY_SERVICE_CHOICES | "";
  setSelectedBeautyService: (
    value: keyof typeof BEAUTY_SERVICE_CHOICES | ""
  ) => void;
  setIsServiceOpen: (value: boolean) => void;
  setSelectedBreed: (value: string) => void;
  selectedBreed: string;
}

export default function SelectiveSearchModal({
  isServiceOpen,
  selectedBeautyService,
  setSelectedBeautyService,
  setIsServiceOpen,
  setSelectedBreed,
  selectedBreed,
}: ISelectiveSearchModalProps) {
  return (
    <Modal
      isOpen={isServiceOpen}
      onClose={() => setIsServiceOpen(false)}
      motionPreset="slideInBottom"
    >
      <ModalOverlay bg="transparent" />
      <ModalContent
        w={{ base: 220, sm: 300 }}
        marginTop={{ base: 130, sm: 140 }}
        marginRight={{ base: -170, sm: -380, md: -620, lg: -700, xl: -720 }}
      >
        <ModalBody fontSize={"sm"} fontWeight={700}>
          <Box>
            <Text>미용 서비스</Text>
            <Select
              value={selectedBeautyService}
              fontSize={"sm"}
              fontWeight={700}
              color={"gray.500"}
              onChange={(e) =>
                setSelectedBeautyService(
                  e.target.value as keyof typeof BEAUTY_SERVICE_CHOICES
                )
              }
            >
              <option value="">서비스를 선택하세요</option>
              {Object.entries(BEAUTY_SERVICE_CHOICES).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </Box>
          <Divider mt={5} />
          <Box mt={5}>
            <Text>반려견 품종</Text>
            <Select
              value={selectedBreed}
              fontSize={"sm"}
              fontWeight={700}
              color={"gray.500"}
              onChange={(e) => setSelectedBreed(e.target.value)}
            >
              <option value="">품종을 선택하세요</option>
              {PET_BREED_OPTIONS.map((breed, index) => (
                <option key={index} value={breed.value}>
                  {breed.label}
                </option>
              ))}
            </Select>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
