import { useEffect, useMemo, useReducer } from "react";
import { Sale } from "../KsStatistics";
import {
  Box,
  Flex,
  HStack,
  Select,
  Stat,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export interface ResMonthlySale extends Sale {
  month: string;
  count: number;
  bathing_count: number;
  bathing_basic_count: number;
  clipping_count: number;
  spotting_count: number;
  scissor_cut_count: number;
  other_count: number;
  total_count: number;
}
interface ResStatsDataProps {
  yearly_sales: Sale[];
  res_monthly_sales: ResMonthlySale[];
}

interface State {
  selectedYear: number;
  selectedMonth: number;
  bathingCount: number; //목욕
  bathingBasicCount: number; //목욕+기본
  clippingCount: number; //클리핑
  spottingCount: number; //스포팅
  scissorCutCount: number; //가위컷
  otherCount: number; //기타
  totalCount: number; //총 미용 건수
}

type Action =
  | { type: "RES_COUNT"; payload: Partial<State> }
  | { type: "other_action" };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "RES_COUNT":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

export default function KsStatisYearlyMonthResCard({
  data,
  type,
}: {
  data: ResStatsDataProps | undefined;
  type: "sales" | "count";
}) {
  const currentDate = useMemo(() => {
    const date = new Date();
    return { year: date.getFullYear(), month: date.getMonth() + 1 };
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    selectedYear: currentDate.year,
    selectedMonth: currentDate.month,
    bathingCount: 0,
    bathingBasicCount: 0,
    clippingCount: 0,
    spottingCount: 0,
    scissorCutCount: 0,
    otherCount: 0,
    totalCount: 0,
  });

  useEffect(() => {
    const resMonthlySales = data?.res_monthly_sales.find(
      (sale) =>
        Number(sale.year) === state.selectedYear &&
        Number(sale.month) === state.selectedMonth
    );

    if (!resMonthlySales) {
      dispatch({
        type: "RES_COUNT",
        payload: {
          bathingCount: 0,
          bathingBasicCount: 0,
          clippingCount: 0,
          spottingCount: 0,
          scissorCutCount: 0,
          otherCount: 0,
          totalCount: 0,
        },
      });
      return;
    }

    if (type === "sales") {
      dispatch({
        type: "RES_COUNT",
        payload: {
          bathingCount: resMonthlySales.bathing_count,
          bathingBasicCount: resMonthlySales.bathing_basic_count,
          clippingCount: resMonthlySales.clipping_count,
          spottingCount: resMonthlySales.spotting_count,
          scissorCutCount: resMonthlySales.scissor_cut_count,
          otherCount: resMonthlySales.other_count,
          totalCount: resMonthlySales.total_count,
        },
      });
    } else if (type === "count") {
      dispatch({
        type: "RES_COUNT",
        payload: { totalCount: resMonthlySales.total_count },
      });
    }
  }, [
    state.selectedYear,
    state.selectedMonth,
    data,
    type,
    state.bathingBasicCount,
  ]);
  const TitleSelect = (
    <HStack>
      <Select
        value={state.selectedYear}
        onChange={(event) =>
          dispatch({
            type: "RES_COUNT",
            payload: { selectedYear: Number(event.target.value) },
          })
        }
        variant={"outline"}
        size={"xs"}
      >
        <option value={currentDate.year}>{currentDate.year}년</option>
        <option value={currentDate.year - 1}>{currentDate.year - 1}년</option>
      </Select>

      <Select
        value={state.selectedMonth.toString().padStart(2, "0")}
        onChange={(event) =>
          dispatch({
            type: "RES_COUNT",
            payload: { selectedMonth: Number(event.target.value) },
          })
        }
        variant={"outline"}
        size={"xs"}
      >
        {[...Array(12)].map((_, i) => (
          <option value={(i + 1).toString().padStart(2, "0")} key={i}>
            {(i + 1).toString().padStart(2, "0")}월
          </option>
        ))}
      </Select>
    </HStack>
  );
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"1"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      {TitleSelect}
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            <HStack>
              <Text fontSize={"md"}>
                {state.selectedYear}년{" "}
                {state.selectedMonth.toString().padStart(2, "0")}월 미용완료 -
              </Text>
              <Text fontSize={"md"} fontWeight={800} color={"gray.600"}>
                {state.totalCount || 0}건
              </Text>
            </HStack>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              목욕 - {state.bathingCount || 0}
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              목욕+기본 - {state.bathingBasicCount || 0}
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              클리핑 - {state.clippingCount || 0}
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              스포팅 - {state.spottingCount || 0}
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              가위컷 - {state.scissorCutCount || 0}
            </Text>
          </StatNumber>
          <StatNumber fontSize={"xs"} fontWeight={"medium"}>
            <Text fontWeight={500} color={"gray.600"}>
              기타 - {state.otherCount || 0}
            </Text>
          </StatNumber>
        </Box>
      </Flex>
    </Stat>
  );
}
