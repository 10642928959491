import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Box, Flex, Select } from "@chakra-ui/react";
import { getKSUsersPetsList } from "../api";
import { IPetPublic } from "../types";
export interface IUserPetProps {
  pk: number;
  name: string;
  username: string;
  user_pets: IPetPublic[];
}
export default function UsersPetsList({
  setSelectedUserPet,
  selectedUserPet,
  isHostUserPk,
}: any) {
  const { data } = useQuery<IUserPetProps[]>(
    ["users", "ks-users-pets"],
    getKSUsersPetsList
  );
  const [selectedUser, setSelectedUser] = useState<number | undefined>(
    typeof isHostUserPk === "number" ? isHostUserPk : undefined
  );
  const [selectedPet, setSelectedPet] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (selectedUserPet) {
      setSelectedUser(selectedUserPet.userId);
      setSelectedPet(selectedUserPet.petId);
    }
  }, [selectedUserPet]);

  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedUserId = parseInt(event.target.value);
    setSelectedUser(selectedUserId);
  };
  const handlePetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPetId = parseInt(event.target.value);
    setSelectedPet(selectedPetId);
    setSelectedUserPet({
      userId: selectedUser || isHostUserPk,
      petId: selectedPetId,
    });
  };

  const selectedUserPets =
    data?.find((user) => user.pk === selectedUser)?.user_pets || [];

  return (
    <Flex align="center" justify="space-between">
      <Box width="49%">
        <Select
          value={selectedUser || ""}
          placeholder="유저를 선택하세요"
          onChange={handleUserChange}
        >
          {data?.map((user) => (
            <option key={user.pk} value={user.pk}>
              {user.name}
            </option>
          ))}
        </Select>
      </Box>
      <Box width="49%">
        <Select
          value={selectedPet || ""}
          placeholder="펫을 선택하세요"
          isDisabled={selectedUser === null}
          onChange={handlePetChange}
        >
          {selectedUserPets.map((pet) => (
            <option key={pet.pk} value={pet.pk}>
              {pet.name}
            </option>
          ))}
        </Select>
      </Box>
    </Flex>
  );
}
