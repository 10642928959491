import {
  Box,
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";
import {
  IIsErrorMessage,
  ISuccessMessage,
  approvalStateVariables,
  deltedBooking,
  getKongsamResList,
  patchApprovalState,
} from "../../api";
import ResBookingInfoModal from "../modal/ResBookingInfoModal";
import { AxiosError } from "axios";
import DoneResCreateModal from "../modal/DoneResCreateModal";
import { MdOutlineAutoDelete, MdOutlinePets } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FcCancel, FcDataConfiguration } from "react-icons/fc";
import { GrUpdate } from "react-icons/gr";
import { FaRegClock } from "react-icons/fa";
import { RenderFlex } from "../../utils/RenderFlex";
import BookingEditModal from "../modal/BookingEditModal";
import { IKongsamBookingListResponse } from "../../types";
import { useResListMultipleDisclosure } from "../hook/useMultipleDisclosure";
import { formatDateTime4 } from "../../lib/utils";

export default function KsResList() {
  const { colorMode } = useColorMode();
  const boxShadowColor =
    colorMode === "dark" ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";
  const [selectedDoneData, setSelectedDoneData] = useState({
    donePk: 0,
    userName: "",
    userPetName: "",
  });
  const [selectedDoneDateTime, setSelectedDoneDateTime] = useState("");
  const [selectedBookingPk, setSelectedBookingPk] = useState(0);
  const disclosures = useResListMultipleDisclosure();

  const {
    data: rescrvationData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<IKongsamBookingListResponse>(
    ["bookings"],
    getKongsamResList,
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage.page.next_link) {
          return undefined;
        }

        const url = new URL(lastPage.page.next_link);
        const searchParams = url.search;
        return searchParams.startsWith("?")
          ? searchParams.slice(1)
          : searchParams;
      },
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const toast = useToast();
  const queryClient = useQueryClient();
  const deltedBookingMutation = useMutation(deltedBooking, {
    onSuccess: () => {
      toast({
        title: "예약삭제 성공!",
        status: "success",
      });
      queryClient.refetchQueries(["bookings"]);
    },
  });

  const onResDelete = (bookingPk: number) => {
    if (window.confirm(`no${bookingPk}_ 미용예약 정보가 삭제됩니다.`)) {
      deltedBookingMutation.mutate(bookingPk);
    }
  };

  const approvalStateBeautyBookingMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    approvalStateVariables
  >(patchApprovalState, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      });
      queryClient.refetchQueries([`bookings`]);
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
    },
  });

  const onResApprovalState = ({
    host_approval_state,
    bookingPk,
  }: approvalStateVariables) => {
    approvalStateBeautyBookingMutation.mutate({
      host_approval_state,
      bookingPk,
    });
  };
  return (
    <Box
      boxShadow={`0px 0px 8px ${boxShadowColor}`}
      overflowX="auto"
      rounded="md"
    >
      {rescrvationData?.pages.flatMap((pageData, i) =>
        pageData?.content.map((booking, index) => (
          <Box
            key={booking.pk}
            bg={
              index % 2 === 0
                ? colorMode === "dark"
                  ? "gray.700"
                  : "gray.50"
                : colorMode === "dark"
                ? "gray.800"
                : "white"
            }
            p={3}
            shadow="md"
            borderWidth="1px"
          >
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Popover placement="bottom" isLazy>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="More booking options"
                      icon={<BsThreeDotsVertical />}
                      variant="solid"
                      w="fit-content"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    w="fit-content"
                    _focus={{ boxShadow: "none" }}
                  >
                    <PopoverArrow />
                    <PopoverBody>
                      <Stack>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<MdOutlinePets />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            setSelectedBookingPk(booking.pk);
                            disclosures.ResBookingInfoModal.onOpen();
                          }}
                        >
                          {booking.user_pets[0].name} _RES INFO
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<FaRegClock />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() =>
                            onResApprovalState({
                              host_approval_state: "예약",
                              bookingPk: booking.pk,
                            })
                          }
                        >
                          예약
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<FcCancel />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() =>
                            onResApprovalState({
                              host_approval_state: "예약취소",
                              bookingPk: booking.pk,
                            })
                          }
                        >
                          예약취소
                        </Button>
                        <Tooltip label={"확인됨 후 안왔을경우"}>
                          <Button
                            w="194px"
                            variant="ghost"
                            rightIcon={<FcCancel />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            fontSize="sm"
                            onClick={() =>
                              onResApprovalState({
                                host_approval_state: "노쇼",
                                bookingPk: booking.pk,
                              })
                            }
                          >
                            노쇼
                          </Button>
                        </Tooltip>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<FcDataConfiguration />}
                          justifyContent="space-between"
                          colorScheme="blue"
                          fontWeight="normal"
                          fontSize="sm"
                          onClick={() => {
                            setSelectedDoneData({
                              donePk: booking.pk,
                              userName: booking.owner.name,
                              userPetName: booking.user_pets
                                .map((pet) => pet.name)
                                .join(", "),
                            });
                            setSelectedDoneDateTime(booking.booking_time_kst);
                            disclosures.DoneResCreateModal.onOpen();
                          }}
                        >
                          미용완료 등록
                        </Button>
                        <Button
                          w="194px"
                          variant="ghost"
                          rightIcon={<GrUpdate />}
                          justifyContent="space-between"
                          fontWeight="normal"
                          colorScheme="green"
                          fontSize="sm"
                          onClick={() => {
                            setSelectedBookingPk(booking.pk);
                            disclosures.BookingEditModal.onOpen();
                          }}
                        >
                          예약정보 수정
                        </Button>
                        <Tooltip label={"삭제 후 되돌릴수 없으니 신중히 삭제"}>
                          <Button
                            w="194px"
                            variant="ghost"
                            rightIcon={<MdOutlineAutoDelete />}
                            justifyContent="space-between"
                            fontWeight="normal"
                            colorScheme="red"
                            fontSize="sm"
                            onClick={() => onResDelete(booking.pk)}
                          >
                            예약정보 삭제
                          </Button>
                        </Tooltip>
                      </Stack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
              <Text fontSize="lg" fontWeight="semibold" lineHeight="short">
                #{booking.owner.name}{" "}
                {booking.user_pets.map((pet) => (
                  <Box as="span" key={pet.pk}>
                    @{pet.name}
                  </Box>
                ))}
              </Text>
            </Flex>
            <RenderFlex
              label={`예약시간`}
              value={formatDateTime4(new Date(booking.booking_time_kst))}
            />
            <RenderFlex label={"미용 서비스"} value={booking.beauty_service} />
            <RenderFlex
              label={"예약상태"}
              value={booking.host_approval_state}
            />
          </Box>
        ))
      )}
      {hasNextPage ? (
        <Button
          colorScheme="gray"
          variant="outline"
          w={"100%"}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "is Loding..."
            : hasNextPage
            ? "Res Load More"
            : "Res List End"}
        </Button>
      ) : null}

      {disclosures.ResBookingInfoModal.isOpen && (
        <ResBookingInfoModal
          isOpen={disclosures.ResBookingInfoModal.isOpen}
          onClose={disclosures.ResBookingInfoModal.onClose}
          bookingPk={selectedBookingPk}
        />
      )}
      {disclosures.BookingEditModal.isOpen && (
        <BookingEditModal
          isOpen={disclosures.BookingEditModal.isOpen}
          onClose={disclosures.BookingEditModal.onClose}
          bookingPk={selectedBookingPk}
        />
      )}

      {disclosures.DoneResCreateModal.isOpen && (
        <DoneResCreateModal
          isOpen={disclosures.DoneResCreateModal.isOpen}
          onClose={disclosures.DoneResCreateModal.onClose}
          resBookingDateTime={selectedDoneDateTime}
          doneData={selectedDoneData}
        />
      )}
    </Box>
  );
}
