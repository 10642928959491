import { useQuery } from "@tanstack/react-query";
import { getSitemap } from "../api";
import { Box } from "@chakra-ui/react";

export default function Sitemap() {
  const { data } = useQuery([`common`, `kongsam-my-sitemap.xml`], getSitemap, {
    retry: false,
  });
  if (!data) return null;
  return <Box dangerouslySetInnerHTML={{ __html: data }} />;
}
