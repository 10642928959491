import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Flex,
  Heading,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Box,
  useToast,
  FormControl,
  FormLabel,
  InputRightElement,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { FiUser } from "react-icons/fi";
import { PiDogThin } from "react-icons/pi";
import { MdOutlinePhonelinkRing } from "react-icons/md";
import {
  IIsErrorMessage,
  ISuccessMessage,
  IUserConfirmationVariables,
  IUserNewPassVariables,
  getUserConfirmation,
  getUserNewPassword,
} from "../../api";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

interface ICallResModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function MyUserConfirmationModal({
  isOpen,
  onClose,
}: ICallResModalProps) {
  const toast = useToast();
  const [userCheck, setUserCheck] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordChk, setShowNewPasswordChk] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUserConfirmationVariables>();
  const userCheckMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IUserConfirmationVariables
  >(getUserConfirmation, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        description: data.location,
        status: "success",
      });
      setUserCheck(!!data.location);
      reset();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
      setUserCheck(!!error.response?.data.location);
    },
  });
  const onSubmit = (data: IUserConfirmationVariables) => {
    userCheckMutation.mutate(data);
  };

  const {
    control,
    handleSubmit: handlePassSubmit,
    formState: { errors: passErrors },
    watch,
    reset: passReset,
  } = useForm<IUserNewPassVariables>();
  const newPassword = watch("new_password");

  const userNewPassMutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IUserNewPassVariables
  >(getUserNewPassword, {
    onSuccess: (data) => {
      toast({
        title: data.message,
        status: "success",
      });
      passReset();
      onClose();
    },
    onError: (error) => {
      toast({
        title: error.response?.data.message,
        description: error.response?.data.location,
        status: "error",
      });
    },
  });
  const handleNewPassword = (data: IUserNewPassVariables) => {
    userNewPassMutation.mutate(data);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {!userCheck ? (
            <Flex
              align={"center"}
              justify={"center"}
              bg={"gray.50"}
              as={"form"}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack
                spacing={4}
                w={"full"}
                maxW={"md"}
                bg={"white"}
                rounded={"xl"}
                boxShadow={"lg"}
                p={6}
                my={5}
              >
                <Heading lineHeight={1.7} fontSize={"2xl"} color={"gray.600"}>
                  회원 확인
                  <Text fontSize={"12px"} fontWeight={700} color={"red.500"}>
                    사용자 아이디는 콩삼으로 문의주시면 발급해드립니다.
                  </Text>
                </Heading>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Box color={"gray.400"}>
                        <FiUser />
                      </Box>
                    }
                  />
                  <Input
                    isInvalid={Boolean(errors.username?.message)}
                    {...register("username", {
                      required: "사용자 이름을 입력해주세요.",
                    })}
                    variant={"filled"}
                    placeholder="사용자 아이디"
                    required
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Box color={"gray.400"}>
                        <PiDogThin />
                      </Box>
                    }
                  />
                  <Input
                    isInvalid={Boolean(errors.pname?.message)}
                    {...register("pname", {
                      required: "반려견 이름을 입력해주세요.",
                    })}
                    variant={"filled"}
                    placeholder="반려견 이름"
                    required
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Box color={"gray.400"}>
                        <MdOutlinePhonelinkRing />
                      </Box>
                    }
                  />
                  <Input
                    isInvalid={Boolean(errors.phone?.message)}
                    {...register("phone", {
                      required: "핸드폰 번호를 입력해주세요.",
                    })}
                    variant={"filled"}
                    placeholder="연락처 번호만 입력"
                    required
                  />
                </InputGroup>
                <Stack spacing={6} direction={["column", "row"]} mb={10}>
                  <Button
                    bg={"red.400"}
                    color={"white"}
                    w="full"
                    _hover={{
                      bg: "red.500",
                    }}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    취소
                  </Button>
                  <Button
                    isLoading={userCheckMutation.isLoading}
                    type="submit"
                    bg={"blue.400"}
                    color={"white"}
                    w="full"
                    _hover={{
                      bg: "blue.500",
                    }}
                  >
                    확인
                  </Button>
                </Stack>
              </Stack>
            </Flex>
          ) : (
            <Flex
              as={"form"}
              onSubmit={handlePassSubmit(handleNewPassword)}
              align={"center"}
              justify={"center"}
              bg={"gray.50"}
              mb={10}
            >
              <Stack
                spacing={4}
                w={"full"}
                maxW={"md"}
                bg={"white"}
                rounded={"xl"}
                boxShadow={"lg"}
                p={6}
              >
                <Heading lineHeight={2.5} fontSize={"2xl"} color={"gray.600"}>
                  비밀번호 설정
                </Heading>

                <FormControl isInvalid={Boolean(passErrors.username?.message)}>
                  <FormLabel
                    htmlFor="username"
                    fontWeight={"bold"}
                    fontSize={"sm"}
                    color={"gray.600"}
                  >
                    사용자 아이디
                  </FormLabel>
                  <Controller
                    control={control}
                    name="username"
                    rules={{ required: "사용자 이름을 입력해주세요." }}
                    defaultValue=""
                    render={({ field }) => (
                      <Input
                        {...field}
                        isInvalid={Boolean(errors.username?.message)}
                        variant={"filled"}
                        placeholder="사용자 아이디"
                        required
                      />
                    )}
                  />
                </FormControl>
                <FormControl
                  isInvalid={Boolean(passErrors.new_password?.message)}
                >
                  <FormLabel
                    htmlFor="new_password"
                    fontWeight={"bold"}
                    fontSize={"sm"}
                    color={"gray.600"}
                  >
                    비밀번호
                    <Text fontSize={"11px"} fontWeight={700} color={"gray.500"}>
                      비밀번호는 영문,숫자,특수문자 조합으로 이루어진 8자이상
                      입니다.
                    </Text>
                  </FormLabel>
                  <InputGroup>
                    <Controller
                      name="new_password"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        pattern: {
                          value:
                            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,128}$/i,
                          message:
                            "비밀번호는 영문,숫자,특수문자 조합으로 이루어진 8자이상 입니다.",
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          variant={"solid"}
                          borderWidth={1}
                          color={"gray.800"}
                          _placeholder={{ color: "gray.400" }}
                          borderColor={
                            passErrors.new_password?.message
                              ? "red.500"
                              : "gray.300"
                          }
                          type={showNewPassword ? "text" : "password"}
                        />
                      )}
                    />
                    <InputRightElement>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowNewPassword(
                            (showNewPassword) => !showNewPassword
                          )
                        }
                      >
                        {showNewPassword ? (
                          <HiOutlineEye />
                        ) : (
                          <HiOutlineEyeOff />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(passErrors.new_password_chk?.message)}
                >
                  <FormLabel
                    htmlFor="new_password_chk"
                    fontWeight={"bold"}
                    fontSize={"sm"}
                    color={"gray.600"}
                  >
                    비밀번호 확인
                  </FormLabel>
                  <InputGroup>
                    <Controller
                      name="new_password_chk"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                        validate: (value) =>
                          value === newPassword ||
                          "새로운 비밀번호가 일치하지 않습니다.",
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          variant={"solid"}
                          borderWidth={1}
                          color={"gray.800"}
                          _placeholder={{ color: "gray.400" }}
                          borderColor={
                            passErrors.new_password_chk?.message
                              ? "red.500"
                              : "gray.300"
                          }
                          type={showNewPasswordChk ? "text" : "password"}
                        />
                      )}
                    />
                    <InputRightElement>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowNewPasswordChk(
                            (showNewPasswordChk) => !showNewPasswordChk
                          )
                        }
                      >
                        {showNewPasswordChk ? (
                          <HiOutlineEye />
                        ) : (
                          <HiOutlineEyeOff />
                        )}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <Text color="red.500" fontSize="11px">
                  {passErrors.new_password?.message}
                </Text>
                <Text color="red.500" fontSize="11px">
                  {passErrors.new_password_chk?.message}
                </Text>
                <Box textAlign="right">
                  <Button
                    isLoading={userNewPassMutation.isLoading}
                    colorScheme="blue"
                    p={5}
                    type="submit"
                  >
                    비밀번호 설정
                  </Button>
                </Box>
              </Stack>
            </Flex>
          )}
          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam user confirmation</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
