import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  Input,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ICategorytemProps, postKsOutlayCategory } from "../../../api";
import { useForm } from "react-hook-form";
import KsStatisOutlayCategoryPutDel from "./KsStatisOutlayCategoryPutDel";
import { useState } from "react";

export interface KsStatisOutlayCategoryProps {
  categoryData: ICategorytemProps[] | undefined;
}

export default function KsStatisOutlayCategory({
  categoryData,
}: KsStatisOutlayCategoryProps) {
  const {
    register: registerCategory,
    handleSubmit: handleSubmitCategory,
    reset: resetCategory,
  } = useForm<ICategorytemProps>();

  const { isOpen: isCategoryOpen, onToggle: onCategoryToggle } =
    useDisclosure();
  const [isListOpen, setIsListOpen] = useState(false);
  const handleToggleList = () => {
    setIsListOpen((prevState) => !prevState);
  };

  const toast = useToast();
  const queryClient = useQueryClient();
  const categoryMutation = useMutation(postKsOutlayCategory, {
    onSuccess: () => {
      toast({
        title: "결제수단 등록!",
        status: "success",
      });
      resetCategory();
      queryClient.refetchQueries([`expenses`, `expense-category`]);
    },
  });
  const onCategorySubmit = (data: ICategorytemProps) => {
    categoryMutation.mutate(data);
  };

  return (
    <>
      <Button onClick={handleToggleList} colorScheme="teal" size={"sm"} mb={3}>
        {isListOpen ? "카테고리 목록 닫기" : "카테고리 목록 열기"}
      </Button>
      {isListOpen && (
        <KsStatisOutlayCategoryPutDel categoryData={categoryData} />
      )}
      <Box as={"form"} onSubmit={handleSubmitCategory(onCategorySubmit)}>
        <Flex>
          <Button
            size={"sm"}
            colorScheme="teal"
            variant="outline"
            onClick={() => {
              onCategoryToggle();
              resetCategory();
            }}
          >
            {isCategoryOpen ? "카테고리 등록 닫기" : "카테고리 등록 열기"}
          </Button>
          <Spacer />
          <Collapse in={isCategoryOpen}>
            <Button size={"sm"} colorScheme="teal" type="submit">
              카테고리 등록
            </Button>
          </Collapse>
        </Flex>
        <Collapse in={isCategoryOpen}>
          <FormControl mt={3}>
            <Input
              mb={1}
              type="text"
              {...registerCategory("name", { required: true })}
              placeholder="카테고리의 이름을 입력하세요"
              required
            />
            <Input
              {...registerCategory("notes")}
              placeholder="비고를 입력하세요"
            />
          </FormControl>
        </Collapse>
      </Box>
    </>
  );
}
