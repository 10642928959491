import { Box, Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "./layout/Header";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Footer from "./layout/Footer";

export default function Root() {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="100vh"
      >
        <Container maxW="container.xl">
          <Box position="sticky" top="0" zIndex="sticky" bg={"white"}>
            <Header />
          </Box>
          <Outlet />
          <ReactQueryDevtools />
        </Container>
        <Footer />
      </Box>
    </>
  );
}
