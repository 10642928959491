import { useMutation, useQuery } from "@tanstack/react-query";
import {
  ICategorytemProps,
  expensesFileDownload,
  getAccount,
  getExpenses,
  getOutlayCategory,
} from "../../../api";
import { IAccountItem } from "./KsStatisAccount";
import {
  Box,
  CloseButton,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../../../lib/utils";
import { SearchIcon } from "@chakra-ui/icons";
import { FaFileExcel } from "react-icons/fa";
import { useKsMultipleDisclosure } from "../../hook/useMultipleDisclosure";
import KsStatisExpenseInfo from "./KsStatisExpenseInfo";

export interface IExpense {
  pk: number;
  account: IAccountItem;
  category: ICategorytemProps;
  amount: number;
  income_expenditure_status: string;
  notes: string;
  date: string;
}
export default function KsStatisExpenseList() {
  const { data: getCategory } = useQuery<ICategorytemProps[]>(
    [`expenses`, `expense-category`],
    getOutlayCategory,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const { data: getAccounts } = useQuery<IAccountItem[]>(
    [`expenses`, `expense-account`],
    getAccount,
    {
      retry: false,
      cacheTime: 0,
    }
  );

  const disclosures = useKsMultipleDisclosure();
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [searchOption, setSearchOption] = useState("");
  const [categorySearchOption, setCategorySearchOption] = useState("");
  const [accountSearchOption, setAccountSearchOption] = useState("");
  const [expensePk, setExpensePk] = useState(0);
  const [query, setQuery] = useState({
    endDate: "",
    startDate: "",
    term: "",
    option: "",
    categoryOption: "",
    accountOption: "",
  });
  /* eslint-disable */
  useEffect(() => {
    setQuery({
      endDate: endDate,
      startDate: startDate,
      term: searchTerm,
      option: searchOption,
      categoryOption: categorySearchOption,
      accountOption: accountSearchOption,
    });
  }, []);

  const { data: expensesDate } = useQuery<IExpense[]>(
    ["expenses", JSON.stringify(query)],
    getExpenses,
    {
      retry: false,
      cacheTime: 0,
    }
  );
  const handleSearch = () => {
    setQuery({
      endDate: endDate,
      startDate: startDate,
      term: searchTerm,
      option: searchOption,
      categoryOption: categorySearchOption,
      accountOption: accountSearchOption,
    });
  };

  const fileMutation = useMutation(expensesFileDownload, {});
  const handleExcelDownload = () => {
    const query = {
      startDate,
      endDate,
      term: searchTerm,
      option: searchOption,
      categoryOption: categorySearchOption,
      accountOption: accountSearchOption,
    };
    fileMutation.mutate(query);
  };

  const totalAmount = expensesDate?.reduce((sum, item) => {
    if (item.income_expenditure_status === "수입") {
      return sum + Number(item.amount);
    } else if (item.income_expenditure_status === "지출") {
      return sum - Number(item.amount);
    } else {
      return sum;
    }
  }, 0);
  return (
    <>
      <Flex direction="column" w={"100%"} gap={1}>
        <Flex>
          <Box flex="1" mr={1}>
            <Input
              fontSize={"14px"}
              fontWeight={600}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Box>
          <Box flex="1">
            <Input
              fontSize={"14px"}
              fontWeight={600}
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Box>
        </Flex>
        <Flex>
          <Box flex="1" mr={1}>
            <Select
              onChange={(e) => setSearchOption(e.target.value)}
              placeholder="지출/수입"
              fontSize={{ base: "xs", md: "sm" }}
              fontWeight={600}
            >
              <option value="지출">지출</option>
              <option value="수입">수입</option>
            </Select>
          </Box>
          <Box flex="1" mr={1}>
            <Select
              onChange={(e) => setCategorySearchOption(e.target.value)}
              placeholder="카테고리 선택"
              fontSize={{ base: "xs", md: "sm" }}
              fontWeight={600}
            >
              {getCategory && getCategory?.length > 0 ? (
                getCategory?.map((category) => (
                  <option key={category.pk} value={category.name}>
                    {category.name}
                  </option>
                ))
              ) : (
                <option value="">카테고리 없음</option>
              )}
            </Select>
          </Box>
          <Box flex="1" mr={1}>
            <Select
              onChange={(e) => setAccountSearchOption(e.target.value)}
              placeholder="콩삼계좌 선택"
              fontSize={{ base: "xs", md: "sm" }}
              fontWeight={600}
            >
              {getAccounts && getAccounts?.length > 0 ? (
                getAccounts?.map((account) => (
                  <option key={account.pk} value={account.account_name}>
                    {account.account_name}
                  </option>
                ))
              ) : (
                <option value="">콩삼계좌 없음</option>
              )}
            </Select>
          </Box>
          <Box flex="1">
            <InputGroup>
              <Input
                fontSize={{ base: "xs", md: "sm" }}
                fontWeight={600}
                placeholder="비고 검색"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              {searchTerm && (
                <InputRightElement>
                  <CloseButton onClick={() => setSearchTerm("")} />
                </InputRightElement>
              )}
              <InputRightElement>
                <IconButton
                  onClick={handleSearch}
                  colorScheme="gray"
                  aria-label="Search database"
                  icon={<SearchIcon />}
                />
              </InputRightElement>
            </InputGroup>
          </Box>
        </Flex>
      </Flex>
      <TableContainer>
        <Table
          variant="striped"
          size={"sm"}
          color="gray.500"
          colorScheme="gray"
          fontWeight={600}
        >
          <TableCaption fontSize={"sm"}>
            콩삼 수입/지출 내역 - 총합: ₩ {Number(totalAmount).toLocaleString()}
            원
          </TableCaption>
          <Thead>
            <Tr>
              <Th pt={2} pb={2}>
                결제일
              </Th>
              <Th pt={2} pb={2}>
                카테고리
              </Th>
              <Th pt={2} pb={2}>
                금액(원)
              </Th>
              <Th pt={2} pb={2}>
                결제수단
              </Th>
              <Th pt={2} pb={2}>
                지출/수입
              </Th>
              <Th pt={2} pb={2}>
                비고
              </Th>
              <Th pt={2} pb={2} textAlign="right">
                <IconButton
                  isLoading={fileMutation.isLoading}
                  onClick={handleExcelDownload}
                  colorScheme="green"
                  aria-label="엑셀출력"
                  h={"30px"}
                  w={"60px"}
                  icon={<FaFileExcel size={"24px"} />}
                />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {expensesDate?.map((item) => (
              <Tr
                cursor={"pointer"}
                key={item.pk}
                onClick={() => {
                  disclosures.KsStatisExpenseInfo.onOpen();
                  setExpensePk(item.pk);
                }}
              >
                <Td>{item.date}</Td>
                <Td>{item.category.name}</Td>
                <Td>
                  {item.income_expenditure_status === "지출"
                    ? `-${Number(item.amount).toLocaleString()}`
                    : Number(item.amount).toLocaleString()}
                </Td>
                <Td>{item.account.account_name}</Td>
                <Td>{item.income_expenditure_status}</Td>
                <Td colSpan={2}>{item.notes ? item.notes : "-"}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {disclosures.KsStatisExpenseInfo.isOpen && (
        <KsStatisExpenseInfo
          isOpen={disclosures.KsStatisExpenseInfo.isOpen}
          onClose={disclosures.KsStatisExpenseInfo.onClose}
          expensePk={expensePk}
        />
      )}
    </>
  );
}
