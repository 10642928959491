import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { PiDogThin } from "react-icons/pi";
import ProtectedPage from "../middleware/ProtectedPage";
import Calendar from "react-calendar";
import "../../styles/calendar.css";
import { useCallback, useMemo, useReducer, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  IBookingVariables,
  IIsErrorMessage,
  ISuccessMessage,
  getBookingDateTimeCheck,
  uploadBeautyBooking,
} from "../../api";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { differenceBy } from "lodash";
import { Controller, FieldError, useForm } from "react-hook-form";
import { formatDate } from "../../lib/utils";
import {
  CONDITION_LABEL,
  CONDITION_OPTIONS,
  DATE_FORMAT,
  DATE_SEPARATOR,
  DEGRRE_OF_TANGLES_LABEL,
  DEGRRE_OF_TANGLES_OPTIONS,
  TIME_START,
} from "../../lib/constants";
import UsersPetsList from "../UsersPetsList";
import { FormRadioGroup } from "../FormGroup";
import { AxiosError } from "axios";
import BeautyCustomTabs from "../BeautyCustomTabs";

interface IBookingModalProps {
  isHostUserPk: number;
  isOpen: boolean;
  onClose: () => void;
}
export function reducer(state: any, action: any) {
  switch (action.type) {
    case "dateChange":
      return { ...state, dates: action.dates };
    case "selectTime":
      return { ...state, selectedTimeIndex: action.index };
    default:
      throw new Error();
  }
}

export default function BookingCreateModal({
  isHostUserPk,
  isOpen,
  onClose,
}: IBookingModalProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm<IBookingVariables>({});

  /* 등록된 예약을 확인 후 예약 가능한 시간 안내 */
  const [state, dispatch] = useReducer(reducer, {
    dates: undefined,
    selectedTimeIndex: null,
  });

  const { data } = useQuery(
    ["datetime-check", state.dates],
    getBookingDateTimeCheck,
    {
      cacheTime: 0,
      enabled: state.dates !== undefined,
    }
  );

  const bookingDateTime = (data || []).map((item: any) => {
    const booking_time = item.booking_time;
    return { ...item, booking_time };
  });

  const handleDateChange = useCallback(
    (value: Value, event: React.MouseEvent<HTMLButtonElement>) => {
      const dates = Array.isArray(value)
        ? value.filter((date): date is Date => date !== null)
        : [value];
      dispatch({ type: "dateChange", dates });
    },
    [dispatch]
  );

  const bookedTimes = useMemo(
    () =>
      bookingDateTime?.map((booking: any) => ({
        time: `${booking.booking_time
          .split(DATE_FORMAT)[1]
          .split(DATE_SEPARATOR)[0]
          .substring(0, 5)}`,
      })),
    [bookingDateTime]
  );

  const allTimes = useMemo(() => {
    if (!state.dates) {
      return;
    }
    const hours = Array.from({ length: 11 }, (_, i) => i + TIME_START);
    const minutes = ["00", "30"];
    const times: { time: string }[] = [];

    hours.forEach((hour) => {
      minutes.forEach((minute) => {
        if (
          (hour === 19 && minute === "30") ||
          (hour === 9 && minute === "00")
        ) {
          return;
        }
        times.push({
          time: `${hour.toString().padStart(2, "0")}:${minute}`,
        });
      });
    });

    return times;
  }, [state.dates]);

  const availableTimes = useMemo(() => {
    if (!state.dates || !allTimes) {
      return;
    }
    return differenceBy(allTimes, bookedTimes, "time");
  }, [state.dates, allTimes, bookedTimes]);

  /* 미용예약 등록 */
  const [selectedUserPet, setSelectedUserPet] = useState<{
    userId: number;
    petId: number;
  } | null>(null);

  const toast = useToast();
  const mutation = useMutation<
    ISuccessMessage,
    AxiosError<IIsErrorMessage>,
    IBookingVariables
  >(uploadBeautyBooking, {
    onSuccess: () => {
      toast({
        title: "예약되었습니다.",
        description: "미용예약 목록에서 확인하세요.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    },
    onError: (error) => {
      alert(error.response?.data.error);
      toast({
        title: error.response?.data.message,
        description: error.response?.data.error,
        status: "error",
      });
    },
  });

  const onSubmit = (data: IBookingVariables) => {
    const userId = selectedUserPet?.userId;
    const petId = selectedUserPet?.petId;
    if (!petId) {
      alert("반려동물을 선택해주세요.");
      return;
    }
    data.user_pets = [petId ?? 0];
    data.owner = userId ?? 0;
    mutation.mutate(data);
  };
  return (
    <Modal
      size={"5xl"}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader fontSize={"sm"}>콩삼 미용 예약</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <ProtectedPage>
              <Box>
                <VStack>
                  <Container maxW="container.lx">
                    <Heading textAlign={"center"}>미용 예약 등록</Heading>
                    <Grid
                      templateColumns={["1fr", null, "1fr 1fr"]}
                      gap={{ base: 0, md: 10, lg: 20 }}
                      mt={10}
                    >
                      <Calendar
                        calendarType="US"
                        onChange={handleDateChange}
                        prev2Label={null}
                        next2Label={null}
                        minDate={new Date()}
                        maxDate={
                          new Date(Date.now() + 60 * 60 * 24 * 7 * 4 * 6 * 1000)
                        }
                        minDetail="month"
                        formatDay={(locale, date) =>
                          date.toLocaleString("en", { day: "numeric" })
                        }
                        tileDisabled={({ date, view }) =>
                          view === "month" && date.getDay() === 0
                        }
                      />
                      <FormControl mt={{ base: 20, full: 0 }}>
                        <FormLabel htmlFor={"booking_time"} fontWeight={"bold"}>
                          예약시간 선택
                        </FormLabel>
                        <Controller
                          name="booking_time"
                          control={control}
                          rules={{
                            required: "예약날짜 또는 시간을 선택해주세요.",
                          }}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              value={state.selectedTime}
                              onChange={(value) => {
                                setValue("booking_time", value);
                              }}
                            >
                              <Grid templateColumns="repeat(4, 1fr)">
                                {availableTimes &&
                                availableTimes?.length > 0 ? (
                                  availableTimes.map((timeObj, index) => (
                                    <Box key={`time${index}`}>
                                      <Button
                                        as="div"
                                        variant="outline"
                                        cursor={"pointer"}
                                        mb={3}
                                        w={{ base: "80px", md: "100px" }}
                                      >
                                        <Radio
                                          isInvalid={Boolean(
                                            errors.booking_time?.message
                                          )}
                                          value={`${formatDate(
                                            state.dates?.[0]
                                          )} ${timeObj.time}`}
                                        >
                                          {timeObj.time}
                                        </Radio>
                                      </Button>
                                    </Box>
                                  ))
                                ) : (
                                  <Button
                                    w="100%"
                                    colorScheme="red"
                                    isDisabled={true}
                                  >
                                    예약 완료
                                  </Button>
                                )}
                              </Grid>
                            </RadioGroup>
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Box mt={10}>
                      <FormLabel fontWeight={"bold"}>
                        유저와 반려견 선택
                      </FormLabel>
                      <UsersPetsList
                        setSelectedUserPet={setSelectedUserPet}
                        isHostUserPk={isHostUserPk}
                      />
                    </Box>
                    <Box mt={10}>
                      <BeautyCustomTabs
                        control={control}
                        errors={errors}
                        reset={reset}
                        getValues={getValues}
                        setValue={setValue}
                      />
                    </Box>
                    <FormRadioGroup
                      name={"degree_of_tangles"}
                      control={control}
                      label={DEGRRE_OF_TANGLES_LABEL}
                      options={DEGRRE_OF_TANGLES_OPTIONS}
                      errors={errors}
                      defaultValue="약간"
                      FormMtProps={{ mt: 10 }}
                    />
                    <FormRadioGroup
                      name={"condition"}
                      control={control}
                      label={CONDITION_LABEL}
                      options={CONDITION_OPTIONS}
                      errors={errors}
                      defaultValue="중간"
                      FormMtProps={{ mt: 10 }}
                    />
                    <Text
                      color="red.500"
                      textAlign={"center"}
                      fontSize="sm"
                      mt={10}
                    >
                      {errors.booking_time?.message}
                    </Text>
                    <Text
                      color="red.500"
                      textAlign={"center"}
                      fontSize="sm"
                      mt={3}
                    >
                      {
                        (Object.values(errors || {}) as FieldError[]).filter(
                          (error) => error.message
                        )[0]?.message
                      }
                    </Text>
                  </Container>
                </VStack>
              </Box>
            </ProtectedPage>
          </HStack>
          <ButtonGroup w="100%" py={"7"}>
            <Flex w="100%" justifyContent="space-between">
              <Button
                colorScheme="teal"
                variant="solid"
                w="50%"
                mr={3}
                size={"lg"}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
              <Button
                w="50%"
                isLoading={mutation.isLoading}
                colorScheme="red"
                variant="solid"
                size={"lg"}
                type="submit"
              >
                미용 예약 등록
              </Button>
            </Flex>
          </ButtonGroup>

          <Stack direction="row" alignItems="center" justifyContent={"center"}>
            <PiDogThin />
            <Text>kongsam booking create</Text>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
